<template>
    <div>
        <v-row>
            <v-col
                class="white"
            >
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('silo_alert_kpi.title') }}
                    </p>
                </v-subheader>

                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('report.kpis'),
                                  disabled: false,
                                  href: '/#/dashboard',
                                },
                                {
                                  text: $t('silo_alert_kpi.title'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                             ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row  >
            <v-col cols="2">
                <v-autocomplete
                    id="logistic_area"
                    v-model="logistic_areas_choices"
                    :clearable="true"
                    :items="orderedAreas"
                    :label="$t('tour.logistic_areas')"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="logistic_area"
                    prepend-icon="mdi-target"
                >
                    <template v-slot:selection="{item, index}">
                        <v-chip v-if="index <= 0">
                            {{item.code}}
                        </v-chip>
                        <span v-if="index === 1">
                            (+ {{logistic_areas_choices.length - 1}})
                        </span>
                    </template>
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="2">
                <VueCtkDateTimePicker
                    id="start_date"
                    v-model="delivery_dates"
                    :button-color="'#e3672b'"
                    :color="'#e3672b'"
                    :custom-shortcuts="customShortcuts"
                    :format="date_format"
                    :formatted="'ll'"
                    :hint="$t('silo_alert_kpi.last_delivery_range')"
                    :label="$t('silo_alert_kpi.last_delivery_range')"
                    :locale="lang"
                    :range="true"
                />
            </v-col>
            <v-col cols="2">
                <v-autocomplete
                    id="transporter"
                    v-model="transporters_choices"
                    :clearable="true"
                    :filter="filterTransporters"
                    :items="transporters"
                    :label="$t('dashboard.transporters')"
                    :search-input.sync="searchTransportersInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="transporter"
                    prepend-icon="mdi-truck"
                    @change="searchTransportersInput=''"
                >
                </v-autocomplete>
            </v-col>
            <v-col cols="2">
                <v-autocomplete
                    id="fleets"
                    v-model="fleet_choices"
                    :clearable="true"
                    :items="fleets"
                    :label="$t('dashboard.fleets')"
                    :search-input.sync="searchFleetsInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="fleets"
                    prepend-icon="mdi-truck"
                    @change="searchFleetsInput=''"
                >
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="2" >
                <v-autocomplete
                    id="conditioning"
                    v-model="conditioning__choices"
                    :clearable="true"
                    :items="conditionings"
                    :label="$t('dashboard.conditioning')"
                    :search-input.sync="searchConditioningsInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="transporter"
                    prepend-icon="mdi-truck"
                    @change="searchConditioningsInput=''"
                >
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <v-autocomplete
                    id="security_levels"
                    v-model="available_security_level_choices"
                    :clearable="true"
                    :items="available_security_levels"
                    :label="$t('silo.security_level')"
                    item-text="name"
                    item-value="id"
                    multiple
                    name="security_levels"
                    prepend-icon="mdi-target">
                        <template slot="item" slot-scope="data">
                        {{ data.item.name }}
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="3">
                <v-autocomplete
                    id="action_types"
                    v-model="action_types_choice"
                    :clearable="true"
                    :items="action_types"
                    :label="$t('silo_alert_kpi.last_action')"
                    item-text="description"
                    item-value="id"
                    multiple
                    name="action_types"
                    prepend-icon="mdi-target">
                </v-autocomplete>
            </v-col>
            <v-col cols="3">
                <VueCtkDateTimePicker
                    v-model="last_delivery_dates"
                    :button-color="'#e3672b'"
                    :color="'#e3672b'"
                    :custom-shortcuts="customShortcuts"
                    :format="date_format"
                    :formatted="'ll'"
                    :hint="$t('silo_alert_kpi.last_action_date_range')"
                    :label="$t('silo_alert_kpi.last_action_date_range')"
                    :locale="lang"
                    :range="true"
                />
            </v-col>
            <v-col cols="3">
                <v-btn color="primary"  @click="applyFilter">{{ $t('tour.apply') }}</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-data-table
                    :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
                    :headers="headers"
                    :items="alerts"
                    :loading="loading"
                    :loading-text="$t('global.loading_text')"
                    :no-data-text="$t('global.no_data')"
                    :no-results-text="$t('global.no_results_text')"
                    :options.sync="options"
                    :server-items-length="total"
                >
                    <template v-slot:item.ref_ext="{ item }">
                        <span v-for="(company, index) in item.site.companies" :key="item.id+company.code">
                            {{ company.code }}
                            <span v-if="(index + 1) < item.site.companies.length ">, </span>
                        </span>
                    </template>
                    <template v-slot:item.last_delivery_datetime="{ item }">
                        {{ item.last_delivery_datetime | moment('L LT') }}
                    </template>
                    <template v-slot:item.user="{ item }">
                <span v-if="item.histories.length > 0  && item.histories[item.histories.length - 1].user">
                    {{ item.histories[item.histories.length - 1].user.name || $t('alert.none') }}
                </span>
                    </template>
                    <template v-slot:item.name_site="{ item }">
                        {{ item.site.name || $t('alert.none') }}
                    </template>
                    <template v-slot:item.code_silo="{ item }">
                        {{ item.code || $t('alert.none') }}
                    </template>
                    <template v-slot:item.security_level="{ item }">
                        <img :src="'images/' + getSecurityLevelImage(item.security_level.color)">
                    </template>
                    <template v-slot:item.risks="{ item }">
                        <span v-for="(risk, index) in item.risks">
                    <span v-if="index !== Object.keys(item.risks).length - 1">
                        {{ risk.name }},
                    </span>
                    <span v-else>
                        {{ risk.name }}
                    </span>
                </span>
                    </template>
                    <template v-slot:item.comments="{ item }">
                        <span v-if="item.histories.length > 0">
                            {{ item.histories[item.histories.length - 1].comment || $t('alert.none') }}
                        </span>
                    </template>

                    <template v-slot:item.last_action="{ item }">
                        <span v-if="item.description">
                            {{item.description}}
                        </span>
                    </template>
                    <template v-slot:item.last_action_date="{ item }">
                        <span v-if="item.date_action">
                            {{item.date_action | moment('L LT')  }}
                        </span>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-icon
                            :title="$t('alert.go_to_add_action')"
                            class="mr-2"
                            small
                            @click="goToAddAction(item)"
                        >
                            mdi-file-plus
                        </v-icon>
                        <v-icon
                            :title="$t('alert.go_to_silo')"
                            class="mr-2"
                            small
                            @click="goToSilo(item.id)"
                        >
                            mdi-arrow-right
                        </v-icon>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import {
    ACTION_TYPES,
    ACTIONS_SILOS_DATATABLE,
    AREA_TRANSPORTERS,
    SECURITY_LEVELS,
    USER_LOGISTIC_AREAS,
    VEHICLE_CONDITIONNINGS,
    VEHICLE_FLEETS
} from '../../api'
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";

export default {
    name:'SiloReport',
    data(){
        return{
            lang,
            action_type:"last_action",

            last_delivery_dates: {start: '', end:''},
            delivery_dates: {start: '', end:''},
            date_format: 'Y-MM-DD H:mm',

            available_security_levels:[],
            available_security_level_choices:[],

            logistic_areas_choices : [],
            logistic_areas : [],

            action_types: [],
            action_types_choice:[],

            transporters_choices : [],
            transporters : [],
            searchTransportersInput:"",

            alerts :[],
            total: 0,

            loading:false,
            toggleAreas :null,

            searchAreasInput:'',

            headers:[
                {text: this.$t('silo_alert_kpi.ref_ext'), value: 'ref_ext', sortable: false},
                {text: this.$t('alert.name_site'), value: 'name_site', sortable: false},
                {text: this.$t('alert.silo'), value: 'code_silo', sortable: false},
                {text: this.$t('alert.security'), value: 'security_level', sortable: false},
                {text: this.$t('alert.risks'), value: 'risks', sortable: false},
                {text: this.$t('alert.comment'), value: 'comments', sortable: false},
                {text: this.$t('silo_alert_kpi.last_action'), value: 'last_action', sortable: false},
                {text: this.$t('silo_alert_kpi.last_12_months_exposition'), value: 'exposition', sortable: false},
                {text: this.$t('silo_alert_kpi.last_action_date'), value: 'last_action_date', sortable: false},
                {text: this.$t('alert.creator'), value: 'user', sortable: false},
                {text: this.$t('silo_alert_kpi.last_delivery'), value: 'last_delivery_datetime', sortable: false},
                {text: this.$t('alert.actions'), value: 'actions', sortable: false},
            ],

            customShortcuts:[
                { key: "next_week", label:  this.$t('dashboard.next_week'), value:() => {
                        return {
                            start: this.$moment().add(7, 'day').startOf('week'),
                            end : this.$moment().add(7, 'day').endOf('week')
                        }}
                },
                { key: 'tomorrow', label: this.$t('dashboard.tomorrow'), value: () =>
                    {
                        return {
                            start: this.$moment().startOf('day').add(1, "day"),
                            end:  this.$moment().endOf('day').add(1, "day")
                        }
                    }
                },
                { key: 'today', label: this.$t('dashboard.today'), value: 'day' },
                { key: 'yesterday', label:this.$t('dashboard.yesterday'), value: '-day' },
                { key: '7_last_days', label: this.$t('dashboard.7_last_days'), value: 7 },
                { key: 'last_week', label: this.$t('dashboard.last_week'), value: '-isoWeek' },
                { key: 'last_month', label: this.$t('dashboard.last_month'), value: '-month' },
                { key: '12_last_months', label: this.$t('dashboard.12_last_months'), value: () => {
                        return{
                            start: this.$moment().add(-12, "month"),
                            end: this.$moment()
                        }}
                },
            ],
            options: {page: 1, itemsPerPage: 20},


            conditioning__choices :[],
            conditionings :[],
            searchConditioningsInput :'',
            fleet_choices :[],
            fleets :[],
            searchFleetsInput :'',
        }
    },
    methods:{
        getSilosActionReport(){
            this.loading = true
            this.axios.get(ACTIONS_SILOS_DATATABLE, {
                params:{...this.filter, ...this.options}
            }).then(response => {
                this.loading = false
                this.alerts = response.data.data
                this.total = response.data.total
            })
        },
        getActionTypes(){
            this.axios.get(ACTION_TYPES).then(response => {
                this.action_types = response.data.filter(item => item.name === 'Sécurité')

            })
        },
        getSecurityLevels(){
          this.available_security_level_choices = this.$store.getters["alert/get_filters"].security_levels;
            this.axios.get(SECURITY_LEVELS).then(response => {
                this.available_security_levels = response.data.filter(security_level => {
                    return security_level.level >= -1;
                });
            })
        },
        applyFilter(){
            this.options.page = 1
            this.getSilosActionReport()
            this.setFilter(this.filter)
        },
        isSelectAllAreasAvailable() {
            return this.searchAreasInput === '' || this.searchAreasInput === null;
        },
        async getLogisticAreas() {
            try {
                let success = await this.axios.get(USER_LOGISTIC_AREAS)
                this.logistic_areas = _.orderBy(success.data, ['name']);
                if (this.$auth.user().reference_logistic_areas.length > 0) {
                    this.logistic_areas_choices = _.map(this.$auth.user().reference_logistic_areas, 'id')
                } else if (this.logistic_areas.length === 1) {
                    this.logistic_areas_choices = [this.logistic_areas[0].id]
                }
                if (this.get_filters().logistic_areas_choices.length > 0){
                    this.logistic_areas_choices =  this.get_filters().logistic_areas_choices
                }
            }catch (e){
                this.showSnackbar('error', this.$t('global.error'));
            }
        },
        iconAreas() {
            if (this.allAreas) return 'mdi-close-box'
            if (this.someAreas) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        filterAreas(item, queryText) {
            var textOne = "";
            var textTwo = "";
            var searchText = "";
            if (item.code && item.name) {
                textOne = item.name.toLowerCase();
                textTwo = item.code.toLowerCase();
                searchText = queryText.toLowerCase();
            }

            return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
        },
        allAreas() {
            return this.logistic_areas_choices.length === this.logistic_areas.length
        },
        filterTransporters(item, queryText) {
            var text = "";
            var searchText = "";
            if (item.code) {
                text = item.code.toLowerCase();
                searchText = queryText.toLowerCase();
            }

            return text.indexOf(searchText) > -1;
        },
        getTransporters() {
            this.axios.get(
                AREA_TRANSPORTERS,
                {
                    params: {
                        logistic_areas: this.logistic_areas_choices
                    },
                }
            ).then((success) => {
                this.transporters = _.orderBy(success.data, ['code']);
                this.transporters_choices = this.get_filters().transporters_choices
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            })
        },
        getSecurityLevelImage(securityLevelColor) {
            switch (securityLevelColor) {
                case 'Green':
                    return 'silo_vert.svg';
                case 'Orange':
                    return 'silo_orange.svg';
                case 'Red':
                    return 'silo_rouge.svg';
                case 'Black':
                    return 'silo_noir.svg';
                default:
                    return 'silo_neutre.svg';
            }
        },
        goToSilo(siloId) {
            window.open((this.$router.resolve({name: 'silo', params: {siloId}})).href, '_blank');

        },
        /**
         * Go to add action
         *
         * @param {object} alert
         */
        goToAddAction(alert) {
            window.open((this.$router.resolve({
                name: 'site',
                params: {siteId: alert.site_id},
                query: {
                    tab: 'tab-actions',
                    showDialog: true,
                    siloId: alert.id,
                    actionTypeCode: 'transmitted.info',
                    actionNotificationSent: true
                }
            })).href, '_blank');
        },
        ...mapGetters({
            get_filters: "alert/get_filters"
        }),
        ...mapActions({
            setFilter:'alert/setFilter'
        }),
        async getFleets(){
            this.fleets = _.sortBy((await axios.get(VEHICLE_FLEETS)).data, ['name'])
        },
        async getConditionings(){
            this.conditionings = _.sortBy((await axios.get(VEHICLE_CONDITIONNINGS)).data, ['name'])
        }
    },
    async mounted() {
            this.delivery_dates = {
                start:this.$moment().add('-12', 'month').format('YYYY-MM-DD'),
                end:this.$moment().format('YYYY-MM-DD')}
        if (this.$route.params.action_id){
            this.action_types_choice.push(this.$route.params.action_id)
        }
        if (this.$route.params.action === "silos"){
            this.delivery_dates = {
                start:this.$store.getters["alert/get_filters"].dates[0],
                end:this.$store.getters["alert/get_filters"].dates[1]}
        }
        this.conditioning__choices = this.$store.getters['alert/get_filters'].conditioning__choices
        this.fleet_choices = this.$store.getters['alert/get_filters'].fleet_choices
        this.available_security_level_choices = this.$store.getters["alert/get_filters"].security_levels
        this.logistic_areas_choices = this.$store.getters["alert/get_filters"].logistic_areas_choices
        this.transporters_choices = this.$store.getters["alert/get_filters"].transporters_choices
        this.getActionTypes()
        await this.getFleets()
        await this.getConditionings()
        this.getSecurityLevels()
        await this.getLogisticAreas()
        this.getTransporters()
        this.getSilosActionReport()


    },
    computed:{
        orderedAreas() {
            return _.orderBy(this.logistic_areas, 'code')
        },
        filter(){
            let last_delivery_dates = null
            if (this.last_delivery_dates.start !== ''){
                last_delivery_dates = [this.last_delivery_dates.start, this.last_delivery_dates.end]
            }
            return {
                last_delivery_dates,
                logistic_areas_choices: this.logistic_areas_choices,
                transporters_choices: this.transporters_choices,
                security_levels: this.available_security_level_choices,
                last_actions: this.action_types_choice,
                dates : [this.delivery_dates.start, this.delivery_dates.end],
                fleet_choices: this.fleet_choices,
                conditioning__choices: this.conditioning__choices,
            }
        },
    },
    watch:{
        toggleAreas() {
            this.$nextTick(() => {
                if (this.allAreas) {

                } else {
                    this.logistic_areas.forEach(element => {
                        if (!this.logistic_areas_choices.includes(element.id)) {
                            this.logistic_areas_choices.push(element.id);
                        }
                    });
                }
            })
        },
        options(){
            this.getSilosActionReport()
        }
    }

}
</script>
