<template>
    <div>
        <v-data-table
            :no-data-text="$t('global.no_data')"
            :loading-text="$t('global.loading_text')"
            :no-results-text="$t('global.no_results_text')"
            :headers="headers"
            :items="delivery_report_states"
            :loading="loading"
            class="elevation-1"
            :items-per-page="10"
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-dialog v-model="dialog" max-width="500px" @input="$validator.reset()">
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" dark class="mb-2" v-on="on">{{ $t('repository.new_item') }}</v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-form>
                                    <v-text-field
                                        :label="$t('repository.name')"
                                        name="name"
                                        prepend-icon="mdi-textbox"
                                        v-validate="'required|max:255'"
                                        type="text"
                                        :error-messages="errors.collect('name')"
                                        data-vv-name="name"
                                        v-model="editedItem.name"
                                    ></v-text-field>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    {{ $t('repository.cancel') }}
                                </v-btn>
                                <v-btn @click="save" :disabled="submit_button_is_disabled">
                                    {{ $t('repository.save') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    :title="$t('repository.update')"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <!-- Retrait à la demande de AVRIL 17/06/2020
               <v-icon
                   small
                   :title="$t('repository.delete')"
                   @click="deleteItem(item)"
               >
                   mdi-delete
               </v-icon>
               -->
           </template>
       </v-data-table>
       <v-dialog v-model="dialogDelete" max-width="290">
           <v-card elevation="0">
               <v-card-title class="headline">{{ $t('repository.sure') }}</v-card-title>
               <v-card-text>{{ $t('repository.irremediable') }}</v-card-text>
               <v-card-actions>
                   <v-spacer></v-spacer>
                   <v-btn color="darken-1" text @click="dialogDelete = false">
                       {{ $t('repository.cancel') }}
                   </v-btn>
                   <v-btn color="warning darken-1" text @click="deleteDeliveryReportState()">
                       {{ $t('repository.delete') }}
                   </v-btn>
               </v-card-actions>
           </v-card>
       </v-dialog>
   </div>
</template>

<script>
   import {DELIVERY_REPORT_STATES, DELIVERY_REPORT_STATE} from "../../api";

   export default {
       name: "delivery_report_states",
       data() {
           return {
               headers: [
                   {text: this.$t('repository.name'), value: 'name', sortable: true},
                   {text: this.$t('repository.actions'), value: 'action', sortable: false},
               ],
               loading: false,
               dialog: false,
               delivery_report_states: [],
               editedIndex: -1,
               editedItem: {
                   name: '',
               },
               defaultItem: {
                   name: '',
               },
               submit_button_is_disabled: false,
               itemToDelete: {},
               dialogDelete: false,
           }
       },
       mounted() {
           this.getDeliveryReportStates();
       },
       computed: {
           formTitle() {
               return this.editedIndex === -1 ? this.$t('repository.new_item') : this.$t('repository.edit_item')
           },
       },
       methods: {
           /**
            * Reset snackbar
            */
           resetSnackbar() {
               this.snackbar_type = null;
               this.snackbar_text = null;
               this.snackbar = false;
           },
           /**
            * Show snackbar
            *
            * @param {string} type
            * @param {string} text
            */
           showSnackbar(type, text) {
               this.snackbar_type = type;
               this.snackbar_text = text;
               this.snackbar = true;
           },
           /**
            * Get delivery_report_states
            */
           getDeliveryReportStates() {
               this.loading = true;

               this.axios.get(DELIVERY_REPORT_STATES).then(success => {
                   this.delivery_report_states = success.data;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               }).finally(() => {
                   this.loading = false;
               })
           },
           save() {
               this.submit_button_is_disabled = true;

               this.$validator.validateAll().then((result) => {
                   if (result) {
                       if (this.editedIndex > -1) {
                           this.updateItem(this.editedItem);
                       } else {
                           this.createItem(this.editedItem);
                       }
                   } else {
                       this.submit_button_is_disabled = false;
                   }
               });
           },
           /**
            * Update delivery report state
            *
            * @param {object}  delivery_report_state
            */
           updateItem(delivery_report_state) {
               this.axios.patch(
                   this.replacePathParams(DELIVERY_REPORT_STATE, {delivery_report_state: delivery_report_state.id}),
                   delivery_report_state
               ).then((success) => {
                   Object.assign(this.delivery_report_states[this.editedIndex], success.data);
                   this.showSnackbar('success', this.$t('repository.updated'));
                   this.close()
               }).catch((error) => {
                   if (error.response.status === 422) {
                       this.showSnackbar('warning', this.parseLaravelError(error));
                   } else {
                       this.showSnackbar('error', this.$t('global.error'));
                   }
               }).finally(() => {
                   this.submit_button_is_disabled = false;
               })
           },
           /**
            * Show edit dialog
            *
            * @param {object} delivery_report_state
            */
           editItem(delivery_report_state) {
               this.editedIndex = this.delivery_report_states.indexOf(delivery_report_state);
               this.editedItem = Object.assign({}, delivery_report_state);
               this.dialog = true;
           },
           /**
            * Add delivery report state
            *
            * @param {object} delivery_report_state
            */
           createItem(delivery_report_state) {
               this.axios.post(
                   DELIVERY_REPORT_STATES,
                   delivery_report_state
               ).then((success) => {
                   this.delivery_report_states.push(success.data);
                   this.showSnackbar('success', this.$t('repository.created'));
                   this.close()
               }).catch((error) => {
                   if (error.response.status === 422) {
                       this.showSnackbar('warning', this.parseLaravelError(error));
                   } else {
                       this.showSnackbar('error', this.$t('global.error'));
                   }
               }).finally(() => {
                   this.submit_button_is_disabled = false;
               })
           },
           /**
            * Hide add/ edit item modal
            */
           close() {
               this.submit_button_is_disabled = false;
               this.dialog = false;
               setTimeout(() => {
                   this.editedItem = Object.assign({}, this.defaultItem);
                   this.editedIndex = -1
               }, 300)
           },
           /**
            * Show delete modal
            *
            * @param {object} delivery_report_state
            */
           deleteItem(delivery_report_state) {
               this.itemToDelete = delivery_report_state;
               this.dialogDelete = true;
           },
           /**
            * Remove delivery report state from database
            */
           deleteDeliveryReportState() {
               this.axios.delete(
                   this.replacePathParams(DELIVERY_REPORT_STATE, {delivery_report_state: this.itemToDelete.id})
               ).then(() => {
                   this.delivery_report_states.splice(this.delivery_report_states.indexOf(this.itemToDelete), 1);
                   this.showSnackbar('success', this.$t('repository.deleted'));
                   this.dialogDelete = false;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               });
           },
       },
       watch: {
           async dialog(val) {
               val || this.close();
           },
       },
   };
</script>
