import Vue from 'vue';
import VeeValidate, {Validator} from 'vee-validate';
const lang = document.documentElement.lang.substr(0, 2);

function loadLocale(lang) {
    return import(`vee-validate/dist/locale/${lang}.js`).then(locale => {
        Validator.localize(lang, locale);
    });
}
Vue.use(VeeValidate, {fieldsBagName: 'formFields'});
loadLocale(lang);

