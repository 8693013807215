<template>
    <div class="exchanges exchanges-module">
        <v-row>
            <v-col
                class="white"
            >
                <!--
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('navigation.exchanges') }}
                    </p>
                </v-subheader>
                -->
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('navigation.administration'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                                {
                                  text: $t('navigation.exchanges'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                              ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-select
                    :label="$t('exchange.type')"
                    name="type"
                    outlined
                    v-model="type"
                    :items="types"
                    item-text="label"
                    item-value="value"
                />
                <v-row>
                    <v-col cols="12">
                        <p class="subtitle-2 mb-0">
                            {{ $t('exchange.date_range') }}
                            <v-icon
                                @click="clearRangeDate"
                                :title="$t('exchange.clear_range_date')"
                            >
                                mdi-refresh
                            </v-icon>
                        </p>
                    </v-col>
                    <v-col cols="6">
                        <VueCtkDateTimePicker
                            v-model="start_datetime"
                            :locale="lang"
                            :max-date="end_datetime?end_datetime:max_datetime"
                            :format="date_format"
                            :hint="$t('exchange.start_date')"
                            :label="$t('exchange.start_date')"
                            :color="'#e3672b'"
                            :button-color="'#e3672b'"
                            id="start_date"
                            @validate="submitRangeDate()"
                        />
                    </v-col>
                    <v-col cols="6">
                        <VueCtkDateTimePicker
                            v-model="end_datetime"
                            :locale="lang"
                            :format="date_format"
                            :max-date="max_datetime"
                            :min-date="start_datetime"
                            :hint="$t('exchange.end_date')"
                            :label="$t('exchange.end_date')"
                            :color="'#e3672b'"
                            :button-color="'#e3672b'"
                            id="end_date"
                            @validate="submitRangeDate()"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <v-data-table
                    :no-data-text="$t('global.no_data')"
                    :loading-text="$t('global.loading_text')"
                    :no-results-text="$t('global.no_results_text')"
                    :headers="headers"
                    :items="exchanges"
                    :loading="loading"
                    class="elevation-1"
                    :server-items-length="totalExchanges"
                    @update:options="changeOptions"
                    :options.sync="options"
                    :footer-props="{
                        'items-per-page-options': rowsPerPageItems,
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
                >
                    <template v-slot:top>
                        <v-toolbar flat color="white">
                            <v-spacer></v-spacer>
                            <v-btn color="primary" dark class="mb-2" @click="getLogs()">
                                {{ $t('alert.refresh') }}
                            </v-btn>
                        </v-toolbar>
                    </template>
                    <template v-slot:item.finished_at="{ item }">
                        {{ item.finished_at | moment('L LTS') }}
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        {{ item.created_at | moment('L LTS') }}
                    </template>
                    <template v-slot:item.payload="{ item }">
                        {{
                            (item.payload.length > 1000) ? item.payload.substr(0, 1000 - 1) + '&hellip;' : item.payload
                        }}
                    </template>
                    <template v-slot:item.return="{ item }">
                        {{
                            (item.return.length > 1000) ? item.return.substr(0, 1000 - 1) + '&hellip;' : item.return
                        }}
                    </template>
                    <template v-slot:item.response="{ item }">
                        <v-chip :color="getColor(item.response)" dark>{{ item.response }}</v-chip>
                    </template>
                    <template v-slot:item.token="{ item }">
                        <div v-if="item.token.length > 0">
                            <ul v-for="token in JSON.parse(item.token)">
                                <li>{{token}}</li>
                            </ul>
                        </div>
                    </template>
                    <template v-slot:item.is_sended="{ item }">
                        <v-chip color="green" dark v-if="item.is_sended === 'success'">
                            <v-icon>mdi-check</v-icon>
                        </v-chip>
                        <v-chip color="red" dark v-else>
                            <v-icon>mdi-close</v-icon>
                        </v-chip>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackbar"
            top
            :color="snackbar_type"
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import {EXCHANGES} from "../api";

export default {
    name: "exchange",
    data() {
        return {
            exchanges: [],
            totalExchanges: 0,
            loading: false,
            page: 1,
            snackbar: false,
            snackbar_text: '',
            snackbar_type: null,
            type: 'api',
            types: [
                {
                    label: 'API Sandra',
                    value: 'api'
                },
                {
                    label: 'API Lora',
                    value: 'sandra'
                },
                {
                    label: this.$t('exchange.tasks'),
                    value: 'task'
                },
                /*
                {
                    label: this.$t('exchange.notification'),
                    value: 'notification'
                }
                */
            ],
            rowsPerPageItems: [50, 100, 200, 500, 1000],
            options: {page: 1, itemsPerPage: 50},
            max_datetime: this.$moment().format('YYYY-MM-DD H:mm'),
            start_datetime: this.$moment().format('YYYY-MM-DD 00:00'),
            end_datetime: null,
            lang,
            date_format: 'Y-MM-DD H:mm',
        }
    },
    mounted() {
        this.getLogs();
    },
    computed: {
        headers() {
            if (this.type === 'api') {
                return [
                    {text: this.$t('exchange.created_at'), value: 'created_at', sortable: false},
                    {text: this.$t('exchange.method'), value: 'method', sortable: false},
                    {text: this.$t('exchange.url'), value: 'url', sortable: false},
                    {text: this.$t('exchange.payload'), value: 'payload', sortable: false},
                    {text: this.$t('exchange.response'), value: 'response', sortable: false},
                    {text: this.$t('exchange.truck'), value: 'trucks', sortable: false},
                    {text: this.$t('exchange.return'), value: 'return', sortable: false},
                    {text: this.$t('exchange.duration'), value: 'duration', sortable: false},
                ];
            }

            if (this.type === 'sandra') {
                return [
                    {text: this.$t('exchange.created_at'), value: 'created_at', sortable: false},
                    {text: this.$t('exchange.method'), value: 'method', sortable: false},
                    {text: this.$t('exchange.url'), value: 'url', sortable: false},
                    {text: this.$t('exchange.payload'), value: 'payload', sortable: false},
                    {text: this.$t('exchange.response'), value: 'response', sortable: false},
                    {text: this.$t('exchange.return'), value: 'return', sortable: false},
                    {text: this.$t('exchange.finished_at'), value: 'finished_at', sortable: false},
                ];
            }

            if (this.type === 'task') {
                return [
                    {text: this.$t('exchange.created_at'), value: 'created_at', sortable: false},
                    {text: this.$t('exchange.command'), value: 'command', sortable: false},
                    {text: this.$t('exchange.payload'), value: 'payload', sortable: false},
                    {text: this.$t('exchange.response'), value: 'response', sortable: false},
                    {text: this.$t('exchange.finished_at'), value: 'finished_at', sortable: false},
                ];
            }

            /*
            if (this.type === 'notification') {
                return [
                    {text: this.$t('exchange.created_at'), value: 'created_at', sortable: false},
                    {text: this.$t('exchange.truck'), value: 'code', sortable: false},
                    {text: this.$t('exchange.tokens'), value: 'token', sortable: false},
                    {text: this.$t('exchange.step_id'), value: 'step_id', sortable: false},
                    {text: this.$t('exchange.type'), value: 'type', sortable: false},
                    {text: this.$t('exchange.is_sended'), value: 'is_sended', sortable: false},
                ];
            }
            */

            return [];
        }
    },
    methods: {
        /**
         * Reset snackbar
         */
        resetSnackbar() {
            this.snackbar_type = null;
            this.snackbar_text = null;
            this.snackbar = false;
        },
        /**
         * Show snackbar
         *
         * @param {string} type
         * @param {string} text
         */
        showSnackbar(type, text) {
            this.snackbar_type = type;
            this.snackbar_text = text;
            this.snackbar = true;
        },
        /**
         * Get logs from API
         */
        getLogs() {
            this.loading = true;

            const {sortBy, sortDesc, page, itemsPerPage} = this.options;

            this.axios.get(
                EXCHANGES,
                {
                    params: {
                        page: this.options.page,
                        per_page: this.options.itemsPerPage,
                        type: this.type,
                        start_datetime: this.start_datetime,
                        end_datetime: this.end_datetime,
                    }
                }
            ).then((success) => {
                this.exchanges = success.data.data;
                this.totalExchanges = success.data.total;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading = false;
            })
        },
        /**
         * Get color
         *
         * @param response
         * @returns {string}
         */
        getColor(response) {
            if (response < 400) return 'success';
            else if (response >= 400 && response < 500) return 'warning';
            else return 'danger';
        },
        /**
         * Change options
         *
         * @param {object} options
         */
        changeOptions(options) {
            if (this.options.itemsPerPage !== options.itemsPerPage || this.options.page !== options.page) {
                this.options.itemsPerPage = options.itemsPerPage;
                this.options.page = options.page;

                this.getLogs();
            }
        },
        submitRangeDate() {
            this.getLogs();
        },
        clearRangeDate() {
            this.start_datetime = null;
            this.end_datetime = null;
            this.getLogs();
        }
    },
    watch: {
        type() {
            this.getLogs();
        },
    }
}
</script>

<style lang="scss">

.exchanges {
    td.text-start {
        max-width: 500px;
        overflow-wrap: break-word;
    }
}

.exchanges-module {
    .v-breadcrumbs li:last-child a {
        font-size: 1.25rem !important;
        color: rgba(0, 0, 0, .6) !important;
        font-weight: 500;
    }

    .v-list--dense .v-list-item {
        .v-list-item__title,
        .v-list-item__subtitle {
            font-size: 0.9rem;
        }
    }
}

</style>
