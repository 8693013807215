<template>
    <div class="dashboard-module">
        <v-row>
            <v-col
                class="white"
            >
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('navigation.dashboard'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                              ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2"
                v-show="logistic_areas.length > 1"
            >
                <v-autocomplete
                    id="logistic_area"
                    v-model="logistic_areas_choices"
                    :clearable="true"
                    :filter="filterAreas"
                    :items="orderedAreas"
                    :label="$t('tour.logistic_areas')"
                    :search-input.sync="searchAreasInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="logistic_area"
                    prepend-icon="mdi-target"
                >
                    <v-list
                        v-if="isSelectAllAreasAvailable"
                        slot="prepend-item"
                        class="pa-0"
                    >
                        <v-list-item-group v-model="toggleAreas" class="d-flex align-center pa-0">
                            <v-list-item>
                                <v-list-item-icon class="mr-2">
                                    <v-icon :color="logistic_areas_choices.length > 0 ? 'primary' : ''">
                                        {{ iconAreas }}
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('global.select_all') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                    <v-divider
                        slot="prepend-item"
                    ></v-divider>
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                    <template v-slot:selection="{item, index}">
                        <v-chip v-if="index <= 0">
                            {{item.code}}
                        </v-chip>
                        <span v-if="index === 1">
                            (+ {{logistic_areas_choices.length - 1}})
                        </span>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="2">
                <VueCtkDateTimePicker
                    id="start_date"
                    v-model="dates"
                    :button-color="'#e3672b'"
                    :color="'#e3672b'"
                    :custom-shortcuts="customShortcuts"
                    :format="date_format"
                    :formatted="'ll'"
                    :hint="$t('exchange.start_date')"
                    :label="$t('exchange.start_date')"
                    :locale="lang"
                    :range="true"
                />
            </v-col>
            <v-col cols="2">
                <v-autocomplete
                    id="transporter"
                    v-model="transporters_choices"
                    :clearable="true"
                    :filter="filterTransporters"
                    :items="transporters"
                    :label="$t('dashboard.transporters')"
                    :search-input.sync="searchTransportersInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="transporter"
                    prepend-icon="mdi-truck"
                    @change="searchTransportersInput=''"
                >
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="2">
                <v-autocomplete
                    id="fleets"
                    v-model="fleet_choices"
                    :clearable="true"
                    :items="fleets"
                    :label="$t('dashboard.fleets')"
                    :search-input.sync="searchFleetsInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="fleets"
                    prepend-icon="mdi-truck"
                    @change="searchFleetsInput=''"
                >
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="2" >
                <v-autocomplete
                    id="conditioning"
                    v-model="conditioning__choices"
                    :clearable="true"
                    :items="conditionings"
                    :label="$t('dashboard.conditioning')"
                    :search-input.sync="searchConditioningsInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="transporter"
                    prepend-icon="mdi-truck"
                    @change="searchConditioningsInput=''"
                >
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="2">
                <v-btn color="primary" style="cursor:pointer;" @click="applyFilter">{{ $t('tour.apply') }}</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="mx-auto" cols="3" style="cursor:pointer;">
                <span class="title">
                    {{ $t("dashboard.report_percent") }}
                </span>
                <div
                    class="chart-container"
                    v-if="first_loading"
                >
                    <pie
                        v-if="Object.entries(chart_data_percentage_of_report).length > 0"
                        :chartData="chart_data_percentage_of_report"
                        :options="reportChartOptions"
                    >
                    </pie>
                    <v-progress-circular v-else indeterminate size="32"></v-progress-circular>
                </div>
            </v-col>
            <v-divider
                class="mx-3"
                cols="2"
                inset
                vertical
            />
            <v-col class="mx-auto" cols="3">
                <span class="title" @click="$router.push({name:'silos_stats_datable'})">
                    {{ $t("dashboard.delivery_steps_percent") }}
                </span>
                <div>
                    <v-select
                        v-model="number_exposition_inventory"
                        :items="[
                            { label: $t('dashboard.exposition'), id: 'exposition' },
                            { label: $t('dashboard.inventory'), id: 'inventory' }
                        ]"
                        item-text="label"
                        item-value="id"
                        outlined
                        @change="updateInterventionMode"
                    ></v-select>
                </div>
                <div
                    class="chart-container"
                    v-if="first_loading"
                >
                    <pie
                        v-if="Object.entries(chart_data_percentage_of_delivery_steps).length > 0"
                        :chartData="chart_data_percentage_of_delivery_steps"
                        :options="{onClick:this.handleClick}"
                    >
                    </pie>
                    <v-progress-circular v-else indeterminate size="32"></v-progress-circular>
                </div>
            </v-col>
            <v-divider
                class="mx-3"
                cols="2"
                inset
                vertical
            />
            <v-col class="mx-auto" cols="3">
                <v-card :elevation="0" :loading="driverKpiContributionLoading" class="my-2">
                    <h4 class="title">{{ $t('dashboard.contribution_driver') }}</h4>
                    <div class="d-flex align-center justify-space-around pa-2">
                        <v-icon :color="colorFromNumber(driverKpiContribution)" size="60px"
                            @click="$router.push({name:'DriverContribution'})"
                        >mdi-truck</v-icon>
                        <div :class="colorFromNumber(driverKpiContribution)+'--text'"
                             class="display-2 font-weight-black clickable"
                        @click="$router.push({name:'DriverContribution'})">{{ driverKpiContribution }}%</div>
                    </div>
                </v-card>
                <v-card :elevation="0" :loading="exploitantKpiContributionLoading" class="my-2">
                    <h4 class="title">{{ $t('dashboard.contribution_exploit') }}</h4>
                    <div class="d-flex align-center justify-space-around pa-2">
                        <v-icon :color="colorFromNumber(exploitantKpiContribution)" size="60px"
                            @click="$router.push({name:'ExploitantContribution'})">mdi-silo</v-icon>
                        <div :class="colorFromNumber(exploitantKpiContribution)+'--text'"
                             class="display-2 font-weight-black clickable"
                             @click="$router.push({name:'ExploitantContribution'})">{{exploitantKpiContribution}}%</div>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="pl-4" cols="12">
               <span class="title">
                    {{ $t("dashboard.last_actions_title") }}
                </span>
                <v-autocomplete
                    v-show="available_security_levels.length > 1"
                    id="security_levels"
                    v-model="available_security_level_choices"
                    :clearable="true"
                    :items="available_security_levels"
                    :label="$t('silo.security_level')"
                    item-text="name"
                    item-value="id"
                    multiple
                    name="security_levels"
                    prepend-icon="mdi-target"
                    style="max-width: 20%"
                    @blur="updateRequest(getActionsSilos,
                {'security_levels':available_security_level_choices})">
                    <template slot="item" slot-scope="data">
                        {{ data.item.name }}
                    </template>
                </v-autocomplete>
                <v-card :elevation="0" :loading="pgBarLoading && first_loading" class="py-0">
                    <v-row>
                        <v-col class="d-flex flex-wrap" cols="12">
                            <div v-for="row in chart_action_silos"
                                 class="px-2 caption  text-center d-flex align-center"
                            >
                                <span :style="{backgroundColor: row.color}" class="mr-1 chart-box-indicator"></span>
                                <span v-if="(Math.round((((row.count * 100) / sumActionsSilos)+ Number.EPSILON) *100 )/100) < 1">
                                    {{row.name}} : ({{(Math.round((((row.count * 100) / sumActionsSilos)+ Number.EPSILON) *100 )/100 || 0)+ '%' }})</span>
                                <span v-else>
                                    {{row.name}}: ({{(Math.round((row.count * 100) / sumActionsSilos) || 0) + '%'  }})</span>
                            </div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="d-flex" cols="12">
                            <div v-for="row in chart_action_silos"
                                 :style="{
                flexBasis: $options.filters.round((row.count * 100) / sumActionsSilos) +'%',
                backgroundColor: row.color,
                color: row.color === '#000000' ?  'white': 'black'
            }"
                                 class="py-4 px-1 text-center clickable"
                                 @click="setFilter(filter);$router.push({name:'silos_stats_datable', params:{action:'last_action',action_id:row.id}})">
                                {{ row.count }}
                            </div>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-expansion-panels
                    v-model="panel"
                    flat
                >
                    <v-expansion-panel
                        ref="panel_security"
                        class="mt-4 panel-primary"
                    >
                        <v-expansion-panel-header>
                            {{ $t('dashboard.security') }}
                            <v-chip class="ml-3 mr-1 white--text" color="black">
                                {{ number_of_security_alert_black_silo_alerts }}
                            </v-chip>
                            <v-chip class="mx-1 white--text" color="red">
                                {{ number_of_security_alert_red_silo_alerts }}
                            </v-chip>
                            <v-chip class="mx-1 white--text" color="orange">
                                {{ number_of_security_alert_orange_silo_alerts }}
                            </v-chip>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content
                            ref="panel_security_content">
                            <v-row>
                                <v-col cols="12">
                                    <security-alert-silos></security-alert-silos>
                                    <v-row no-gutters>
                                        <v-col class="d-flex flex-row-reverse" cols="12">
                                            <v-btn color="primary" small text @click="goToSilos">
                                                {{ $t('dashboard.see_all') }}
                                                <v-icon>mdi-arrow-right</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel
                        ref="panel_execution_monitoring"
                        class="mt-4 panel-secondary"
                    >
                        <v-expansion-panel-header>
                            {{ $t('dashboard.execution_monitoring') }}
                            <v-chip class="ml-3 mx-1 white--text" color="red">
                                {{ number_of_report_alerts }}
                            </v-chip>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12">
                                    <span class="title">
                                        {{ $t("dashboard.reports_not_empty") }}
                                    </span>
                                    <report-alert-datatable></report-alert-datatable>
                                </v-col>
                                <v-col class="d-flex flex-row-reverse" cols="12">
                                    <v-btn color="primary" small text @click="goToReports">
                                        {{ $t('dashboard.see_all') }}
                                        <v-icon>mdi-arrow-right</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel
                        ref="panel_administration"
                        class="mt-4 panel-accent"
                    >
                        <v-expansion-panel-header>
                            {{ $t('dashboard.administration') }}
                            <v-chip class="ml-3 mr-1 white--text" color="red">
                                {{ number_of_duplicated_site_alerts }}
                            </v-chip>
                            <v-chip class="mx-1 white--text" color="red">
                                {{ number_of_gps_alerts }}
                            </v-chip>
                            <v-chip class="mx-1 white--text" color="orange">
                                {{ number_of_site_update_request_alerts }}
                            </v-chip>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                <v-col cols="12">
                                    <span class="title">
                                        {{ $t("dashboard.new_unknown_sites") }}
                                    </span>
                                    <site-alert-datatable></site-alert-datatable>
                                </v-col>
                                <v-col class="d-flex flex-row-reverse" cols="12">
                                    <v-btn color="primary" small text @click="goToSite">
                                        {{ $t('dashboard.see_all') }}
                                        <v-icon>mdi-arrow-right</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <span class="title">
                                        {{ $t("dashboard.new_gps_points_to_locked") }}
                                    </span>
                                    <gps-alert-datatable></gps-alert-datatable>
                                </v-col>
                                <v-col class="d-flex flex-row-reverse" cols="12">
                                    <v-btn color="primary" small text @click="goToGps">
                                        {{ $t('dashboard.see_all') }}
                                        <v-icon>mdi-arrow-right</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="12">
                                    <span class="title">
                                        {{ $t("dashboard.new_client_informations") }}
                                    </span>
                                    <site-update-alert-datatable></site-update-alert-datatable>
                                </v-col>
                                <v-col class="d-flex flex-row-reverse" cols="12">
                                    <v-btn color="primary" small text @click="goToSiteUpdate">
                                        {{ $t('dashboard.see_all') }}
                                        <v-icon>mdi-arrow-right</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
        </v-row>
    </div>
</template>

<style lang="scss">
.dashboard-badge {
    top: -15px;
}

.chart-container {
    max-width: 380px;
    margin: auto;
}

.v-card {
    .v-card-bg-icon {
        position: absolute;

        width: 100%;
        height: 100%;

        z-index: 1;

        .v-icon {
            position: relative;

            font-size: 7rem;

            top: calc(95% - 7rem);
            left: 5%;

            color: hsla(0, 0, 0, 0.3);
        }
    }
}

.panel-primary {
    border: 1px solid #e3672b;

    .v-expansion-panel-header {
        background: #e3672b;
        color: #fff;

        & > :not(.v-expansion-panel-header__icon) {
            flex: initial;
        }
    }
}

.panel-secondary {
    border: 1px solid #74538b;

    .v-expansion-panel-header {
        background: #74538b;
        color: #fff;

        & > :not(.v-expansion-panel-header__icon) {
            flex: initial;
        }
    }
}

.panel-accent {
    border: 1px solid #2f4858;

    .v-expansion-panel-header {
        background: #2f4858;
        color: #fff;

        & > :not(.v-expansion-panel-header__icon) {
            flex: initial;
        }
    }
}
.clickable{
    cursor: pointer;
}
</style>

<script>
import {mapActions, mapGetters} from "vuex";
import Pie from "../components/Charts/Pie";
import SiteAlertDatatable from "../components/Alerts/SiteAlertDatatable";
import SecurityAlertSilos from "../components/Alerts/SecurityAlertSilos";
import GpsAlertDatatable from "../components/Alerts/GpsAlertDatatable";
import SiteUpdateAlertDatatable from "../components/Alerts/SiteUpdateAlertDatatable";
import ReportAlertDatatable from "../components/Alerts/ReportAlertDatatable";
import {
    ACTIONS_SILOS_STATISTICS,
    AREA_TRANSPORTERS,
    DELIVERY_STEPS_STATISTICS,
    DRIVER_CONTRIBUTION,
    EXPLOITANT_CONTRIBUTION,
    REPORT_STATISTICS,
    SECURITY_LEVELS,
    USER_LOGISTIC_AREAS,
    VEHICLE_CONDITIONNINGS,
    VEHICLE_FLEETS
} from "../api";
import _ from "lodash";
import {bus} from "../app";

export default {
    name: "dashboard",
    components: {
        Pie,
        SiteAlertDatatable,
        SecurityAlertSilos,
        GpsAlertDatatable,
        SiteUpdateAlertDatatable,
        ReportAlertDatatable
    },
    async mounted() {
        this.number_exposition_inventory = localStorage.getItem('number_exposition_inventory') ?? 'exposition'
        if (this.$store.getters["alert/get_filters"].dates){
            this.dates = {
                start:this.$store.getters["alert/get_filters"].dates[0],
                end:this.$store.getters["alert/get_filters"].dates[1]
            }
        }else{
            this.dates = {
                start: this.$moment().add(-7, 'days').format('YYYY-MM-DD'),
                end: this.$moment().format('YYYY-MM-DD')
            }
        }
        await this.getLogisticAreas();
        await this.getTransporters();
        await this.getFleets()
        await this.getConditionings()
        this.setFilter(this.filter)
        this.initFilter()
        //this.getExploitantRatio() // Kpi Contribution exploitants
        //this.getDriverRatio() // Kpi Contribution chauffeurs
        //this.getReportsStatistic() // Kpi Compte-rendus
        //this.getActionsSilos({security_levels:this.available_security_level_choices}) // Kpi suivi des actions silos
        this.getSecurityLevels()
        //this.getDeliveryStepsStatistics() // Kpi Silo
    },
    data() {
        return {
            panel: [],
            chart_data_percentage_of_report: {},
            chart_data_percentage_of_delivery_steps: {},
            chart_action_silos: {},
            date_picker: false,
            date_format: 'YYYY-MM-DD',
            dates: {},
            logistic_areas: [],
            logistic_areas_choices: [],
            searchAreasInput: '',
            lang,
            toggleAreas: null,

            transporters: [],
            searchTransportersInput: '',
            transporters_choices: [],
            customShortcuts: [
                {
                    key: "next_week", label: this.$t('dashboard.next_week'), value: () => {
                        return {
                            start: this.$moment().add(7, 'day').startOf('week'),
                            end: this.$moment().add(7, 'day').endOf('week')
                        }
                    }
                },
                {
                    key: 'tomorrow', label: this.$t('dashboard.tomorrow'), value: () => {
                        return {
                            start: this.$moment().startOf('day').add(1, "day"),
                            end: this.$moment().endOf('day').add(1, "day")
                        }
                    }
                },
                {key: 'today', label: this.$t('dashboard.today'), value: 'day'},
                {key: 'yesterday', label: this.$t('dashboard.yesterday'), value: '-day'},
                {key: '7_last_days', label: this.$t('dashboard.7_last_days'), value: 7},
                {key: 'last_week', label: this.$t('dashboard.last_week'), value: '-isoWeek'},
                {key: 'last_month', label: this.$t('dashboard.last_month'), value: '-month'},
                {
                    key: '12_last_months', label: this.$t('dashboard.12_last_months'), value: () => {
                        return {
                            start: this.$moment().add(-12, "month"),
                            end: this.$moment()
                        }
                    }
                },
            ],

            available_security_levels: [],
            available_security_level_choices: [3, 4, 5],
            number_exposition_inventory: 'exposition',

            pgBarLoading:true,

            exploitantKpiContributionLoading: false,
            exploitantKpiContribution : 0,

            driverKpiContributionLoading: false,
            driverKpiContribution : 0,

            reportChartOptions: {
                onClick: ()  => {this.$router.push({ name: 'reports'})},
            },

            deliverySiloLabels :[],

            conditioning__choices :[],
            conditionings :[],
            searchConditioningsInput :'',
            fleet_choices :[],
            fleets :[],
            searchFleetsInput :'',
            first_loading: false,
        }
    },
    computed: {
        ...mapGetters({
            number_of_alerts: 'alert/number_of_alerts',
            number_of_site_is_missing_from_company_alerts: 'alert/number_of_site_is_missing_from_company_alerts',
            number_of_security_alert_black_silo_alerts: 'alert/number_of_security_alert_black_silo_alerts',
            number_of_security_alert_red_silo_alerts: 'alert/number_of_security_alert_red_silo_alerts',
            number_of_security_alert_orange_silo_alerts: 'alert/number_of_security_alert_orange_silo_alerts',
            number_of_site_update_request_alerts: 'alert/number_of_site_update_request_alerts',
            number_of_gps_alerts: 'alert/number_of_gps_alerts',
            number_of_report_alerts: 'alert/number_of_report_alerts',
            number_of_duplicated_site_alerts: 'alert/number_of_duplicate_site_alerts',
            get_filters: 'alert/get_filters'
        }),
        orderedAreas() {
            return _.orderBy(this.logistic_areas, 'code')
        },
        isSelectAllAreasAvailable() {
            return this.searchAreasInput === '' || this.searchAreasInput === null;
        },
        iconAreas() {
            if (this.allAreas) return 'mdi-close-box'
            if (this.someAreas) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        allAreas() {
            return this.logistic_areas_choices.length === this.logistic_areas.length
        },
        someAreas() {
            return this.logistic_areas_choices.length > 0 && !this.allAreas
        },
        filter() {
            // si pas de ZL sélectionnées, on les envoie toutes pour que le serveur ne prenne pas en comtpe que les ZL managées
            const logitic_areas = this.logistic_areas_choices.length > 0 ? this.logistic_areas_choices : _.map(this.logistic_areas, "id");
            return {
                dates: [this.dates.start, this.dates.end],
                logistic_areas_choices: logitic_areas,
                transporters_choices: this.transporters_choices,
                security_levels: this.available_security_level_choices,
                fleet_choices: this.fleet_choices,
                conditioning__choices: this.conditioning__choices,
            }
        },
        sumActionsSilos() {
            if (!this.chart_action_silos) {
                return 0
            }
            return _.sumBy(this.chart_action_silos, 'count')
        }
    },
    methods: {
        updateInterventionMode(change){
            this.getDeliveryStepsStatistics()
            localStorage.setItem('number_exposition_inventory', this.number_exposition_inventory)
        },
        handleClick(e, array){
            this.available_security_level_choices = []
            switch (array[0]._view.label){
                case this.deliverySiloLabels[0]:
                    this.available_security_level_choices.push(1)
                    break;
                case this.deliverySiloLabels[1]:
                    this.available_security_level_choices.push(2)
                    break;
                case this.deliverySiloLabels[2]:
                    this.available_security_level_choices.push(3)
                    break;
                case this.deliverySiloLabels[3]:
                    this.available_security_level_choices.push(4)
                    break;
                case this.deliverySiloLabels[4]:
                    this.available_security_level_choices.push(5)
                    break;
                default:
                    break;
            }
            this.setFilter(this.filter)
            this.$router.push({name:'silos_stats_datable', params:{action:'silos'}})
        },
        goToSilos() {
            this.$router.push({name: 'alert_silos'});
        },
        goToReports() {
            this.$router.push({name: 'alert_report'});
        },
        goToSite() {
            this.$router.push({name: 'alert_sites'});
        },
        goToGps() {
            this.$router.push({name: 'alert_gps'});
        },
        goToSiteUpdate() {
            this.$router.push({name: 'alert_sites_update'});
        },
        getReportsStatistic() {
            this.chart_data_percentage_of_report = {}
            this.axios.get(REPORT_STATISTICS,
                {params: {...this.filter}}).then((response) => {
                let total = response.data.number_of_steps_completed + response.data.number_of_steps_uncompleted
                this.chart_data_percentage_of_report = {
                    labels: [
                        this.$t('dashboard.number_of_steps_completed') + ` (${Math.round((response.data.number_of_steps_completed / total) * 100) || 0}%) ` ,
                        this.$t('dashboard.number_of_steps_uncompleted') + ` (${Math.round((response.data.number_of_steps_uncompleted/total) * 100) || 0 }%) `,
                    ],
                    datasets: [
                        {
                            data: [
                                response.data.number_of_steps_completed,
                                response.data.number_of_steps_uncompleted,
                            ],
                            backgroundColor: ['#448112', '#2f4858']
                        }
                    ],
                };
            })
        },
        getActionsSilos(optionsFilter = null) {
            this.pgBarLoading = true
            this.chart_action_silos = []

            this.security_levels = optionsFilter === null ? [3, 4, 5] : optionsFilter.security_levels

            this.axios.get(ACTIONS_SILOS_STATISTICS, {params:
                    {
                        logistic_areas_choices: this.filter.logistic_areas_choices,
                        transporters_choices: this.filter.transporters_choices,
                        security_levels: this.security_levels,
                        fleets: this.fleet_choices,
                        conditionings: this.conditioning__choices,
                    }})
                .then((response) => {
                    this.pgBarLoading = false
                    this.chart_action_silos = response.data
                })
            .catch(err  => {
                this.pgBarLoading = false
            })
        },
        allowedFutureDate(val) {
            return (this.dates[0] && !this.dates[1]) ? this.$moment(this.dates[0]) <= this.$moment(val) : true;
        },
        filterAreas(item, queryText) {
            var textOne = "";
            var textTwo = "";
            var searchText = "";
            if (item.code && item.name) {
                textOne = item.name.toLowerCase();
                textTwo = item.code.toLowerCase();
                searchText = queryText.toLowerCase();
            }

            return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
        },
        async getTransporters() {
            try {
                let response = await this.axios.get(
                    AREA_TRANSPORTERS
                )
                this.transporters = _.orderBy(response.data, ['code']);
            }catch (e){
                this.showSnackbar('error', this.$t('global.error'));
            }
            this.transporters_choices = this.get_filters.transporters_choices
        },
        async getLogisticAreas() {
            try {
               let success = await this.axios.get(USER_LOGISTIC_AREAS)
                this.logistic_areas = _.orderBy(success.data, ['name']);
                if (this.$auth.user().reference_logistic_areas.length > 0) {
                    this.logistic_areas_choices = _.map(this.$auth.user().reference_logistic_areas, 'id')
                } else if (this.logistic_areas.length === 1) {
                    this.logistic_areas_choices = [this.logistic_areas[0].id]
                }
                if (this.get_filters.logistic_areas_choices.length > 0){
                    this.logistic_areas_choices =  this.get_filters.logistic_areas_choices
                }
            }catch (e){
                this.showSnackbar('error', this.$t('global.error'));
            }
        },
        getDeliveryStepsStatistics() {
            this.chart_data_percentage_of_delivery_steps = {}
            this.axios.get(DELIVERY_STEPS_STATISTICS,
                {params: {...this.filter, number_exposition_inventory: this.number_exposition_inventory}}).then((response) => {
                let total = response.data.green_delivery_steps_number + response.data.orange_delivery_steps_number + response.data.black_alert_delivery_steps_number + response.data.red_alert_delivery_steps_number
                    + response.data.blue_alert_delivery_steps_number
                this.deliverySiloLabels =  [
                    this.$t('dashboard.number_of_delivery_steps_blue_alert') + ` (${Math.round((((response.data.blue_alert_delivery_steps_number  / total)*100) * 100))/100 < 1  ? Math.round((((response.data.blue_alert_delivery_steps_number / total)*100) * 100))/100 :Math.round((response.data.blue_alert_delivery_steps_number / total) * 100) || 0}%) `,
                    this.$t('dashboard.number_of_delivery_steps_with_green_alert')  +  ` (${Math.round((((response.data.green_delivery_steps_number  / total)*100) * 100))/100 < 1  ? Math.round((((response.data.green_delivery_steps_number / total)*100) * 100))/100 :Math.round((response.data.green_delivery_steps_number / total) * 100)  || 0}%)  `,
                    this.$t('dashboard.number_of_delivery_steps_with_orange_alert')  + ` (${Math.round((((response.data.orange_delivery_steps_number  / total)*100) * 100))/100 < 1  ? Math.round((((response.data.orange_delivery_steps_number / total)*100) * 100))/100 :Math.round((response.data.orange_delivery_steps_number / total) * 100)  || 0}%) `   ,
                    this.$t('dashboard.number_of_delivery_steps_red_alert') + ` (${Math.round((((response.data.red_alert_delivery_steps_number  / total)*100) * 100))/100 < 1  ? Math.round((((response.data.red_alert_delivery_steps_number / total)*100) * 100))/100 :Math.round((response.data.red_alert_delivery_steps_number / total) * 100)  || 0}%) `,
                    this.$t('dashboard.number_of_delivery_steps_black_alert') + ` (${Math.round((((response.data.black_alert_delivery_steps_number  / total)*100) * 100))/100 < 1  ? Math.round((((response.data.black_alert_delivery_steps_number / total)*100) * 100))/100 :Math.round((response.data.black_alert_delivery_steps_number / total) * 100) || 0}%) `,
                ]

                this.chart_data_percentage_of_delivery_steps = {
                    labels: this.deliverySiloLabels,
                    datasets: [
                        {
                            data: [
                                response.data.blue_alert_delivery_steps_number,
                                response.data.green_delivery_steps_number,
                                response.data.orange_delivery_steps_number,
                                response.data.red_alert_delivery_steps_number,
                                response.data.black_alert_delivery_steps_number,
                            ],
                            backgroundColor: ['#0993f3', '#27ff00', '#ff8400', '#ff0000', '#000000']
                        }
                    ],
                };
            })
        },
        getSecurityLevels() {
            this.axios.get(SECURITY_LEVELS)
                .then(success => {
                    this.available_security_levels = success.data.filter(security_level => {
                        return security_level.level > 1;
                    });
                })
        },
        filterTransporters(item, queryText) {
            var text = "";
            var searchText = "";
            if (item.code) {
                text = item.code.toLowerCase();
                searchText = queryText.toLowerCase();
            }

            return text.indexOf(searchText) > -1;
        },
        ...mapActions({
            setFilter: 'alert/setFilter',
            alertCount: 'alert/count'
        }),
        applyFilter() {
            this.first_loading = true
            this.setFilter(this.filter)
            this.axios.all([
                this.getExploitantRatio(),
                this.getDriverRatio(),
               this.getReportsStatistic(),
               this.getDeliveryStepsStatistics(),
                this.getActionsSilos({security_levels:this.available_security_level_choices})
            ])
            bus.$emit("applyFilter")
            this.alertCount()
        },
        updateRequest(request, optionalFilters){
            this.setFilter(this.filter)
            request(optionalFilters)
        },
        async getExploitantRatio(){
            this.exploitantKpiContributionLoading = true
            this.exploitantKpiContribution = 0
            let {data} = await this.axios.get(EXPLOITANT_CONTRIBUTION, {params:{...this.filter, order:'site'}})

            let rowCount = 0
            let relativeAverage = 0
            _.each(data , group => {
                _.each(group, driver => {
                    rowCount++
                    relativeAverage += Math.round((driver.noRasRatio + driver.gpsNotLocked + driver.actionRatio + driver.newSiteRatio)/4)
                })
            })
            this.exploitantKpiContributionLoading = false
            if (rowCount === 0){
                this.exploitantKpiContribution = 0
                return
            }
            this.exploitantKpiContribution = Math.round(relativeAverage/rowCount)

        },
        formatNaN(number){
            if (isNaN(number) || number === Infinity){
                return 0
            }
            return number
        },
        async getDriverRatio(){
            this.driverKpiContributionLoading = true
            this.driverKpiContribution = 0

            let {data} = await this.axios.get(DRIVER_CONTRIBUTION, {params:{...this.filter, order:'driver'}})

            let rowCount = 0
            let relativeAverage = 0

            _.each(data.items , group => {
                _.each(group, driver => {
                    let ratioCrValidated = this.formatNaN(Math.round((driver.CRCompleted * 100) / driver.totalSteps))
                    let rowTourReport = this.formatNaN(Math.round((driver.tourReport * 100) / driver.numberTours))
                    let rowPlannedLoading = this.formatNaN(Math.round((driver.loadingPlanned * 100) /  driver.totalLoadingStep))
                    let row = ratioCrValidated + rowTourReport + rowPlannedLoading
                    if(row === 0){
                        relativeAverage += 0
                    }else {
                        relativeAverage += Math.round(row / 3)
                    }
                    rowCount++
                })
            })

            this.driverKpiContributionLoading = false
            if (rowCount === 0){
                this.driverKpiContribution = 0
                return
            }
            this.driverKpiContribution = Math.round(relativeAverage/rowCount)
        },
        colorFromNumber(number){
            number = parseInt(number)
            if(number === 0) {
                return 'grey'
            }
            if( _.inRange(number, 0, 50)) {
                return 'red'
            }
            if( _.inRange(number, 50, 85)) {
                return 'orange'
            }
            if( _.inRange(number, 85, 100)) {
                return 'yellow'
            }
            if( number === 100) {
                return "green"
            }
        },
        initFilter(){
            this.transporters_choices =  this.get_filters.transporters_choices
            this.conditioning__choices = this.get_filters.conditioning__choices
            this.fleet_choices = this.get_filters.fleet_choices
        },
        async getFleets(){
            this.fleets = _.sortBy((await axios.get(VEHICLE_FLEETS)).data, ['name'])
            this.fleet_choices = this.get_filters.fleet_choices
        },
        async getConditionings(){
            this.conditionings = _.sortBy((await axios.get(VEHICLE_CONDITIONNINGS)).data, ['name'])
            this.conditioning__choices = this.get_filters.conditioning__choices
        }

    },
    watch: {
        toggleAreas() {
            this.$nextTick(() => {
                if (this.allAreas) {
                    this.logistic_areas_choices = []
                } else {
                    this.logistic_areas.forEach(element => {
                        if (!this.logistic_areas_choices.includes(element.id)) {
                            this.logistic_areas_choices.push(element.id);
                        }
                    });
                }
            })
        }
    },
    filters: {
        round(value) {
            return _.round(value) ? _.round(value) : 1
        }
    }
}
</script>

<style lang="scss">

.chart-box-indicator {
    display: block;
    width: 10px;
    height: 10px;
}

.dashboard-module {
    .v-breadcrumbs li:last-child a {
        font-size: 1.25rem !important;
        color: rgba(0, 0, 0, .6) !important;
        font-weight: 500;
    }

    .v-list--dense .v-list-item {
        .v-list-item__title,
        .v-list-item__subtitle {
            font-size: 0.9rem;
        }
    }
}

</style>
