<template>
    <div>
        <v-row>
            <v-col class="white">
                <v-subheader>
                    <p class="title mb-0">
                        Get Orders
                    </p>
                </v-subheader>
            </v-col>
        </v-row>

        <v-col>
            <v-card>
                <v-card-text>
                    Date de début
                    <v-row>
                        <v-col cols="4">
                            <v-menu
                                ref="startDateMenu"
                                v-model="startDateMenu"
                                :close-on-content-click="false"
                                :return-value.sync="start_date"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="start_date"
                                        label="Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="start_date" no-title scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="startDateMenu = false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="$refs.startDateMenu.save(start_date)">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="4">
                            <v-menu
                                ref="startTimeMenu"
                                v-model="startTimeMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="start_time"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="start_time"
                                        label="Heure"
                                        prepend-icon="mdi-clock-outline"
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-if="startTimeMenu"
                                    v-model="start_time"
                                    format="24hr"
                                    full-width
                                    @click:minute="$refs.startTimeMenu.save(start_time)"
                                ></v-time-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    Date de fin
                    <v-row>
                        <v-col cols="4">
                            <v-menu
                                ref="maxDateMenu"
                                v-model="maxDateMenu"
                                :close-on-content-click="false"
                                :return-value.sync="max_date"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="max_date"
                                        label="Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="max_date" no-title scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="maxDateMenu = false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="$refs.maxDateMenu.save(max_date)">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="4">
                            <v-menu
                                ref="maxTimeMenu"
                                v-model="maxTimeMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                :return-value.sync="max_time"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="max_time"
                                        label="Heure"
                                        prepend-icon="mdi-clock-outline"
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-time-picker
                                    v-if="maxTimeMenu"
                                    v-model="max_time"
                                    format="24hr"
                                    full-width
                                    @click:minute="$refs.maxTimeMenu.save(max_time)"
                                ></v-time-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    Site de chargement :
                    <v-row>
                        <v-col cols="4">
                            <v-text-field
                                v-model="loading"
                                outlined
                                dense
                                prepend-icon="mdi-truck-check-outline"
                            />
                        </v-col>
                    </v-row>
                    Site de livraison :
                    <v-row>
                        <v-col cols="4">
                            <v-text-field
                                v-model="delivery"
                                outlined
                                dense
                                prepend-icon="mdi-truck-delivery-outline"
                            />
                        </v-col>
                    </v-row>
                    Camion :
                    <v-row>
                        <v-col cols="4">
                            <v-text-field
                                v-model="truck_id"
                                outlined
                                dense
                                prepend-icon="mdi-truck"
                            />
                        </v-col>
                        <v-col cols="4">
                            <v-menu
                                ref="filteringDateMenu"
                                v-model="filteringDateMenu"
                                :close-on-content-click="false"
                                :return-value.sync="filtering_date"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="filtering_date"
                                        label="Date"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        outlined
                                        dense
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker v-model="filtering_date" no-title scrollable>
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" @click="filteringDateMenu = false">Cancel</v-btn>
                                    <v-btn text color="primary" @click="$refs.filteringDateMenu.save(filtering_date)">OK</v-btn>
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-btn @click="getOrders()">
                        Enregistrer
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-col>

        <v-snackbar v-model="snackbar" top :color="snackbar_type">
            {{ snackbar_text }}
            <v-btn text @click="snackbar = false">
                Close
            </v-btn>
        </v-snackbar>

    </div>
</template>

<script>
    export default {
        name: "GetOrders",
        data() {
            return {
                filteringDateMenu: false,
                filtering_date: null,
                start_time: null,
                start_date: null,
                startTimeMenu: false,
                startDateMenu: false,
                max_time: this.$moment().format('LT'),
                max_date: this.$moment().format('YYYY-MM-DD'),
                maxTimeMenu: false,
                maxDateMenu: false,
                loading: null,
                delivery: null,
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                truck_id: null,
            }
        },
        methods: {
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            formatTime(time) {
                return time ?time.replace(':', '-') : '00-00';
            },
            getOrders() {
                this.axios
                    .post('/api/getorders', {
                        date: this.start_date ? this.start_date + '-' + this.formatTime(this.start_time): null,
                        max_date: this.max_date ? this.max_date + '-' + this.formatTime(this.max_time): null,
                        loading: this.loading,
                        delivery: this.delivery,
                        truck: this.truck_id,
                        filtering_date: this.filtering_date,
                    })
                    .then(() => {
                        this.showSnackbar('success', 'Import effectué avec succès');
                    })
                    .catch(() => {
                        this.showSnackbar('error', this.$t('global.error'));
                    })
            }
        }
    }
</script>
