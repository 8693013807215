<template>
    <div>
        <v-data-table
            :no-data-text="$t('global.no_data')"
            :loading-text="$t('global.loading_text')"
            :no-results-text="$t('global.no_results_text')"
            :headers="headers"
            :items="categories"
            :loading="loading"
            class="elevation-1"
            :items-per-page="10"
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-dialog v-model="dialog" max-width="500px" @input="$validator.reset()">
                      <!--
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" dark class="mb-2" v-on="on">{{ $t('repository.new_item') }}</v-btn>
                        </template>
                        -->
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-form>
                                    <v-text-field
                                        :label="$t('repository.code')"
                                        name="code"
                                        prepend-icon="mdi-numeric"
                                        v-validate="'required|max:4'"
                                        type="text"
                                        :error-messages="errors.collect('code')"
                                        data-vv-name="code"
                                        v-model="editedItem.code"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('repository.name')"
                                        name="name"
                                        prepend-icon="mdi-textbox"
                                        v-validate="'required|max:100'"
                                        type="text"
                                        :error-messages="errors.collect('name')"
                                        data-vv-name="name"
                                        v-model="editedItem.name"
                                    ></v-text-field>
                                    <div>
                                        <span class="v-label mt-4 d-flex" style="justify-content: space-between; align-items: center">
                                            <div>
                                                <v-icon class="icon-image">mdi-image</v-icon>
                                                {{ $t('repository.pictogram')  }}
                                            </div>
                                            <v-btn :disabled="editedItem.media[0].id === undefined" color="red darken-1" text @click="dialogPictogramDelete = true">
                                                {{ $t('repository.delete') }}
                                            </v-btn>
                                        </span>
                                        <div v-if="editedItem.media[0].id !== undefined" class="text-center ma-5">
                                            <img
                                                height="32"
                                                :src="`storage/${editedItem.media[0].id}/${editedItem.media[0].name}`"
                                                :alt="$t('repository.pictogram')"
                                            >
                                        </div>
                                        <vue-base64-file-upload
                                            v-if="dialog"
                                            prepend-icon="mdi-image"
                                            accept="image/*"
                                            :max-size="customImageMaxSize"
                                            :placeholder="$t('repository.select_file')"
                                            @load="onLoad"/>
                                    </div>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    {{ $t('repository.cancel') }}
                                </v-btn>
                                <v-btn @click="save" :disabled="submit_button_is_disabled">
                                    {{ $t('repository.save') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.media="{ item }">
                <template v-if="item.media[0]">
                    <img v-if="item.media[0].id != undefined"
                        height="32"
                        :src="`storage/${item.media[0].id}/${item.media[0].name}`"
                        :alt="$t('repository.pictogram')"
                    >
                </template>
            </template>
            <template v-slot:item.action="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    :title="$t('repository.update')"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <!-- Retrait à la demande de AVRIL 17/06/2020
               <v-icon
                   small
                   :title="$t('repository.delete')"
                   @click="deleteItem(item)"
               >
                   mdi-delete
               </v-icon>
               -->
           </template>
       </v-data-table>
       <v-dialog v-model="dialogDelete" max-width="290">
           <v-card elevation="0">
               <v-card-title class="headline">{{ $t('repository.sure') }}</v-card-title>
               <v-card-text>{{ $t('repository.irremediable') }}</v-card-text>
               <v-card-actions>
                   <v-spacer></v-spacer>
                   <v-btn color="darken-1" text @click="dialogDelete = false">
                       {{ $t('repository.cancel') }}
                   </v-btn>
                   <v-btn color="warning darken-1" text @click="deleteCategory()">
                       {{ $t('repository.delete') }}
                   </v-btn>
               </v-card-actions>
           </v-card>
       </v-dialog>
       <v-dialog v-model="dialogPictogramDelete" max-width="290">
           <v-card elevation="0">
               <v-card-title class="headline">{{ $t('repository.sure') }}</v-card-title>
               <v-card-text>{{ $t('repository.irremediable') }}</v-card-text>
               <v-card-actions>
                   <v-spacer></v-spacer>
                   <v-btn color="darken-1" text @click="dialogPictogramDelete = false">
                       {{ $t('repository.cancel') }}
                   </v-btn>
                   <v-btn color="warning darken-1" text @click="deletePictogramCategory()">
                       {{ $t('repository.delete') }}
                   </v-btn>
               </v-card-actions>
           </v-card>
       </v-dialog>
   </div>
</template>

<style lang="scss">
   .icon-image {
       position: relative;
       top: -2px;
   }

   .vue-base64-file-upload {
       border: 1px dashed #999;
       border-radius: 2px;

       margin-top: 5px;
       padding-top: 5px;

       text-align: center;

       input {
           padding: 0 15px 5px 15px;
           cursor: pointer;
       }
   }
</style>

<script>
   import {AREAS, CATEGORIES, CATEGORY} from "../../api";
   import VueBase64FileUpload from "vue-base64-file-upload";

   export default {
       name: "categories",
       components: {
           VueBase64FileUpload
       },
       data() {
           return {
               headers: [
                   {text: this.$t('repository.code'), value: 'code', sortable: true},
                   {text: this.$t('repository.pictogram'), value: 'media', sortable: true},
                   {text: this.$t('repository.name'), value: 'name', sortable: true},
                   {text: this.$t('repository.actions'), value: 'action', sortable: false},
               ],
               customImageMaxSize: 3,
               loading: false,
               dialog: false,
               categories: [],
               editedIndex: -1,
               editedItem: {
                   code: '',
                   logistic_area_id: null,
                   media: [{}]
               },
               defaultItem: {
                   code: '',
                   logistic_area: 1,
                   media: [{}]
               },
               submit_button_is_disabled: false,
               itemToDelete: {},
               dialogDelete: false,
               dialogPictogramDelete: false,
               logistic_areas: []
           }
       },
       mounted() {
           this.getCategories();
       },
       computed: {
           formTitle() {
               return this.editedIndex === -1 ? this.$t('repository.new_item') : this.$t('repository.edit_item')
           },
       },
       methods: {
           onLoad(dataUri) {
               this.editedItem.pictogram = dataUri;
           },
           /**
            * Reset snackbar
            */
           resetSnackbar() {
               this.snackbar_type = null;
               this.snackbar_text = null;
               this.snackbar = false;
           },
           /**
            * Show snackbar
            *
            * @param {string} type
            * @param {string} text
            */
           showSnackbar(type, text) {
               this.snackbar_type = type;
               this.snackbar_text = text;
               this.snackbar = true;
           },
           /**
            * Get categories
            */
           getCategories() {
               this.loading = true;

               this.axios.get(CATEGORIES).then(success => {
                   this.categories = success.data;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               }).finally(() => {
                   this.loading = false;
               })
           },
           save() {
               this.submit_button_is_disabled = true;

               this.$validator.validateAll().then((result) => {
                   if (result) {
                       if (this.editedIndex > -1) {
                           this.updateItem(this.editedItem);
                       } else {
                           this.createItem(this.editedItem);
                       }
                   } else {
                       this.submit_button_is_disabled = false;
                   }
               });
           },
           /**
            * Update category
            *
            * @param {object}  category
            */
           updateItem(category) {
               this.axios.patch(
                   this.replacePathParams(CATEGORY, {category: category.id}),
                   category
               ).then((success) => {
                   Object.assign(this.categories[this.editedIndex], success.data);
                   this.showSnackbar('success', this.$t('repository.updated'));
                   this.close()
               }).catch((error) => {
                   if (error.response.status === 422) {
                       this.showSnackbar('warning', this.parseLaravelError(error));
                   } else {
                       this.showSnackbar('error', this.$t('global.error'));
                   }
               }).finally(() => {
                   this.submit_button_is_disabled = false;
               })
           },
           /**
            * Show edit dialog
            *
            * @param {object} category
            */
           editItem(category) {
               let tmpArea = Object.assign({}, category.logistic_area);
               this.editedIndex = this.categories.indexOf(category);
               this.editedItem = Object.assign({}, category);
               if(this.editedItem.media.length < 1) {
                   this.editedItem.media.push({});
               }
               this.dialog = true;
               category.logistic_area = tmpArea;
           },
           /**
            * Add category
            *
            * @param {object} category
            */
           createItem(category) {
               this.axios.post(
                   CATEGORIES,
                   category
               ).then((success) => {
                   this.categories.push(success.data);
                   this.showSnackbar('success', this.$t('repository.created'));
                   this.close()
               }).catch((error) => {
                   if (error.response.status === 422) {
                       this.showSnackbar('warning', this.parseLaravelError(error));
                   } else {
                       this.showSnackbar('error', this.$t('global.error'));
                   }
               }).finally(() => {
                   this.submit_button_is_disabled = false;
               })
           },
           /**
            * Hide add/ edit item modal
            */
           close() {
               this.submit_button_is_disabled = false;
               this.dialog = false;
               setTimeout(() => {
                   this.editedItem = Object.assign({}, this.defaultItem);
                   this.editedIndex = -1
               }, 300)
           },
           /**
            * Show delete modal
            *
            * @param {object} category
            */
           deleteItem(category) {
               this.itemToDelete = category;
               this.dialogDelete = true;
           },
           /**
            * Remove category from database
            */
           deleteCategory() {
               this.axios.delete(
                   this.replacePathParams(CATEGORY, {category: this.itemToDelete.id})
               ).then(() => {
                   this.categories.splice(this.categories.indexOf(this.itemToDelete), 1);
                   this.showSnackbar('success', this.$t('repository.deleted'));
                   this.dialogDelete = false;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               });
           },
           /**
            * Remove pictogram category from database
            */
           deletePictogramCategory() {
               this.axios.delete(
                   this.replacePathParams(CATEGORY, {category: this.editedItem.id}) + '/pictogram'
               ).then(() => {
                   this.categories[this.editedIndex].media = [{}];
                   this.editedItem.media = [{}];
                   this.showSnackbar('success', this.$t('repository.deleted'));
                   this.dialogPictogramDelete = false;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               });
           },
           getLogisticAreas() {
               if (this.logistic_areas.length === 0) {
                   this.axios.get(AREAS).then((success) => {
                       this.logistic_areas = success.data
                   }).catch(() => {
                       this.showSnackbar('error', this.$t('global.error'));
                   });
               }
           }
       },
       watch: {
           async dialog(val) {
               if (val) {
                   await this.getLogisticAreas();
               } else {
                   this.close();
               }
           },
       },
   };
</script>
