<template>
    <div>
        <div id="map">
            <div class="no_coordinate_gps" style="display: none">
                {{ $t('maps.no_gps_coordinate') }}
            </div>
        </div>
        <v-dialog
            v-model="dialog"
            max-width="500"
        >
            <v-card>
                <v-card-title class="headline">{{ $t('maps.change_gps_coordinates') }}</v-card-title>
                <v-card-text>
                    {{ $t('maps.are_you_sure_GPS_coordinates_change') }}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="cancel"
                    >
                        {{ $t('maps.cancel') }}
                    </v-btn>
                    <v-btn
                        text
                        @click="submit"
                    >
                        {{ $t('maps.agree') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>

    require('leaflet-bing-layer');
    require('@runette/leaflet-fullscreen');

    var map = {
        remove: () => {
        }
    };

    export default {
        name: "maps",
        data() {
            return {
                zoom: 14,
                center: [0, 0],
                map_plan_path: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                mapIcon: {
                    iconUrl: '../images/marker-icon.png',
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                    tooltipAnchor: [16, -28],
                    shadowSize: [41, 41]
                },
                dialog: false,
                latLngOrigin: {},
                latLngNew: {},
            }
        },
        props: {
            latitude: {
                type: String,
                required: false
            },
            longitude: {
                type: String,
                required: false
            }
        },
        watch: {
            latitude() {
                this.destroyMap();
                this.createMap(this.latitude, this.longitude);
            },
            longitude() {
                this.destroyMap();
                this.createMap(this.latitude, this.longitude);
            }
        },
        methods: {
            /**
             * Create map
             */
            createMap(latitude = null, longitude = null) {
                let point = [latitude ?? '0', longitude ?? '0'];

                map = L.map('map', {
                    fullscreenControl: true,
                    scrollWheelZoom: false,
                    zoom: this.zoom,
                    center: point
                });

                if (longitude && latitude) {
                    let marker = L.marker(
                        point,
                        {
                            icon: L.icon(this.mapIcon),
                            draggable: true
                        }
                    ).addTo(map);

                    marker.on('dragstart', (e) => {
                        this.latLngOrigin = e.sourceTarget._latlng
                    })
                    marker.on('dragend', (e) => {
                        this.latLngNew = e.target._latlng
                        this.dialog = true;
                    })
                } else {
                    $('.no_coordinate_gps').show();
                }

                var osm = L.tileLayer(this.map_plan_path, {
                        attribution:
                            'Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
                    }),
                    bing = L.tileLayer.bing(process.env.MIX_BING_API_KEY)
                ;

                let baseMaps = {
                    "Plan": osm,
                    "Satellite": bing
                };

                map.addLayer(baseMaps.Plan);

                L.control.layers(baseMaps, null, {position: 'topright'}).addTo(map);
            },
            /**
             * Destroy map
             */
            destroyMap() {
                $('.no_coordinate_gps').hide();
                if (map) map.remove();
            },
            submit() {
                this.dialog = false;
                this.destroyMap();
                this.createMap(this.latLngNew.lat, this.latLngNew.lng);
                this.$emit('updateCoordinates',
                    {
                        longitude: String(this.latLngNew.lng.toFixed(6)),
                        latitude: String(this.latLngNew.lat.toFixed(6))
                    }
                )
            },
            cancel() {
                this.dialog = false;
                this.destroyMap();
                this.createMap(this.latLngOrigin.lat, this.latLngOrigin.lng);
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "~leaflet/dist/leaflet.css";
    @import "~@runette/leaflet-fullscreen/dist/leaflet.fullscreen.css";

    .v-list-item__subtitle {
        white-space: normal;
    }

    #map {
        height: 280px;
        z-index: 1;

        .no_coordinate_gps {
            position: relative;
            z-index: 999;
            width: 100%;
            height: 100%;
            background: hsla(0, 100%, 0%, .5);

            display: flex;
            align-items: center;
            justify-content: center;

            color: #fff;
            font-size: 1.2rem;
        }
    }
</style>
