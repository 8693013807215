import {COUNT_ALERTS, UNREAD_MESSAGES} from "../../api";
import {COUNT_ACTION, COUNT_MESSAGES_ACTION} from "../mutation-types";

const state = {
    number_of_alerts: [],
    number_of_unread_messages : null,
    filter:{
        date_range: null,
        logistic_areas_choices: [],
        transporters_choices: [],
        security_levels: []
    }
};

const getters = {
    number_of_alerts: (state, getters) => {
        return getters.number_of_site_is_missing_from_company_alerts
            + getters.number_of_duplicate_site_alerts
            + getters.number_of_security_alert_silo_alerts
            + getters.number_of_site_update_request_alerts
            + getters.number_of_gps_alerts
            + getters.number_of_report_alerts
    },
    number_of_site_is_missing_from_company_alerts: state => {
        return state.number_of_alerts['companies']?state.number_of_alerts['companies']:0
    },
    number_of_duplicate_site_alerts: state => {
        return state.number_of_alerts['sites']?state.number_of_alerts['sites']:0
    },
    number_of_security_alert_silo_alerts: (state, getter) => {
        return getter.number_of_security_alert_black_silo_alerts
            + getter.number_of_security_alert_red_silo_alerts
            + getter.number_of_security_alert_orange_silo_alerts
    },
    number_of_security_alert_black_silo_alerts: state => {
        return state.number_of_alerts['security_alert_silos']?.[4]?state.number_of_alerts['security_alert_silos'][4]:0
    },
    number_of_security_alert_red_silo_alerts: state => {
        return state.number_of_alerts['security_alert_silos']?.[3]?state.number_of_alerts['security_alert_silos'][3]:0;
    },
    number_of_security_alert_orange_silo_alerts: state => {
        return state.number_of_alerts['security_alert_silos']?.[2]?state.number_of_alerts['security_alert_silos'][2]:0;
    },
    number_of_site_update_request_alerts: state => {
        return state.number_of_alerts['sites_update_requests']?state.number_of_alerts['sites_update_requests']:0
    },
    number_of_gps_alerts: state => {
        return state.number_of_alerts['gps']?state.number_of_alerts['gps']:0
    },
    number_of_report_alerts: state => {
        return state.number_of_alerts['reports']?state.number_of_alerts['reports']:0
    },
    number_of_unread_messages: state => {
        return state.number_of_unread_messages?state.number_of_unread_messages:0
    },
    get_filters:state => {
        return state.filter
    }
};

const actions = {
    count({state, commit}) {
        axios.get(
            COUNT_ALERTS,
            {
                params:{
                    logistic_areas: state.filter.logistic_areas_choices
                }
            }
        ).then((success) => {
            commit(COUNT_ACTION, success.data)
        });
    },

    unread_messages_count({commit}) {
        axios.get(
            UNREAD_MESSAGES
        ).then((success) => {
            commit(COUNT_MESSAGES_ACTION, success.data)
        });
    },

    setFilter({commit}, filter){
        commit('setFilter', filter)
    }
};

const mutations = {
    [COUNT_ACTION](state, data) {
        state.number_of_alerts = data;
    },

    [COUNT_MESSAGES_ACTION](state, data) {
        state.number_of_unread_messages= data;
    },

    setFilter(state, data){
        state.filter = data
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
