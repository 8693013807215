<template>
    <div>
        <v-row>
            <v-col class="white">
                <v-subheader>
                    <p class="title mb-0">
                        Order track
                    </p>
                </v-subheader>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <span class="subtitle-1">
                Numéro de commande :
                </span>
            </v-col>
            <v-col cols="4">
                <v-text-field
                    v-model="order_sequence"
                    cols="4"
                />
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12">
                <v-btn @click="getOrders">Rechercher</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <div class="loader" v-if="loading"></div>
                <tree-view
                    v-if="order_founded"
                    :data="order_founded"
                    :options="{ rootObjectKey: 'order' }"
                 />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import TreeView from "vue-json-tree-view"
import Vue from 'vue'

Vue.use(TreeView);

export default {
    name: "PrivateOrder",
    data() {
        return {
            loading: false,
            order_founded: null,
            order_sequence: null,
        }
    },
    methods: {
        getOrders() {
            this.loading = true;
            this.axios.get('/api/private_orders', {
                params: {
                    "key": "pu24122020",
                    "order": this.order_sequence,
                }
            })
                .then(data => {
                    this.order_founded = data.data;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
}
</script>

<style>
.tree-view-item-node .tree-view-item-key {
    color: #333 !important;
}
.tree-view-item-key {
    color: #b300ff;
}
</style>
