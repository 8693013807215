import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'

import fr from  'vuetify/lib/locale/fr'

Vue.use(Vuetify);

const opts = {
    icons: {
        iconfont: 'mdi',
    },
    lang:{
        locales :{ fr },
        current: 'fr'
    },
    theme: {
        themes: {
            light: {
                primary: '#e3672b',
                secondary: '#74538b',
                accent: '#2f4858',
                error: '#d84c60',
                info: '#ae4c83',
                success: '#448112',
                warning: '#b47700',
            },
            dark: {
                primary: '#e3672b',
                secondary: '#74538b',
                accent: '#2f4858',
                error: '#d84c60',
                info: '#ae4c83',
                success: '#448112',
                warning: '#b47700',
            },
        },
    },
};

export default new Vuetify(opts);
