<template>
    <div>
        <v-row>
            <v-col cols="12">
                <span class="title">
                    {{ $t("dashboard.new_black_silos") }}
                </span>
                <security-alert-silo-data-table
                    ref="security_black_alert_silos_datatable"
                    :alerts="black_alerts"
                    :number_of_alerts="number_of_black_alerts"
                    @refresh="refresh"
                ></security-alert-silo-data-table>
            </v-col>
            <v-col cols="12">
                <span class="title">
                    {{ $t("dashboard.new_red_silos") }}
                </span>
                <security-alert-silo-data-table
                    ref="security_red_alert_silos_datatable"
                    :alerts="red_alerts"
                    :number_of_alerts="number_of_red_alerts"
                    @refresh="refresh"
                >
                </security-alert-silo-data-table>
            </v-col>
            <v-col cols="12">
                <span class="title">
                    {{ $t("dashboard.new_orange_silos") }}
                </span>
                <security-alert-silo-data-table
                    ref="security_orange_alert_silos_datatable"
                    :alerts="orange_alerts"
                    :number_of_alerts="number_of_orange_alerts"
                    @refresh="refresh"
                >
                </security-alert-silo-data-table>
            </v-col>
        </v-row>

        <v-snackbar
            :color="snackbar_type"
            top
            v-model="snackbar"
        >
            {{ snackbar_text }}
            <v-btn
                @click="snackbar = false"
                text
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {SECURITY_ALERT_SILO} from "../../api";
    import SecurityAlertSiloDataTable from "./SecurityAlertSiloDataTable";
    import {bus} from "../../app";

    export default {
        name: 'security-alert-silos',
        components: {SecurityAlertSiloDataTable},
        async mounted() {
           await this.init()
        },
        data() {
            return {
                black_alerts: [],
                red_alerts: [],
                orange_alerts: [],
                number_of_black_alerts: null,
                number_of_red_alerts: null,
                number_of_orange_alerts: null,
                loading: false,
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
            }
        },
        created() {
            bus.$on("applyFilter", () => {
                this.init()
            })
        },
        methods: {
            ...mapActions({'get': 'alert/count'}),
            ...mapGetters({get_filters: 'alert/get_filters'}),
            async init(){
                [this.black_alerts, this.number_of_black_alerts] = await this.getSecurityAlertSilos(4);
                this.$refs.security_black_alert_silos_datatable.loading = false;
                [this.red_alerts, this.number_of_red_alerts] = await this.getSecurityAlertSilos(3);
                this.$refs.security_red_alert_silos_datatable.loading = false;
                [this.orange_alerts, this.number_of_orange_alerts] = await this.getSecurityAlertSilos(2);
                this.$refs.security_orange_alert_silos_datatable.loading = false;
            },
            /**
             * Get security alert silo
             */
            getSecurityAlertSilos(level, options = {page: 1, itemsPerPage: 10}) {
                return new Promise(resolve => {
                    this.loading = true;
                    this.axios.get(SECURITY_ALERT_SILO,
                        {params: {
                            page: options.page,
                            per_page: options.itemsPerPage,
                            level,
                            lastActionFilter: level == 4,
                            logistic_areas: this.get_filters().logistic_areas_choices
                        }}
                    ).then((success) => {
                        resolve([success.data.data, success.data.total]);
                    }).catch(() => {
                        this.showSnackbar('error', this.$t('global.error'));
                    }).finally(() => {
                        this.loading = false;
                    });
                });
            },
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            async refresh(data) {
                switch (data.level) {
                    case 2:
                        [this.orange_alerts, this.number_of_orange_alerts] = await this.getSecurityAlertSilos(
                            2,
                            data.options
                        );
                        break;
                    case 3:
                        [this.red_alerts, this.number_of_red_alerts] = await this.getSecurityAlertSilos(
                            3,
                            data.options
                        );
                        break;
                    case 4:
                        [this.black_alerts, this.number_of_black_alerts] = await this.getSecurityAlertSilos(
                            4,
                            data.options
                        );
                        break;
                }
            }
        },
    }
</script>
