<template>
    <div>
        <v-data-table
            :items="filtered_coordinates"
            :no-data-text="$t('global.no_data')"
            :loading-text="$t('global.loading_text')"
            :no-results-text="$t('global.no_results_text')"
            :headers="headers"
            :loading="loading"
            :server-items-length="number_of_alerts"
            @update:options="changeOptions"
            :options.sync="options"
            :footer-props="{
                        'items-per-page-options': rowsPerPageItems,
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
        >
            <!-- Désactivation à la demande de AVRIL 28/05/2020
            <template v-slot:top>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-col>
                        <v-select
                            :items="type_choices"
                            v-validate="'required'"
                            :label="$t('alert.type')"
                            required
                            prepend-icon="mdi-form-dropdown"
                            item-text="name"
                            item-value="id"
                            data-vv-name="type"
                            name="type"
                            id="type"
                            v-model="type_choice"
                        >
                        </v-select>
                    </v-col>
                </v-row>
            </template>
            -->

            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | moment('L LT') }}
            </template>
            <template v-slot:item.type="{ item }">
                {{ typeAlert(item.alertable_type) }}
            </template>
            <template v-slot:item.concerned_element="{ item }">
                {{ item.alertable.name }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn
                    class="mr-2"
                    :loading="loading"
                    color="primary"
                    tile
                    x-small
                    @click="goTo(item)"
                    :title="$t('alert.go_to_alert_site')"
                >
                    <v-icon
                        small
                        :title="$t('alert.manage')"
                        class="mr-1"
                    >
                        mdi-hammer-screwdriver
                    </v-icon>
                    {{ $t('alert.manage') }}
                </v-btn>
                <v-icon
                    @click="deleteItem(item)"
                    :loading="loading"
                    :title="$t('alert.delete')"
                    small
                >
                    mdi-delete
                </v-icon>
            </template>
        </v-data-table>

        <v-dialog v-model="dialogDelete" max-width="290">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('alert.sure') }}</v-card-title>
                <v-card-text>{{ $t('alert.irremediable') }}</v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="darken-1" text @click="dialogDelete = false">
                        {{ $t('alert.cancel') }}
                    </v-btn>
                    <v-btn color="warning darken-1" text @click="deleteAlert()">
                        {{ $t('alert.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackbar"
            top
            :color="snackbar_type"
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
    import {ALERTS, GPS} from "../../api";
import {bus} from "../../app";

    export default {
        name: 'gps-alert-datable',
        data() {
            return {
                loading: false,
                headers: [
                    {text: this.$t('alert.created_at'), value: 'created_at', sortable: false},
                    {text: this.$t('alert.type'), value: 'type', sortable: false},
                    {text: this.$t('alert.name'), value: 'concerned_element', sortable: false},
                    {text: this.$t('alert.actions'), value: 'actions', sortable: false},
                ],
                dialogDelete: false,
                itemToDelete: {},
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                type_choices: [
                    {id: 'all', name: this.$t('alert.all')},
                    {id: 'site', name: this.$t('alert.site')},
                    {id: 'silo', name: this.$t('alert.silo')}
                ],
                type_choice: 'all',
                alerts: [],
                number_of_alerts: 0,
                options: {page: 1, itemsPerPage: 10},
                rowsPerPageItems: [5, 10, 100],
            }
        },
        created() {
            bus.$on("applyFilter", () => {
                this.getGpsAlerts()
            })
        },
        mounted() {
            this.getGpsAlerts();
        },
        computed: {
            coordinates() {
                return this.alerts;
            },
            filtered_coordinates() {
                if (this.type_choice === 'site') {
                    return _.filter(this.coordinates, {alertable_type: 'App\\Site'});
                }

                if (this.type_choice === 'silo') {
                    return _.filter(this.coordinates, {alertable_type: 'App\\Silo'});
                }

                return this.coordinates;
            }
        },
        methods: {
            ...mapActions({count_alerts: 'alert/count'}),
            ...mapGetters({get_filters: 'alert/get_filters'}),
            /**
             * Format alert type
             */
            typeAlert(value) {
                if (value === 'App\\Site') return this.$t('alert.site');
                if (value === 'App\\Silo') return this.$t('alert.silo');

                return '';
            },
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Show delete modal
             *
             * @param {object} item
             */
            deleteItem(item) {
                this.itemToDelete = item;
                this.dialogDelete = true;
            },
            /**
             * Method used for the suppression of the alert
             */
            deleteAlert() {
                this.axios.delete(ALERTS + '/' + this.itemToDelete.id).then(() => {
                    this.count_alerts();
                    this.getGpsAlerts();
                    this.showSnackbar('success', this.$t('alert.deleted'));
                    this.dialogDelete = false;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Updating the datable after alert processing
             */
            getGpsAlerts() {
                this.loading = true;
                this.axios.get(GPS,
                    {params: {page: this.options.page, per_page: this.options.itemsPerPage,
                            logistic_areas: this.get_filters().logistic_areas_choices}}
                ).then((success) => {
                    this.alerts = success.data.data;
                    this.number_of_alerts = success.data.total;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                });
            },
            /**
             * @param {Object} item
             */
            goTo(item) {
                if (item.alertable_type === 'App\\Site') this.$router.push({
                    name: 'site',
                    params: {siteId: item.alertable_id}
                });
                if (item.alertable_type === 'App\\Silo') this.$router.push({
                    name: 'silo',
                    params: {siloId: item.alertable_id}
                });

                return '';
            },
            /**
             * Change options
             *
             * @param {object} options
             */
            changeOptions(options) {
                if (this.options.itemsPerPage !== options.itemsPerPage || this.options.page !== options.page) {
                    this.options.itemsPerPage = options.itemsPerPage;
                    this.options.page = options.page;

                    this.getGpsAlerts();
                }
            },
        }
    }
</script>
