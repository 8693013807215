import Vue from 'vue'
import * as _ from "lodash";

Vue.mixin({
    methods: {
        /**
         * Replace path params :var_id by value
         *
         * @param url
         * @param params
         */
        'replacePathParams' : (url, params) => {
            _.each(params, (value, name) => {
                url = _.replace(url, ':' + name, value);
            });

            return url;
        }
    }
});
