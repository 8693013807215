import Vue from 'vue';
import VueMoment from 'vue-moment';
import moment from 'moment'

const lang = document.documentElement.lang.substr(0, 2);
moment.locale(lang);

Vue.use(VueMoment, {
    moment
});
