<template>
    <div>
        <v-row>
            <v-col cols="8">
                <v-row>
                    <v-col v-if="silo_selected.sales_representative_name" cols="6">
                        <v-list dense subheader>
                            <v-subheader>
                                {{ $t('silo.sales_representative') }}
                            </v-subheader>
                            <v-list-item
                                v-if="silo_selected.sales_representative_name"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-account</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ silo_selected.sales_representative_name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="silo_selected.sales_representative_email"
                                :href="'mailto:'+ silo_selected.sales_representative_email"
                            >
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-email</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ silo_selected.sales_representative_email }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="silo_selected.sales_representative_phone"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-phone-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ silo_selected.sales_representative_phone }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="silo_selected.sales_representative_mobile"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-cellphone-android</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ silo_selected.sales_representative_mobile }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                        </v-list>
                    </v-col>
                    <v-col v-for="logistic_area in silo_selected.site.logistic_areas"
                           v-if="logistic_area.logistic_manager_name"
                           :key="logistic_area.id + logistic_area.logistic_manager_name"
                           cols="6"
                    >
                        <v-list
                            dense
                            subheader
                        >
                            <v-subheader>
                                {{ $t('repository.logistic_manager') }} &nbsp;
                                <span v-if="silo_selected.site.logistic_areas.length > 1">
                            ({{ $t('silo.logistic_area', {'id': logistic_area.code}) }})
                        </span>
                            </v-subheader>
                            <v-list-item v-if="logistic_area.logistic_manager_name">
                                <v-list-item-icon>
                                    <v-icon>mdi-account</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ logistic_area.logistic_manager_name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="logistic_area.logistic_manager_email"
                                :href="'mailto:'+ logistic_area.logistic_manager_email"
                            >
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-email</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ logistic_area.logistic_manager_email }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="logistic_area.logistic_manager_phone_number">
                                <v-list-item-icon>
                                    <v-icon>mdi-phone-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ logistic_area.logistic_manager_phone_number }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="logistic_area.logistic_manager_mobile_phone_number">
                                <v-list-item-icon>
                                    <v-icon>mdi-cellphone-android</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ logistic_area.logistic_manager_mobile_phone_number }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                        </v-list>
                    </v-col>
                    <v-col v-for="logistic_area in silo_selected.site.logistic_areas"
                           v-if="logistic_area.quality_safety_manager_name"
                           :key="logistic_area.id + logistic_area.quality_safety_manager_name"
                           cols="6"
                    >
                        <v-list
                            dense
                            subheader
                        >
                            <v-subheader>
                                {{ $t('repository.quality_safety_manager') }} &nbsp;
                                <span v-if="silo_selected.site.logistic_areas.length > 1">
                            ({{ $t('silo.logistic_area', {'id': logistic_area.code}) }})
                        </span>
                            </v-subheader>
                            <v-list-item v-if="logistic_area.quality_safety_manager_name">
                                <v-list-item-icon>
                                    <v-icon>mdi-account</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ logistic_area.quality_safety_manager_name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="logistic_area.quality_safety_manager_email"
                                :href="'mailto:'+ logistic_area.quality_safety_manager_email"
                            >
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-email</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ logistic_area.quality_safety_manager_email }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="logistic_area.quality_safety_manager_phone_number">
                                <v-list-item-icon>
                                    <v-icon>mdi-phone-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ logistic_area.quality_safety_manager_phone_number }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="logistic_area.quality_safety_manager_mobile_phone_number">
                                <v-list-item-icon>
                                    <v-icon>mdi-cellphone-android</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ logistic_area.quality_safety_manager_mobile_phone_number }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                        </v-list>
                    </v-col>
                    <v-col v-for="logistic_area in silo_selected.site.logistic_areas"
                           v-if="logistic_area.sandra_manager_name"
                           :key="logistic_area.id + logistic_area.sandra_manager_name"
                           cols="6"
                    >
                        <v-list
                            dense
                            subheader
                        >
                            <v-subheader>
                                {{ $t('repository.sandra_manager') }} &nbsp;
                                <span v-if="silo_selected.site.logistic_areas.length > 1">
                            ({{ $t('silo.logistic_area', {'id': logistic_area.code}) }})
                        </span>
                            </v-subheader>
                            <v-list-item v-if="logistic_area.sandra_manager_name">
                                <v-list-item-icon>
                                    <v-icon>mdi-account</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ logistic_area.sandra_manager_name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="logistic_area.sandra_manager_email"
                                :href="'mailto:'+ logistic_area.sandra_manager_email"
                            >
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-email</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ logistic_area.sandra_manager_email }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="logistic_area.sandra_manager_phone_number">
                                <v-list-item-icon>
                                    <v-icon>mdi-phone-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ logistic_area.sandra_manager_phone_number }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="logistic_area.sandra_manager_mobile_phone_number">
                                <v-list-item-icon>
                                    <v-icon>mdi-cellphone-android</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ logistic_area.sandra_manager_mobile_phone_number }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                        </v-list>
                    </v-col>
                    <v-col v-for="logistic_area in silo_selected.site.logistic_areas"
                           v-if="logistic_area.sales_manager_name"
                           :key="logistic_area.id + logistic_area.sales_manager_name"
                           cols="6"
                    >
                        <v-list
                            dense
                            subheader
                        >
                            <v-subheader>
                                {{ $t('repository.sales_manager') }} &nbsp;
                                <span v-if="silo_selected.site.logistic_areas.length > 1">
                            ({{ $t('silo.logistic_area', {'id': logistic_area.code}) }})
                        </span>
                            </v-subheader>
                            <v-list-item v-if="logistic_area.sales_manager_name">
                                <v-list-item-icon>
                                    <v-icon>mdi-account</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ logistic_area.sales_manager_name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item
                                v-if="logistic_area.sales_manager_email"
                                :href="'mailto:'+ logistic_area.sales_manager_email"
                            >
                                <v-list-item-icon>
                                    <v-icon color="primary">mdi-email</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ logistic_area.sales_manager_email }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="logistic_area.sales_manager_phone">
                                <v-list-item-icon>
                                    <v-icon>mdi-phone-outline</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ logistic_area.sales_manager_phone }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="logistic_area.sales_manager_mobile">
                                <v-list-item-icon>
                                    <v-icon>mdi-cellphone-android</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ logistic_area.sales_manager_mobile }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-divider></v-divider>
                        </v-list>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="4">
                <div class="d-flex justify-space-between align-center">
                    <h2 class="my-4">
                        {{ $t('silo.actual_risks') }}
                    </h2>
                    <v-btn
                        color="primary"
                        @click="dialog = true"
                    >
                        {{ $t('silo.edit') }}
                    </v-btn>
                </div>

                <div v-if="orderedRisks.length > 0">
                    <div v-for="risk in orderedRisks">
                        <div class="d-flex flex-row align-center my-2">
                            <div v-if="risk.media[0] !== undefined" class="text-center">
                                <img
                                    :alt="$t('repository.pictogram')"
                                    :src="`storage/${risk.media[0].id}/${risk.media[0].name}`"
                                    height="48"
                                >
                            </div>
                            <p v-if="risk.media[0] !== undefined" class="ma-0 pl-4">
                                {{ risk.name }}
                            </p>
                            <p v-else class="ma-0">
                                {{ risk.name }}
                            </p>
                        </div>
                        <v-divider></v-divider>
                    </div>
                </div>
                <div v-else>
                    <p>{{ $t('silo.no_risk') }}</p>
                </div>

                <h2 class="my-4">
                    {{ $t('silo.instructions') }}
                </h2>

                <div v-if="silo.instructions.length > 0">
                    <div v-for="instruction in silo.instructions">
                        <p>
                            {{ instruction.name }}
                        </p>
                    </div>
                </div>
                <div v-else>
                    <p>{{ default_instruction.name }}</p>
                </div>

                <h2 class="my-4">
                    {{ $t('silo.comment') }}
                </h2>

                <p v-if="silo.comments">{{ silo.comments }}</p>
                <p v-else>{{ $t('silo.no_comment') }}</p>

            </v-col>
        </v-row>

        <v-dialog v-model="dialog" max-width="600px">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('silo.edit') }}</v-card-title>
                <v-card-text>
                    <v-list shaped>
                        <v-list-item-group
                            v-model="silo_to_update.risks"
                            multiple
                        >
                            <template v-for="(risk, i) in risks">
                                <v-list-item
                                    :key="`item-${i}`"
                                    :value="risk.id"
                                    active-class="primary--text text--accent-4"
                                    class="py-2"
                                >
                                    <template v-slot:default="{ active, toggle }">
                                        <div v-if="risk.media[0] !== undefined" class="text-center">
                                            <img
                                                :alt="$t('repository.pictogram')"
                                                :src="`storage/${risk.media[0].id}/${risk.media[0].name}`"
                                                height="48"
                                            >
                                        </div>
                                        <v-list-item-content>
                                            <v-list-item-title class="pl-4">
                                                {{ risk.name }}
                                            </v-list-item-title>
                                        </v-list-item-content>

                                        <v-list-item-action>
                                            <v-checkbox
                                                :input-value="active"
                                                :true-value="risk"
                                                class="z-index: 10"
                                                color="primary accent-4"
                                                @click="toggle = true"
                                            ></v-checkbox>
                                        </v-list-item-action>
                                    </template>
                                    <v-divider></v-divider>
                                </v-list-item>
                                <v-divider></v-divider>
                            </template>
                        </v-list-item-group>
                    </v-list>
                    <v-col cols="12">
                        <v-textarea
                            v-model="silo_to_update.comments"
                            :label="$t('silo.comment')"
                            :value="silo_to_update.comments"
                            maxlength="255"
                            name="input-7-4"
                            outlined
                        ></v-textarea>
                    </v-col>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" text @click="dialog = false">
                        {{ $t('silo.cancel') }}
                    </v-btn>
                    <v-btn :disabled="updating" color="warning darken-1" text @click="updateSiloRisks()">
                        {{ $t('silo.edit') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" :color="snackbar_type" top>
            {{ snackbar_text }}
            <v-btn text @click="snackbar = false">
                Close
            </v-btn>
        </v-snackbar>

    </div>
</template>

<script>
import {INSTRUCTIONS, RISKS, SILO} from "../../api";
import {mapActions} from "vuex";

export default {
    name: "information",
    data() {
        return {
            silo: {
                security_level: {},
                risks: [],
                instructions: [],
                comments: null,
            },
            risks: [],
            default_instruction: {},
            dialog: false,
            updating: false,
            snackbar: false,
            snackbar_text: '',
            snackbar_type: null,
            silo_to_update: {
                comments: null,
                risks: [],
            },
        }
    },
    props: {
        silo_selected: {
            type: Object,
            required: true
        }
    },
    methods: {
        ...mapActions({'countAlerts': 'alert/count'}),
        /**
         * Reset snackbar
         */
        resetSnackbar() {
            this.snackbar_type = null;
            this.snackbar_text = null;
            this.snackbar = false;
        },
        /**
         * Show snackbar
         *
         * @param {string} type
         * @param {string} text
         */
        showSnackbar(type, text) {
            this.snackbar_type = type;
            this.snackbar_text = text;
            this.snackbar = true;
        },
        /**
         * Get all the instructions
         *
         * @returns {Promise<void>}
         */
        getDefaultInstruction() {
            this.axios.get(INSTRUCTIONS).then((success) => {
                this.default_instruction = success.data.find(function (element) {
                    return element.is_default === 1;
                });
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },
        /**
         * Get all the risks
         *
         * @returns {Promise<void>}
         */
        getRisks() {
            this.axios.get(RISKS).then((success) => {
                this.risks = success.data
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            });
        },
        /**
         * Method used to update the silo risks
         *
         * @param silo
         */
        updateSiloRisks() {
            this.updating = true;
            this.axios.patch(
                this.replacePathParams(SILO, {silo: this.silo.id}),
                this.silo_to_update
            ).then((success) => {
                this.showSnackbar('success', this.$t('silo.silo_updated'));
                Object.assign(this.silo, success.data);
                this.$emit('updateRisksInvolved', success.data);
                this.countAlerts();
            }).catch((error) => {
                if (error.response.status === 422) {
                    this.showSnackbar('warning', this.parseLaravelError(error));
                } else {
                    this.showSnackbar('error', this.$t('global.error'));
                    this.dialog = false;
                }
            }).finally(() => {
                this.updating = false;
                this.dialog = false;
            })
        },
    },
    mounted() {
        this.getDefaultInstruction();
        this.getRisks();
    },
    watch: {
        silo_selected: function (val) {
            this.silo = val;
            this.silo_to_update.comments = this.silo.comments;
            var risks = [];
            this.silo.risks.forEach((risk) => {
                risks.push(risk.id);
            });

            this.silo_to_update.risks = Array.from(new Set(risks));
        }
    },
    computed: {
        /**
         * Order risks by position
         *
         */
        orderedRisks() {
            return _.orderBy(this.silo.risks, 'position')
        },
    }
}
</script>

<style scope="scss">
.v-application--is-ltr .v-list--shaped .v-list-item, .v-application--is-ltr .v-list--shaped .v-list-item::before, .v-application--is-ltr .v-list--shaped .v-list-item > .v-ripple__container {
    border-radius: 0 !important;
}
</style>
