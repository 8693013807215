import Vue from 'vue'
import {AUTH_LOGIN, AUTH_LOGOUT, AUTH_ME, AUTH_REFRESH} from "../api";
import __utils from '@websanova/vue-auth/src/lib/utils.js'

var __transitionPrev = null,
    __transitionThis = null,
    __transitionRedirectType = null;

Vue.use(require('@websanova/vue-auth'), {
    auth: {
        request: function (req, token) {
            this.options.http._setHeaders.call(this, req, {Authorization: 'Bearer ' + token});
        },
        response: function (res) {
            let token = this.options.http._httpData.call(this, res).access_token;
            if (token) {
                token = token.split(/Bearer:?\s?/i);
                return token[token.length > 1 ? 1 : 0].trim();
            }
        }
    },
    http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
    router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
    fetchData: {
        url: AUTH_ME,
        method: 'POST',
    },
    loginData: {
        url: AUTH_LOGIN,
        redirect: '',
        fetchUser: true
    },
    parseUserData: function (data) {
        return data || {};
    },
    registerData: {
        url: '',
    },
    logoutData: {
        url: AUTH_LOGOUT,
        redirect: '/login',
    },
    refreshData: {
        url: AUTH_REFRESH,
        method: 'POST',
        redirect: ''
    },
    transitionEach(transition, routeAuth, cb) {
        let authRedirect = (routeAuth || '').redirect || this.options.authRedirect,
            forbiddenRedirect = (routeAuth || '').forbiddenRedirect || (routeAuth || '').redirect || this.options.forbiddenRedirect,
            notFoundRedirect = (routeAuth || '').redirect || this.options.notFoundRedirect;

        routeAuth = __utils.toArray((routeAuth || '').roles !== undefined ? routeAuth.roles : routeAuth);

        if (routeAuth && (routeAuth === true || routeAuth.constructor === Array || __utils.isObject(routeAuth))) {
            if ( ! this.check()) {
                __transitionRedirectType = 401;
                cb.call(this, authRedirect);
            }
            else if ((routeAuth.constructor === Array || __utils.isObject(routeAuth)) && (_.intersectionBy(_.map(routeAuth, role=>{return {name:role.name}}), _.map(this.watch.data[this.options.rolesVar], role=>{return {name:role.name}}), 'name')).length <= 0) {
                __transitionRedirectType = 403;
                cb.call(this, forbiddenRedirect);
            }
            else {
                this.watch.redirect = __transitionRedirectType ? {type: __transitionRedirectType, from: __transitionPrev, to: __transitionThis} : null;
                __transitionRedirectType = null;

                return cb();
            }
        }
        else if (routeAuth === false && this.check()) {
            __transitionRedirectType = 404;
            cb.call(this, notFoundRedirect);
        }
        else {
            this.watch.redirect = __transitionRedirectType ? {type: __transitionRedirectType, from: __transitionPrev, to: __transitionThis} : null;
            __transitionRedirectType = null;

            return cb();
        }
    },
    check(role, key) {
        if (this.watch.authenticated === true) {
            if (role) {
                return !! _.find(this.watch.data[key || this.options.rolesVar], function(o) { return o.name === role; })
            }

            return true;
        }

        return false;
    }
});
