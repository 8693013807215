<template>
    <v-col class=" flex-column" cols="12">
        <v-row>
            <v-col
                class="white"
            >
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('contribution.driver_title') }}
                    </p>
                </v-subheader>
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('report.kpis'),
                                  disabled: false,
                                  href: '/#/dashboard',
                                },
                                {
                                  text: $t('contribution.driver_title'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                              ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2"
                v-show="logistic_areas.length > 1"
            >
                <v-autocomplete
                    id="logistic_area"
                    v-model="logistic_areas_choices"
                    :clearable="true"
                    :filter="filterAreas"
                    :items="orderedAreas"
                    :label="$t('tour.logistic_areas')"
                    :search-input.sync="searchAreasInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="logistic_area"
                    prepend-icon="mdi-target"
                >
                    <template v-slot:selection="{item, index}">
                        <v-chip v-if="index <= 0">
                            {{item.code}}
                        </v-chip>
                        <span v-if="index === 1">
                            (+ {{logistic_areas_choices.length - 1}})
                        </span>
                    </template>
                    <v-list
                        v-if="isSelectAllAreasAvailable"
                        slot="prepend-item"
                        class="pa-0"
                    >
                        <v-list-item-group v-model="toggleAreas" class="d-flex align-center pa-0">
                            <v-list-item>
                                <v-list-item-icon class="mr-2">
                                    <v-icon :color="logistic_areas_choices.length > 0 ? 'primary' : ''">
                                        {{ iconAreas }}
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('global.select_all') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                    <v-divider
                        slot="prepend-item"
                    ></v-divider>
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="2">
                <VueCtkDateTimePicker
                    id="start_date"
                    v-model="dates"
                    :button-color="'#e3672b'"
                    :color="'#e3672b'"
                    :custom-shortcuts="customShortcuts"
                    :format="date_format"
                    :formatted="'ll'"
                    :hint="$t('exchange.start_date')"
                    :label="$t('exchange.start_date')"
                    :locale="lang"
                    :range="true"
                />
            </v-col>
            <v-col cols="2">
                <v-autocomplete
                    id="transporter"
                    v-model="transporters_choices"
                    :clearable="true"
                    :filter="filterTransporters"
                    :items="transporters"
                    :label="$t('dashboard.transporters')"
                    :search-input.sync="searchTransportersInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="transporter"
                    prepend-icon="mdi-truck"
                    @change="searchTransportersInput=''"
                >
                </v-autocomplete>
            </v-col>
            <v-col cols="2">
                <v-autocomplete
                    id="fleets"
                    v-model="fleet_choices"
                    :clearable="true"
                    :items="fleets"
                    :label="$t('dashboard.fleets')"
                    :search-input.sync="searchFleetsInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="fleets"
                    prepend-icon="mdi-truck"
                    @change="searchFleetsInput=''"
                >
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="2" >
                <v-autocomplete
                    id="conditioning"
                    v-model="conditioning__choices"
                    :clearable="true"
                    :items="conditionings"
                    :label="$t('dashboard.conditioning')"
                    :search-input.sync="searchConditioningsInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="transporter"
                    prepend-icon="mdi-truck"
                    @change="searchConditioningsInput=''"
                >
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="2">
                <v-btn color="primary" @click="applyFilter">{{ $t('tour.apply') }}</v-btn>
            </v-col>
        </v-row>
        <v-row class="mt-0">

        </v-row>
        <div class="d-flex justify-center align-center flex-row ">
           <div :class="{
               'active-tab': active_tab === 'driver',
               'inactive-tab': active_tab !== 'driver'
               }"
                class="pa-4 clickable text--white"
                @click="active_tab='driver'"
           ><v-icon color="black">mdi-account</v-icon></div>
           <div :class="{
               'active-tab': active_tab === 'vehicle',
               'inactive-tab': active_tab !== 'vehicle'
                }"
                class="pa-4 clickable text--white"
                @click="active_tab='vehicle'">
               <v-icon color="black">
                   mdi-truck
               </v-icon>
           </div>
       </div>
        <v-card :loading="loading" class="d-block w-full mt-4" elevation="0">
            <div v-if="loading || items.length === 0" class=" d-flex justify-center align-center ">Aucune donnée</div>
            <table v-else class="w-full m-auto">
                <tr class="table-header stick-top">
                    <th>Véhicule/conducteur</th>
                    <th  class="clickable" @click="sortColumn('RowCRValidated')">CR Validé
                        <span v-if="lastColumnSorted === 'RowCRValidated'">
                             <span v-if="sortValues[sortIndex%3] === 'asc'">
                                 <v-icon small>mdi-arrow-up</v-icon>
                             </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                    </th>
                    <th class="clickable" @click="sortColumn('RowTourReport')">Relevé par tour
                        <span v-if="lastColumnSorted === 'RowTourReport'">
                             <span v-if="sortValues[sortIndex%3] === 'asc'">
                                 <v-icon small>mdi-arrow-up</v-icon>
                             </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                    </th>
                    <th class="clickable" @click="sortColumn('RowPlanedLoading')">Planning Chargement
                        <span v-if="lastColumnSorted === 'RowPlanedLoading'">
                             <span v-if="sortValues[sortIndex%3] === 'asc'">
                                 <v-icon small>mdi-arrow-up</v-icon>
                             </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                    </th>
                    <th class="clickable" @click="sortColumn('RowTotal')">Total
                        <span v-if="lastColumnSorted === 'RowTotal'">
                             <span v-if="sortValues[sortIndex%3] === 'asc'">
                                 <v-icon small>mdi-arrow-up</v-icon>
                             </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                    </th>
                </tr>
                <template v-for="(item, code) in items">
                    <tr :key="code+item">
                        <td class="table-title font-weight-bold clickable"  @click="item.visibility = !item.visibility">
                            <span v-if="item.visibility"><v-icon>mdi-minus</v-icon></span>
                            <span v-if="item.visibility === false" ><v-icon>mdi-plus</v-icon></span>
                            {{ item.main }}</td>
                        <td :class="colorFromNumber(RowCRValidated(item))">{{RowCRValidated(item)}}%</td>
                        <td :class="colorFromNumber(RowTourReport(item))">{{RowTourReport(item)}}%</td>
                        <td :class="colorFromNumber(RowPlanedLoading(item))">{{RowPlanedLoading(item)}}%</td>
                        <td :class="colorFromNumber(RowTotal(item))">{{RowTotal(item)}}%</td>
                    </tr>
                    <tr v-for="(transporter, name) in item.data" v-if="item.visibility" :key="name+code">
                        <td class="table-subtitle">{{name}}</td>
                        <td :class="colorFromNumber(formatNaN(Math.round((transporter.CRCompleted * 100) / transporter.totalSteps)))">
                            {{formatNaN(Math.round((transporter.CRCompleted * 100) / transporter.totalSteps))}}%</td>
                        <td  :class="colorFromNumber(formatNaN(Math.round((transporter.tourReport * 100) / transporter.numberTours)))" class="border">
                            {{formatNaN(Math.round((transporter.tourReport * 100) / transporter.numberTours)) }}%</td>
                        <td :class="colorFromNumber(formatNaN(Math.round((transporter.loadingPlanned * 100) /  transporter.totalLoadingStep)))">
                            {{formatNaN(Math.round((transporter.loadingPlanned * 100) /  transporter.totalLoadingStep))}}%</td>
                        <td :class="colorFromNumber(Math.round((
                            formatNaN(Math.round((transporter.CRCompleted * 100) / transporter.totalSteps)) +
                            formatNaN(Math.round((transporter.tourReport * 100) / transporter.numberTours)) +
                            formatNaN(Math.round((transporter.loadingPlanned * 100) /  transporter.totalLoadingStep))
                            ) /3))" >
                            {{
                                Math.round((
                                    formatNaN(Math.round((transporter.CRCompleted * 100) / transporter.totalSteps)) +
                                    formatNaN(Math.round((transporter.tourReport * 100) / transporter.numberTours)) +
                                    formatNaN(Math.round((transporter.loadingPlanned * 100) /  transporter.totalLoadingStep))
                                ) /3)

                            }} %</td>
                    </tr>
                </template>

            </table>
        </v-card>
    </v-col>
</template>
<style>
.clickable{
    cursor: pointer;
}
</style>
<script>
import {
    AREA_TRANSPORTERS,
    DRIVER_CONTRIBUTION,
    USER_LOGISTIC_AREAS,
    VEHICLE_CONDITIONNINGS,
    VEHICLE_FLEETS
} from '../../api'
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";

export default {
name: "DriverContribution",
    data(){
        return{
            dates: {
                start: this.$moment().add(-7, 'days').format('YYYY-MM-DD'),
                end: this.$moment().format('YYYY-MM-DD')
            },
            logistic_areas: [],
            logistic_areas_choices: [],
            transporters: [],
            searchTransportersInput: '',
            transporters_choices: [],
            number_exposition_inventory: null,
            customShortcuts: [
                {
                    key: "next_week", label: this.$t('dashboard.next_week'), value: () => {
                        return {
                            start: this.$moment().add(7, 'day').startOf('week'),
                            end: this.$moment().add(7, 'day').endOf('week')
                        }
                    }
                },
                {
                    key: 'tomorrow', label: this.$t('dashboard.tomorrow'), value: () => {
                        return {
                            start: this.$moment().startOf('day').add(1, "day"),
                            end: this.$moment().endOf('day').add(1, "day")
                        }
                    }
                },
                {key: 'today', label: this.$t('dashboard.today'), value: 'day'},
                {key: 'yesterday', label: this.$t('dashboard.yesterday'), value: '-day'},
                {key: '7_last_days', label: this.$t('dashboard.7_last_days'), value: 7},
                {key: 'last_week', label: this.$t('dashboard.last_week'), value: '-isoWeek'},
                {key: 'last_month', label: this.$t('dashboard.last_month'), value: '-month'},
                {
                    key: '12_last_months', label: this.$t('dashboard.12_last_months'), value: () => {
                        return {
                            start: this.$moment().add(-12, "month"),
                            end: this.$moment()
                        }
                    }
                },
            ],
            searchAreasInput: '',
            lang,
            date_format: 'Y-MM-DD H:mm',
            toggleAreas: null,

            active_tab:"driver",
            loading: false,

            items:[],
            global:{},

            sortValues:["asc", "dsc", null],
            sortIndex:0,
            lastColumnSorted:null,

            conditioning__choices :[],
            conditionings :[],
            searchConditioningsInput :'',
            fleet_choices :[],
            fleets :[],
            searchFleetsInput :'',
        }
    },
    methods:{
        getTable(){
            this.loading = true
            this.axios.get(DRIVER_CONTRIBUTION, {params:{...this.filter, order:this.active_tab}})
            .then(response => {
                this.loading = false
                this.items= response.data
                this.items = _.forEach(this.items.items,(item, index) => {
                    const data = item
                    this.items.items[index] = {
                        main: index,
                        visibility: false,
                        data,
                    }
                })
                this.items = _.sortBy(this.items, item => item.main)
                this.global= response.data.global
            })
        },
        toPercent(value, total){
            return _.round((value * 100)/total);
        },
        async getTransporters() {
            let {data} = await this.axios.get(
                AREA_TRANSPORTERS,
                {
                    params: {
                        logistic_areas: this.logistic_areas_choices
                    },
                }
            );

            this.transporters = _.orderBy(data, ['code']);
            this.transporters_choices = this.get_filters().transporters_choices
        },
        async getLogisticAreas() {
            try {
                let success = await this.axios.get(USER_LOGISTIC_AREAS)
                this.logistic_areas = _.orderBy(success.data, ['name']);
                if (this.$auth.user().reference_logistic_areas.length > 0) {
                    this.logistic_areas_choices = _.map(this.$auth.user().reference_logistic_areas, 'id')
                } else if (this.logistic_areas.length === 1) {
                    this.logistic_areas_choices = [this.logistic_areas[0].id]
                }
                if (this.get_filters().logistic_areas_choices.length > 0){
                    this.logistic_areas_choices =  this.get_filters().logistic_areas_choices
                }
            }catch (e){
                this.showSnackbar('error', this.$t('global.error'));
            }
        },
        applyFilter() {
            this.setFilter(this.filter)
            this.getTable();
        },
        ...mapActions({
            setFilter: 'alert/setFilter'
        }),
        ...mapGetters({
            get_filters: "alert/get_filters"
        }),
        filterAreas(item, queryText) {
            let textOne = "";
            let textTwo = "";
            let searchText = "";
            if (item.code && item.name) {
                textOne = item.name.toLowerCase();
                textTwo = item.code.toLowerCase();
                searchText = queryText.toLowerCase();
            }

            return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
        },
        filterTransporters(item, queryText) {
            var text = "";
            var searchText = "";
            if (item.code) {
                text = item.code.toLowerCase();
                searchText = queryText.toLowerCase();
            }

            return text.indexOf(searchText) > -1;
        },
        RowCRValidated(item){
            let completed = 0
            let total = 0
            _.forEach(item.data,row => {
                completed += row.CRCompleted
                total += row.totalSteps
            })
            return this.formatNaN(Math.round((completed * 100) /total))
        },
        RowTourReport(item){
            let reported = 0
            let total = 0
            _.forEach(item.data,row => {
                reported += row.tourReport
                total += row.numberTours
            })
            return this.formatNaN(Math.round((reported * 100)/total))
        },
        RowPlanedLoading(item){
            let planned = 0
            let total = 0
            _.forEach(item.data,row => {
                planned += row.loadingPlanned
                total += row.totalLoadingStep
            })
            return this.formatNaN(Math.round((planned * 100)/total))
        },
        RowTotal(item){
            return this.formatNaN(Math.round((this.RowCRValidated(item) + this.RowPlanedLoading(item) + this.RowTourReport(item)) / 3))
        },
        colorFromNumber(number){
            number = parseInt(number)
            if(number == 0) {
                return 'grey-cell  '
            }
            if( _.inRange(number, 0, 50)) {
                return 'red-cell  '
            }
            if( _.inRange(number, 50, 85)) {
                return 'orange-cell  '
            }
            if( _.inRange(number, 85, 100)) {
                return 'yellow-cell '
            }
            if( number == 100) {
                return "green-cell "
            }
        },
        formatNaN(number){
            if (isNaN(number) || number === Infinity){
                return 0
            }
            return number
        },
        sortColumn(columnName){
            if (this.lastColumnSorted === columnName){
                this.sortIndex ++
            }else{
                this.sortIndex = 0
                this.lastColumnSorted = columnName
            }

            this.items = _.sortBy(this.items, item => {
                switch (columnName){
                    case "RowCRValidated":
                        return this.RowCRValidated(item)
                    case "RowTourReport":
                        return this.RowTourReport(item)
                    case "RowPlanedLoading":
                        return this.RowPlanedLoading(item)
                    case "RowTotal":
                        return this.RowTotal(item)
                    }
            })
            switch(this.sortValues[this.sortIndex % 3]){
                case "dsc":
                    this.items =_.reverse(this.items)
                    break
                case "asc":
                    break
                default:
                    this.items = _.sortBy(this.items, item => item.main)
                    break
            }
        },
        async getFleets(){
            this.fleets = _.sortBy((await axios.get(VEHICLE_FLEETS)).data, ['name'])
        },
        async getConditionings(){
            this.conditionings = _.sortBy((await axios.get(VEHICLE_CONDITIONNINGS)).data, ['name'])
        }
    },
    watch:{
        active_tab(){
            this.getTable()
        },
        toggleAreas() {
            this.$nextTick(() => {
                if (this.allAreas) {
                    this.logistic_areas_choices = []
                } else {
                    this.logistic_areas.forEach(element => {
                        if (!this.logistic_areas_choices.includes(element.id)) {
                            this.logistic_areas_choices.push(element.id);
                        }
                    });
                }
            })
        },
    },
    computed:{
        orderedAreas() {
            return _.orderBy(this.logistic_areas, 'code')
        },
        isSelectAllAreasAvailable() {
            return this.searchAreasInput === '' || this.searchAreasInput === null;
        },
        filter() {
            return {
                dates: [this.dates.start, this.dates.end],
                logistic_areas_choices: this.logistic_areas_choices,
                transporters_choices: this.transporters_choices,
                fleet_choices: this.fleet_choices,
                conditioning__choices: this.conditioning__choices,
            }
        },
        iconAreas() {
            if (this.allAreas) return 'mdi-close-box'
            if (this.someAreas) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    async mounted() {
        await this.getTransporters()
        await this.getLogisticAreas()
        await this.getFleets()
        await this.getConditionings()
        this.transporters_choices = this.$store.getters["alert/get_filters"].transporters_choices
        this.conditioning__choices = this.$store.getters['alert/get_filters'].conditioning__choices
        this.fleet_choices = this.$store.getters['alert/get_filters'].fleet_choices
        if (this.$store.getters["alert/get_filters"].dates){
            this.dates.start = this.$store.getters["alert/get_filters"].dates[0]
            this.dates.end = this.$store.getters["alert/get_filters"].dates[1]
        }
        this.getTable()
    }
}
</script>

<style scoped>
.w-full{
    width: 100%;
}
.stick-top{
    top: 64px;
}

table tr th{
    height: 4rem;
    background-color: white;
}
td, th {
    width: 100px;
    overflow: hidden;
}

tr {
    text-align: center;
}

tr:hover {
    background: rgba(0,0,0,.02);
}
.table-header {
    color: rgba(0, 0, 0, 0.6);
}

.table-title {
    text-align: left;
    padding-left: 1rem;
    line-height: 56px;
    background-color: #f1f1f1;
}

.table-subtitle {
    color: rgba(0,0,0,.7);
    text-align: left;
    padding-left: 1rem;
}
table {
    table-layout: fixed;
}
table, td, th {
    border-collapse: collapse;
    border: 1px solid black;
}
.m-auto {
    margin: auto;
}


.active-tab{
    border-bottom: 5px solid orangered;
}
.red-cell{
    background: #ffcdd2;
}
.green-cell{
    background: #c8e6c9;
}
.orange-cell{
    background: #ffe0b2 ;
}
.yellow-cell{
    background: #fff9c4 ;
}
.grey-cell{
    background: #e0e0e0 ;
}

</style>
