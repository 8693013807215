<template>
    <div>
        <v-row>
            <v-col
                class="white"
            >
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('navigation.profile') }}
                    </p>
                </v-subheader>

                <v-breadcrumbs
                    :items="[
                                {
                                  text: $auth.user().name,
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                                {
                                  text: $t('navigation.profile'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                              ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-card
                    center
                >
                    <v-card-text>
                        <v-form name="profile" data-vv-scope="profile">
                            <v-text-field
                                :label="$t('profile.email')"
                                name="profile.email"
                                prepend-icon="mdi-email"
                                v-validate="'required|email'"
                                type="text"
                                :error-messages="errors.collect('profile.email')"
                                data-vv-name="profile.email"
                                v-model="email"
                            ></v-text-field>
                            <v-text-field
                                :label="$t('profile.name')"
                                v-validate="'required|min:3'"
                                id="profile.name"
                                name="profile.name"
                                prepend-icon="mdi-account"
                                type="text"
                                :error-messages="errors.collect('profile.name')"
                                data-vv-name="profile.name"
                                v-model="name"
                            ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn
                            :disabled="submit_button_is_disabled"
                            @click.prevent="validateBeforeSubmit('profile')"
                        >
                            {{ $t('profile.update') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col cols="6">
                <v-card
                    center
                >
                    <v-card-text>
                        <v-form name="security" data-vv-scope="security">
                            <v-text-field
                                :label="$t('profile.password')"
                                v-validate="'required'"
                                id="security.password"
                                name="security.password"
                                prepend-icon="mdi-lock-alert"
                                type="password"
                                :error-messages="errors.collect('security.password')"
                                data-vv-name="security.password"
                                v-model="password"
                            ></v-text-field>
                            <v-text-field
                                :label="$t('profile.new_password')"
                                v-validate="'required|min:8'"
                                id="security.new_password"
                                name="security.new_password"
                                prepend-icon="mdi-lock"
                                type="password"
                                :error-messages="errors.collect('security.new_password')"
                                data-vv-name="security.new_password"
                                v-model="new_password"
                                ref="security.new_password"
                            ></v-text-field>
                            <v-text-field
                                :label="$t('profile.new_password_confirmation')"
                                v-validate="'required|confirmed:security.new_password|min:8'"
                                id="security.new_password_confirmation"
                                name="security.new_password_confirmation"
                                prepend-icon="mdi-lock"
                                type="password"
                                :error-messages="errors.collect('security.new_password_confirmation')"
                                data-vv-name="security.new_password_confirmation"
                                data-vv-as="security.new_password"
                                v-model="new_password_confirmation"
                            ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn
                            :disabled="submit_button_is_disabled"
                            @click.prevent="validateBeforeSubmit('security')"
                        >
                            {{ $t('profile.update') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-overlay :value="loading">
                <v-progress-circular indeterminate size="64"></v-progress-circular>
            </v-overlay>
        </v-row>
        <v-snackbar
            v-model="snackbar"
            top
            :color="snackbar_type"
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import {PROFILES} from "../api";

    export default {
        name: "profile",
        data() {
            return {
                email: this.$auth.user().email,
                name: this.$auth.user().name,
                password: null,
                new_password: null,
                new_password_confirmation: null,
                submit_button_is_disabled: false,
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                loading: false,
            }
        },
        methods: {
            /**
             * Validate form from scope
             *
             * @param {string} scope
             *
             */
            validateBeforeSubmit(scope) {
                this.submit_button_is_disabled = true;

                this.$validator.validateAll(scope).then((result) => {
                    if (result) {
                        let data = {};
                        if (scope === 'profile') {
                            data.name = this.name;
                            data.email = this.email;
                        }

                        if (scope === 'security') {
                            data.password = this.password;
                            data.new_password = this.new_password;
                            data.new_password_confirmation = this.new_password_confirmation;
                        }

                        this.update(data).finally(() => {
                            this.submit_button_is_disabled = false;
                            this.$auth.fetch()
                        });
                    }

                    this.submit_button_is_disabled = false;
                });
            },
            update(profile) {
                return new Promise((cb_success, cb_error) => {
                    this.loading = true;
                    this.axios.patch(PROFILES, profile).then(() => {
                        this.showSnackbar('success', this.$t('profile.updated'));
                        cb_success();
                    }).catch((error) => {
                        if (error.response.status === 422) {
                            this.showSnackbar('warning', this.parseLaravelError(error));
                        } else {
                            this.showSnackbar('error', this.$t('global.error'));
                        }
                        cb_error();
                    }).finally(() => {
                        this.loading = false;
                    })
                });
            },
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            }
        }
    }
</script>
