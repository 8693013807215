<template>

    <div>

        <v-data-table
            ref="databaseSilo"
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
            :headers="headers"
            :items="silos"
            :loading-text="$t('global.loading_text')"
            :no-data-text="$t('global.no_data')"
            :no-results-text="$t('global.no_results_text')"
        >
            <template v-slot:item.location_state.name="{ item }">
                <v-badge
                    :color="getGpsColor(item)"
                    :icon="item.location_state_id === 3 ? 'mdi-lock' : ''"
                    bordered
                    overlap
                >
                    <v-icon :title="getTitleFromGps(item)" left>mdi-crosshairs-gps</v-icon>
                </v-badge>
            </template>

            <template v-slot:item.last_security_action="{ item }">
                <span v-if="item.last_security_action">
                    {{item.last_security_action.type.description}}
                </span>
                <span v-else>
                   -
                </span>
            </template>
            <template v-slot:item.last_delivery_datetime="{ item }">
                <span v-if="item.last_delivery_datetime">
                    {{ item.last_delivery_datetime | moment('L LT') }}
                </span>
                <span v-else>
                    {{ $t('silo.none') }}
                </span>
            </template>
            <template v-slot:item.category.name="{ item }">
                <span v-if="item.category">
                    {{item.category.name||$t('site.no_data')}}
                </span>
            </template>
            <template v-slot:item.security_level.color="{ item }">
                <img v-if="item.security_level" :src="'images/' + getSecurityLevelImage(item.security_level.color)">
            </template>
            <template v-slot:item.risks="{ item }">
                <img v-for="risk in item.risks"
                     v-if="risk.media && risk.media.length > 0"
                     :src="`storage/${risk.media[0].id}/${risk.media[0].name}`"
                     width="48"
                >
            </template>
            <template v-slot:item.actions="{ item }">
                <v-row>
                    <v-icon
                        :title="$t('site.update')"
                        small
                        @click="action(item)"
                    >
                        mdi-lead-pencil
                    </v-icon>
                    <v-icon
                        :title="$t('silo.go_to_silo')"
                        class="mr-2"
                        small
                        @click="goTo(item.id)"
                    >
                        mdi-arrow-right
                    </v-icon>
                    <v-icon
                        v-if="mergeEnabled"
                        :title="$t('silo.merge')"
                        class="mr-2"
                        small
                        @click="openMergeDialog(item)"
                    >
                        mdi-file-replace-outline
                    </v-icon>
                </v-row>
            </template>
        </v-data-table>

        <v-dialog v-model="dialog" max-width="600px">

            <v-card>

                <v-list>
                    <v-subheader>{{ $t('alert.treated_silo') }}</v-subheader>
                </v-list>

                <v-card-text>

                    <v-form name="silos">

                        <v-text-field
                            v-model="existing_silo.code"
                            :label="$t('alert.code')"
                            data-vv-name="alert.code"
                            name="alert.code"
                            prepend-icon="mdi-alpha-c-box"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            id="alert.name"
                            v-model="existing_silo.name"
                            :label="$t('alert.name')"
                            data-vv-name="alert.name"
                            name="alert.name"
                            prepend-icon="mdi-rename-box"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            id="alert.comment"
                            v-model="existing_silo.comments"
                            :label="$t('alert.comment')"
                            data-vv-name="alert.comment"
                            name="alert.comment"
                            prepend-icon="mdi-comment-text-multiple-outline"
                            type="text"
                        ></v-text-field>

                        <v-select
                            id="alert.equipment"
                            v-model="existing_silo.equipment_id"
                            v-validate="'required'"
                            :items="equipment"
                            :label="$t('alert.equipment')"
                            chips
                            data-vv-name="equipment"
                            item-text="name"
                            item-value="id"
                            name="alert.equipment"
                            prepend-icon="mdi-toolbox"
                            required
                        >
                        </v-select>

                        <v-select
                            id="alert.accesses"
                            v-model="existing_silo.access_id"
                            v-validate="'required'"
                            :items="accesses"
                            :label="$t('alert.accesses')"
                            chips
                            data-vv-name="accesses"
                            item-text="name"
                            item-value="id"
                            name="alert.accesses"
                            prepend-icon="mdi-access-point"
                            required
                        >
                        </v-select>

                        <v-select
                            id="alert.location_states"
                            v-model="existing_silo.location_state_id"
                            v-validate="'required'"
                            :disabled="true"
                            :items="location_states"
                            :label="$t('alert.location_state')"
                            chips
                            data-vv-name="location_states"
                            item-text="name"
                            item-value="id"
                            name="alert.location_states"
                            prepend-icon="mdi-crosshairs"
                            required
                        >
                        </v-select>

                        <v-text-field
                            v-model="existing_silo.longitude"
                            :label="$t('alert.longitude')"
                            data-vv-name="alert.longitude"
                            name="alert.longitude"
                            prepend-icon="mdi-longitude"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="existing_silo.latitude"
                            :label="$t('alert.latitude')"
                            data-vv-name="alert.latitude"
                            name="alert.latitude"
                            prepend-icon="mdi-latitude"
                            type="text"
                        ></v-text-field>

                        <v-select
                            id="alert.silo_types"
                            v-model="existing_silo.silo_type_id"
                            v-validate="'required'"
                            :items="silo_types"
                            :label="$t('alert.silo_type')"
                            chips
                            data-vv-name="silo_types"
                            item-text="name"
                            item-value="id"
                            name="alert.silo_types"
                            prepend-icon="mdi-clock-outline"
                            required
                        >
                        </v-select>

                        <v-text-field
                            id="silo.qr_code"
                            v-model="existing_silo.qr_code"
                            :label="$t('silo.qr_code')"
                            data-vv-name="silo.qr_code"
                            name="silo.qr_code"
                            prepend-icon="mdi-qrcode"
                            type="text"
                            clearable
                        />

                        <v-checkbox
                            v-model="existing_silo.qrcode_pairing_is_disabled"
                            :label="$t('alert.disable_qrcode_pairing')"
                        />

                        <v-card-actions>
                            <div class="flex-grow-1"></div>
                            <v-btn color="blue darken-1" text @click="close">{{ $t('user.cancel') }}</v-btn>
                            <v-btn @click="updateSilo(existing_silo)">
                                {{ $t('alert.update') }}
                            </v-btn>
                        </v-card-actions>

                    </v-form>

                </v-card-text>

            </v-card>

        </v-dialog>

        <v-dialog v-if="siloToMerge" v-model="mergeDialog" max-width="600px" @input="$validator.reset()">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ $t('silo.merge_dialog_title') }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form name="siloMergeForm">

                        <span class="font-weight-bold">Silo à fusionner :&nbsp;{{ siloToMerge.code }}</span>

                        <v-select
                            v-model="siloToKeep"
                            v-validate="'required'"
                            :error-messages="errors.collect('silo')"
                            :items="filteredSilos"
                            :label="$t('silo.silo')"
                            chips
                            data-vv-name="silo"
                            item-text="code"
                            item-value="id"
                            name="silo"
                            prepend-icon="mdi-silo"
                            return-object
                        >
                        </v-select>

                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="mergeDialog= false">
                        {{ $t('silo.cancel') }}
                    </v-btn>
                    <v-btn @click="validateMergeSilo">
                        {{ $t('silo.validate') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-if="siloToKeep" v-model="dialogAlert" max-width="290">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('site.attention') }}</v-card-title>
                <v-card-text>Souhaitez-vous vraiment fusionner le silo {{ siloToMerge.code }} avec le silo {{ siloToKeep.code }} ?
                    ATTENTION, le silo {{ siloToMerge.code }} et ses éléments associés (actions, photos, etc.) seront supprimés. Seul son historique de livraisons sera rattaché au silo {{ siloToKeep.code }}. </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="darken-1" text @click="dialogAlert = false">
                        {{ $t('alert.cancel') }}
                    </v-btn>
                    <v-btn color="warning darken-1" text @click="mergeSilos(siloToKeepId, siloToMerge.id)">
                        {{ $t('alert.yes') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type"
            top
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>

    </div>

</template>

<script>
import {EQUIPMENTS, LOCATION_STATES, SILO_TYPES, SILOS, SILOS_MERGE, SITE, VEHICLE_ACCESSES} from "../../api";

export default {
    name: "silos",
    props: {
        silos: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            headers: [
                {text: this.$t('site.id'), value: 'code', sortable: false},
                {text: this.$t('site.location_state'), value: 'location_state.name', sortable: true},
                {text: this.$t('site.equipment'), value: 'equipment.name', sortable: true},
                {text: this.$t('site.accesses'), value: 'vehicle_access.name', sortable: true},
                {text: this.$t('site.silo_type'), value: 'silo_type.name', sortable: true},
                {text: this.$t('site.category'), value: 'category.name', sortable: true},
                {text: this.$t('site.last_operation'), value: 'last_delivery_datetime', sortable: true},
                {text: this.$t('site.exposition_count'), value: 'exposition_count', sortable: true},
                {text: this.$t('site.last_security_action'), value: 'last_security_action', sortable: true},
                {text: this.$t('site.security_level'), value: 'security_level.color', sortable: true},
                {text: this.$t('site.risks'), value: 'risks', sortable: true},
                {text: this.$t('site.actions'), value: 'actions', sortable: true},
            ],
            existing_silo: {},
            dialog: false,
            mergeEnabled: false,
            mergeDialog: false,
            siloToMerge: null,
            siloToKeep: null,
            siloToKeepId: null,
                dialogAlert: false,
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                updating: false,
                equipment: [],
                accesses: [],
                location_states: [],
                silo_types: [],
                site_to_update: {}
            }
        },
        watch: {
            silos(new_silos) {
                this.mergeEnabled = new_silos.length > 1;
            },
        },
        computed: {
            filteredSilos() {
                return this.silos.filter((silo) => {
                    return (
                        silo.id !== this.siloToMerge.id
                    );
                });
            },
        },
        methods: {
            /**
             * Get color for the GPS
             * Location state id : 1 => Locked
             * Location state id : 2 => Unlocked
             *
             * @param item
             */
            getGpsColor(item) {
                switch (item.location_state_id) {
                    case 2 :
                    case 3 :
                        return 'success';
                    default :
                        return 'error';
                }
            },
            /**
             * Get pictogram for the security level
             *
             * @param securityLevelColor
             * @returns {string}
             */
            getSecurityLevelImage(securityLevelColor) {
                switch (securityLevelColor) {
                    case 'Green':
                        return 'silo_vert.svg';
                    case 'Orange':
                        return 'silo_orange.svg';
                    case 'Red':
                        return 'silo_rouge.svg';
                    case 'Black':
                        return 'silo_noir.svg';
                    default:
                        return 'silo_neutre.svg';
                }
            },
            /**
             * Get the title for the associated GPS
             *
             */
            getTitleFromGps(item) {
                switch (item.location_state_id) {
                    case 2 :
                        return this.$t('site.unlocked');
                    case 3 :
                        return this.$t('site.locked');
                    default:
                        return this.$t('site.not_specified');
                }
            },
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Get color for the location state.
             *
             * @param status
             */
            getColorFromStatus(status) {
                switch (status) {
                    case 1 :
                        return 'red lighten-2';
                    case 2 :
                        return 'green darken-1';
                    default:
                        return 'blue';
                }
            },
            /**
             * Get all the equipments
             *
             * @returns {Promise<void>}
             */
            getEquipments() {
                this.axios.get(EQUIPMENTS).then((success) => {
                    this.equipment = success.data
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Get all the vehicle accesses
             *
             * @returns {Promise<void>}
             */
            getVehicleAccesses() {
                this.axios.get(VEHICLE_ACCESSES).then((success) => {
                    this.accesses = success.data
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Get all the location states
             *
             * @returns {Promise<void>}
             */
            getLocationStates() {
                this.axios.get(LOCATION_STATES).then((success) => {
                    this.location_states = success.data
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Get all the silo types
             *
             * @returns {Promise<void>}
             */
            getSiloTypes() {
                this.axios.get(SILO_TYPES).then((success) => {
                    this.silo_types = success.data
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Get the wanted Silo
             *
             * @returns {Promise<void>}
             */
            getSilo(item) {
                this.axios.get(SILOS + '/' + item.id).then((success) => {
                    this.existing_silo = success.data
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Action used on the click on the update button
             */
            action(item) {
                this.getSilo(item);
                this.getEquipments();
                this.getVehicleAccesses();
                this.getLocationStates();
                this.getSiloTypes();
                this.dialog = true;
            },
            /**
             * Method used to update the silo
             *
             * @param silo
             */
            updateSilo(silo) {
                this.validateSilo(silo);
                delete silo.qr_code_linked_at;
                this.axios.patch(
                    SILOS + '/' + silo.id,
                    _.omit(silo,'risks', 'qr_code_linked_at')
                ).then((success) => {
                    this.showSnackbar('success', this.$t('alert.silo_updated'));
                    Object.assign(this.existing_silo, success.data);
                    this.updateDataTable(success.data.site_id);
                    this.close()
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.showSnackbar('warning', this.parseLaravelError(error));
                    } else {
                        this.showSnackbar('error', this.$t('global.error'));
                        this.close();
                    }
                }).finally(() => {
                    this.close();
                })
            },
            /**
             * Method used to validate the silo
             *
             * @param silo
             */
            validateSilo(silo) {
                if(silo.location_state_id !== 1 && (silo.longitude === '' || silo.latitude === '')){
                    silo.location_state_id = 1;
                }
                if(( silo.location_state_id === 1  || silo.location_state_id === null)&& ((silo.longitude !== '' && silo.longitude!== null) && ( silo.latitude !== '' && silo.latitude !== null))){
                    silo.location_state_id = 2;
                }else if (silo.location_state_id !== 1 && ( silo.longitude === '' || silo.longitude === null || silo.latitude === '' || silo.latitude === null)) {
                    silo.location_state_id = 1;
                }
            },
            /**
             * Get the site that owns the wanted silo to update
             *
             * @param {int} id
             * @returns {Promise<unknown>}
             */
            async getSite(id) {
                return new Promise((resolve, reject) => {
                    this.axios.get(this.replacePathParams(SITE, {site: id})).then(success => {
                        this.site_to_update = success.data;
                        this.silos = this.site_to_update.silos;
                        resolve();
                    }).catch(() => {
                        this.showSnackbar('error', this.$t('global.error'));
                        reject();
                    })
                });
            },
            /**
             * Method used to update de datable after an update.
             */
            async updateDataTable(site) {
                await this.getSite(site);
            },
            /**
             * Method used to close the Updating Silo dialog
             */
            close() {
                this.dialog = false;
            },
            /**
             * @param {integer} id
             */
            goTo(id) {
                this.$router.push({name: 'silo', params: {siloId: id}})
            },
            openMergeDialog(siloToMerge) {
                this.siloToMerge = siloToMerge;
                this.mergeDialog = true;
            },
            validateMergeSilo() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.siloToKeepId = this.siloToKeep.id;
                        this.dialogAlert = true;
                        // this.mergeSilos(this.siloToKeepId, this.siloToMerge.id);
                    }
                });
            },
            mergeSilos(siloToKeepId, siloToMergeId) {
                let mergePostData = {
                    'siloToKeepId': siloToKeepId
                };
                this.axios.post(
                    this.replacePathParams(SILOS_MERGE, {siloToMergeId: siloToMergeId}),
                    mergePostData
                ).then((success) => {
                    this.showSnackbar('success', this.$t('silo.silos_merged'));
                    this.$emit('updateSite')
                }).catch((error) => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.dialogAlert = false;
                    this.mergeDialog = false;
                })
            },
        }
    }
</script>
