<template>
    <div>
        <v-img src="images/avril_logo.jpg" width="150" aspect-ratio="1.7" contain class="float-right mr-4 d-sm-none d-md-block"></v-img>
        <pre id="contents"></pre>
    </div>
</template>

<script>
    import { Remarkable } from 'remarkable';

    export default {
        name: "about",
        methods: {
            getChangelog() {
                let xhr = new XMLHttpRequest();
                xhr.onload = function () {
                    let md = new Remarkable();
                    $('#contents').append(md.render(this.responseText));
                };
                xhr.open('GET', '/changelog.md');
                xhr.send();
            }
        },
        mounted() {
            this.getChangelog();
        }
    }
</script>
