var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"drivers-module"},[_c('v-row',[_c('v-col',{staticClass:"white"},[_c('v-breadcrumbs',{staticClass:"mt-0 pt-0 pl-4",attrs:{"items":[
                            {
                              text: _vm.$t('navigation.administration'),
                              disabled: true,
                              href: 'breadcrumbs_dashboard',
                            },
                            {
                              text: _vm.$t('navigation.transporters'),
                              disabled: true,
                              href: 'breadcrumbs_dashboard',
                            } ]}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-dialog',{attrs:{"max-width":"500px"},on:{"input":function($event){return _vm.$validator.reset()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v(_vm._s(_vm.$t('driver.new')))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._v(" "),_c('v-card',{attrs:{"center":""}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('transporter.new')))])]),_vm._v(" "),_c('v-card-text',[_c('v-form',[_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required|email'),expression:"'required|email'"}],attrs:{"error-messages":_vm.errors.collect('email'),"label":_vm.$t('driver.email'),"data-vv-name":"email","name":"email","prepend-icon":"mdi-email","type":"text"},model:{value:(_vm.editedItem.email),callback:function ($$v) {_vm.$set(_vm.editedItem, "email", $$v)},expression:"editedItem.email"}}),_vm._v(" "),_c('v-text-field',{directives:[{name:"validate",rawName:"v-validate",value:('required|min:3'),expression:"'required|min:3'"}],attrs:{"id":"name","error-messages":_vm.errors.collect('name'),"label":_vm.$t('driver.name'),"data-vv-name":"name","name":"name","prepend-icon":"mdi-account","type":"text"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}}),_vm._v(" "),_c('v-autocomplete',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"id":"roles","error-messages":_vm.errors.collect('roles'),"item-value":function (item) { return item.name; },"items":_vm.filteredRoles,"label":_vm.$t('user.roles'),"chips":"","data-vv-name":"roles","item-text":"name","multiple":"","name":"roles","prepend-icon":"mdi-chess-queen","required":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                          var item = ref.item;
                          var attrs = ref.attrs;
                          var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_vm._v(" "),_c('v-list-item-content',[_vm._v("\n                                                    "+_vm._s(_vm.$t(("role." + (item.name))))+"\n                                                ")])]}}],null,true)},'v-list-item',attrs,false),on))]}},{key:"selection",fn:function(data){return [_c('v-chip',[_vm._v(_vm._s(_vm.$t(("role." + (data.item.name)))))])]}}]),model:{value:(_vm.editedItem.roles),callback:function ($$v) {_vm.$set(_vm.editedItem, "roles", $$v)},expression:"editedItem.roles"}}),_vm._v(" "),_c('v-autocomplete',{attrs:{"id":"transporters","item-value":function (item) { return item; },"items":_vm.transporters,"label":_vm.$t('transporter.transporters_default'),"chips":"","item-text":"code","multiple":"","name":"transporters","prepend-icon":"mdi-lan"},model:{value:(_vm.editedItem.transporters),callback:function ($$v) {_vm.$set(_vm.editedItem, "transporters", $$v)},expression:"editedItem.transporters"}})],1)],1),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.close}},[_vm._v(_vm._s(_vm.$t('driver.cancel')))]),_vm._v(" "),_c('v-btn',{attrs:{"disabled":_vm.submit_button_is_disabled},on:{"click":function($event){return _vm.save(_vm.editedItem)}}},[_vm._v("\n                                    "+_vm._s(_vm.formSubmit)+"\n                                ")])],1)],1)],1),_vm._v(" "),_c('v-spacer'),_vm._v(" "),_c('v-text-field',{attrs:{"label":_vm.$t('transporter.search'),"append-icon":"mdi-magnify","hide-details":"","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_vm._v(" "),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.transporters_users,"search":_vm.search},scopedSlots:_vm._u([{key:"item.transporters",fn:function(ref){
                          var item = ref.item;
return _vm._l((item.transporters),function(transporter){return _c('div',{key:transporter.id},[_c('v-chip',[_vm._v(_vm._s(transporter.code))])],1)})}},{key:"item.last_login_date",fn:function(ref){
                          var item = ref.item;
return [(item)?_c('span',[_vm._v("\n                        "+_vm._s(_vm._f("moment")(item.last_login_date,'L LT'))+"\n                        ")]):_c('span',[_vm._v("\n                            -\n                        ")])]}},{key:"item.created_at",fn:function(ref){
                          var item = ref.item;
return [(item)?_c('span',[_vm._v("\n                            "+_vm._s(_vm._f("moment")(item.created_at,'L LT'))+"\n                        ")]):_c('span',[_vm._v("\n                            -\n                        ")])]}},{key:"item.updated_at",fn:function(ref){
                          var item = ref.item;
return [(item)?_c('span',[_vm._v("\n                            "+_vm._s(_vm._f("moment")(item.updated_at,'L LT'))+"\n                        ")]):_c('span',[_vm._v("\n                            -\n                        ")])]}},{key:"item.action",fn:function(ref){
                          var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"title":_vm.$t('driver.edit'),"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("\n                            mdi-pencil\n                        ")])]}}])})],1)],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('driver.sure')))]),_vm._v(" "),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('driver.irremediable')))]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("\n                    "+_vm._s(_vm.$t('driver.cancel'))+"\n                ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"warning darken-1","text":""},on:{"click":function($event){return _vm.deleteDriver()}}},[_vm._v("\n                    "+_vm._s(_vm.$t('driver.delete'))+"\n                ")])],1)],1)],1),_vm._v(" "),_c('v-snackbar',{attrs:{"color":_vm.snackbar_type,"top":""},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n        "+_vm._s(_vm.snackbar_text)+"\n        "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("\n            Close\n        ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }