<template>

    <div class="mobile-parameters-module">

        <v-row>
            <v-col
                class="white"
            >
                <!--
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('navigation.mobile_parameters') }}
                    </p>
                </v-subheader>
                -->
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('navigation.administration'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                                {
                                  text: $t('navigation.mobile_parameters'),
                                  disabled: true,
                                  href: 'breadcrumbs_mobile_parameters',
                                },
                           ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>

        <v-row>

            <v-col>

                <v-card style="position:relative">

                    <v-btn @click="action(mobile_parameters[0])"
                           color="primary"
                           elevation="0"
                           class="ma-2 white--text"
                           absolute
                           top
                           right
                    >
                        Modifier
                        <v-icon
                            right
                            dark
                        >
                            mdi-pencil
                        </v-icon>

                    </v-btn>

                    <div class="grid mt-4">

                        <template v-for="(item, key) in mobile_parameter[0]">

                            <template v-if="key === 'required_link_and_scan_logistic_area_ids'">
                                <div class="param-title">{{ $t(`mobile_parameters.${key}`) }}</div>
                                <div class="param-value">
                                    <v-chip v-for="zl in item" :key="zl">
                                        {{ logistic_areas.find(row => row.id === zl).code }}
                                    </v-chip>
                                </div>
                            </template>

                            <template v-else>
                                <div class="param-title">{{ $t(`mobile_parameters.${key}`) }}</div>
                                <div class="param-value">{{ item }}</div>
                            </template>

                        </template>

                    </div>

                    <v-dialog v-model="dialog" max-width="600px" @input="$validator.reset()">

                        <v-card>

                            <v-card-title>
                                {{ $t('mobile_parameters.update_mobile_parameters') }}
                            </v-card-title>

                            <v-card-text>

                                <v-form>
                                    <v-text-field
                                        :label="$t('mobile_parameters.database_retention_time')"
                                        name="database_retention_time"
                                        prepend-icon="mdi-cellphone"
                                        v-validate="'required|numeric'"
                                        type="text"
                                        data-vv-name="database_retention_time"
                                        v-model="editedItem.database_retention_time"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('mobile_parameters.message_retention_time')"
                                        name="message_retention_time"
                                        prepend-icon="mdi-cellphone"
                                        v-validate="'required|numeric'"
                                        type="text"
                                        data-vv-name="message_retention_time"
                                        v-model="editedItem.message_retention_time"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('mobile_parameters.avril_finished_steps_max')"
                                        name="avril_finished_steps_max"
                                        prepend-icon="mdi-cellphone"
                                        v-validate="'required|numeric'"
                                        type="text"
                                        data-vv-name="avril_finished_steps_max"
                                        v-model="editedItem.avril_finished_steps_max"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('mobile_parameters.current_steps_before')"
                                        name="current_steps_before"
                                        prepend-icon="mdi-cellphone"
                                        v-validate="'required|numeric'"
                                        type="text"
                                        data-vv-name="current_steps_before"
                                        v-model="editedItem.current_steps_before"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('mobile_parameters.current_steps_after')"
                                        name="current_steps_after"
                                        prepend-icon="mdi-cellphone"
                                        v-validate="'required|numeric'"
                                        type="text"
                                        data-vv-name="current_steps_after"
                                        v-model="editedItem.current_steps_after"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('mobile_parameters.last_silo_operations')"
                                        name="last_silo_operations"
                                        prepend-icon="mdi-cellphone"
                                        v-validate="'required|numeric'"
                                        type="text"
                                        data-vv-name="last_silo_operations"
                                        v-model="editedItem.last_silo_operations"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('mobile_parameters.silo_photos')"
                                        name="silo_photos"
                                        prepend-icon="mdi-cellphone"
                                        v-validate="'required|numeric'"
                                        type="text"
                                        data-vv-name="silo_photos"
                                        v-model="editedItem.silo_photos"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('mobile_parameters.seconds_for_site_arrival')"
                                        name="seconds_for_site_arrival"
                                        prepend-icon="mdi-cellphone"
                                        v-validate="'required|numeric'"
                                        type="text"
                                        data-vv-name="seconds_for_site_arrival"
                                        v-model="editedItem.seconds_for_site_arrival"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('mobile_parameters.meters_for_site_arrival')"
                                        name="meters_for_site_arrival"
                                        prepend-icon="mdi-cellphone"
                                        v-validate="'required|numeric'"
                                        type="text"
                                        data-vv-name="meters_for_site_arrival"
                                        v-model="editedItem.meters_for_site_arrival"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('mobile_parameters.seconds_for_site_departure')"
                                        name="seconds_for_site_departure"
                                        prepend-icon="mdi-cellphone"
                                        v-validate="'required|numeric'"
                                        type="text"
                                        data-vv-name="seconds_for_site_departure"
                                        v-model="editedItem.seconds_for_site_departure"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('mobile_parameters.meters_for_site_departure')"
                                        name="meters_for_site_departure"
                                        prepend-icon="mdi-cellphone"
                                        v-validate="'required|numeric'"
                                        type="text"
                                        data-vv-name="meters_for_site_departure"
                                        v-model="editedItem.meters_for_site_departure"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('mobile_parameters.tracking_interval')"
                                        name="tracking_interval"
                                        prepend-icon="mdi-cellphone"
                                        v-validate="'required|numeric'"
                                        type="text"
                                        data-vv-name="tracking_interval"
                                        v-model="editedItem.tracking_interval"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('mobile_parameters.inventory_delay_exposition')"
                                        name="inventory_delay_exposition"
                                        prepend-icon="mdi-cellphone"
                                        v-validate="'required|numeric'"
                                        type="text"
                                        data-vv-name="inventory_delay_exposition"
                                        v-model="editedItem.inventory_delay_exposition"
                                    ></v-text-field>

                                    <v-autocomplete
                                        id="logistic_area"
                                        v-model="logistic_areas_choices"
                                        placeholder=" "
                                        :clearable="true"
                                        :items="logistic_areas"
                                        :label="$t('mobile_parameters.required_link_and_scan_logistic_area_ids_form')"
                                        :search-input.sync="searchAreasInput"
                                        item-text="code"
                                        item-value="id"
                                        multiple
                                        name="logistic_area"
                                        prepend-icon="mdi-target"
                                    >
                                        <v-list
                                            v-if="isSelectAllAreasAvailable"
                                            slot="prepend-item"
                                            class="pa-0"
                                        >
                                            <v-list-item-group v-model="toggleAreas" class="d-flex align-center pa-0">
                                                <v-list-item>
                                                    <v-list-item-icon class="mr-2">
                                                        <v-icon
                                                            :color="logistic_areas_choices.length > 0 ? 'primary' : ''">
                                                            {{ iconAreas }}
                                                        </v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{
                                                                $t('global.select_all')
                                                            }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                        <v-divider
                                            slot="prepend-item"
                                        ></v-divider>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.code }} ({{ data.item.name }})
                                        </template>
                                        <template v-slot:selection="{item, index}">
                                            <v-chip v-if="index <= 2">
                                                {{ item.code }}
                                            </v-chip>
                                            <span v-if="index === 3">
                                                (+ {{ logistic_areas_choices.length - 3 }})
                                            </span>
                                        </template>
                                    </v-autocomplete>

                                    <v-text-field
                                        :label="$t('mobile_parameters.disable_localisation_after_last_step_in_minutes')"
                                        name="disable_localisation_after_last_step_in_minutes"
                                        prepend-icon="mdi-cellphone"
                                        v-validate="'required|numeric'"
                                        type="number"
                                        data-vv-name="disable_localisation_after_last_step_in_minutes"
                                        v-model="editedItem.disable_localisation_after_last_step_in_minutes"
                                    ></v-text-field>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    {{ $t('mobile_parameters.cancel') }}
                                </v-btn>
                                <v-btn @click="updateItem()">
                                    {{ $t('mobile_parameters.save') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                </v-card>

            </v-col>

        </v-row>

        <v-snackbar
            v-model="snackbar"
            top
            :color="snackbar_type"
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>

    </div>

</template>

<script>

import {MOBILE_PARAMETER, MOBILE_PARAMETERS, LOGISTIC_AREAS} from "../api";
import _ from "lodash";

export default {
    name: "MobileParameters",

    data() {
        return {
            snackbar: false,
            snackbar_text: '',
            snackbar_type: null,
            mobile_parameters: [],
            loading: false,
            dialog: false,
            editedItem: {},
            editedIndex: -1,

            logistic_areas: [],
            logistic_areas_choices: [],
            searchAreasInput: '',
            toggleAreas: null,

        }
    },

    methods: {
        /**
         * Reset snackbar
         */
        resetSnackbar() {
            this.snackbar_type = null;
            this.snackbar_text = null;
            this.snackbar = false;
        },
        /**
         * Show snackbar
         *
         * @param {string} type
         * @param {string} text
         */
        showSnackbar(type, text) {
            this.snackbar_type = type;
            this.snackbar_text = text;
            this.snackbar = true;
        },
        /**
         * Get mobile parameters
         */
        getMobileParameters() {
            this.loading = true;
            this.axios.get(MOBILE_PARAMETERS).then(success => {
                this.mobile_parameters = success.data;
                this.logistic_areas_choices = this.mobile_parameters[0].required_link_and_scan_logistic_area_ids
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading = false;
            })
        },
        /**
         * action on click to open the dialog
         */
        action(item) {
            this.editedIndex = this.mobile_parameters.indexOf(item);
            this.editedItem = item;
            this.dialog = true;
        },

        /**
         * Hide edit dialog
         */
        close() {
            this.dialog = false;
        },

        /**
         * Update mobile parameter
         *
         */
        updateItem() {
            this.axios.patch(
                this.replacePathParams(MOBILE_PARAMETER, {mobile_parameter: this.editedItem.id}),
                {...this.editedItem, required_link_and_scan_logistic_area_ids: this.logistic_areas_choices}
            ).then((success) => {
                this.editedItem = success.data;
                this.showSnackbar('success', this.$t('mobile_parameters.updated'));
                this.getMobileParameters()
                this.close()
            }).catch((error) => {
                if (error.response.status === 422) {
                    this.showSnackbar('warning', this.parseLaravelError(error));
                } else {
                    this.showSnackbar('error', this.$t('global.error'));
                }
            })
        },

        async getLogisticAreas() {
            try {
                let success = await this.axios.get(LOGISTIC_AREAS)
                this.logistic_areas = _.orderBy(success.data, ['name']);
            } catch (e) {
                this.showSnackbar('error', this.$t('global.error'));
            }
        },
    },
    computed: {
        mobile_parameter() {
            const param = structuredClone(this.mobile_parameters)
            return param.map(item => {
                delete item.created_at
                delete item.updated_at
                delete item.id
                return {...item}
            })
        },
        iconAreas() {
            if (this.allAreas) return 'mdi-close-box'
            if (this.someAreas) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        allAreas() {
            return this.logistic_areas_choices.length === this.logistic_areas.length
        },
        someAreas() {
            return this.logistic_areas_choices.length > 0 && !this.allAreas
        },
        isSelectAllAreasAvailable() {
            return this.searchAreasInput === '' || this.searchAreasInput === null;
        },
    },
    async mounted() {
        this.getMobileParameters();
        await this.getLogisticAreas()
    },
    watch: {
        toggleAreas() {
            this.$nextTick(() => {
                if (this.allAreas) {
                    this.logistic_areas_choices = []
                } else {
                    this.logistic_areas.forEach(element => {
                        if (!this.logistic_areas_choices.includes(element.id)) {
                            this.logistic_areas_choices.push(element.id);
                        }
                    });
                }
            })
        }
    },
}
</script>

<style lang="scss">

.grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 2fr 1fr);
    row-gap: 2rem;
    padding: 6rem 1rem 1rem;
}

.grid div {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.grid div:last-child {
    padding-bottom: 10px;
    border-bottom: none;
}

.grid div:nth-last-child(2) {
    padding-bottom: 10px;
    border-bottom: none;
}

.param-title {
    color: rgba(0, 0, 0, 0.6);
}

.param-value {
    text-align: center;
}

@media (min-width: 1200px) {
    .param-value {
        text-align: left;
    }
}

.mobile-parameters-module {

.v-breadcrumbs li:last-child a {
    font-size: 1.25rem !important;
    color: rgba(0, 0, 0, .6) !important;
    font-weight: 500;
}

.v-list--dense .v-list-item {

.v-list-item__title,
.v-list-item__subtitle {
    font-size: 0.9rem;
}

}
}

</style>
