<template>
    <div class="sites-alerts-module">
        <v-row>
            <v-col class="white">
                <!--
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('navigation.alert_sites') }}
                    </p>
                </v-subheader>
                -->
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('navigation.alerts'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                                {
                                  text: $t('navigation.alert_sites'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                              ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <alert-sites-datatable></alert-sites-datatable>
    </div>
</template>

<script>
    import AlertSitesDatatable from "../../components/Alerts/SiteAlertDatatable";

    export default {
        name: "sites",
        components: {
            AlertSitesDatatable
        },
    };
</script>

<style lang="scss">

.sites-alerts-module {
    .v-breadcrumbs li:last-child a {
        font-size: 1.25rem !important;
        color: rgba(0, 0, 0, .6) !important;
        font-weight: 500;
    }

    .v-list--dense .v-list-item {
        .v-list-item__title,
        .v-list-item__subtitle {
            font-size: 0.9rem;
        }
    }
}

</style>
