<template>
    <div>
        <v-row>
            <v-col class="white">
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('navigation.alert_sites') }}
                    </p>
                </v-subheader>
                <v-breadcrumbs
                    :items="[
                        {
                          text: $t('navigation.alerts'),
                          disabled: true,
                        },
                        {
                          text: $t('navigation.alert_sites'),
                          disabled: false,
                          href: '#/alert_sites',
                        },
                        {
                          text: $t('navigation.site') + ' ' + alert.alertable.name,
                          disabled: true,
                        },
                    ]"
                    class="mt-0 pt-0 pl-4"
                />
            </v-col>
            <v-col cols="12">
                <v-btn icon x-large @click="$router.go(-1)" :title="$t('alert.back')">
                    <v-icon>mdi-arrow-left-thick</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col colls="12">
                <v-card
                    class="mx-auto"
                    max-width="750"
                    :loading="loading"
                >
                    <v-card-title class="secondary white--text">
                        {{ alert.alertable.name||$t('alert.none') }}
                    </v-card-title>
                    <v-card-text class="text--primary pt-5">
                        <div class="row">
                            <div class="col p-0">
                                {{alert.alertable.address_1||$t('site.none')}}
                                <br v-if="alert.alertable.address_1">
                                {{alert.alertable.address_2}}
                                <br v-if="alert.alertable.address_2">
                                {{alert.alertable.address_3}}
                                <br v-if="alert.alertable.address_3">
                                {{alert.alertable.zip}}, {{alert.alertable.city}}
                                <span v-if="alert.alertable.contact_avril_name || alert.alertable.contact_avril_mobile || alert.alertable.contact_avril_telephone">
                                <br />
                                {{ $t('alert.contact') }} : {{ alert.alertable.contact_avril_name }}
                                <span v-if="alert.alertable.contact_avril_name && alert.alertable.contact_avril_telephone">
                                ,
                                </span>
                                {{ alert.alertable.contact_avril_telephone }}
                                <span v-if="(alert.alertable.contact_avril_name && alert.alertable.contact_avril_mobile) || (alert.alertable.contact_avril_telephone && alert.alertable.contact_avril_mobile)">
                                ,
                                </span>
                                {{ alert.alertable.contact_avril_mobile }}
                            </span>
                                <span v-if="site_references.length > 0">
                                <br />
                                {{ $t('alert.farmer') }} :
                                <span
                                    v-for="(company,key) in site_references"
                                    v-if="site_references.length > 0"
                                >
                                    {{ company }}<span v-if="key+1 !== site_references.length">,</span>
                                </span>
                            </span>
                            </div>
                            <div class="col-3 d-flex align-center">
                                <v-btn
                                    tile
                                    outlined
                                    @click="goToSite(alert.alertable.id)"
                                >
                                    <v-icon
                                        small
                                        class="mr-2"
                                        :title="$t('alert.go_to_alert_site')"
                                    >
                                        mdi-eye
                                    </v-icon>
                                    {{ $t('site.see_site') }}
                                </v-btn>
                            </div>
                        </div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-tabs
                    v-model="tab"
                    grow
                    background-color="primary"
                    dark
                    icons-and-text
                >
                    <v-tab
                        href="#reattach-resembling-site"
                    >
                        {{ $t('alert.reattach_with_resembling_site') }}
                        <v-icon>mdi-swap-horizontal</v-icon>
                    </v-tab>
                    <v-tab
                        href="#reattach-site"
                    >
                        {{ $t('alert.reattach_with_site') }}
                        <v-icon>mdi-arrow-up</v-icon>
                    </v-tab>
                    <v-tab
                        href="#create-site"
                    >
                        {{ $t('alert.new_site') }}
                        <v-icon>mdi-plus</v-icon>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item
                        :value="'reattach-resembling-site'"
                    >
                        <v-card class="pa-5">
                            <v-data-table
                                :no-data-text="$t('global.no_data')"
                                :loading-text="$t('global.loading_text')"
                                :no-results-text="$t('global.no_results_text')"
                                :headers="headers"
                                :items="duplicated_sites"
                                :loading="loading"
                                :items-per-page="10"
                                :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
                            >
                                <template v-slot:item.alertable.name="{ item }">
                                    {{ item.name || $t('alert.none') }}
                                </template>
                                <template v-slot:item.alertable.address="{ item }">
                                    {{ item.address_1 || $t('alert.none') }} {{item.address_2}} {{item.address_3}}
                                </template>
                                <template v-slot:item.alertable.city="{ item }">
                                    {{ item.zip }} {{ item.city }}
                                </template>
                                <template v-slot:item.alertable.companies="{ item }">
                                    <span
                                        v-for="(company,key) in item.companies"
                                        v-if="item.companies.length > 0"
                                    >
                                        {{ company.code }}<span v-if="key+1 !== item.companies.length">,</span>
                                    </span>
                                </template>
                                <template v-slot:item.alertable.contact="{ item }">
                                    {{ item.contact_avril_name }}
                                    <span v-if="item.contact_avril_name && item.contact_avril_telephone">
                                    ,
                                    </span>
                                    {{ item.contact_avril_telephone }}
                                    <span v-if="(item.contact_avril_name && item.contact_avril_mobile) || (item.contact_avril_telephone && item.contact_avril_mobile)">
                                    ,
                                    </span>
                                    {{ item.contact_avril_mobile }}
                                </template>
                                <template v-slot:item.alertable.created_at="{ item }">
                                    {{ item.created_at | moment('L LT') }}
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-btn
                                        tile
                                        outlined
                                        @click="goToSite(item.id)"
                                    >
                                        <v-icon
                                            small
                                            class="mr-2"
                                            :title="$t('alert.go_to_alert_site')"
                                        >
                                            mdi-eye
                                        </v-icon>
                                        {{ $t('site.see_site') }}
                                    </v-btn>
                                    <v-btn
                                        color="secondary"
                                        tile
                                        :title="$t('alert.reattach')"
                                        outlined
                                        @click="resolve(item.id)"
                                    >
                                        <v-icon left>mdi-swap-horizontal</v-icon>
                                        {{ $t('alert.reattach') }}
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item
                        :value="'reattach-site'"
                    >
                        <v-card class="pa-5">
                            <v-card-title class="d-flex align-center">
                                <v-spacer></v-spacer>
                                <v-autocomplete
                                    class="px-4 mb-3"
                                    :items="orderedAreas"
                                    :label="$t('site.logistic_area')"
                                    id="logistic_area"
                                    name="logistic_area"
                                    item-text="code"
                                    item-value="id"
                                    :clearable="true"
                                    :filter="filterAreas"
                                    v-model="chosen_logistic_area"
                                    v-show="logistic_areas.length > 1"
                                >
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.code }} ({{ data.item.name }})
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.code }} ({{ data.item.name }})
                                    </template>
                                </v-autocomplete>
                                <v-text-field
                                    v-model="search_site"
                                    class="mt-4 pa-0"
                                    clearable
                                    append-icon="mdi-magnify"
                                    :label="$t('site.search')"
                                    single-line
                                ></v-text-field>
                                <v-col cols="3" class="d-flex justify-center pa-0">
                                    <v-btn @click="filter" color="primary">{{ $t('tour.apply') }}</v-btn>
                                </v-col>
                            </v-card-title>
                            <v-data-table
                                :no-data-text="$t('global.no_data')"
                                :loading-text="$t('global.loading_text')"
                                :no-results-text="$t('global.no_results_text')"
                                :headers="headersFullSites"
                                :server-items-length="fullSites"
                                :items="sites"
                                :loading="loading"
                                :items-per-page="itemPerPage"
                                :search="search_site"
                                @update:options="optionsSitesPage"
                                :options.sync="options"
                                :footer-props="{
                                    'items-per-page-options': rowsPerPageItems,
                                    'items-per-page-text': $t('global.rows_per_page')
                                }"
                            >
                                <template v-slot:item.created_at="{ item }">
                                    {{ item.created_at | moment('L LT') }}
                                </template>
                                <template v-slot:item.companies="{ item }">
                                    <span
                                        v-for="(company,key) in item.companies"
                                        v-if="item.companies.length > 0"
                                    >
                                        {{ company.code }}<span v-if="key+1 !== item.companies.length">,</span>
                                    </span>
                                </template>
                                <template v-slot:item.contact="{ item }">
                                    {{ item.contact_avril_name }}
                                    <span v-if="item.contact_avril_name && item.contact_avril_telephone">
                                    ,
                                    </span>
                                    {{ item.contact_avril_telephone }}
                                    <span v-if="(item.contact_avril_name && item.contact_avril_mobile) || (item.contact_avril_telephone && item.contact_avril_mobile)">
                                    ,
                                    </span>
                                    {{ item.contact_avril_mobile }}
                                </template>
                                <template v-slot:item.actions="{ item }">
                                    <v-btn
                                        tile
                                        outlined
                                        @click="goToSite(item.id)"
                                    >
                                        <v-icon
                                            small
                                            class="mr-2"
                                            :title="$t('alert.go_to_alert_site')"
                                        >
                                            mdi-eye
                                        </v-icon>
                                        {{ $t('site.see_site') }}
                                    </v-btn>
                                    <v-btn
                                        color="secondary"
                                        tile
                                        :title="$t('alert.reattach')"
                                        outlined
                                        @click="resolve(item.id)"
                                    >
                                        <v-icon left>mdi-arrow-up</v-icon>
                                        {{ $t('alert.reattach') }}
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item
                        :value="'create-site'"
                    >
                        <v-card class="pa-5">
                            <v-btn color="success" class="float-right" @click="resolve()">
                                {{ $t('alert.new_site') }}
                            </v-btn>
                            <v-icon>mdi-map-marker</v-icon>
                            <h2 class="title d-inline">{{ alert.alertable.name||$t('alert.none') }}</h2>
                            <p>
                                {{alert.alertable.address_1||$t('site.none')}}
                                <br v-if="alert.alertable.address_1">
                                {{alert.alertable.address_2}}
                                <br v-if="alert.alertable.address_2">
                                {{alert.alertable.address_3}}
                                <br v-if="alert.alertable.address_3">
                                {{alert.alertable.zip}}, {{alert.alertable.city}}
                                <br><br>
                                <v-icon>mdi-crosshairs-gps</v-icon>
                                <span v-if="alert.alertable.longitude && alert.alertable.latitude">
                                    {{ alert.alertable.longitude }}, {{ alert.alertable.latitude }}
                                </span>
                                <span v-else>
                                    0, 0
                                </span>
                                <br><br>
                                <v-icon>mdi-android-messages</v-icon>
                                {{ alert.alertable.comments||$t('site.none') }}
                                <br><br>
                                <v-icon>mdi-circle</v-icon>
                                <span v-if="alert.alertable.active">
                                    {{ $t('site.active') }}
                                </span>
                                <span v-else>
                                    {{ $t('site.inactive') }}
                                </span>
                            </p>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackbar"
            top
            :color="snackbar_type"
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import {ACTIONS, ALERT, AREA_SITES, SITES, USER_LOGISTIC_AREAS} from "../../api";
    import _ from "lodash";
    import {mapActions} from "vuex";

    export default {
        name: 'Site',
        data() {
            return {
                alert: {duplicated_sites: [], alertable: {}},
                sites: [],
                loading: false,
                fullSites: null,
                rowsPerPageItems: [10, 20, 100, 200],
                logistic_areas: [],
                chosen_logistic_area: null,
                headers: [
                    {
                        text: this.$t('alert.name'),
                        value: 'alertable.name',
                        sortable: true,
                        filterable: true
                    },
                    {text: this.$t('alert.address'), value: 'alertable.address', sortable: false},
                    {text: this.$t('alert.city'), value: 'alertable.city', sortable: false},
                    {text: this.$t('alert.farmer'), value: 'alertable.companies', sortable: false},
                    {text: this.$t('alert.contact'), value: 'alertable.contact', sortable: false},
                    {text: this.$t('alert.created_at'), value: 'alertable.created_at', sortable: false},
                    {text: this.$t('alert.actions'), value: 'actions', sortable: false},
                ],
                headersFullSites: [
                    {
                        text: this.$t('alert.name'),
                        value: 'name',
                        sortable: false
                    },
                    {text: this.$t('alert.address'), value: 'address_1', sortable: false},
                    {text: this.$t('alert.city'), value: 'city', sortable: false},
                    {text: this.$t('alert.farmer'), value: 'companies', sortable: false},
                    {text: this.$t('alert.contact'), value: 'contact', sortable: false},
                    {text: this.$t('alert.created_at'), value: 'created_at', sortable: false},
                    {text: this.$t('alert.actions'), value: 'actions', sortable: false},
                ],
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                search_site: null,
                tab: 'reattach-resembling-site',
                options: {page: 1, itemsPerPage: 10},
                itemPerPage: 10
            }
        },
        async mounted() {
            this.loading = true;
            await this.getAlert(this.$route.params.alertId);
            await this.getSites();
            this.getLogisticAreas();
            this.loading = false;
        },
        computed: {
            orderedAreas() {
                return _.orderBy(this.logistic_areas, 'code')
            },
            duplicated_sites() {
                return _.reject(this.alert.duplicated_sites, {id: this.alert.alertable.id});
            },
            site_references() {
                let site_references = [];
                _.each(this.alert.alertable.companies, (company) => {
                    site_references.push(company.code);
                });

                return site_references;
            },
        },
        methods: {
            ...mapActions({ count_alerts : 'alert/count'}),
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Get all the logistic areas
             *
             * @returns {Promise<void>}
             */
            getLogisticAreas() {
                this.axios.get(USER_LOGISTIC_AREAS).then((success) => {
                    this.logistic_areas = success.data
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Filter areas drop-down
             */
            filterAreas (item, queryText) {
                const textOne = item.name.toLowerCase();
                const textTwo = item.code.toLowerCase();
                const searchText = queryText.toLowerCase();

                return textOne.indexOf(searchText) > -1 ||
                    textTwo.indexOf(searchText) > -1
            },
            filter()
            {
                this.options.itemsPerPage = 10;
                if (this.chosen_logistic_area != null) {
                    this.getSitesFromLogisticArea(this.chosen_logistic_area);
                } else {
                    this.getSites();
                }
            },
            /**
             * Get the sites that are associated to a specific logistic area.
             *
             * @param {int} id
             */
            async getSitesFromLogisticArea(id) {
                this.loading = true;
                var page = this.currentPage;
                var per_page = this.options.itemsPerPage;
                var filter = this.search_site;
                await this.axios.get(this.replacePathParams(AREA_SITES, {logistic_area: id}), {params: {page, per_page, filter}}).then(({data: pagination}) => {
                    this.sites = Object.values(pagination.data);
                    this.fullSites = pagination.total;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                });
            },
            /**
             * Get alert
             *
             *  @param {integer} alert : Alert's id
             */
            getAlert(alert) {
                this.axios.get(
                    this.replacePathParams(ALERT, {alert})
                ).then((success) => {
                    this.alert = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Go to site specified
             *
             * @param {integer} siteId
             */
            goToSite(siteId) {
                let routeData = this.$router.resolve({name: 'site', params: {siteId}});
                window.open(routeData.href, '_blank');
            },
            async createAction() {
                this.loading = true;
                let action = {
                    site_id: this.alert.alertable.id,
                    type_id: 16, // @todo A change with avril_id
                    details: "Alerte de rattachement de site",
                };

                await this.axios.post(ACTIONS, action);
                this.loading = false;
            },
            /**
             * Resolve conflict
             *
             * @param{integer} id
             */
            resolve(id = null) {
                this.axios.delete(
                    this.replacePathParams(ALERT, {alert: this.alert.id}),
                    {
                        params: {merge_site: id}
                    }
                ).then(() => {
                    this.count_alerts();
                    this.createAction();
                    this.$router.push({name: 'alert_sites'});
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.showSnackbar('warning', this.parseLaravelError(error));
                    } else {
                        this.showSnackbar('error', this.$t('global.error'));
                    }
                })
            },
            /**
             * Get all sites
             *
             * @param {int} page
             * @param {int} per_page
             * @param {string} filter
             */
            getSites(page = null, per_page = this.options.itemsPerPage, filter = this.search_site) {
                this.loading = true;
                this.axios.get(SITES, {params: {page, per_page, filter}}).then(({data: pagination}) => {
                    this.sites = _.reject(pagination.data, {id: this.alert.alertable.id});
                    this.fullSites = pagination.total;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                });
            },
            /**
             * Change page
             *
             * @param {object} options
             */
            optionsSitesPage(options) {
                this.currentPage = options.page;
                this.options.itemsPerPage = options.itemsPerPage;

                if (this.chosen_logistic_area) {
                    this.getSitesFromLogisticArea(this.chosen_logistic_area);
                } else {
                    this.getSites(options.page, options.itemsPerPage);
                }
            },
        },
        watch: {
            options(new_options, old_options) {
                if (new_options.page !== old_options.page || new_options.itemsPerPage !== old_options.itemsPerPage) {
                    this.itemPerPage = new_options.itemsPerPage;
                    this.getSites(new_options.page, new_options.itemsPerPage);
                }
            }
        }
    }
</script>
