<template>
    <div class="gps-module">
        <v-row>
            <v-col class="white">
                <!--
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('navigation.gps_coordinates') }}
                    </p>
                </v-subheader>
                -->
                <v-breadcrumbs
                    :items="[
                        {
                          text: $t('navigation.alerts'),
                          disabled: true,
                          href: 'breadcrumbs_dashboard',
                        },
                        {
                          text: $t('navigation.gps_coordinates'),
                          disabled: true,
                          href: 'breadcrumbs_dashboard',
                        },
                    ]"
                    class="mt-0 pt-0 pl-4"
                />
            </v-col>
        </v-row>
        <gps-alert-datatable></gps-alert-datatable>
    </div>
</template>

<script>
    import GpsAlertDatatable from "../../components/Alerts/GpsAlertDatatable";

    export default {
        name: 'gps',
        components: {
            GpsAlertDatatable
        },
    }
</script>

<style lang="scss">

.gps-module {
    .v-breadcrumbs li:last-child a {
        font-size: 1.25rem !important;
        color: rgba(0, 0, 0, .6) !important;
        font-weight: 500;
    }

    .v-list--dense .v-list-item {
        .v-list-item__title,
        .v-list-item__subtitle {
            font-size: 0.9rem;
        }
    }
}

</style>
