<template>
    <div>
        <v-data-table
            :no-data-text="$t('global.no_data')"
            :loading-text="$t('global.loading_text')"
            :no-results-text="$t('global.no_results_text')"
            :headers="headers"
            :items="silo_selected.histories"
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
        >
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | moment('L') }}
            </template>

            <template v-slot:item.history_silos_media="{ item }">
                <span v-for="history in item.history_silos">
                    <div v-if="history.media.length > 0">
                        <img
                            height="32"
                            :src="`storage/${history.media[0].id}/${history.media[0].name}`"
                            :alt="$t('repository.pictogram')"
                        >
                    </div>
                </span>
            </template>

            <template v-slot:item.history_silos_label="{ item }">
                <span v-for="(history, index) in item.history_silos">
                    <span v-if="index !== Object.keys(item.history_silos).length - 1">
                        {{ history.name }},
                    </span>
                    <span v-else>
                        {{ history.name }}
                    </span>
                </span>
            </template>

            <template v-slot:item.security_level.color="{ item }">
                <img v-if="item.security_level" :src="'images/' + getSecurityLevelImage(item.security_level.color)">
            </template>
            <template v-slot:item.user="{ item }">
                <span v-if="item.user">
                    {{ item.user.name }}
                </span>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    export default {
        name: "risks_history",
        data() {
            return {
                headers: [
                    {text: this.$t('silo.date'), value: 'created_at', sortable: false},
                    {text: this.$t('silo.history_risks'), value: 'history_silos_media', sortable: false},
                    {text: this.$t('silo.history_name'), value: 'history_silos_label', sortable: false},
                    {text: this.$t('silo.security_level'), value: 'security_level.color', sortable: false},
                    {text: this.$t('silo.comment'), value: 'comment', sortable: false},
                    {text: this.$t('silo.driver'), value: 'user', sortable: false},
                ],
            }
        },
        props: {
            silo_selected: {
                type: Object,
                required: true
            }
        },
        methods: {
            /**
             * Get pictogram for the security level
             *
             * @param securityLevelColor
             * @returns {string}
             */
            getSecurityLevelImage(securityLevelColor) {
                switch (securityLevelColor) {
                    case 'Green':
                        return 'silo_vert.svg';
                    case 'Orange':
                        return 'silo_orange.svg';
                    case 'Red':
                        return 'silo_rouge.svg';
                    case 'Black':
                        return 'silo_noir.svg';
                    default:
                        return 'silo_neutre.svg';
                }
            },
        }
    }
</script>
