<template>
    <div>
        <v-card-title>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                class="ml-4"
                @click="newMedia()"
            >
                {{ $t('repository.new_item') }}
            </v-btn>
        </v-card-title>

        <v-data-table
            :headers="headers"
            :items="silo_selected.media"
            :loading-text="$t('global.loading_text')"
            :no-data-text="$t('global.no_data')"
            :no-results-text="$t('global.no_results_text')"
            ref="databasePicture"
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
        >
            <template v-slot:item.created_at="{ item }">
                <span v-if="item.created_at">
                    {{ item.created_at | moment('L LT') }}
                </span>
                <span v-else>
                    {{ $t('site.no_data') }}
                </span>
            </template>
            <template v-slot:item.comment="{ item }">
                <span v-if="item.custom_properties && item.custom_properties.comment !== 'null' && item.custom_properties.comment !== null" >
                    {{ item.custom_properties.comment }}
                </span>
                <span v-else>
                    {{ $t('site.no_data') }}
                </span>
            </template>
            <template v-slot:item.to_delete="{ item }">
                <v-tooltip color="rgba(0,0,0,0.9)" v-if="item.custom_properties && item.custom_properties.to_delete !== 'null' && item.custom_properties.to_delete === true" bottom>
                    <template v-slot:activator="{ on }">
                        <v-btn text color="green darken-2" v-on="on">
                            <v-icon left>mdi-check-circle</v-icon>
                        </v-btn>
                    </template>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-subtitle class="primary--text">
                                {{ $t('silo.driver') }}
                            </v-list-item-subtitle>
                            <v-list-item-title class="white--text">
                                {{ item.user }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-subtitle class="primary--text">
                                {{ $t('silo.truck_code') }}
                            </v-list-item-subtitle>
                            <v-list-item-title class="white--text">
                                {{ item.truck }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item two-line>
                        <v-list-item-content>
                            <v-list-item-subtitle class="primary--text">
                                {{ $t('silo.picture_date') }}
                            </v-list-item-subtitle>
                            <v-list-item-title class="white--text">
                                {{ item.custom_properties.ask_date | moment('L LT') }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-row>
                    <a :href="`storage/${item.id}/${item.file_name}`" download>
                        <v-icon
                            small
                        >
                            mdi-eye
                        </v-icon>
                    </a>
                    <v-icon
                        small
                        @click="edit(item)"
                    >
                        mdi-lead-pencil
                    </v-icon>
                    <v-icon
                        class="mr-2"
                        small
                        :title="$t('site.delete')"
                        @click="deleteDialog(item)"
                    >
                        mdi-delete
                    </v-icon>
                </v-row>
            </template>
        </v-data-table>

        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <div>
                            <v-file-input
                                accept="image/*"
                                data-vv-name="file"
                                :error-messages="errors.collect('file')"
                                :label="$t('repository.select_file')"
                                prepend-icon="mdi-paperclip"
                                v-if="!update_media"
                                v-model="file"
                                v-validate="'required'"
                            >
                            </v-file-input>
                            <p class="ml-4">Format autorisés : jpg, JPG, jpeg, JPEG, png, PNG, svg, SVG</p>
                            <v-text-field
                                data-vv-name="name"
                                :error-messages="errors.collect('name')"
                                :label="$t('silo.picture_name')"
                                prepend-icon="mdi-textbox"
                                v-model="file_to_update.name"
                                v-validate="'required'"
                            ></v-text-field>
                            <v-textarea
                                :label="$t('silo.picture_comment')"
                                maxlength="255"
                                outlined
                                prepend-icon="mdi-message"
                                :value="file_to_update.custom_properties.comment"
                                v-model="file_to_update.custom_properties.comment"
                            ></v-textarea>
                        </div>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                        {{ $t('repository.cancel') }}
                    </v-btn>
                    <v-btn @click="save()">
                        {{ $t('repository.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="290">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('repository.sure') }}</v-card-title>
                <v-card-text>{{ $t('repository.irremediable') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" text @click="dialogDelete = false">
                        {{ $t('repository.cancel') }}
                    </v-btn>
                    <v-btn color="warning darken-1" text @click="deleteMedia()">
                        {{ $t('repository.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            top
            :color="snackbar_type"
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import {SILO_PICTURE} from "../../api";

    export default {
        name: "pictures",
        props: {
            silo_selected: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                default_file: {
                    id: null,
                    name: null,
                    custom_properties: {
                        comment: null,
                    }
                },
                dialog: false,
                dialogDelete: false,
                file: {},
                file_to_delete: {},
                file_to_update: {
                    id: null,
                    name: null,
                    custom_properties: {
                        comment: null,
                    }
                },
                headers: [
                    {text: this.$t('silo.picture_date'), value: 'created_at', sortable: false},
                    {text: this.$t('silo.picture_name'), value: 'name', sortable: false},
                    {text: this.$t('silo.picture_comment'), value: 'comment', sortable: false},
                    {text: this.$t('silo.ask_picture_deletion'), value: 'to_delete', sortable: false},
                    {text: this.$t('silo.actions'), value: 'actions', sortable: false},
                ],
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                update_media: false,
                updating: false,
            }
        },
        computed: {
            formTitle () {
                return this.update_media ? this.$t('silo.edit_picture') : this.$t('silo.add_picture')
            },
        },
        methods: {
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Method used to add a picture to the silo
             */
            addMedia() {
                this.updating = true;
                let formData = new FormData();
                formData.append("picture", this.file);
                formData.append("name", this.file_to_update.name);
                if(this.file_to_update.custom_properties.comment !== null &&
                    this.file_to_update.custom_properties.comment !== 'null'){
                    formData.append("comment", this.file_to_update.custom_properties.comment);
                }
                this.axios.post(this.replacePathParams(SILO_PICTURE, {silo: this.silo_selected.id}), formData
                ).then((success) => {
                    this.silo_selected.media.push(success.data);
                    this.showSnackbar('success', this.$t('site.site_updated'));
                    this.file = null;
                    Object.assign(this.file_to_update, this.default_file);
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.updating = false;
                    this.dialog = false;
                })
            },
            /**
             * Method used to delete a picture to the silo
             */
            deleteMedia() {
                this.axios.delete(this.replacePathParams(SILO_PICTURE, {silo: this.silo_selected.id}), {
                    params: {
                        id: this.file_to_delete.id
                    },
                }).then(() => {
                    const index = this.silo_selected.media.indexOf(this.file_to_delete);
                    if (index > -1) {
                        this.silo_selected.media.splice(index, 1);
                    }
                    this.showSnackbar('success', this.$t('silo.silo_updated'));
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.updating = false;
                    this.dialogDelete = false;
                })
            },
            /**
             * Method used to update a picture to the silo
             */
            updateMedia() {
                this.axios.patch(this.replacePathParams(SILO_PICTURE, {silo: this.silo_selected.id}),
                    this.file_to_update
                ).then(() => {
                    Object.assign(this.silo_selected.media.find(x => x.id === this.file_to_update.id), this.file_to_update);
                    this.showSnackbar('success', this.$t('silo.silo_updated'));
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.updating = false;
                    this.dialog = false;
                    this.update_media = false;
                })
            },
            /**
             * Method used to open the dialog delete
             * @param media
             */
            deleteDialog(media) {
                this.file_to_delete = media;
                this.dialogDelete = true;
            },
            /**
             * Method used to open the dialog add
             */
            newMedia() {
                this.update_media = false;
                this.dialog = true;
            },
            /**
             * Method used to open the dialog edit
             * @param media
             */
            edit(media) {
                Object.assign(this.file_to_update, media);
                this.update_media = true;
                this.dialog = true;
            },
            /**
             * Method used to save dialog modifications
             */
            save() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        if (this.update_media) {
                            this.updateMedia();
                        } else {
                            this.addMedia();
                        }
                    }
                });
            }
        },
    }
</script>
