<template>
    <div>
        <v-row>
            <v-col class="white">
                <v-subheader>
                    <p class="title mb-0">
                        Facturation
                    </p>
                </v-subheader>
            </v-col>
        </v-row>

        <v-col>
            <v-card>
                <v-card-text>
                    <v-row>
                        <v-col cols="5">
                            Date de début
                            <v-row>
                                <v-col cols="10">
                                    <v-menu
                                        ref="startDateMenu"
                                        v-model="startDateMenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="start_date_formatted"
                                                label="Date"
                                                prepend-icon="mdi-calendar"
                                                outlined
                                                dense
                                                v-bind="attrs"
                                                v-on="on"
                                                @blur="start_date = parseDate(start_date_formatted)"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="start_date"
                                            @input="startDateMenu = false"
                                            no-title
                                            scrollable
                                            :locale="this.$i18n.locale">
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            Date de fin
                            <v-row>
                                <v-col cols="10">
                                    <v-menu
                                        ref="endDateMenu"
                                        v-model="endDateMenu"
                                        :close-on-content-click="false"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="end_date_formatted"
                                                label="Date"
                                                prepend-icon="mdi-calendar"
                                                outlined
                                                dense
                                                v-bind="attrs"
                                                v-on="on"
                                                @blur="end_date = parseDate(end_date_formatted)"
                                            ></v-text-field>
                                        </template>
                                        <v-date-picker
                                            v-model="end_date"
                                            @input="endDateMenu = false"
                                            no-title
                                            :locale="this.$i18n.locale"
                                        ></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-btn @click="getOrders()">
                                Envoyer
                            </v-btn>
                        </v-col>
                        <v-col cols="3" class="d-flex flex-column align-center justify-center" style="border: 1px darkgrey solid;">
                            <h1 class="mb-1" style="font-size: 3rem">
                                {{ orders }}
                            </h1>
                            <h4 class="mt-1">
                                {{ orders > 1 ? "commandes" : "commande" }}
                            </h4>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>

        <v-snackbar v-model="snackbar" top :color="snackbar_type">
            {{ snackbar_text }}
            <v-btn text @click="snackbar = false">
                Close
            </v-btn>
        </v-snackbar>

    </div>
</template>

<script>
    export default {
        name: "billing",
        data() {
            return {
                start_date: this.$moment().startOf('month').format('YYYY-MM-DD'),
                start_date_formatted: this.formatDate(this.$moment().startOf('month').format('YYYY-MM-DD')),
                startDateMenu: false,
                end_date: this.$moment().format('YYYY-MM-DD'),
                end_date_formatted: this.formatDate(this.$moment().format('YYYY-MM-DD')),
                endDateMenu: false,
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                orders: 0,
            }
        },
        mounted() {
            this.getOrders();
        },
        methods: {
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            getOrders() {
                this.axios
                    .get('/api/billing', {params: {
                            start_date: this.start_date,
                            end_date: this.end_date,
                        }})
                    .then(({data: orders_number}) => {
                        this.orders = orders_number;
                    })
                    .catch(() => {
                        this.showSnackbar('error', this.$t('global.error'));
                    })
            },
            formatDate (date) {
                if (!date) return null;

                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`
            },
            parseDate (date) {
                if (!date) return null;

                const [day, month, year] = date.split('/');
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
        },
        watch: {
            start_date (val) {
                this.start_date_formatted = this.formatDate(this.start_date)
            },
            end_date (val) {
                this.end_date_formatted = this.formatDate(this.end_date)
            },
        },
    }
</script>
