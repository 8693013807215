var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"no-data-text":_vm.$t('global.no_data'),"loading-text":_vm.$t('global.loading_text'),"no-results-text":_vm.$t('global.no_results_text'),"headers":_vm.headers,"items":_vm.silo_selected.histories,"footer-props":{
                    'items-per-page-text': _vm.$t('global.rows_per_page')
                }},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm._f("moment")(item.created_at,'L'))+"\n        ")]}},{key:"item.history_silos_media",fn:function(ref){
                var item = ref.item;
return _vm._l((item.history_silos),function(history){return _c('span',[(history.media.length > 0)?_c('div',[_c('img',{attrs:{"height":"32","src":("storage/" + (history.media[0].id) + "/" + (history.media[0].name)),"alt":_vm.$t('repository.pictogram')}})]):_vm._e()])})}},{key:"item.history_silos_label",fn:function(ref){
                var item = ref.item;
return _vm._l((item.history_silos),function(history,index){return _c('span',[(index !== Object.keys(item.history_silos).length - 1)?_c('span',[_vm._v("\n                    "+_vm._s(history.name)+",\n                ")]):_c('span',[_vm._v("\n                    "+_vm._s(history.name)+"\n                ")])])})}},{key:"item.security_level.color",fn:function(ref){
                var item = ref.item;
return [(item.security_level)?_c('img',{attrs:{"src":'images/' + _vm.getSecurityLevelImage(item.security_level.color)}}):_vm._e()]}},{key:"item.user",fn:function(ref){
                var item = ref.item;
return [(item.user)?_c('span',[_vm._v("\n                "+_vm._s(item.user.name)+"\n            ")]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }