export default [
    {
        path: "/login",
        component: require('./pages/Login').default,
        name: "login",
        meta: {
            auth: false
        }
    },
    {
        path: '/reset-password/:token',
        name: 'reset-password-form',
        component: require('./pages/ResetPassword').default,
        meta: {
            auth: false
        }
    },
    {
        name: 'silos_stats_datable',
        path: '/stats/silos',
        component: require('./pages/Statistics/SiloReport').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: require('./pages/Dashboard').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/profile',
        name: 'profile',
        component: require('./pages/Profile').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}, {name: 'transporter'}]
        }
    },
    {
        path: '/tour',
        name: 'tour',
        component: require('./pages/Tour').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}, {name: 'transporter'}]
        }
    },
    {
        path: '/tours_tracking',
        name: 'tours_tracking',
        component: require('./pages/ToursTracking.vue').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}, {name: 'transporter'}]
        }
    },
    {
        path: '/sites',
        name: 'sites',
        component: require('./pages/Sites').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/cartography',
        name: 'cartography',
        component: require('./pages/Cartography').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/cartography/:mode',
        name: 'cartography-mode',
        component: require('./pages/Cartography').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/site/:siteId',
        name: 'site',
        component: require('./pages/Site').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/silo/:siloId',
        name: 'silo',
        component: require('./pages/Silo').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/repository',
        name: 'repository',
        component: require('./pages/Repository').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/messaging',
        name: 'messaging',
        component: require('./pages/Messaging').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/alert_sites',
        name: 'alert_sites',
        component: require('./pages/Alerts/Sites').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/alert_silos',
        name: 'alert_silos',
        component: require('./pages/Alerts/Silos').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/alert_gps',
        name: 'alert_gps',
        component: require('./pages/Alerts/Gps').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/alert_sites_update',
        name: 'alert_sites_update',
        component: require('./pages/Alerts/SitesUpdate').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/alert_report',
        name: 'alert_report',
        component: require('./pages/Alerts/Report').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/alert_sites/:alertId',
        name: 'alerte_site',
        component: require('./pages/Alerts/Site').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/kpis/reports',
        name: 'reports',
        component: require('./pages/Kpis/Reports').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/kpis/driver_contribution',
        name: 'DriverContribution',
        component: require('./pages/Kpis/DriverContribution').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/kpis/exploitant_contribution',
        name: 'ExploitantContribution',
        component: require('./pages/Kpis/ExploitantContribution').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/exchange',
        name: 'exchange',
        component: require('./pages/Exchange').default,
        meta: {
            auth: [{name: 'admin'}]
        }
    },
    {
        path: '/user',
        name: 'user',
        component: require('./pages/User').default,
        meta: {
            auth: [{name: 'admin'}]
        }
    },
    {
        path: '/driver',
        name: 'driver',
        component: require('./pages/Driver').default,
        meta: {
            auth: [{name: 'admin'}]
        }
    },
    {
        path: '/transporters',
        name: 'transporters',
        component: require('./pages/Transporters').default,
        meta: {
            auth: [{name: 'admin'}]
        }
    },
    {
        path: '/mobile_parameters',
        name: 'mobile_parameters',
        component: require('./pages/MobileParameters').default,
        meta: {
            auth: [{name: 'admin'}]
        }
    },
    {
        path: '/back_office_parameters',
        name: 'back_office_parameters',
        component: require('./pages/BackOfficeParameters').default,
        meta: {
            auth: [{name: 'admin'}]
        }
    },
    {
        path: '/versions',
        name: 'versions',
        component: require('./pages/Versions').default,
        meta: {
            auth: [{name: 'admin'}]
        }
    },
    {
        path: '/about',
        name: 'about',
        component: require('./pages/About').default,
        meta: {
            auth: [{name: 'admin'}]
        }
    },
    {
        path: '/get_orders',
        name: 'get_orders',
        component: require('./pages/GetOrders').default,
        meta: {
            auth: [{name: 'admin'}]
        }
    },
    {
        path: '/order_tracking',
        name: 'order_tracking',
        component: require('./pages/PrivateOrder').default,
        meta: {
            auth: [{name: 'admin'}]
        }
    },
    {
        path: '/cgu_localisation',
        name: 'cgu_localisation',
        component: require('./pages/CGULocalisation').default,
        meta: {
            auth: [{name: 'admin'}]
        }
    },
    {
        path: '/documents',
        name: 'documents',
        component: require('./pages/Documents').default,
        meta: {
            auth: [{name: 'web'}, {name: 'admin'}]
        }
    },
    {
        path: '/facturation',
        name: 'billing',
        component: require('./pages/Billing').default,
        meta: {
            auth: [{name: 'admin'}]
        }
    },
    {path: '/', redirect: '/dashboard'},
    {
        path: '/403',
        name: 'forbidden',
        component: require('./pages/403').default,
        meta: {
            auth: true
        }
    },
    {
        path: '/404',
        name: 'not-found',
        component: require('./pages/404').default,
        meta: {
            auth: true
        }
    },
    {
        path: '*',
        redirect: '/404'
    },
];
