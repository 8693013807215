<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-autocomplete
                    class="px-4"
                    :items="orderedAreas"
                    :label="$t('site.logistic_area')"
                    id="logistic_area"
                    name="logistic_area"
                    item-text="code"
                    item-value="id"
                    :filter="filterAreas"
                    v-model="chosen_logistic_area"
                    :disabled="logistic_areas.length < 1"
                >
                    <template slot="selection" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12">
                <v-autocomplete
                    class="px-4 mb-3"
                    :items="trucks"
                    :label="$t('driver.trucks')"
                    id="trucks"
                    name="truck"
                    item-text="code"
                    item-value="id"
                    :clearable="true"
                    :filter="filterTrucks"
                    v-model="selected_trucks"
                    :disabled="trucks.length < 1"
                    multiple
                >
                    <v-list
                        slot="prepend-item"
                        class="pa-0"
                    >
                        <v-list-item-group v-model="toggleTrucks" class="d-flex align-center pa-0">
                            <v-list-item>
                                <v-list-item-icon class="mr-2">
                                    <v-icon :color="selected_trucks.length > 0 ? 'primary' : ''">
                                        {{iconAreas}}
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('global.select_all') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                    <template v-slot:selection="{item, index}">
                        <v-chip v-if="index <= 2">
                            {{item.code}}
                        </v-chip>
                        <span v-if="index === 3">
                            (+ {{selected_trucks.length - 3}})
                        </span>
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="12">
                <l-map
                    :zoom="map.zoom"
                    :center="map.center"
                    :bounds.sync="map.bounds"
                    :options="map.options"
                    style="height: 600px;z-index:2"
                    ref="map"
                    @ready="refreshMap()"
                >
                    <l-control position="topright">
                        <v-btn @click="setMapBounds" icon color="primary">
                            <v-icon>mdi-crosshairs-gps</v-icon>
                        </v-btn>
                    </l-control>
                    <l-marker v-for="marker in markers" :lat-lng="marker.latLng" :key="marker.id" :icon="map.icon">
                        <l-popup>
                            {{ $t('cartography.truck_code') }} : {{ marker.truck.code }}<br>
                            {{ $t('cartography.transition_date') }} : {{ marker.transition_date | moment('L LT') }}<br>
                            <span v-if="marker.truck.is_used_by">
                            {{ $t('cartography.driver') }} : {{ marker.truck.is_used_by.name }}
                        </span>
                        </l-popup>
                    </l-marker>
                </l-map>
            </v-col>
        </v-row>

        <v-snackbar
            v-model="snackbar"
            top
            :color="snackbar_type"
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>
<style lang="scss">
    @import "~leaflet/dist/leaflet.css";
</style>

<script>
    import {icon, latLng} from "leaflet";
    import {LControl, LMap, LMarker, LPopup, LTileLayer, LTooltip} from "vue2-leaflet";

    import {AREA_TRUCKS, TRACKING_TRUCKS_LIVE, USER_LOGISTIC_AREAS, USERS} from "../../api";
    import _ from "lodash";


    export default {
        name: "trucks-live",
        components: {
            LMap,
            LTileLayer,
            LMarker,
            LPopup,
            LTooltip,
            LControl,
        },
        data() {
            return {
                toggleTrucks:null,
                map: {
                    zoom: 6,
                    center: latLng(47.076119, 2.419790),
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    icon: icon({
                        iconUrl: '../images/truck-icon.svg',
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                        popupAnchor: [1, -34],
                        tooltipAnchor: [16, -28],
                        shadowSize: [41, 41]
                    }),
                    attribution: 'Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
                    bounds: null,
                    options: {
                      fullscreenControl: true
                    },
                },
                selected_trucks:[],
                tracking_trucks: [],
                trucks: [],
                markers: [],
                logistic_areas: [],
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                chosen_logistic_area: null,
            }
        },
        async mounted() {
            this.createLayers();
            await this.getLogisticAreas();
            this.chosen_logistic_area = this.orderedAreas[0].id
        },
       async created() {
            this.$messaging.getToken({
                vapidKey: process.env.MIX_VAPIKEY
            })
           .then(token => {
                this.axios.put(USERS + '/' + this.$auth.user().id ,{
                    fcm_token: token
                })
           })

           this.$messaging.onMessage(payload =>{
               const tracking_truck = this.createTrackingTruckPoint(payload.data)
               if (this.selected_trucks.includes(tracking_truck.id)){
                   this.replaceTrackingTruck(tracking_truck)
               }
           })
        },
        methods: {
            createTrackingTruckPoint(body){
                const truck = JSON.parse(body.truck)
                return {
                    id: truck.id,
                    latitude: body.latitude,
                    longitude: body.longitude,
                    truck: truck,
                    transition_date: body.transition_date,
                }
            },
            /**
             * Filter trucks drop-down
             */
            filterTrucks(item, queryText) {
                let text = "";
                let searchText = "";

                if (item.code) {
                    text = item.code.toLowerCase();
                    searchText = queryText.toLowerCase();
                }

                return text.indexOf(searchText) > -1;
            },


            createLayers() {
                let osm = L.tileLayer(this.map.url, {
                        attribution: this.map.attribution
                    }),
                    bing = L.tileLayer.bing(process.env.MIX_BING_API_KEY)

                let baseMaps = {
                    "Plan": osm,
                    "Satellite": bing
                };

                this.$refs.map.mapObject.addLayer(baseMaps.Plan);

                L.control.layers(baseMaps, null, {position: 'topright'}).addTo(this.$refs.map.mapObject);
            },
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Get live emplacement of trucks
             */
            async getTrackingTrucks() {
                if (this.selected_trucks.length > 0){
                    await this.axios.get(
                        TRACKING_TRUCKS_LIVE,
                        {params: {trucks:  this.selected_trucks}}
                    ).then((success) => {
                        this.$refs.map.mapObject.setView(latLng(47.076119, 2.419790), 6);
                        this.tracking_trucks = success.data;
                    })
                }
            },
            /**
             * Get trucks from auth user
             */
            async getTrucksFromLogisticArea() {
                await this.axios.get(AREA_TRUCKS, {params: {logistic_areas: [this.chosen_logistic_area] }})
                    .then((success) => {
                        this.trucks = success.data;
                        this.selected_trucks = _.map(this.trucks, 'id')
                    }).catch(() => {
                        this.showSnackbar('error', this.$t('global.error'));
                    })
            },
            /**
             * Create markers in this map
             */
            createMarkers() {
                this.markers = [];

                _.each(this.tracking_trucks, (tracking_truck) => {
                    this.markers.push(this.createMarker(tracking_truck))
                });

                this.setMapBounds();
            },
            /**
             * Set map bounds
             */
            setMapBounds() {
                this.map.bounds = _.map(this.markers, (marker) => {
                    return marker.latLng;
                })

            },
            /**
             * Set map on france
             **/
            resetMap(){
              this.map.bounds = this.map.center
            },
            /**
             * Replace tracking truck
             */
            replaceTrackingTruck(tracking_truck) {
                let tracking_truck_index_founded = _.findIndex(
                    this.markers, (marker) => {
                        return marker.id === tracking_truck.truck.id
                    }
                );

                if (tracking_truck_index_founded !== -1) {
                    this.markers.splice(tracking_truck_index_founded, 1);
                }

                this.markers.push(this.createMarker(tracking_truck));
            },
            /**
             * Create marker
             *
             */
            createMarker(tracking_truck) {
                return {
                    id: tracking_truck.truck.id,
                    latLng: latLng(tracking_truck.latitude, tracking_truck.longitude),
                    truck: tracking_truck.truck,
                    transition_date: tracking_truck.transition_date,
                }
            },
            /**
             * Create listener
             */
            async createListener() {
                await this.closeListener();

                _.each(this.trucks, (truck) => {
                    // this.$echo.private(`tracking_truck.${truck.id}`)
                    //     .listen('TrackingTruckCreated', (e) => {
                    //         this.replaceTrackingTruck(e.tracking_truck)
                    //     });
                });
            },
            /**
             * Close listener
             */
            async closeListener() {
                _.each(this.trucks, (truck) => {
                   // this.$echo.leave(`tracking_truck.${truck.id}`)
                });
            },
            /**
             * Get all the logistic areas
             *
             * @returns {Promise<void>}
             */
            async getLogisticAreas() {
                await this.axios.get(USER_LOGISTIC_AREAS).then((success) => {
                    this.logistic_areas = success.data
                    this.chosen_logistic_area = null;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Filter areas drop-down
             */
            filterAreas(item, queryText) {
                const searchText = queryText.toLowerCase();
                return item.name.toLowerCase().indexOf(searchText) > -1 ||
                    item.code.toLowerCase().indexOf(searchText) > -1
            },
            refreshMap() {
                setTimeout(() => {
                    this.$refs.map.mapObject.invalidateSize();
                }, 0);
            }
        },
        computed: {
            allTrucks() {
                return this.selected_trucks.length === this.trucks.length
            },
            someTrucks() {
                return this.selected_trucks.length > 0 && !this.allTrucks
            },
            iconAreas() {
                if (this.allTrucks) return 'mdi-close-box'
                if (this.someTrucks) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            },
            orderedAreas() {
                return _.orderBy(this.logistic_areas, 'code')
            },
        },
        watch: {
            async trucks() {
                await this.getTrackingTrucks();
            },
            tracking_trucks() {
                this.createListener();
                this.createMarkers();
            },
            async selected_trucks(){
                await this.getTrackingTrucks()
            },
            chosen_logistic_area() {
                this.getTrucksFromLogisticArea();
            },
            toggleTrucks() {
                this.$nextTick(() => {
                    if (this.allTrucks) {
                        this.selected_trucks = []
                    } else {
                        this.trucks.forEach(element => {
                            if (!this.selected_trucks.includes(element.id)) {
                                this.selected_trucks.push(element.id);
                            }
                        });
                    }
                })
            }
        }
    }
</script>
