import Vue from 'vue'
import VueRouter from 'vue-router'
import paths from '../path'

Vue.use(VueRouter);

export const router = new VueRouter({
    routes: paths,
});


Vue.router = router;
