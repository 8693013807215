<template>
    <div class="versions-module">
        <v-row>
            <v-col
                class="white"
            >
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('navigation.administration'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                                {
                                  text: $t('navigation.documents'),
                                  disabled: true,
                                  href: 'documents',
                                },
                              ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-dialog v-model="dialog" max-width="700px"
                                  @input="$validator.reset()"
                                  @click:outside="close">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="mb-2" color="primary" dark>{{ $t('user.new') }}</v-btn>
                            </template>
                            <v-card
                                center
                            >
                                <v-card-title>
                                    <span class="headline">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-form>
                                        <v-text-field
                                            v-model="edited_document.name"
                                            :label="$t('document.name') + ' *'"
                                            hint="Requis"
                                            prepend-icon="mdi-alpha-n-box"
                                            :rules="[rules.required]"
                                            aria-required="true"
                                        ></v-text-field>
                                        <v-file-input
                                            accept=".pdf"
                                            data-vv-name="file"
                                            :label="$t('document.select_file')"
                                            prepend-icon="mdi-file"
                                            v-model="edited_document.file"
                                        ></v-file-input>
                                        <v-text-field
                                            v-if="edited_document.has_pdf"
                                            :value="edited_document.pdf_file"
                                            :label="$t('document.current_file')"
                                            maxlength="10000"
                                            readonly
                                            disabled
                                            prepend-icon="mdi-file"
                                        ></v-text-field>
                                        <v-text-field
                                            v-model="edited_document.link"
                                            :label="$t('document.link')"
                                            prepend-icon="mdi-link"
                                            :rules="[rules.url]"
                                        ></v-text-field>
                                        <v-autocomplete
                                            v-model="logistic_area_ids"
                                            :items="logistic_areas"
                                            :filter="filter"
                                            :label="$t('tour.logistic_areas')"
                                            prepend-icon="mdi-target"
                                            item-value="id"
                                            item-text="name"
                                            multiple
                                        >
                                            <v-list
                                                v-if="isSelectAllAreasAvailable"
                                                slot="prepend-item"
                                                class="pa-0"
                                            >
                                                <v-list-item-group v-model="toggleAreas" class="d-flex align-center pa-0">
                                                    <v-list-item>
                                                        <v-list-item-icon class="mr-2">
                                                            <v-icon :color="logistic_area_ids.length > 0 ? 'primary' : ''">
                                                                {{ iconAreas }}
                                                            </v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ $t('global.select_all') }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                            <v-divider
                                                slot="prepend-item"
                                            ></v-divider>
                                            <template slot="item" slot-scope="data">
                                                {{ data.item.code }} ({{ data.item.name }})
                                            </template>
                                            <template v-slot:selection="{item, index}">
                                                <v-chip v-if="index <= 0">
                                                    {{item.code}}
                                                </v-chip>
                                                <span v-if="index === 1">(+ {{logistic_area_ids.length - 1}})</span>
                                            </template>
                                        </v-autocomplete>
                                        <v-autocomplete
                                            v-model="transporter_ids"
                                            :items="transporters"
                                            :filter="filter"
                                            :label="$t('navigation.transporters')"
                                            prepend-icon="mdi-lan"
                                            item-value="id"
                                            item-text="name"
                                            multiple
                                        >
                                            <v-list
                                                v-if="isSelectAllTransportersAvailable"
                                                slot="prepend-item"
                                                class="pa-0"
                                            >
                                                <v-list-item-group
                                                    v-model="toggleTransporters"
                                                    class="d-flex align-center pa-0">
                                                    <v-list-item>
                                                        <v-list-item-icon class="mr-2">
                                                            <v-icon
                                                                :color="transporter_ids.length > 0 ? 'primary' : ''">
                                                                {{ iconTransporters }}
                                                            </v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ $t('global.select_all') }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                            <v-divider
                                                slot="prepend-item"
                                            ></v-divider>
                                            <template slot="item" slot-scope="data">
                                                {{ data.item.code }} ({{ data.item.name }})
                                            </template>
                                            <template v-slot:selection="{item, index}">
                                                <v-chip v-if="index <= 0">
                                                    {{item.code}}
                                                </v-chip>
                                                <span
                                                    v-if="index === 1">(+{{transporter_ids.length - 1}})</span>
                                            </template>
                                        </v-autocomplete>
                                        <v-autocomplete
                                            v-model="driver_ids"
                                            :items="drivers"
                                            :label="$t('navigation.drivers')"
                                            prepend-icon="mdi-truck"
                                            item-value="id"
                                            item-text="name"
                                            multiple
                                        >
                                            <v-list
                                                v-if="isSelectAllDriversAvailable"
                                                slot="prepend-item"
                                                class="pa-0"
                                            >
                                                <v-list-item-group
                                                    v-model="toggleDrivers"
                                                    class="d-flex align-center pa-0">
                                                    <v-list-item>
                                                        <v-list-item-icon class="mr-2">
                                                            <v-icon
                                                                :color="driver_ids.length > 0 ? 'primary' : ''">
                                                                {{ iconDrivers }}
                                                            </v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ $t('global.select_all') }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                            <v-divider
                                                slot="prepend-item"
                                            ></v-divider>
                                            <template slot="item" slot-scope="data">
                                                {{ data.item.name }}
                                            </template>
                                            <template v-slot:selection="{item, index}">
                                                <v-chip v-if="index <= 0">
                                                    {{item.name}}
                                                </v-chip>
                                                <span v-if="index === 1">(+{{driver_ids.length - 1}})</span>
                                            </template>
                                        </v-autocomplete>

                                        <v-text-field
                                            v-if="edited_document.id !== undefined"
                                            :value="edited_document.last_update|moment('L LT')"
                                            :label="$t('document.last_update')"
                                            maxlength="10000"
                                            readonly
                                            disabled
                                            prepend-icon="mdi-calendar"
                                        ></v-text-field>
                                        <v-checkbox
                                            :label="$t('document.must_be_read')"
                                            v-model="edited_document.must_be_read"
                                        ></v-checkbox>
                                        <v-checkbox
                                            :label="$t('document.actif')"
                                            v-model="edited_document.actif"
                                        ></v-checkbox>
                                        <v-textarea
                                            v-model="edited_document.comment"
                                            :label="$t('document.comment')"
                                            outlined
                                            prepend-icon="mdi-message"
                                        ></v-textarea>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="close">{{ $t('user.cancel') }}</v-btn>
                                    <v-btn @click="save"
                                           elevation="0"
                                           color="primary"
                                           :disabled="!formIsValid">
                                        Enregistrer
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-spacer></v-spacer>
                    </v-card-title>
                    <v-data-table
                        :loading="document_loading"
                        :headers="headers"
                        :items="documents"
                    >
                        <template v-slot:item.comment="{ item }">
                            <p style="white-space: pre-wrap"
                               class="mt-4"
                               v-html="item.comment"></p>
                        </template>
                        <template #[`item.last_update`]="{item}">
                            {{item.last_update | moment('L LT')}}
                        </template>
                        <template #[`item.actif`]="{item}">
                            {{item.actif ? 'Oui' :'Non'}}
                        </template>
                        <template #[`item.must_be_read`]="{item}">
                            {{item.must_be_read ? 'Oui' :'Non'}}
                        </template>
                        <template #[`item.action`]="{item}">
                            <a v-if="item.has_pdf"
                               :href="`storage/${item.pdf_id}/${item.pdf_file}`"  download>
                                <v-icon
                                    class="mr-2"
                                    small
                                >
                                    mdi-eye
                                </v-icon>
                            </a>
                                <v-icon
                                    disabled
                                    v-else
                                    class="mr-2"
                                    small
                                >
                                    mdi-eye
                                </v-icon>
                            <v-icon
                                small
                                class="mr-2"
                                :title="$t('version.edit')"
                                @click="showFile(item)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                class="mr-2"
                                :title="$t('version.delete')"
                                @click="openDeleteModal(item)"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
                <v-snackbar
                    v-model="snackbar"
                    top
                    :color="snackbar_type"
                >
                    {{ snackbar_text }}
                    <v-btn
                        text
                        @click="snackbar = false"
                    >
                        Close
                    </v-btn>
                </v-snackbar>
                <v-dialog v-model="dialogDelete" max-width="290">
                    <v-card elevation="0">
                        <v-card-title class="headline">{{ $t('repository.sure') }}</v-card-title>
                        <v-card-text>{{ $t('repository.irremediable') }}</v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="darken-1" text @click="dialogDelete = false">
                                {{ $t('repository.cancel') }}
                            </v-btn>
                            <v-btn color="warning darken-1" text
                                   :loading="deleteLoading"
                                   @click="deleteDocument(edited_document.id)">
                                {{ $t('repository.delete') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import {LOGISTIC_AREAS, TRANSPORTERS, USERS} from "../api";

export default {
    name: "Documents",
    data(){
        return{
            document_loading: false,
            dialog: false,

            documents:[],

            edited_document: {
                name:"",
                comment:  "",
                link: null,
                ressource_updated_datetime:null,
                file: null,
                must_be_read: false,
                actif: true,
                has_pdf:false,
                affectations:[]
            },

            template: {
                name:"",
                comment:  "",
                link: null,
                ressource_updated_datetime:null,
                file: null,
                must_be_read: false,
                actif: true,
                has_pdf:false,
                affectations:[]
            },

            transporter_ids:[],
            driver_ids:[],
            logistic_area_ids:[],

            snackbar: false,
            snackbar_text: '',
            snackbar_type: null,

            save_loading: false,

            headers:[
                {
                    text: 'Nom',
                    value:'name',
                    sortable: false,
                },{
                    text: 'Lien',
                    value:'link',
                    sortable: false,
                },{
                    text: 'Dernière modification',
                    value:'last_update',
                    sortable: false,
                },
                {
                    text: 'Commentaire',
                    value:'comment',
                    sortable: false,
                },
                {
                    text: 'Actif',
                    value:'actif',
                    sortable: false,
                },
                {
                    text: 'Obligatoire',
                    value:'must_be_read',
                    sortable: false,
                },
                {
                    text: 'Actions',
                    value:'action',
                    sortable: false,
                }
            ],

            transporters:[],
            drivers:[],
            logistic_areas:[],

            toggleAreas: null,
            searchAreasInput:null,

            toggleDrivers: null,
            searchDriversInput:null,

            toggleTransporters: null,
            searchTransportersInput:null,

            dialogDelete: false,
            deleteLoading: false,

            rules:{
                required: value => !!value || 'Requis',
                url: value => {
                    const pattern = /^(https|http):\/\/([a-z\d_]+\.)?(([a-zA-Z\d_]+)(\.[a-zA-Z]{2,6}))(\/[a-zA-Z\d_\%\-=\+]+)*(\?)?([a-zA-Z\d=_\+\%\-&\{\}\:]+)?/
                    return (pattern.test(value) || value?.length === 0) || 'Lien invalide'
                }
            }
        }
    },
    mounted() {
        this.getDocuments()
        this.getDriverUsers()
        this.getTransporters()
        this.getLogisticAreas()
    },
    computed:{
        formTitle(){
            return this.edited_document?.id ? 'Modifier le Document' : 'Ajouter un Document'
        },
        current(){
            return this.edited_document
        },
        formIsValid(){
                // à un document, des assignations et un nom
              return this.hasOneDocument && this.hasAtLeastOnAssignation && this.edited_document.name !== ''
        },
        hasOneDocument(){
            return (this.current.file || this.current.has_pdf) || this.current.link
        },
        hasAtLeastOnAssignation(){
            return this.transporter_ids.length > 0
                || this.driver_ids.length > 0
                || this.logistic_area_ids.length > 0
        },

        //ZL
        iconAreas() {
            if (this.allAreas) return 'mdi-close-box'
            if (this.someAreas) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        isSelectAllAreasAvailable() {
            return this.searchAreasInput === '' || this.searchAreasInput === null;
        },
        allAreas() {
            return this.logistic_area_ids.length === this.logistic_areas.length
        },
        someAreas() {
            return this.logistic_area_ids.length > 0 && !this.allAreas
        },

        //Driver
        iconDrivers() {
            if (this.allDrivers) return 'mdi-close-box'
            if (this.someDrivers) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        isSelectAllDriversAvailable() {
            return this.searchDriversInput === '' || this.searchDriversInput === null;
        },
        allDrivers() {
            return this.driver_ids.length === this.drivers.length
        },
        someDrivers() {
            return this.driver_ids.length > 0 && !this.allDrivers
        },

        // transporters
        iconTransporters() {
            if (this.allTransporters) return 'mdi-close-box'
            if (this.someTransporters) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
        isSelectAllTransportersAvailable() {
            return this.searchTransportersInput === '' || this.searchTransportersInput === null;
        },
        allTransporters() {
            return this.transporter_ids.length === this.transporters.length
        },
        someTransporters() {
            return this.transporter_ids.length > 0 && !this.allTransporters
        },
    },
    methods:{
        getDocuments(){
            this.document_loading = true
            this.axios.get('/api/documents')
                .then(res => {
                    this.documents = res.data.data
                })
                .catch(err => {})
                .finally(() => {
                    this.document_loading = false
                })
        },
        close(){
            this.dialog = false
            this.edited_document = structuredClone(this.template)
            this.transporter_ids = []
            this.driver_ids = []
            this.logistic_area_ids = []
            this.getDocuments()
        },
        showFile(file){
            this.dialog = true
            this.edited_document = structuredClone(file)

            this.logistic_area_ids = file.affectations.find(item => {return item.group === 'logistic_area'})?.ids ?? []
            this.driver_ids = file.affectations.find(item => {return item.group === 'user'})?.ids ?? []
            this.transporter_ids = file.affectations.find(item => {return item.group === 'transporter'})?.ids ?? []
        },
        isAnUpdate(){
            return this.edited_document?.id > 0
        },
        openDeleteModal(file){
            this.dialogDelete = true
            this.edited_document = structuredClone(file)
        },
        async deleteDocument(id){
            try {
                this.deleteLoading = true
                await this.axios.delete(`/api/documents/${id}`)
                this.getDocuments()
                this.dialogDelete = false
            }catch (e) {
                this.showSnackbar('error', this.$t('document.delete_fail'));
            }
            finally {
                this.deleteLoading = false
            }
        },
        async getLogisticAreas(){
          const data = (await axios.get(LOGISTIC_AREAS)).data
          this.logistic_areas = data
        },
        async getDriverUsers(){
          const data = (await axios.get(USERS + '?filter[roles]=driver')).data
          this.drivers = _.sortBy(data, ['name'])
        },
        async getTransporters(){
          const data = (await axios.get(TRANSPORTERS)).data
            this.transporters = _.sortBy(data, ['code'])
        },
        async save(){
            if (!this.formIsValid){
                return
            }

            const payload = this.buildPayload()

            try {
                this.save_loading = true
                if (this.isAnUpdate()){
                    await
                        axios.post('/api/documents/' + this.edited_document.id, payload)
                }else {
                    await axios.post('/api/documents', payload)
                }
                this.showSnackbar('success', this.$t('document.updated_success'));
                this.close()
            } catch (e) {
                this.showSnackbar('error', this.$t('document.update_has_fail'));
            } finally {
                this.save_loading= false
            }

        },
        buildPayload(){
            let formData = new FormData();

            formData.append('name', this.edited_document.name)

            if (this.edited_document.file){
                formData.append("file", this.edited_document.file);
            }

            if (this.edited_document.comment){
                formData.append('comment', this.edited_document.comment)
            }else{
                formData.append('comment', " ")
            }

            if (this.edited_document.link){
                formData.append('link', this.edited_document.link)
            }else{
                formData.append('link', " ")
            }
            formData.append('actif', this.edited_document.actif ? 1 : 0)
            formData.append('must_be_read', this.edited_document.must_be_read ? 1 : 0)

            let affectations = []
            if (this.logistic_area_ids?.length > 0)
                affectations.push({group: 'logistic_area', ids: this.logistic_area_ids})
            if (this.driver_ids?.length > 0)
                affectations.push({group: 'user', ids: this.driver_ids})
            if (this.transporter_ids?.length > 0)
                affectations.push({group: 'transporter', ids: this.transporter_ids})

            formData.append('affectations', JSON.stringify(affectations))

            return formData
        },
        showSnackbar(type, text) {
            this.snackbar_type = type;
            this.snackbar_text = text;
            this.snackbar = true;
        },
        filter(item, queryText, itemText){
          queryText = queryText.toLocaleLowerCase()
          return item?.name?.toLocaleLowerCase().includes(queryText) ||
              item?.code?.toLocaleLowerCase().includes(queryText)
        },

    },
    watch: {
        toggleAreas() {
            this.$nextTick(() => {
                if (this.allAreas) {
                    this.logistic_area_ids = []
                } else {
                    this.logistic_areas.forEach(element => {
                        if (!this.logistic_area_ids.includes(element.id)) {
                            this.logistic_area_ids.push(element.id);
                        }
                    });
                }
            })
        },
        toggleDrivers() {
            this.$nextTick(() => {
                if (this.allDrivers) {
                    this.driver_ids = []
                } else {
                    this.drivers.forEach(element => {
                        if (!this.driver_ids.includes(element.id)) {
                            this.driver_ids.push(element.id);
                        }
                    });
                }
            })
        },
        toggleTransporters() {
            this.$nextTick(() => {
                if (this.allTransporters) {
                    this.transporter_ids = []
                } else {
                    this.transporters.forEach(element => {
                        if (!this.transporter_ids.includes(element.id)) {
                            this.transporter_ids.push(element.id);
                        }
                    });
                }
            })
        },
    },
}
</script>

<style scoped>

</style>
