<template>
    <div>
        <v-data-table
            :no-data-text="$t('global.no_data')"
            :loading-text="$t('global.loading_text')"
            :no-results-text="$t('global.no_results_text')"
            :headers="headers"
            :items="trucks"
            :loading="loading"
            class="elevation-1"
            :items-per-page="10"
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-dialog v-model="dialog" max-width="500px" @input="$validator.reset()">
                        <!--
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" dark class="mb-2" v-on="on">{{ $t('repository.new_item') }}</v-btn>
                        </template>
                        -->
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-form>
                                    <v-text-field
                                        :label="$t('repository.code')"
                                        name="code"
                                        prepend-icon="mdi-tanker-truck"
                                        v-validate="'required|max:3'"
                                        type="text"
                                        :error-messages="errors.collect('code')"
                                        data-vv-name="code"
                                        v-model="editedItem.code"
                                    ></v-text-field>
                                    <v-select
                                        v-model="editedItem.logistic_area_id"
                                        :items="logistic_areas"
                                        v-validate="'required'"
                                        :label="$t('repository.logistic_area')"
                                        :error-messages="errors.collect('logistic_area')"
                                        data-vv-name="logistic_area"
                                        id="logistic_area"
                                        name="logistic_area"
                                        prepend-icon="mdi-crosshairs"
                                        chips
                                        item-text="code"
                                        item-value="id"
                                    >
                                    </v-select>
                                    <v-select
                                        clearable
                                        v-model="editedItem.transporter_id"
                                        :items="transporters"
                                        :label="$t('repository.transporter')"
                                        :error-messages="errors.collect('transporter')"
                                        data-vv-name="transporter"
                                        id="transporter"
                                        name="transporter"
                                        prepend-icon="mdi-truck"
                                        chips
                                        item-text="code"
                                        item-value="id"
                                    >
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.code }} ({{ data.item.name }})
                                        </template>
                                    </v-select>
                                    <v-select
                                        clearable
                                        v-model="editedItem.vehicle_conditionning_id"
                                        :items="vehicle_conditionnings"
                                        :label="$t('repository.conditioning')"
                                        :error-messages="errors.collect('conditioning')"
                                        data-vv-name="conditioning"
                                        id="conditioning"
                                        name="conditioning"
                                        prepend-icon="mdi-truck"
                                        chips
                                        item-text="code"
                                        item-value="id"
                                    >
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.code }} ({{ data.item.name }})
                                        </template>
                                    </v-select>
                                    <v-select
                                        clearable
                                        v-model="editedItem.vehicle_fleet_id"
                                        :items="vehicle_fleets"
                                        :label="$t('repository.vehicle_fleet')"
                                        :error-messages="errors.collect('vehicle_fleet')"
                                        data-vv-name="vehicle_fleet"
                                        id="vehicle_fleet"
                                        name="vehicle_fleet"
                                        prepend-icon="mdi-truck"
                                        chips
                                        item-text="code"
                                        item-value="id"
                                    >
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.code }} ({{ data.item.name }})
                                        </template>
                                    </v-select>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    {{ $t('repository.cancel') }}
                                </v-btn>
                                <v-btn @click="save" :disabled="submit_button_is_disabled">
                                    {{ $t('repository.save') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>

            <!--

            <template v-slot:item.conditioning="{ item }">
                <div v-if="item && item.vehicle_conditionning && item.vehicle_conditionning.name">
                    {{ item.vehicle_conditionning.name }}
                </div>
                <div v-else>
                    -
                </div>
            </template>


            <template v-slot:item.fleet="{ item }">
                <div v-if="item && item.vehicle_fleet && item.vehicle_fleet.name">
                    {{ item.vehicle_fleet.name }}
                </div>
                <div v-else>
                    -
                </div>
            </template>

            -->

            <template v-slot:item.action="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    :title="$t('repository.update')"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <!-- Retrait à la demande de AVRIL 17/06/2020
               <v-icon
                   small
                   :title="$t('repository.delete')"
                   @click="deleteItem(item)"
               >
                   mdi-delete
               </v-icon>
               -->
           </template>

        </v-data-table>
       <v-dialog v-model="dialogDelete" max-width="290">
           <v-card elevation="0">
               <v-card-title class="headline">{{ $t('repository.sure') }}</v-card-title>
               <v-card-text>{{ $t('repository.irremediable') }}</v-card-text>
               <v-card-actions>
                   <v-spacer></v-spacer>
                   <v-btn color="darken-1" text @click="dialogDelete = false">
                       {{ $t('repository.cancel') }}
                   </v-btn>
                   <v-btn color="warning darken-1" text @click="deleteTruck()">
                       {{ $t('repository.delete') }}
                   </v-btn>
               </v-card-actions>
           </v-card>
       </v-dialog>
   </div>
</template>

<script>
   import {AREAS, TRUCK, TRUCKS, TRANSPORTERS, VEHICLE_FLEETS, VEHICLE_CONDITIONNINGS} from "../../api";

   export default {
       name: "trucks",
       data() {
           return {
               headers: [
                   {text: this.$t('repository.code'), value: 'code', sortable: true},
                   {text: this.$t('repository.logistic_area'), value: 'logistic_area.code', sortable: true},
                   {text: this.$t('repository.transporter'), value: 'transporter.code', sortable: true},
                   {text: this.$t('repository.conditioning'), value: 'vehicle_conditionning.name', sortable: true},
                   {text: this.$t('repository.vehicle_fleet'), value: 'vehicle_fleet.name', sortable: true},
                   {text: this.$t('repository.actions'), value: 'action', sortable: false},
               ],
               loading: false,
               dialog: false,
               trucks: [],
               editedIndex: -1,
               editedItem: {
                   code: '',
                   logistic_area_id: null,
                   transporter_id: null,
                   vehicle_conditionning_id: null,
                   vehicle_fleet_id:null,
               },
               defaultItem: {
                   code: '',
                   logistic_area: 1,
                   transporter: 1,
               },
               submit_button_is_disabled: false,
               itemToDelete: {},
               dialogDelete: false,
               logistic_areas: [],
               transporters: [],
               vehicle_fleets: [],
               vehicle_conditionnings: [],
           }
       },
       mounted() {
           this.getTrucks();
       },
       computed: {
           formTitle() {
               return this.editedIndex === -1 ? this.$t('repository.new_item') : this.$t('repository.edit_item')
           },
       },
       methods: {
           /**
            * Reset snackbar
            */
           resetSnackbar() {
               this.snackbar_type = null;
               this.snackbar_text = null;
               this.snackbar = false;
           },
           /**
            * Show snackbar
            *
            * @param {string} type
            * @param {string} text
            */
           showSnackbar(type, text) {
               this.snackbar_type = type;
               this.snackbar_text = text;
               this.snackbar = true;
           },
           /**
            * Get trucks
            */
           getTrucks() {
               this.loading = true;

               this.axios.get(TRUCKS).then(success => {
                   this.trucks = success.data;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               }).finally(() => {
                   this.loading = false;
               })
           },
           save() {
               this.submit_button_is_disabled = true;

               this.$validator.validateAll().then((result) => {
                   if (result) {
                       if (this.editedIndex > -1) {
                           this.updateItem(this.editedItem);
                       } else {
                           this.createItem(this.editedItem);
                       }
                   } else {
                       this.submit_button_is_disabled = false;
                   }
               });
           },
           /**
            * Update truck
            *
            * @param {object}  truck
            */
           updateItem(truck) {

                if(!truck.transporter_id) truck.transporter_id = null;
                if(!truck.vehicle_conditionning_id) truck.vehicle_conditionning_id = null;
                if(!truck.vehicle_fleet_id) truck.vehicle_fleet_id = null;

               this.axios.patch(
                   this.replacePathParams(TRUCK, {truck: truck.id}),
                   truck
               ).then((success) => {
                   Object.assign(this.trucks[this.editedIndex], success.data);
                   this.showSnackbar('success', this.$t('repository.updated'));
                   this.getTrucks();
                   this.close()
               }).catch((error) => {
                   if (error.response.status === 422) {
                       this.showSnackbar('warning', this.parseLaravelError(error));
                   } else {
                       this.showSnackbar('error', this.$t('global.error'));
                   }
               }).finally(() => {
                   this.submit_button_is_disabled = false;
               })
           },
           /**
            * Show edit dialog
            *
            * @param {object} truck
            */
           editItem(truck) {
               let tmpArea = Object.assign({}, truck.logistic_area);
               let tmpTransporter = Object.assign({}, truck.transporter);
               let tmpVehicleConditionning = Object.assign({}, truck.vehicle_conditionning);
               let tmpVehicleFleet = Object.assign({}, truck.vehicle_fleet);
               this.editedIndex = this.trucks.indexOf(truck);
               this.editedItem = Object.assign({}, truck);
               this.dialog = true;
               truck.logistic_area = tmpArea;
               truck.transporter = tmpTransporter;
               truck.vehicle_conditionning = tmpVehicleConditionning;
               truck.vehicle_fleet = tmpVehicleFleet;
           },
           /**
            * Add truck
            *
            * @param {object} truck
            */
           createItem(truck) {
               this.axios.post(
                   TRUCKS,
                   truck
               ).then((success) => {
                   this.trucks.push(success.data);
                   this.showSnackbar('success', this.$t('repository.created'));
                   this.close()
               }).catch((error) => {
                   if (error.response.status === 422) {
                       this.showSnackbar('warning', this.parseLaravelError(error));
                   } else {
                       this.showSnackbar('error', this.$t('global.error'));
                   }
               }).finally(() => {
                   this.submit_button_is_disabled = false;
               })
           },
           /**
            * Hide add/ edit item modal
            */
           close() {
               this.submit_button_is_disabled = false;
               this.dialog = false;
               setTimeout(() => {
                   this.editedItem = Object.assign({}, this.defaultItem);
                   this.editedIndex = -1
               }, 300)
           },
           /**
            * Show delete modal
            *
            * @param {object} truck
            */
           deleteItem(truck) {
               this.itemToDelete = truck;
               this.dialogDelete = true;
           },
           /**
            * Remove truck from database
            */
           deleteTruck() {
               this.axios.delete(
                   this.replacePathParams(TRUCK, {truck: this.itemToDelete.id})
               ).then(() => {
                   this.trucks.splice(this.trucks.indexOf(this.itemToDelete), 1);
                   this.showSnackbar('success', this.$t('repository.deleted'));
                   this.dialogDelete = false;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               });
           },
           getLogisticAreas() {
               if (this.logistic_areas.length === 0) {
                   this.axios.get(AREAS).then((success) => {
                       this.logistic_areas = success.data
                   }).catch(() => {
                       this.showSnackbar('error', this.$t('global.error'));
                   });
               }
           },
           getVehicleFleets() {
               if (this.vehicle_fleets.length === 0) {
                   this.axios.get(VEHICLE_FLEETS).then((success) => {
                       this.vehicle_fleets = success.data
                   }).catch(() => {
                       this.showSnackbar('error', this.$t('global.error'));
                   });
               }
           },
           getVehicleConditionnings() {
               if (this.vehicle_conditionnings.length === 0) {
                   this.axios.get(VEHICLE_CONDITIONNINGS).then((success) => {
                       this.vehicle_conditionnings = success.data
                   }).catch(() => {
                       this.showSnackbar('error', this.$t('global.error'));
                   });
               }
           },
           getTransporters() {
               if (this.transporters.length === 0) {
                   this.axios.get(TRANSPORTERS).then((success) => {
                       this.transporters = success.data
                   }).catch(() => {
                       this.showSnackbar('error', this.$t('global.error'));
                   });
               }
           }
       },
       watch: {
           async dialog(val) {
               if (val) {
                   await this.getLogisticAreas();
                   await this.getTransporters();
                   await this.getVehicleFleets();
                   await this.getVehicleConditionnings();

               } else {
                   this.close();
               }
           },
       },
   };
</script>
