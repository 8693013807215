<template>
    <div>
        <v-card-title>
            <v-spacer></v-spacer>
            <v-btn
                class="ml-4"
                color="primary"
                @click="newMedia()"
            >
                {{ $t('repository.new_item') }}
            </v-btn>
        </v-card-title>

        <v-data-table
            :no-data-text="$t('global.no_data')"
            :loading-text="$t('global.loading_text')"
            :no-results-text="$t('global.no_results_text')"
            :headers="headers"
            :items="site.media"
            ref="databaseContact"
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
        >
            <template v-slot:item.created_at="{ item }">
                <span v-if="item.created_at">
                    {{ item.created_at | moment('L LT') }}
                </span>
                <span v-else>
                    {{ $t('site.no_data') }}
                </span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-row>
                    <a :href="`storage/${item.id}/${item.file_name}`" download>
                        <v-icon
                            small
                        >
                            mdi-eye
                        </v-icon>
                    </a>
                    <v-icon
                        small
                        @click="edit(item)"
                    >
                        mdi-lead-pencil
                    </v-icon>
                    <v-icon
                        small
                        class="mr-2"
                        @click="deleteDialog(item)"
                        :title="$t('site.delete')"
                    >
                        mdi-delete
                    </v-icon>
                </v-row>
            </template>
        </v-data-table>

        <v-dialog v-model="dialog" max-width="500px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <div>
                            <v-file-input
                                v-if="!update_media"
                                v-model="file"
                                accept="*"
                                prepend-icon="mdi-paperclip"
                                :label="$t('repository.select_file')"
                                :error-messages="errors.collect('file')"
                                data-vv-name="file"
                                v-validate="'required'"
                            >
                            </v-file-input>
                            <v-text-field
                                v-model="file_to_update.name"
                                prepend-icon="mdi-textbox"
                                :label="$t('site.attachment_name')"
                                :error-messages="errors.collect('name')"
                                data-vv-name="name"
                                v-validate="'required'"
                            ></v-text-field>
                        </div>
                    </v-form>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="dialog = false">
                        {{ $t('repository.cancel') }}
                    </v-btn>
                    <v-btn @click="save()">
                        {{ $t('repository.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="290">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('repository.sure') }}</v-card-title>
                <v-card-text>{{ $t('repository.irremediable') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" text @click="dialogDelete = false">
                        {{ $t('repository.cancel') }}
                    </v-btn>
                    <v-btn color="warning darken-1" text @click="deleteMedia()">
                        {{ $t('repository.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            top
            :color="snackbar_type"
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import {SITE_ATTACHMENT} from "../../api";

    export default {
        name: "attachments",
        props: {
            site: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                headers: [
                    {text: this.$t('site.attachment_date'), value: 'created_at', sortable: false},
                    {text: this.$t('site.attachment_name'), value: 'name', sortable: true},
                    {text: this.$t('site.actions'), value: 'actions', sortable: false},
                ],
                dialog: false,
                dialogDelete: false,
                update_media: false,
                updating: false,
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                file: {},
                file_to_delete: {},
                file_to_update: {
                    id: null,
                    name: null,
                },
                default_file: {
                    id: null,
                    name: null,
                }
            }
        },
        computed: {
            formTitle () {
                return this.update_media ? this.$t('site.edit_attachment') : this.$t('site.add_attachment')
            },
        },
        methods: {
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Method used to update the site
             */
            addMedia() {
                this.updating = true;
                let formData = new FormData();
                formData.append("attachment", this.file);
                formData.append("name", this.file_to_update.name);
                this.axios.post(this.replacePathParams(SITE_ATTACHMENT, {site: this.site.id}), formData
                ).then((success) => {
                    this.site.media.push(success.data);
                    this.showSnackbar('success', this.$t('site.site_updated'));
                    this.file = null;
                    Object.assign(this.file_to_update, this.default_file);
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.updating = false;
                    this.dialog = false;
                })
            },
            deleteMedia() {
                this.axios.delete(this.replacePathParams(SITE_ATTACHMENT, {site: this.site.id}), {
                    params: {
                        id: this.file_to_delete.id
                    },
                }).then(() => {
                    const index = this.site.media.indexOf(this.file_to_delete);
                    if (index > -1) {
                        this.site.media.splice(index, 1);
                    }
                    this.showSnackbar('success', this.$t('site.site_updated'));
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.updating = false;
                    this.dialogDelete = false;
                })
            },
            updateMedia() {
                this.axios.patch(this.replacePathParams(SITE_ATTACHMENT, {site: this.site.id}),
                    this.file_to_update
                ).then(() => {
                    Object.assign(this.site.media.find(x => x.id === this.file_to_update.id), this.file_to_update);
                    this.showSnackbar('success', this.$t('site.site_updated'));
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.updating = false;
                    this.dialog = false;
                    this.update_media = false;
                })
            },
            deleteDialog(media) {
                this.file_to_delete = media;
                this.dialogDelete = true;
            },
            newMedia() {
                this.update_media = false;
                this.dialog = true;
            },
            edit(media) {
                Object.assign(this.file_to_update, media);
                this.update_media = true;
                this.dialog = true;
            },
            save() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        if (this.update_media) {
                            this.updateMedia();
                        } else {
                            this.addMedia();
                        }
                    }
                });
            }
        },
    }
</script>
