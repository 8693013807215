<template>
    <div class="versions-module">
        <v-row>
            <v-col
                class="white"
            >
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('navigation.administration'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                                {
                                  text: $t('navigation.cgu_localisation'),
                                  disabled: true,
                                  href: 'cgu_localisation',
                                },
                              ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-data-table
                        :headers="headers"
                        :items="file"
                    >
                        <template v-slot:item.comment="{ item }">
                            <p style="white-space: pre" v-html="item.comment"></p>
                        </template>
                        <template #[`item.last_update`]="{item}">
                            {{item.last_update | moment('L LT')}}
                        </template>
                        <template #[`item.action`]="{item}">
                            <a v-if="item.media[0]" :href="`storage/${item.media[0].id}/${item.media[0].file_name}`" download>
                                <v-icon
                                    small
                                >
                                    mdi-eye
                                </v-icon>
                            </a>
                            <v-icon
                                small
                                class="mr-2"
                                :title="$t('version.edit')"
                                @click="showFile(item)"
                            >
                                mdi-pencil
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog v-if="edit_file" v-model="dialog" max-width="500px"
                          @click:outside="close">
                    <v-card center>
                        <v-card-title>
                            <span class="headline">{{$t('cgu.edit_file') }}</span>
                        </v-card-title>
                        <v-card-text>
                            <v-form>
                                <v-file-input
                                    accept=".pdf"
                                    data-vv-name="file"
                                    :label="$t('cgu.select_file')"
                                    prepend-icon="mdi-file"
                                    v-model="new_file"
                                    v-validate="'required'"
                                ></v-file-input>
                                <v-text-field
                                    v-model="edit_file.filename"
                                    :label="$t('cgu.filename')"
                                    maxlength="10000"
                                    readonly
                                    disabled
                                    prepend-icon="mdi-file"
                                ></v-text-field>
                                <v-text-field
                                    :value="edit_file.last_update|moment('L LT')"
                                    :label="$t('cgu.last_update')"
                                    maxlength="10000"
                                    readonly
                                    disabled
                                    prepend-icon="mdi-calendar"
                                ></v-text-field>
                                <v-textarea
                                    v-model="edit_file.comment"
                                    :label="$t('cgu.comment')"
                                    maxlength="10000"
                                    outlined
                                    prepend-icon="mdi-message"
                                ></v-textarea>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="close">{{ $t('version.cancel') }}</v-btn>
                            <v-btn @click="save" :loading="save_loading" >
                                {{ $t('cgu.save') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-snackbar
                    v-model="snackbar"
                    top
                    :color="snackbar_type"
                >
                    {{ snackbar_text }}
                    <v-btn
                        text
                        @click="snackbar = false"
                    >
                        Close
                    </v-btn>
                </v-snackbar>
            </v-col>
        </v-row>
    </div>

</template>

<script>
export default {
    name: "CGULocalisation",
    data(){
        return{
            save_loading:false,
            dialog:false,
            new_file:null,
            edit_file:null,
            file:[],
            loading:false,
            headers:[
                {
                    text: 'Fichier',
                    value:'filename',
                    sortable: false
                },
                {
                    text: 'Dernière modification',
                    value:'last_update',
                    sortable: false
                },
                {
                    text: 'Commentaire',
                    value:'comment',
                    sortable: false
                },
                {
                    text: 'Actions',
                    value:'action',
                    sortable: false
                }
            ],

            snackbar: false,
            snackbar_text: '',
            snackbar_type: null,
        }
    },
    mounted() {
        this.getCguFile()
    },
    methods:{
        async getCguFile(){
            try {
                this.loading = true
                this.file = (await axios.get('/api/cgus')).data
            }catch (e) {

            }finally {
                this.loading = false
            }
        },
        async save(){
            this.dialog = false
            let formData = new FormData();
            if (this.new_file){
                formData.append("file", this.new_file);
            }
            formData.append('comment', this.edit_file.comment)
            try {
                this.save_loading= true
                await axios.post('/api/cgus', formData)
                this.showSnackbar('success', this.$t('cgu.updated'));
            } catch (e) {
                this.showSnackbar('error', this.$t('global.error'));
            } finally {
                this.save_loading= false
            }
            this.close()
            this.getCguFile()
        },
        showFile(item){
            this.edit_file = structuredClone(item)
            this.dialog = true
        },
        close(){
            this.edit_file = null
            this.new_file = null
            this.dialog = false
        },
        showSnackbar(type, text) {
            this.snackbar_type = type;
            this.snackbar_text = text;
            this.snackbar = true;
        },
    }
}
</script>

<style scoped>

</style>
