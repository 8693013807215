<template>
    <div>
        <v-data-table
            :no-data-text="$t('global.no_data')"
            :loading-text="$t('global.loading_text')"
            :no-results-text="$t('global.no_results_text')"
            :headers="headers"
            :items="drivers"
            :loading="loading"
            class="elevation-1"
            :items-per-page="10"
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
        >
            <template v-slot:item.action="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    :title="$t('repository.update')"
                    @click="openDialog(item)"
                >
                    mdi-pencil
                </v-icon>
            </template>
       </v-data-table>

        <v-dialog v-model="dialog" max-width="500px" @input="$validator.reset()">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <v-text-field
                            :label="$t('repository.code')"
                            name="code"
                            prepend-icon="mdi-alpha-c-box"
                            v-validate="'required|max:3'"
                            type="text"
                            data-vv-name="code"
                            v-model="driver.code"
                            :disabled="true"
                        ></v-text-field>
                        <v-text-field
                            :label="$t('repository.name')"
                            name="name"
                            prepend-icon="mdi-rename-box"
                            type="text"
                            data-vv-name="name"
                            v-model="driver.name"
                            :disabled="true"
                        ></v-text-field>
                        <v-autocomplete
                            id="transporter"
                            name="transporter"
                            v-model="driver.transporter_id"
                            data-vv-name="transporter"
                            chips
                            item-text="code"
                            item-value="id"
                            prepend-icon="mdi-truck"
                            :items="transporters"
                            :label="$t('repository.transporter')"
                            :disabled="transporters.length < 1"
                        >
                            <template slot="item" slot-scope="data">
                                {{ data.item.code }} ({{ data.item.name }})
                            </template>
                        </v-autocomplete>
                        <v-autocomplete
                            id="user"
                            name="user"
                            v-model="driver.user_id"
                            data-vv-name="user"
                            chips
                            item-text="name"
                            item-value="id"
                            prepend-icon="mdi-truck"
                            :items="unused_users"
                            :label="$t('repository.driver')"
                            :disabled="unused_users.length < 1"
                        >
                        </v-autocomplete>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                        {{ $t('repository.cancel') }}
                    </v-btn>
                    <v-btn @click="updateDriver()">
                        {{ $t('repository.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar" top :color="snackbar_type">
            {{ snackbar_text }}
            <v-btn text @click="snackbar = false">
                Close
            </v-btn>
        </v-snackbar>
   </div>
</template>

<script>
import {TRANSPORTERS, USERS, DRIVERS, DRIVER} from "../../api";

   export default {
       name: "expected_drivers",
       data() {
           return {
               headers: [
                   {text: this.$t('repository.code'), value: 'code', sortable: true},
                   {text: this.$t('repository.name'), value: 'name', sortable: true},
                   {text: this.$t('repository.transporter'), value: 'transporter.code', sortable: true},
                   {text: this.$t('repository.driver'), value: 'user.name', sortable: true},
                   {text: this.$t('repository.actions'), value: 'action', sortable: false},
               ],
               snackbar: false,
               snackbar_text: '',
               snackbar_type: null,
               loading: false,
               dialog: false,
               drivers: [],
               driver : {},
               transporters: [],
               users: []
           }
       },
       mounted() {
           this.getDrivers();
           this.getTransporters();
           this.getUsers();
       },
       computed: {
           // FIXME pour corriger une erreur console de render
           formTitle() {
               return '';
           },
           /**
            * Get all users not already linked with a driver (except the one of edited item)
            */
           unused_users() {
               const { user_id: editedItemUserId = null } = this.driver || {}
               const user_ids = this.drivers
                   .map(o => o.user_id)
                   .filter(id => !!id && id !== editedItemUserId)
               return this.users
                   .filter(o => user_ids.indexOf(o.id) < 0)
           }
       },
       methods: {
           /**
            * Reset snackbar
            */
           resetSnackbar() {
               this.snackbar_type = null;
               this.snackbar_text = null;
               this.snackbar = false;
           },
           /**
            * Show snackbar
            *
            * @param {string} type
            * @param {string} text
            */
           showSnackbar(type, text) {
               this.snackbar_type = type;
               this.snackbar_text = text;
               this.snackbar = true;
           },
           /**
            * Get drivers
            */
           getDrivers() {
               this.loading = true;
               this.axios.get(DRIVERS).then(success => {
                   this.drivers = success.data;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               }).finally(() => {
                   this.loading = false;
               })
           },
           /**
            * Get transporters
            */
           getTransporters() {
               if (this.transporters.length === 0) {
                   this.axios.get(TRANSPORTERS).then((success) => {
                       this.transporters = success.data
                   }).catch(() => {
                       this.showSnackbar('error', this.$t('global.error'));
                   });
               }
           },
           /**
            * Get users
            */
           getUsers() {
               if (this.drivers.length === 0) {
                   const config = {
                       params: {
                           // FIXME provoque une erreur côté serveur
                           // sort: 'name',
                           "filter[roles]": "driver",
                           per_page: -1
                       }
                   }
                   this.axios.get(USERS, config).then(({ data }) => {
                       this.users = data
                       // TODO en attendant la correction du tri par nom coté serveur
                       this.users = this.users.sort(({name: nameA = ''}, {name: nameB = ''}) => nameA.localeCompare(nameB));
                   }).catch(() => {
                       this.showSnackbar('error', this.$t('global.error'));
                   })
               }
           },
           /**
            * Open dialog
            */
           openDialog(item){
               this.dialog = true;
               this.driver = item;
           },
           /**
            * Update the driver
            */
           updateDriver(){
               this.loading = true;
               // si les champs sont undefined à cause du clear, ils ne sont pas envoyés ...
               this.driver.transporter_id = this.driver.transporter_id || null;
               this.driver.user_id = this.driver.user_id || null;

               this.axios.patch(
                   this.replacePathParams(DRIVER, {driver: this.driver.id}),
                   this.driver
               ).then((success) => {
                   this.driver = success.data;
                   this.getDrivers();
                   this.showSnackbar('success', this.$t('repository.updated'));
                   this.close()
               }).catch((error) => {
                   if (error.response.status === 422) {
                       this.showSnackbar('warning', this.parseLaravelError(error));
                   } else {
                       this.showSnackbar('error', this.$t('global.error'));
                   }
               }).finally(() => {
                   this.loading = false;
                   this.dialog = false;
               })
           },
           /**
            * Close update dialog
            */
           close() {
               this.dialog = false;
               setTimeout(() => {
                   this.driver = {};
               }, 300)
           },
       },

   };
</script>
