<template>
    <div>

        <v-data-table
            :footer-props="{
                        'items-per-page-options': rowsPerPageItems,
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
            :headers="headers"
            :items="sites_update_requests"
            :loading="loading"
            :loading-text="$t('global.loading_text')"
            :no-data-text="$t('global.no_data')"
            :no-results-text="$t('global.no_results_text')"
            :options.sync="options"
            :server-items-length="number_of_alerts"
            @update:options="changeOptions"
        >
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | moment('L LT') }}
            </template>

            <template v-slot:item.name_site="{ item }">
                {{ item.alertable.site.name }}
            </template>

            <template v-slot:item.type="{ item }">
                {{ item.alertable.type }}
            </template>
            <template v-slot:item.comment="{ item }">
                {{ item.alertable.comment }}
            </template>


            <template v-slot:item.driver="{ item }">
                <span v-if="item.alertable.user">
                    {{ item.alertable.user.name }}
                </span>
                <span v-else>
                    {{$t('alert.empty')}}
                </span>
            </template>

            <template v-slot:item.truck="{ item }">
                <span v-if="item.alertable.truck">
                     {{ item.alertable.truck.code }}
                </span>
                <span v-else>
                    {{$t('alert.empty')}}
                </span>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-icon
                    :title="$t('alert.go_to_alert_site')"
                    class="mr-2"
                    small
                    @click="action(item)"
                >
                    mdi-arrow-right
                </v-icon>

                <v-icon
                    :title="$t('alert.send_an_update_request')"
                    class="mr-2"
                    small
                    @click="goToAddAction(item)"
                >
                    mdi-file-plus
                </v-icon>
            </template>

        </v-data-table>
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar color="primary" dark>
                    <v-btn dark icon @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title> {{ this.$t('alert.alert_detail') }}</v-toolbar-title>
                </v-toolbar>
                <v-row>
                    <v-col cols="6 text-center">
                        <v-list subheader three-line>
                            <v-subheader class="title">{{ this.$t('alert.site_informations') }}</v-subheader>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon>mdi-book-open-variant</v-icon>
                                        {{ $t('site.name') }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ this.site.name || $t('site.none') }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon>mdi-map-marker</v-icon>
                                        {{ $t('site.contact_information') }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{this.site.address_1||$t('site.none')}}
                                        <br v-if="site.address_1">
                                        {{this.site.address_2}}
                                        <br v-if="site.address_2">
                                        {{this.site.address_3}}
                                        <br v-if="site.address_3">
                                        {{this.site.zip}}, {{this.site.city}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon>mdi-factory</v-icon>
                                        {{ $t('site.type') }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-if="this.site.companies">
                                        <span v-if="this.site.companies[0].code.length > 2">
                                            {{ $t('site.breeding') }}
                                        </span>
                                        <span v-else>
                                            {{ $t('site.factory') }}
                                        </span>
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-else>
                                        {{$t('site.none')}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon>mdi-crosshairs-gps</v-icon>
                                        {{ $t('site.gps_coordinate') }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span v-if="site.longitude && site.latitude">
                                            {{ site.latitude }}, {{ site.longitude }}
                                        </span>
                                        <span v-else>
                                            0, 0
                                        </span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon>mdi-message</v-icon>
                                        {{ $t('site.comments') }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ this.site.comments||$t('site.none') }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon>mdi-message</v-icon>
                                        {{ $t('site.route_comments') }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ this.site.route_comments||$t('site.none') }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon>mdi-google-earth</v-icon>
                                        {{ $t('site.site_references') }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        <span
                                            v-for="(company,key) in companies"
                                            v-if="companies.length > 0"
                                        >
                                            {{ company }}<span v-if="key+1 !== companies.length">,</span>
                                        </span>
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon>mdi-account-box</v-icon>
                                        {{this.$t('site.contact_name')}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-if="this.selected_alert.alertable.site">
                                        {{this.site.contact_avril_name ||$t('site.none')}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon>mdi-phone-outline</v-icon>
                                        {{this.$t('site.phone')}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-if="this.selected_alert.alertable.site">
                                        {{this.site.contact_avril_telephone ||$t('site.none')}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <div class="text-center">
                            <v-btn
                                color="grey darken-1"
                                outlined
                                tile
                                @click="refresh()"
                            >
                                <v-icon
                                    class="mr-2"
                                    small
                                >
                                    mdi-refresh
                                </v-icon>
                                {{ $t('tour.refresh') }}
                            </v-btn>
                        </div>
                    </v-col>
                    <v-divider vertical></v-divider>
                    <v-col cols="5 text-center">
                        <v-list subheader three-line>
                            <v-subheader class="title">{{ this.$t('alert.request_detail') }}</v-subheader>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon>mdi-shape</v-icon>
                                        {{ this.$t('alert.type') }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{ this.selected_alert.alertable.type }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon>mdi-message</v-icon>
                                        {{this.$t('alert.comment')}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-if="selected_alert.alertable">
                                        {{this.selected_alert.alertable.comment}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon>mdi-car-child-seat</v-icon>
                                        {{this.$t('alert.driver')}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-if="selected_alert.alertable.user">
                                        {{this.selected_alert.alertable.user.name}}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-else>
                                        {{$t('site.none')}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-icon>mdi-truck</v-icon>
                                        {{this.$t('alert.truck')}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle v-if="selected_alert.alertable.truck">
                                        {{this.selected_alert.alertable.truck.code || $t('site.none')}}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle v-else>
                                        {{$t('site.none')}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                        <div class="text-center ml-6">
                            <v-btn
                                class="ml-6"
                                color="grey darken-1"
                                outlined
                                tile
                                @click="goToSite(selected_alert.alertable.site.id)"
                            >
                                <v-icon
                                    class="mr-2"
                                    small
                                >
                                    mdi-eye
                                </v-icon>
                                {{ $t('tour.see_site') }}
                            </v-btn>

                        </div>
                        <div class="text-center ml-9 mt-3">
                            <v-btn
                                class="ml-6"
                                color="grey darken-1"
                                outlined
                                tile
                                @click="goToAddAction(selected_alert)"
                            >
                                <v-icon
                                    class="mr-2"
                                    small
                                >
                                    mdi-email
                                </v-icon>
                                {{ $t('alert.send_an_update_request') }}
                            </v-btn>

                            <v-btn
                                class="ml-6"
                                color="red lighten-2"
                                outlined
                                tile
                                @click="closeAlert(selected_alert.id)"
                            >
                                <v-icon
                                    class="mr-2"
                                    small
                                >
                                    mdi-delete
                                </v-icon>
                                {{ $t('alert.resolve_alert') }}
                            </v-btn>

                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type"
            top
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {ACTIONS, ALERT, SITE, SITE_UPDATE_REQUEST} from "../../api";
import {bus} from "../../app";

export default {
    data() {
        return {
            loading: false,
            headers: [
                {text: this.$t('alert.created_at'), value: 'created_at', sortable: false},
                {text: this.$t('alert.name_site'), value: 'name_site', sortable: false},
                {text: this.$t('alert.type'), value: 'type', sortable: false},
                {text: this.$t('alert.comment'), value: 'comment', sortable: false},
                {text: this.$t('alert.driver'), value: 'driver', sortable: false},
                {text: this.$t('alert.truck'), value: 'truck', sortable: false},
                {text: this.$t('alert.actions'), value: 'actions', sortable: false},
            ],
            snackbar: false,
            snackbar_text: '',
            snackbar_type: null,
            dialog: false,
            selected_alert: {alertable: {site: {name}, user: {}, truck: {}}},
            site: {},
            alerts: [],
            number_of_alerts: 0,
                options: {page: 1, itemsPerPage: 10},
                rowsPerPageItems: [5, 10, 100],
                alert : {}
            }
        },
        computed: {
            sites_update_requests() {
                return this.alerts;
            },
            companies() {
                let companies = [];
                _.each(this.site.companies, (company) => {
                    companies.push(company.code);
                });

                return companies;
            }
        },
        mounted() {
            this.getSiteUpdateRequest();
        },
        created() {
            bus.$on("applyFilter", () => {
                this.getSiteUpdateRequest()
            })
        },
        methods: {
            ...mapActions({ count_alerts : 'alert/count'}),
            ...mapGetters({get_filters:'alert/get_filters'}),
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },

            /**
             * Method used on the click on an alert
             *
             * @param {object} item
             */
            async action(item) {
                this.selected_alert = item;
                await this.getSite(this.selected_alert.alertable.site.id);
                this.dialog = true;
            },

            /**
             * Go to site specified
             *
             * @param {integer} siteId
             */
            goToSite(siteId) {
                let routeData = this.$router.resolve({name: 'site', params: {siteId}});
                window.open(routeData.href, '_blank');
            },

            /**
             * Go to site specified
             *
             * @param {integer} alertId
             */
            closeAlert() {
                this.dialog = false;
                this.deleteAlert();
            },
            async createAction() {
                this.loading = true;
                let action = {
                    site_id: this.selected_alert.id,
                    type_id: 17, // @todo A change with avril_id
                    details: "Clôture de la demande de changement d'information du site",
                };

                await this.axios.post(ACTIONS, action);
                this.loading = false;
            },
            /**
             * Method used for the suppression of the alert
             */
            deleteAlert() {
                this.loading = true;
                this.axios.delete(this.replacePathParams(ALERT, {alert: this.selected_alert.id})).then(() => {
                    this.createAction();
                    this.showSnackbar('success', this.$t('alert.deleted'));
                    this.count_alerts();
                    this.getSiteUpdateRequest();
                    this.dialog = false;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                });
            },

            /**
             * Get the site with a given ID
             */
            async getSite(site) {
                await this.axios.get(
                    this.replacePathParams(SITE, {site})
                ).then((success) => {
                    this.site = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                })
            },
            /**
             * Method used to refresh site's data
             * @returns {Promise<void>}
             */
            async refresh() {
                this.loading = true;
                await this.getSite(this.selected_alert.alertable.site.id);
                this.loading = false;
            },
            /**
             * Get alerts
             */
            getSiteUpdateRequest() {
                this.loading = true;
                this.axios.get(SITE_UPDATE_REQUEST,
                    {params: {page: this.options.page, per_page: this.options.itemsPerPage,
                            logistic_areas: this.get_filters().logistic_areas_choices}}
                ).then((success) => {
                    this.alerts = success.data.data;
                    this.number_of_alerts = success.data.total;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                });
            },
            /**
             * Send new alert without parameter
             */
            async sendAlert() {
                let site_details =
                    this.$t('alert.type') +' : ' + this.selected_alert.alertable.type + ' / ' +
                    this.$t('alert.comment') +' : ' + this.selected_alert.alertable.comment + ' / ' +
                    this.$t('alert.driver') +' : ' + this.selected_alert.alertable.user.name + ' / ' +
                    this.$t('alert.truck') +' : ' + this.selected_alert.alertable.truck.code ;

                let action = {
                    site_id: this.selected_alert.alertable.site.id,
                    type_id: 17,
                    details: site_details,
                };

                await this.postAction(action);
            },

            /**
             * Send new alert with parameter
             */
            async sendAlertWithParameter(item) {
                let site_details =
                    this.$t('alert.type') +' : ' + item.alertable.type + ' / ' +
                    this.$t('alert.comment') +' : ' + item.alertable.comment + ' / ' +
                    this.$t('alert.driver') +' : ' + item.alertable.user.name + ' / ' +
                    this.$t('alert.truck') +' : ' + item.alertable.truck.code ;

                let action = {
                    site_id: item.alertable.site.id,
                    type_id: 17,
                    details: site_details,
                };

                await this.postAction(action);
            },

            async postAction(action) {
                await this.axios.post(ACTIONS, action).then(() => {
                    this.showSnackbar('success', this.$t('alert.update_request_sent'));
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                });
            },

            /**
             * Change options
             *
             * @param {object} options
             */
            changeOptions(options) {
                this.options.itemsPerPage = options.itemsPerPage;
                this.options.page = options.page;
                this.getSiteUpdateRequest();
            },
            /**
             * Go to add action
             *
             * @param {object} alert
             */
            goToAddAction(alert) {
                window.open((this.$router.resolve({
                    name: 'site',
                    params: {siteId: alert.alertable.site_id},
                    query: {
                        tab: 'tab-actions',
                        showDialog: true,
                        actionTypeCode: 'update.site',
                        actionNotificationSent: true,
                        siteUpdateRequestId: alert.alertable.id,
                        alertId: alert.id
                    }
                })).href, '_blank');
            }
        },
    }
</script>
