<template>
    <div>
        <v-data-table
            :no-data-text="$t('global.no_data')"
            :loading-text="$t('global.loading_text')"
            :no-results-text="$t('global.no_results_text')"
            :headers="headers"
            :items="mobile_parameters"
            :loading="loading"
            class="elevation-1"
            :items-per-page="10"
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-dialog v-model="dialog" max-width="500px" @input="$validator.reset()">
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" dark class="mb-2" v-on="on">{{ $t('repository.new_item') }}</v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-form>
                                    <v-text-field
                                        :label="$t('repository.database_retention_time')"
                                        name="database_retention_time"
                                        prepend-icon="mdi-database-refresh"
                                        v-validate="'required|numeric'"
                                        type="text"
                                        :error-messages="errors.collect('database_retention_time')"
                                        data-vv-name="database_retention_time"
                                        v-model="editedItem.database_retention_time"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('repository.message_retention_time')"
                                        name="message_retention_time"
                                        prepend-icon="mdi-cellphone-message"
                                        v-validate="'required|numeric'"
                                        type="text"
                                        :error-messages="errors.collect('message_retention_time')"
                                        data-vv-name="message_retention_time"
                                        v-model="editedItem.message_retention_time"
                                    ></v-text-field>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    {{ $t('repository.cancel') }}
                                </v-btn>
                                <v-btn @click="save" :disabled="submit_button_is_disabled">
                                    {{ $t('repository.save') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.updated_at="{ item }">
                {{ item.updated_at | moment('L LT') }}
            </template>
            <template v-slot:item.action="{ item }">
                <v-icon
                    small
                    :title="$t('repository.update')"
                    class="mr-2"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <!-- Retrait à la demande de AVRIL 17/06/2020
               <v-icon
                   small
                   :title="$t('repository.delete')"
                   @click="deleteItem(item)"
               >
                   mdi-delete
               </v-icon>
               -->
           </template>
       </v-data-table>
       <v-dialog v-model="dialogDelete" max-width="290">
           <v-card elevation="0">
               <v-card-title class="headline">{{ $t('repository.sure') }}</v-card-title>
               <v-card-text>{{ $t('repository.irremediable') }}</v-card-text>
               <v-card-actions>
                   <v-spacer></v-spacer>
                   <v-btn color="darken-1" text @click="dialogDelete = false">
                       {{ $t('repository.cancel') }}
                   </v-btn>
                   <v-btn color="warning darken-1" text @click="deleteMobileParameter()">
                       {{ $t('repository.delete') }}
                   </v-btn>
               </v-card-actions>
           </v-card>
       </v-dialog>
   </div>
</template>

<script>
   import {MOBILE_PARAMETER, MOBILE_PARAMETERS} from "../../api";

   export default {
       name: "mobile-parameters",
       data() {
           return {
               headers: [
                   {
                       text: this.$t('repository.updated_at'),
                       value: 'updated_at',
                       sortable: true
                   },
                   {
                       text: this.$t('repository.database_retention_time'),
                       value: 'database_retention_time',
                       sortable: true
                   },
                   {
                       text: this.$t('repository.message_retention_time'),
                       value: 'message_retention_time',
                       sortable: true
                   },
                   {
                       text: this.$t('repository.actions'),
                       value: 'action',
                       sortable: false
                   },
               ],
               loading: false,
               dialog: false,
               mobile_parameters: [],
               editedIndex: -1,
               editedItem: {
                   database_retention_time: 0,
                   message_retention_time: 0,
               },
               defaultItem: {
                   database_retention_time: 7,
                   message_retention_time: 14,
               },
               submit_button_is_disabled: false,
               itemToDelete: {},
               dialogDelete: false,
           }
       },
       mounted() {
           this.getMobileParameters();
       },
       computed: {
           formTitle () {
               return this.editedIndex === -1 ? this.$t('repository.new_item') : this.$t('repository.edit_item')
           },
       },
       methods: {
           /**
            * Reset snackbar
            */
           resetSnackbar() {
               this.snackbar_type = null;
               this.snackbar_text = null;
               this.snackbar = false;
           },
           /**
            * Show snackbar
            *
            * @param {string} type
            * @param {string} text
            */
           showSnackbar(type, text) {
               this.snackbar_type = type;
               this.snackbar_text = text;
               this.snackbar = true;
           },
           /**
            * Get mobile parameters
            */
           getMobileParameters() {
               this.loading = true;

               this.axios.get(MOBILE_PARAMETERS).then(success => {
                   this.mobile_parameters = success.data;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               }).finally(() => {
                   this.loading = false;
               })
           },
           save () {
               this.submit_button_is_disabled = true;

               this.$validator.validateAll().then((result) => {
                   if (result) {
                       if (this.editedIndex > -1) {
                           this.updateItem(this.editedItem);
                       } else {
                           this.createItem(this.editedItem);
                       }
                   } else {
                       this.submit_button_is_disabled = false;
                   }
               });
           },
           /**
            * Update mobile parameter
            *
            * @param {object}  mobile_parameter
            */
           updateItem(mobile_parameter) {
               this.axios.patch(
                   this.replacePathParams(MOBILE_PARAMETER, {mobile_parameter: mobile_parameter.id}),
                   mobile_parameter
               ).then((success) => {
                   Object.assign(this.mobile_parameters[this.editedIndex], success.data);
                   this.showSnackbar('success', this.$t('repository.updated'));
                   this.close()
               }).catch((error) => {
                   if (error.response.status === 422) {
                       this.showSnackbar('warning', this.parseLaravelError(error));
                   } else {
                       this.showSnackbar('error', this.$t('global.error'));
                   }
               }).finally(() => {
                   this.submit_button_is_disabled = false;
               })
           },
           /**
            * Show edit dialog
            *
            * @param {object} mobile_parameter
            */
           editItem(mobile_parameter) {
               this.editedIndex = this.mobile_parameters.indexOf(mobile_parameter);
               this.editedItem = Object.assign({}, mobile_parameter);
               this.dialog = true;
           },
           /**
            * Add mobile parameter
            *
            * @param {object} mobile_parameter
            */
           createItem(mobile_parameter) {
               this.axios.post(
                   MOBILE_PARAMETERS,
                   mobile_parameter
               ).then((success) => {
                   this.mobile_parameters.push(success.data);
                   this.showSnackbar('success', this.$t('repository.created'));
                   this.close()
               }).catch((error) => {
                   if (error.response.status === 422) {
                       this.showSnackbar('warning', this.parseLaravelError(error));
                   } else {
                       this.showSnackbar('error', this.$t('global.error'));
                   }
               }).finally(() => {
                   this.submit_button_is_disabled = false;
               })
           },
           /**
            * Hide add/ edit item modal
            */
           close () {
               this.submit_button_is_disabled = false;
               this.dialog = false;
               setTimeout(() => {
                   this.editedItem = Object.assign({}, this.defaultItem);
                   this.editedIndex = -1
               }, 300)
           },
           /**
            * Show delete modal
            *
            * @param {object} mobile_parameter
            */
           deleteItem(mobile_parameter) {
               this.itemToDelete = mobile_parameter;
               this.dialogDelete = true;
           },
           /**
            * Remove mobile parameter from database
            */
           deleteMobileParameter() {
               this.axios.delete(
                   this.replacePathParams(MOBILE_PARAMETER, {mobile_parameter: this.itemToDelete.id})
               ).then(() => {
                   this.mobile_parameters.splice(this.mobile_parameters.indexOf(this.itemToDelete), 1);
                   this.showSnackbar('success', this.$t('repository.deleted'));
                   this.dialogDelete = false;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               });
           },
       },
       watch: {
           dialog (val) {
               val || this.close()
           },
       },
   };
</script>
