<template>
    <div class="reset-password">
        <v-row
            align="center"
            justify="center"
            class="reset-password-row"
        >
            <v-col
                cols="12"
                sm="8"
                md="4"
            >
                <v-card
                    class="elevation-12"
                    align-self="center"
                >
                    <v-toolbar
                        color="primary"
                        dark
                        flat
                    >
                        <v-toolbar-title>{{ $t('login.reset_password')}}</v-toolbar-title>
                        <div class="flex-grow-1"></div>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field
                                :label="$t('login.email')"
                                name="email"
                                prepend-icon="mdi-email"
                                v-validate="'required|email'"
                                type="text"
                                :error-messages="errors.collect('email')"
                                data-vv-name="email"
                                v-model="email"
                            ></v-text-field>
                            <v-text-field
                                :label="$t('login.password')"
                                v-validate="'required|min:2'"
                                id="password"
                                name="password"
                                prepend-icon="mdi-lock"
                                type="password"
                                :error-messages="errors.collect('password')"
                                data-vv-name="password"
                                v-model="password"
                                ref="password"
                            ></v-text-field>
                            <v-text-field
                                :label="$t('login.password_confirmation')"
                                v-validate="'required|confirmed:password|min:8'"
                                id="password_confirmation"
                                name="password_confirmation"
                                prepend-icon="mdi-lock"
                                type="password"
                                :error-messages="errors.collect('password_confirmation')"
                                data-vv-name="password_confirmation"
                                data-vv-as="password"
                                v-model="password_confirmation"
                            ></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn color="primary"
                               :disabled="submit_button_is_disabled"
                               @click.prevent="validateBeforeSubmit"
                        >
                            {{ $t('login.reset_password') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackbar"
            top
            :color="snackbar_type"
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<style lang="scss" scoped>
    .reset-password {
        position: fixed;

        z-index: 9999;

        width: 100%;
        height: 100%;

        top: 0;
        left: 0;

        margin: 0;
        padding: 0;
        background: url(/images/background_login.jpg) no-repeat center fixed;
        -webkit-background-size: cover;
        background-size: cover;

        .reset-password-row {
            height: 100%;

            background: hsla(360, 0%, 0%, 0.4);
        }
    }
</style>

<script>
    import {PASSWORD_RESET} from "../api";

    export default {
        name: 'reset-password',
        data() {
            return {
                email: null,
                password: null,
                password_confirmation: null,
                submit_button_is_disabled: null,
                snackbar: false,
                snackbar_type: null,
                snackbar_text: null,
            }
        },
        methods: {
            /**
             * Validate data before call reset method
             */
            validateBeforeSubmit() {
                this.resetSnackbar();

                this.submit_button_is_disabled = true;
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.resetPassword(
                            {
                                email: this.email,
                                password: this.password,
                                password_confirmation: this.password_confirmation,
                                token: this.token,
                            }
                        );
                    } else {
                        this.submit_button_is_disabled = false;
                    }
                });
            },
            /**
             * Reset password
             *
             * @param {object} credential
             */
            resetPassword(credential) {
                this.axios.post(PASSWORD_RESET, credential).then((success) => {
                    this.showSnackbar('success', success.data);
                    this.$router.push({name: 'login'})
                }).catch((error) => {
                    if (error.response.status === 401) {
                        this.showSnackbar('warning', this.$t('reset_password.bad_credential'));
                    } else if (error.response.status === 400) {
                        this.showSnackbar('warning', error.response.data);
                    } else {
                        this.showSnackbar('error', this.$t('global.error'));
                    }

                    this.submit_button_is_disabled = false;
                });
            },
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            }
        },
        computed: {
            token() {
                return this.$route.params.token;
            }
        }
    }
</script>

