<template>
    <div>

        <v-data-table
            ref="table"
            :custom-sort="customSort"
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
            :headers="headers"
            :items="indexedHistories"
            :loading="loading"
            :loading-text="$t('global.loading_text')"
            :no-data-text="$t('global.no_data')"
            :no-results-text="$t('global.no_results_text')"
            item-key="index"
            sort-by="date"
        >
            <template v-slot:item.date="{ item }">
                <span> {{ getOperationDate(item) | moment('L') }}</span>
            </template>

            <template v-slot:item.start_time="{ item }">
                <span> {{ getRealStartTime(item) | moment('LT') }}</span>
            </template>

            <template v-slot:item.end_time="{ item }">
                <span> {{ getRealEndTime(item) | moment('LT') }}</span>
            </template>

            <template v-slot:item.driver="{ item }">
                <span v-if="item.user"> {{ item.user }}</span>
                <span v-else> {{ item.driver }}</span>
            </template>

            <template v-slot:item.vehicle="{ item }">
                <span> {{ item.truck }} </span>
            </template>

            <template v-slot:item.order="{ item }">
                <span> {{ item.order_id }} - {{ item.line_id }} </span>
            </template>

            <template v-slot:item.product="{ item }">
                <span v-if="item.category.media[0]">
                    <img
                        :alt="$t('repository.pictogram')"
                        :src="`storage/${item.category.media[0].id}/${item.category.media[0].name}`"
                        height="32"
                    >
                </span>
                <span>{{ getProduct(item) }} </span>
            </template>

            <template v-slot:item.delivered_quantity="{ item }">
                <span>{{ getQuantity(item) }} </span>
            </template>

            <template v-slot:item.silo="{ item }">
                <span>{{ getSilo(item) }} </span>
            </template>

            <template v-slot:item.type="{ item }">
                <span>{{ getType(item) }} </span>
            </template>

            <template v-slot:item.scanned="{ item }">
                <div v-if="item.scanned !== null">
                    <v-icon
                        :color="item.scanned ? 'green': 'orange' "
                    >mdi-barcode-scan
                    </v-icon>
                </div>
            </template>

            <template v-slot:item.biosecurity="{ item }">
                <biosecurity-info :biosecurity="item.biosecurity"/>
            </template>

        </v-data-table>

        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type"
            top
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import {SITE_HISTORY} from "../../api";
import BiosecurityInfo from "../Biosecurity/BiosecurityInfo.vue";

export default {
    name: "history",
    components: {BiosecurityInfo},

    data() {
        return {
            props: {
                site: {
                    type: Object,
                    required: true
                },
            },
            headers: [
                {text: this.$t('site.date'), value: 'date', sortable: true},
                {text: this.$t('site.entry'), value: 'start_time', sortable: false},
                {text: this.$t('site.exit'), value: 'end_time', sortable: false},
                {text: this.$t('site.driver'), value: 'driver', sortable: false},
                {text: this.$t('site.vehicle'), value: 'vehicle', sortable: false},
                {text: this.$t('site.order_id'), value: 'order', sortable: false},
                {text: this.$t('site.product'), value: 'product', sortable: false},
                {text: this.$t('site.delivered_quantity'), value: 'delivered_quantity', sortable: false},
                {text: this.$t('site.cr_status'), value: 'status', sortable: false},
                {text: this.$t('site.silo'), value: 'silo', sortable: false},
                {text: this.$t('site.loading_company'), value: 'loading_company', sortable: false},
                {text: this.$t('site.op_type'), value: 'type', sortable: false},
                {text: this.$t('site.cr_comment'), value: 'comment', sortable: false},
                {text: this.$t('site.qrc'), value: 'scanned', sortable: false},
                {text: this.$t('site.public_health_alert'), value: 'biosecurity', sortable: false},
                {text: '', value: 'data-table-expand'},
            ],
            history: [],
            snackbar: false,
            snackbar_text: '',
            snackbar_type: null,
            loading: false
        }
    },
    methods: {
        customSort(items, index, isDesc) {
            return items;
        },
        /**
         * Reset snackbar
         */
        resetSnackbar() {
            this.snackbar_type = null;
            this.snackbar_text = null;
            this.snackbar = false;
        },

        /**
         * Show snackbar
         *
         * @param {string} type
         * @param {string} text
         */
        showSnackbar(type, text) {
            this.snackbar_type = type;
            this.snackbar_text = text;
            this.snackbar = true;
        },

        /**
         * Get Silo's history
         *
         * @param site
         */
        async getHistory(site) {
            this.loading = true
            await this.axios.get(
                this.replacePathParams(SITE_HISTORY, {site})
            ).then((success) => {
                this.history = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            }).finally(() => {
                this.loading = false
            })
        },

        /**
         * Get the operation date according to the step type
         *
         * @param item
         * @returns {*}
         */
        getOperationDate: function (item) {

            if (item.type === 'LOADING') {

                if (item.loading_real_start_datetime !== null) {
                    return item.loading_real_start_datetime;
                }

                if (item.loading_driver_start_datetime !== null) {
                    return item.loading_driver_start_datetime
                }

                if (item.loading_expected_datetime !== null) {
                    return item.loading_expected_datetime
                }
            } else if (item.type === 'DELIVERY') {
                if (item.delivery_real_start_datetime !== null) {
                    return item.delivery_real_start_datetime;
                }

                if (item.delivery_driver_start_datetime !== null) {
                    return item.delivery_driver_start_datetime
                }

                if (item.delivery_expected_datetime !== null) {
                    return item.delivery_expected_datetime
                }
            }

        },

        /**
         * Get the real start time according to the step type
         *
         * @param item
         * @returns {*}
         */
        getRealStartTime: function (item) {
            if (item.type === 'LOADING') {

                if (item.loading_driver_start_datetime !== null) {
                    return item.loading_driver_start_datetime;
                }
            } else if (item.type === 'DELIVERY') {

                if (item.delivery_driver_start_datetime !== null) {
                    return item.delivery_driver_start_datetime;
                }
            }

            return '-';
        },

        /**
         * Get the real end time according to the step type
         *
         * @param item
         * @returns {*}
         */
        getRealEndTime: function (item) {
            if (item.type === 'LOADING') {

                if (item.loading_driver_end_datetime !== null) {
                    return item.loading_driver_end_datetime;
                }

            } else if (item.type === 'DELIVERY') {

                if (item.delivery_driver_end_datetime !== null) {
                    return item.delivery_driver_end_datetime;
                }
            }

            return '-';
        },

        /**
         * Get the product from the step
         *
         * @param item
         * @returns {string}
         */
        getProduct: function (item) {
            return item.product_code + ' - ' + item.product_name;
        },

        /**
         * Get quantity product
         *
         * @param item
         */
        getQuantity: function (item) {

            if (item.product_loaded_quantity !== null) {
                return item.product_loaded_quantity + 'kg';
            }

            if (item.product_ordered_quantity !== null) {
                return item.product_ordered_quantity + 'kg';
            }

            return this.$t('silo.none');
        },

        getSilo: function (item) {

            if (item.type === 'DELIVERY') {
                return item.silo;
            }

            return '';

        },

        /**
         * Get operation type
         *
         * @param item
         */
        getType: function (item) {

            if (item.type === 'LOADING') {
                return 'C';
            }

            if (item.type === 'DELIVERY') {
                return 'L';
            }

            return this.$t('silo.none');
        },
        getBiosecurityDescription(biosecurity){
            let code = biosecurity.code ?? '/'
            let name = biosecurity.name ?? '/'
            return code + ' - ' + name
        }
    },
    mounted() {
        this.getHistory(this.$route.params.siteId);
    },
    computed: {
        indexedHistories() {
            return this.history.map((item, index) => {
                return {
                    index,
                    ...item
                }
            })
        }
    }
}
</script>
