<template>
    <div class="versions-module">
        <v-row>
            <v-col
                class="white"
            >
                <!--
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('navigation.versions') }}
                    </p>
                </v-subheader>
                -->
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('navigation.administration'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                                {
                                  text: $t('navigation.versions'),
                                  disabled: true,
                                  href: 'breadcrumbs_versions',
                                },
                              ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-btn color="primary" dark class="mb-2" @click="openDialog()">{{ $t('version.new') }}</v-btn>
                        <v-dialog v-model="dialog" max-width="500px" @input="$validator.reset()">
                            <v-card center>
                                <v-card-title>
                                    <span class="headline">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-form>
                                        <v-file-input
                                            accept=".apk"
                                            data-vv-name="file"
                                            :error-messages="errors.collect('file')"
                                            :label="$t('repository.select_file')"
                                            prepend-icon="mdi-paperclip"
                                            v-if="!update_version"
                                            v-model="file"
                                            v-validate="'required'"
                                        ></v-file-input>
                                        <v-menu
                                            v-model="menu"
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    v-model="dateFormatted"
                                                    :label="$t('version.date')"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-on="on"
                                                    @blur="date = parseDate(dateFormatted)"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="date" no-title @input="menu = false"></v-date-picker>
                                        </v-menu>
                                        <v-text-field
                                            data-vv-name="number"
                                            :error-messages="errors.collect('number')"
                                            :label="$t('version.number')"
                                            prepend-icon="mdi-textbox"
                                            v-model="version_to_update.number"
                                            v-validate="{ required: true, regex: /^([0-9\.]+)$/ }"
                                            maxlength="9"
                                            :hint="$t('version.number_hint')"
                                        ></v-text-field>
                                        <v-switch
                                            v-model="version_to_update.is_major"
                                            :label="$t('version.major')"
                                            prepend-icon="mdi-alert-circle"
                                        ></v-switch>
                                        <v-textarea
                                            v-model="version_to_update.comment"
                                            :label="$t('version.comment')"
                                            maxlength="10000"
                                            outlined
                                            prepend-icon="mdi-message"
                                        ></v-textarea>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="close">{{ $t('version.cancel') }}</v-btn>
                                    <v-btn @click="save" :disabled="submit_button_is_disabled">
                                        {{ formTitle }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            :label="$t('version.search')"
                            single-line
                            hide-details
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :no-data-text="$t('global.no_data')"
                        :loading-text="$t('global.loading_text')"
                        :no-results-text="$t('global.no_results_text')"
                        :headers="headers"
                        :items="orderedVersions"
                        :loading="loading"
                        class="elevation-1"
                        :items-per-page="15"
                        :search="search"
                        :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
                    >
                        <template v-slot:item.date="{ item }">
                            {{ item.date | moment('L') }}
                        </template>
                        <template v-slot:item.is_major="{ item }">
                            <span v-if="item.is_major">{{ $t('version.yes') }}</span>
                            <span v-else>{{ $t('version.no') }}</span>
                        </template>
                        <template v-slot:item.comment="{ item }">
                            <p style="white-space: pre" v-html="item.comment"></p>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <a v-if="item.media[0]" :href="`storage/${item.media[0].id}/${item.media[0].file_name}`" download>
                                <v-icon
                                    small
                                >
                                    mdi-eye
                                </v-icon>
                            </a>
                            <v-icon
                                small
                                class="mr-2"
                                :title="$t('version.edit')"
                                @click="edit(item)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                small
                                :title="$t('version.delete')"
                                @click="deleteDialog(item)"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackbar"
            top
            :color="snackbar_type"
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>

        <v-dialog v-model="dialogDelete" max-width="290">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('repository.sure') }}</v-card-title>
                <v-card-text>{{ $t('repository.irremediable') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" text @click="dialogDelete = false">
                        {{ $t('repository.cancel') }}
                    </v-btn>
                    <v-btn color="warning darken-1" text @click="deleteVersion()">
                        {{ $t('repository.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {VERSION, VERSIONS} from "../api";
    import _ from "lodash";

    export default {
        name: "versions",
        data() {
            return {
                versions: [],
                file: {},
                date: new Date().toISOString().substr(0, 10),
                dateFormatted: this.formatDate(new Date().toISOString().substr(0, 10)),
                menu: false,
                version_to_delete: {},
                version_to_update: {
                    id: null,
                    is_major: true,
                    comment: '',
                    number: ''
                },
                defaultVersion: {
                    id: null,
                    is_major: true,
                    comment : '',
                    number: '',
                },
                headers: [
                    {text: this.$t('version.date'), value: 'date', sortable: false},
                    {text: this.$t('version.number'), value: 'number', sortable: false},
                    {text: this.$t('version.comment'), value: 'comment', sortable: false},
                    {text: this.$t('version.major'), value: 'is_major', sortable: false},
                    {text: this.$t('version.actions'), value: 'action', sortable: false},
                ],
                loading: false,
                search: "",
                dialog: false,
                dialogDelete: false,
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                update_version: false,
                submit_button_is_disabled: false,
            }
        },
        mounted() {
            this.getAppVersions();
        },
        watch: {
            date (val) {
                this.dateFormatted = this.formatDate(this.date)
            },
        },
        computed: {
            formTitle() {
                return this.update_version ? this.$t('version.edit') : this.$t('version.new')
            },
            computedDateFormatted () {
                return this.formatDate(this.date)
            },
            orderedVersions() {
                return _.orderBy(this.versions, ['date', 'created_at'], ['desc', 'desc'])
            },
        },
        methods: {
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Get app versions from API
             */
            getAppVersions() {
                this.loading = true;
                this.axios.get(
                    VERSIONS,
                ).then((success) => {
                    this.versions = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                })
            },
            addNewVersion() {
                this.loading = true;
                this.axios.post(
                    VERSIONS,
                    this.version_to_update
                ).then(({data: version}) => {
                    this.versions.push(version);
                    let formData = new FormData();
                    formData.append("file", this.file);
                    this.axios.post(this.replacePathParams(VERSION, {version: version.id}), formData)
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                    this.dialog = false;
                })
            },
            updateVersion() {
                this.axios.patch(this.replacePathParams(VERSION, {version: this.version_to_update.id}),
                    this.version_to_update
                ).then(() => {
                    Object.assign(this.versions.find(x => x.id === this.version_to_update.id), this.version_to_update);
                    this.showSnackbar('success', this.$t('version.updated'));
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.updating = false;
                    this.dialog = false;
                    this.update_version = false;
                })
            },
            deleteVersion() {
                this.axios.delete(
                    this.replacePathParams(VERSION, {version: this.version_to_delete.id})
                ).then(() => {
                    const index = this.versions.indexOf(this.version_to_delete);
                    if (index > -1) {
                        this.versions.splice(index, 1);
                    }
                    this.showSnackbar('success', this.$t('version.deleted'));
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.updating = false;
                    this.dialogDelete = false;
                })
            },
            close() {
                this.version_to_update = this.defaultVersion;
                this.dialog = false;
            },
            formatDate (date) {
                if (!date) return null;

                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`
            },
            parseDate (date) {
                if (!date) return null;

                const [day, month, year] = date.split('/');
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            save() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.version_to_update.date = this.date;
                        if (this.update_version) {
                            this.updateVersion();
                        } else {
                            this.addNewVersion();
                        }
                    }
                });
            },
            edit(version) {
                Object.assign(this.version_to_update, version);
                this.dateFormatted = this.formatDate(version.date);
                this.date = new Date(version.date).toISOString().substr(0, 10);
                this.update_version = true;
                this.dialog = true;
            },
            deleteDialog(version) {
                this.version_to_delete = version;
                this.dialogDelete = true;
            },
            openDialog() {
                this.update_version = false;
                this.file = null;
                Object.assign(this.version_to_update, this.defaultVersion);
                this.dateFormatted = this.formatDate(new Date().toISOString().substr(0, 10));
                this.date = new Date().toISOString().substr(0, 10);
                this.dialog = true;
            }
        },
    }
</script>

<style lang="scss">

.versions-module {
    .v-breadcrumbs li:last-child a {
        font-size: 1.25rem !important;
        color: rgba(0, 0, 0, .6) !important;
        font-weight: 500;
    }

    .v-list--dense .v-list-item {
        .v-list-item__title,
        .v-list-item__subtitle {
            font-size: 0.9rem;
        }
    }
}

</style>
