<template>
    <div class="silos-alerts-module">
        <v-row>
            <v-col class="white">
                <!--
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('alert.reported_hazards') }}
                    </p>
                </v-subheader>
                -->
                <v-breadcrumbs
                    :items="[
                        {
                          text: $t('navigation.alerts'),
                          disabled: true,
                          href: 'breadcrumbs_tours',
                        },
                        {
                          text: $t('alert.reported_hazards'),
                          disabled: true,
                          href: 'breadcrumbs_tours',
                        },
                    ]"
                    class="mt-0 pt-0 pl-4"
                />
            </v-col>
        </v-row>
        <v-card>
            <v-tabs
                grow
                background-color="primary"
                dark
                icons-and-text
            >
                <!--                <v-tab-->
                <!--                    href="#tab-verification-silos"-->
                <!--                >-->
                <!--                    {{ $t('alert.verification_silos') }}-->
                <!--                    <v-icon>mdi-check-all</v-icon>-->
                <!--                </v-tab>-->
                <v-tab
                    href="#tab-security-alert-silos"
                >
                    <v-badge
                        color="secondary"
                        :content="number_of_security_alert_silo_alerts()"
                    >
                        <v-icon>mdi-security</v-icon>
                        {{ $t('alert.security_alert_silos') }}
                    </v-badge>
                </v-tab>

                <!--                <v-tab-item :value="'tab-verification-silos'">-->
                <!--                    <verification-silos></verification-silos>-->
                <!--                </v-tab-item>-->
                <v-tab-item :value="'tab-security-alert-silos'">
                    <v-col cols="12">
                        <security-alert-silos></security-alert-silos>
                    </v-col>
                </v-tab-item>
            </v-tabs>
        </v-card>
    </div>
</template>

<script>
    import VerificationSilos from "../../components/Alerts/VerificationSilos";
    import SecurityAlertSilos from "../../components/Alerts/SecurityAlertSilos";
    import {mapGetters} from "vuex";

    export default {
        name: "Silos",
        components: {VerificationSilos, SecurityAlertSilos},
        methods: {
            ...mapGetters({
                number_of_security_alert_silo_alerts: 'alert/number_of_security_alert_silo_alerts',
            })
        }
    }
</script>

<style lang="scss">

.silos-alerts-module {
    .v-breadcrumbs li:last-child a {
        font-size: 1.25rem !important;
        color: rgba(0, 0, 0, .6) !important;
        font-weight: 500;
    }

    .v-list--dense .v-list-item {
        .v-list-item__title,
        .v-list-item__subtitle {
            font-size: 0.9rem;
        }
    }
}

</style>
