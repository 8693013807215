<template>
    <div>

        <v-data-table
            :no-data-text="$t('global.no_data')"
            :loading-text="$t('global.loading_text')"
            :no-results-text="$t('global.no_results_text')"
            :headers="headers"
            :items="contacts"
            ref="databaseContact"
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
        >
            <template v-slot:item.category="{ item }">
                <span v-if="item.category.media[0]">
                    <img
                        height="32"
                        :src="`storage/${item.category.media[0].id}/${item.category.media[0].name}`"
                        :alt="$t('repository.pictogram')"
                    >
                </span>
                <span v-else>
                    {{item.category.code}}
                </span>
            </template>
            <template v-slot:item.mail="{ item }">
                <span v-if="item.mail">
                    {{item.mail||$t('site.no_data')}}
                    <v-btn icon color="primary" :href="'mailto:'+ item.mail">
                        <v-icon>mdi-email</v-icon>
                    </v-btn>
                </span>
            </template>
            <template v-slot:item.company.code="{ item }">
                <span v-if="item.company">
                    {{item.company.code||$t('site.no_data')}}
                </span>
                <span v-else>
                    {{ $t('site.no_data') }}
                </span>
            </template>
        </v-data-table>

        <v-snackbar
            v-model="snackbar"
            top
            :color="snackbar_type"
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    export default {
        name: "contacts",
        props: {
            contacts: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                headers: [
                    {text: this.$t('site.id'), value: 'id', sortable: false},
                    {text: this.$t('site.name'), value: 'name', sortable: true},
                    {text: this.$t('site.phone'), value: 'phone', sortable: true},
                    {text: this.$t('site.mobile'), value: 'mobile', sortable: true},
                    {text: this.$t('site.mail'), value: 'mail', sortable: true},
                    {text: this.$t('site.category'), value: 'category', sortable: false},
                    {text: this.$t('site.company'), value: 'company.code', sortable: false},
                ],
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
            }
        },
        methods: {}
    }
</script>
