<template>
    <div class="messaging-module">
        <v-row>
            <v-col class="white">
                <!--
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('navigation.messaging') }}
                    </p>
                </v-subheader>
                -->
                <v-breadcrumbs
                    :items="[
                        {
                          text: $t('navigation.messaging'),
                          disabled: true,
                          href: 'chat',
                        },
                    ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card elevation="1">
                    <Split style="height: 500px;">
                        <SplitArea :size="25">
                            <v-list subheader>
                                <v-subheader>{{ $t('messaging.threads') }}</v-subheader>
                                <v-list-item
                                    v-for="thread in threads"
                                    :key="thread.id"
                                    :class="thread.id === threadSelected.id ?'primary':''"
                                    @click="selectThread(thread.id)"
                                >
                                    <v-list-item-content
                                        :class="thread.id === threadSelected.id ?'white--text':''"
                                        three-line
                                    >
                                        <v-list-item-title v-text="thread.subject"></v-list-item-title>
                                          <v-list-item-subtitle
                                        >
                                            <span v-for="(participant, index) in thread.participants"
                                                  :key="participant.id"
                                                  :class="thread.id === threadSelected.id ?'white--text':''">
                                                {{ participant.user.name}}
                                                 <span v-if="index !== thread.participants.length-1">,&nbsp</span>
                                            </span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon
                                        :class="thread.id === threadSelected.id ?'white--text':''">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div v-bind="attrs" v-on="on">
                                                    <v-icon :color="thread.unread > 0?'info' : ''">
                                                        mdi-chat
                                                    </v-icon>
                                                </div>
                                            </template>
                                            <v-list >
                                                <v-list-item
                                                    v-for="(participant, index) in thread.participants"
                                                    :key="participant.id">
                                                    <v-list-item-content>
                                                        {{participant.user.name}}
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-tooltip>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>
                            <v-divider></v-divider>
                        </SplitArea>
                        <SplitArea :size="75">
                            <v-row>
                                <v-col cols="12">
                                    <v-timeline v-if="threadSelected.id" class="pa-4">
                                        <v-timeline-item
                                            v-for="message in threadSelected.messages"
                                            :key="message.id"
                                            largev
                                        >
                                            <template v-slot:icon>
                                                <v-icon class="white--text">
                                                    mdi-account
                                                </v-icon>
                                            </template>
                                            <template v-slot:opposite>
                                                <span>{{ message.created_at | moment('L LTS') }}</span>
                                            </template>
                                            <v-card class="elevation-2">
                                                <v-card-title class="headline">{{ message.user.name }}</v-card-title>
                                                <v-card-text>
                                                    {{ message.body }}
                                                </v-card-text>
                                            </v-card>
                                        </v-timeline-item>
                                    </v-timeline>
                                    <div v-else>
                                        <v-text-field
                                            v-model.sync="topic"
                                            :label="$t('messaging.thread')"
                                            class="px-4"
                                        ></v-text-field>
                                        <v-autocomplete
                                            id="orderedAreas"
                                            v-model="chosen_logistic_areas"
                                            :clearable="true"
                                            :filter="filterLogisticAreas"
                                            :items="orderedAreas"
                                            :label="$t('messaging.logistic_areas')"
                                            :search-input.sync="searchLogisticAreasInput"
                                            chips
                                            class="px-3"
                                            item-text="name"
                                            item-value="id"
                                            multiple
                                            name="orderedAreas"
                                            @change="searchLogisticAreasInput=''"
                                        >
                                        <template slot="item" slot-scope="data">
                                            <span v-html="data.item.code +' - '+ data.item.name"></span>
                                        </template>
                                        </v-autocomplete>
                                        <v-autocomplete
                                            id="chosen_users"
                                            v-model="chosen_users"
                                            :clearable="true"
                                            :filter="filterUsers"
                                            :items="orderedUsers"
                                            :label="$t('messaging.users')"
                                            :search-input.sync="searchUsersInput"
                                            chips
                                            class="px-3"
                                            item-text="name"
                                            item-value="id"
                                            multiple
                                            name="chosen_users"
                                            @change="searchUsersInput=''"
                                        >
                                            <v-list
                                                v-if="isSelectAllUsersAvailable"
                                                slot="prepend-item"
                                                class="pa-0"
                                            >
                                                <v-list-item-group v-model="toggleUsers" class="d-flex align-center pa-0">
                                                    <v-list-item>
                                                        <v-list-item-icon class="mr-2">
                                                            <v-icon :color="chosen_users.length > 0 ? 'primary' : ''">{{ iconUsers }}</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{
                                                                    $t('global.select_all')
                                                                }}
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                            <v-divider
                                                slot="prepend-item"
                                            ></v-divider>
                                            <template slot="item" slot-scope="data">
                                                {{ data.item.name }} ({{ data.item.email }})
                                            </template>
                                        </v-autocomplete>
                                    </div>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        v-model.sync="message"
                                        :label="$t('messaging.message')"
                                        class="px-4"
                                    ></v-textarea>
                                    <v-btn class="primary float-right mx-4" @click="sendMessage">
                                        {{ $t('messaging.send') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </SplitArea>
                    </Split>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type"
            top
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import {LOGISTIC_AREAS_USER, MESSAGES, THREADS, USER_CONTACTS} from "../api";
import _ from "lodash";

export default {
    name: "messaging",
    data() {
        return {
            tab: null,
            toggleUsers: null,
            loading: false,
            threads: [],
            snackbar: false,
            snackbar_text: '',
            snackbar_type: null,
                message: '',
                topic: '',
                user: null,
                users: [],
                chosen_users : [],
                logistic_areas: [],
                chosen_logistic_areas: [],
                threadSelected: {},
                searchUsersInput:'',
                searchLogisticAreasInput:'',
            };
        },
        mounted() {
            this.getThreads();
            this.getUsers(this.$auth.user().id);
            this.getLogisticAreas();
        },
        computed: {
            orderedUsers() {
                return _.orderBy(this.users, 'name')
            },
            orderedAreas() {
                return _.orderBy(this.logistic_areas, 'name')
            },
            allUsers () {
                return this.chosen_users.length === this.orderedUsers.length
            },
            someUsers () {
                return this.chosen_users.length > 0 && !this.chosen_users.length
            },
            isSelectAllUsersAvailable() {
                return this.searchUsersInput === '' || this.searchUsersInput === null;
            },
            iconUsers () {
                if (this.allUsers) return 'mdi-close-box'
                if (this.someUsers) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            },
        },
        methods: {

            /**
             * Get contacts from API
             */
            async getUsers(user) {
                this.loading = true;
                await this.axios.get(
                    this.replacePathParams(USER_CONTACTS, {user})
                    ).then((success) => {
                    this.users = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                })
            },

            /**
             * Get all logistic areas
             */
            getLogisticAreas() {
                this.loading = true;

                this.axios.get(LOGISTIC_AREAS_USER).then((success) => {
                    this.logistic_areas = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                })
            },
            /**
             * Get all thread
             */
            getThreads() {
                this.loading = true;
                this.axios.get(THREADS).then((success) => {
                    this.threads = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                })
            },
            /**
             * Filter users drop-down
             */
            filterUsers(item, queryText) {
                var text = "";
                var searchText = "";
                if (item.name) {
                    text = item.name.toLowerCase();
                    searchText = queryText.toLowerCase();
                }

                return text.indexOf(searchText) > -1;
            },
            /**
             * Filter logistic areas drop-down
             */
            filterLogisticAreas(item, queryText) {
                var text = "";
                var searchText = "";
                if (item.name) {
                    text = item.name.toLowerCase();
                    searchText = queryText.toLowerCase();
                }

                return text.indexOf(searchText) > -1;
            },


            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Select thread
             *
             * @param id
             */
            selectThread(id) {
                if (this.threadSelected.id === id) {
                    this.threadSelected = {};
                } else {
                    this.loading = true;
                    this.axios.get(MESSAGES + '/' + id).then((success) => {
                        this.threadSelected = success.data;
                    }).catch(() => {
                        this.showSnackbar('error', this.$t('global.error'));
                    }).finally(() => {
                        this.loading = false;
                    })
                }
            },
            /**
             * Send message
             */
            sendMessage() {
                this.loading = true;

                if (this.threadSelected.id) {
                    this.axios.put(MESSAGES + '/' + this.threadSelected.id,
                        {message: this.message}
                    ).then((success) => {
                        this.threadSelected = success.data;
                        this.message = '';
                    }).catch(() => {
                        this.showSnackbar('error', this.$t('global.error'));
                    }).finally(() => {
                        this.loading = false;
                    })
                } else {
                    this.axios.post(THREADS,
                        {body: this.message, subject: this.topic, recipients: this.chosen_users, logistic_areas: this.chosen_logistic_areas}
                    ).then((success) => {
                        this.threadSelected = success.data;
                        this.threads.push(success.data);
                        this.message = '';
                        this.topic = '';
                        this.chosen_users = [];
                        this.chosen_logistic_areas = [];
                    }).catch((error) => {
                        if (error.response.status === 422) {
                            this.showSnackbar('warning', this.parseLaravelError(error));
                        } else {
                            this.showSnackbar('error', this.$t('global.error'));
                        }
                    }).finally(() => {
                        this.loading = false;
                        this.getThreads();
                    })
                }
            }
        },
        watch: {
            /**
             * Select all users or not
             */
            toggleUsers () {
                this.$nextTick(() => {
                    if (this.allUsers) {
                        this.chosen_users = []
                    } else {
                        this.orderedUsers.forEach(element => {
                            if (!this.chosen_users.includes(element.id)) {
                                this.chosen_users.push(element.id);
                            }
                        });
                    }
                })
            },
        }
    }
</script>

<style lang="scss">

.messaging-module {
    .v-breadcrumbs li:last-child a {
        font-size: 1.25rem !important;
        color: rgba(0, 0, 0, .6) !important;
        font-weight: 500;
    }

    .v-list--dense .v-list-item {
        .v-list-item__title,
        .v-list-item__subtitle {
            font-size: 0.9rem;
        }
    }
}

</style>
