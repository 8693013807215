var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"items":_vm.filtered_coordinates,"no-data-text":_vm.$t('global.no_data'),"loading-text":_vm.$t('global.loading_text'),"no-results-text":_vm.$t('global.no_results_text'),"headers":_vm.headers,"loading":_vm.loading,"server-items-length":_vm.number_of_alerts,"options":_vm.options,"footer-props":{
                    'items-per-page-options': _vm.rowsPerPageItems,
                    'items-per-page-text': _vm.$t('global.rows_per_page')
                }},on:{"update:options":[_vm.changeOptions,function($event){_vm.options=$event}]},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm._f("moment")(item.created_at,'L LT'))+"\n        ")]}},{key:"item.type",fn:function(ref){
                var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.typeAlert(item.alertable_type))+"\n        ")]}},{key:"item.concerned_element",fn:function(ref){
                var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.alertable.name)+"\n        ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2",attrs:{"loading":_vm.loading,"color":"primary","tile":"","x-small":"","title":_vm.$t('alert.go_to_alert_site')},on:{"click":function($event){return _vm.goTo(item)}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","title":_vm.$t('alert.manage')}},[_vm._v("\n                    mdi-hammer-screwdriver\n                ")]),_vm._v("\n                "+_vm._s(_vm.$t('alert.manage'))+"\n            ")],1),_vm._v(" "),_c('v-icon',{attrs:{"loading":_vm.loading,"title":_vm.$t('alert.delete'),"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v("\n                mdi-delete\n            ")])]}}])}),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('alert.sure')))]),_vm._v(" "),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('alert.irremediable')))]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false}}},[_vm._v("\n                    "+_vm._s(_vm.$t('alert.cancel'))+"\n                ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"warning darken-1","text":""},on:{"click":function($event){return _vm.deleteAlert()}}},[_vm._v("\n                    "+_vm._s(_vm.$t('alert.delete'))+"\n                ")])],1)],1)],1),_vm._v(" "),_c('v-snackbar',{attrs:{"top":"","color":_vm.snackbar_type},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n        "+_vm._s(_vm.snackbar_text)+"\n        "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("\n            Close\n        ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }