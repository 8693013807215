<template>
    <div>
        <v-data-table
            :no-data-text="$t('global.no_data')"
            :loading-text="$t('global.loading_text')"
            :no-results-text="$t('global.no_results_text')"
            :headers="headers"
            :items="risks"
            :loading="loading"
            class="elevation-1"
            :items-per-page="10"
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-dialog v-model="dialog" max-width="500px" @input="$validator.reset()">
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" dark class="mb-2" v-on="on" @click="open">{{ $t('repository.new_item') }}</v-btn>
                        </template>
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-form>
                                    <v-text-field
                                        :label="$t('repository.order')"
                                        name="position"
                                        prepend-icon="mdi-format-list-numbered"
                                        v-validate="'required'"
                                        type="number"
                                        :error-messages="errors.collect('order')"
                                        data-vv-name="order"
                                        v-model="editedItem.position"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('repository.code')"
                                        name="code"
                                        prepend-icon="mdi-numeric"
                                        v-validate="'required|max:255'"
                                        type="text"
                                        :error-messages="errors.collect('code')"
                                        data-vv-name="code"
                                        v-model="editedItem.code"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('repository.name')"
                                        name="name"
                                        prepend-icon="mdi-textbox"
                                        v-validate="'required|max:255'"
                                        type="text"
                                        :error-messages="errors.collect('name')"
                                        data-vv-name="name"
                                        v-model="editedItem.name"
                                    ></v-text-field>
                                    <v-select
                                        :label="$t('repository.security_level')"
                                        name="security_level"
                                        prepend-icon="mdi-sort-numeric"
                                        v-validate="'required'"
                                        :error-messages="errors.collect('security_level')"
                                        data-vv-name="security_level"
                                        :items="availableSecurityLevels"
                                        item-value="id"
                                        item-text="name"
                                        chips
                                        v-model="editedItem.security_level_id"
                                    ></v-select>
                                    <v-select
                                        :label="$t('repository.is_cumulative')"
                                        name="is_cumulative"
                                        prepend-icon="mdi-layers-triple"
                                        v-validate="'required'"
                                        :error-messages="errors.collect('is_cumulative')"
                                        data-vv-name="is_cumulative"
                                        :items="instructionsTypes"
                                        item-value="id"
                                        item-text="name"
                                        chips
                                        v-model="editedItem.is_cumulative"
                                    ></v-select>
                                    <v-select
                                        :label="$t('repository.instructions')"
                                        name="instructions"
                                        prepend-icon="mdi-file-document-box-outline"
                                        v-validate="'required'"
                                        multiple
                                        chips
                                        :error-messages="errors.collect('instructions')"
                                        data-vv-name="instructions"
                                        :items="availableInstructions"
                                        item-value="id"
                                        item-text="name"
                                        v-model="editedItem.instructions"
                                    >
                                        <template slot="selection" slot-scope="data">
                                            <v-chip>
                                                {{ data.item.code }} - {{ data.item.name }}
                                            </v-chip>
                                        </template>
                                        <template slot="item" slot-scope="data">
                                            {{ data.item.code }} - {{ data.item.name }}
                                        </template>
                                    </v-select>
                                    <div>
                                        <span class="v-label mt-4 d-flex" style="justify-content: space-between; align-items: center">
                                            <div>
                                                <v-icon class="icon-image">mdi-image</v-icon>
                                                {{ $t('repository.pictogram')  }}
                                            </div>
                                            <v-btn :disabled="editedItem.media[0].id === undefined" color="red darken-1" text @click="dialogPictogramDelete = true">
                                                {{ $t('repository.delete') }}
                                            </v-btn>
                                        </span>
                                        <div v-if="editedItem.media[0].id !== undefined" class="text-center ma-5">
                                            <img
                                                height="32"
                                                :src="`storage/${editedItem.media[0].id}/${editedItem.media[0].name}`"
                                                :alt="$t('repository.pictogram')"
                                            >
                                        </div>
                                        <vue-base64-file-upload
                                            v-if="dialog"
                                            prepend-icon="mdi-image"
                                            accept="image/*"
                                            :max-size="customImageMaxSize"
                                            :placeholder="$t('repository.select_file')"
                                            @load="onLoad"/>
                                    </div>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    {{ $t('repository.cancel') }}
                                </v-btn>
                                <v-btn @click="save" :disabled="submit_button_is_disabled">
                                    {{ $t('repository.save') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.media="{ item }">
                <template v-if="item.media[0]">
                    <img v-if="item.media[0].id != undefined"
                         height="32"
                         :src="`storage/${item.media[0].id}/${item.media[0].name}`"
                         :alt="$t('repository.pictogram')"
                    >
                </template>
            </template>
            <template v-slot:item.security_level="{ item }">
                <v-chip v-bind:class="getColorToDisplay(item.security_level.color)">
                </v-chip>
            </template>
            <template v-slot:item.instructions="{ item }">
                <v-chip v-for="instruction in item.instructions" :key="instruction.code">
                    {{ instruction.code }}
                </v-chip>
            </template>
            <template v-slot:item.is_cumulative="{ item }">
                <v-chip :color="getRiskTypeColor(item)" class="is_treated" outlined dark>
                    {{ getRiskTypeName(item) }}
                </v-chip>
            </template>
            <template v-slot:item.action="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    :title="$t('repository.update')"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <!-- Retrait à la demande de AVRIL 17/06/2020
               <v-icon
                   small
                   :title="$t('repository.delete')"
                   @click="deleteItem(item)"
               >
                   mdi-delete
               </v-icon>
               -->
           </template>
       </v-data-table>
       <v-dialog v-model="dialogDelete" max-width="290">
           <v-card elevation="0">
               <v-card-title class="headline">{{ $t('repository.sure') }}</v-card-title>
               <v-card-text>{{ $t('repository.irremediable') }}</v-card-text>
               <v-card-actions>
                   <v-spacer></v-spacer>
                   <v-btn color="darken-1" text @click="dialogDelete = false">
                       {{ $t('repository.cancel') }}
                   </v-btn>
                   <v-btn color="warning darken-1" text @click="deleteRisk()">
                       {{ $t('repository.delete') }}
                   </v-btn>
               </v-card-actions>
           </v-card>
       </v-dialog>
       <v-dialog v-model="dialogPictogramDelete" max-width="290">
           <v-card elevation="0">
               <v-card-title class="headline">{{ $t('repository.sure') }}</v-card-title>
               <v-card-text>{{ $t('repository.irremediable') }}</v-card-text>
               <v-card-actions>
                   <v-spacer></v-spacer>
                   <v-btn color="darken-1" text @click="dialogPictogramDelete = false">
                       {{ $t('repository.cancel') }}
                   </v-btn>
                   <v-btn color="warning darken-1" text @click="deletePictogramRisk()">
                       {{ $t('repository.delete') }}
                   </v-btn>
               </v-card-actions>
           </v-card>
       </v-dialog>
   </div>
</template>

<style lang="scss">
   .icon-image {
       position: relative;
       top: -2px;
   }

   .vue-base64-file-upload {
       border: 1px dashed #999;
       border-radius: 2px;

       margin-top: 5px;
       padding-top: 5px;

       text-align: center;

       input {
           padding: 0 15px 5px 15px;
           cursor: pointer;
       }
   }
</style>

<script>
   import {RISKS, RISK, SECURITY_LEVELS, INSTRUCTIONS} from "../../api";
   import VueBase64FileUpload from "vue-base64-file-upload";

   export default {
       name: "risks",
       components: {
           VueBase64FileUpload
       },
       data() {
           return {
               headers: [
                   {text: this.$t('repository.code'), value: 'code', sortable: true},
                   {text: this.$t('repository.label'), value: 'name', sortable: true},
                   {text: this.$t('repository.pictogram'), value: 'media', sortable: true},
                   {text: this.$t('repository.security_level'), value: 'security_level', sortable: true},
                   {text: this.$t('repository.instructions'), value: 'instructions', sortable: true},
                   {text: this.$t('repository.is_cumulative'), value: 'is_cumulative', sortable: true},
                   {text: this.$t('repository.order'), value: 'position', sortable: true},
                   {text: this.$t('repository.actions'), value: 'action', sortable: false},
               ],
               customImageMaxSize: 3,
               loading: false,
               dialog: false,
               risks: [],
               availableSecurityLevels: [],
               availableInstructions: [],
               editedIndex: -1,
               editedItem: {
                   code: '',
                   media: [{}]
               },
               defaultItem: {
                   code: '',
                   is_cumulative: 1,
                   media: [{}]
               },
               submit_button_is_disabled: false,
               itemToDelete: {},
               dialogDelete: false,
               dialogPictogramDelete: false,
               instructionsTypes: [
                   {id: 0, name: "Unique"},
                   {id: 1, name: "Cumulable"},
                   {id: 2, name: "Prioritaire"}
               ],
           }
       },
       mounted() {
           this.getRisks();
           this.getSecurityLevels();
           this.getInstructions();
       },
       computed: {
           formTitle() {
               return this.editedIndex === -1 ? this.$t('repository.new_item') : this.$t('repository.edit_item')
           },
       },
       methods: {
           onLoad(dataUri) {
               this.editedItem.pictogram = dataUri;
           },
           /**
            * Reset snackbar
            */
           resetSnackbar() {
               this.snackbar_type = null;
               this.snackbar_text = null;
               this.snackbar = false;
           },
           /**
            * Show snackbar
            *
            * @param {string} type
            * @param {string} text
            */
           showSnackbar(type, text) {
               this.snackbar_type = type;
               this.snackbar_text = text;
               this.snackbar = true;
           },
           getRiskTypeColor(risk) {
               switch (risk.is_cumulative) {
                   case 0:
                       return 'warning';
                   case 1:
                       return 'success';
                   case 2:
                       return 'error';
                   default:
                       return '';
               }
           },
           getRiskTypeName(risk) {
               switch (risk.is_cumulative) {
                   case 0:
                       return this.$t('repository.no');
                   case 1:
                       return this.$t('repository.yes');
                   case 2:
                       return this.$t('repository.priority');
                   default:
                       return '';
               }
           },
           /**
            * Get instructions
            */
           getInstructions() {
               this.loading = true;

               this.axios.get(INSTRUCTIONS).then(success => {
                   this.availableInstructions = success.data;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               }).finally(() => {
                   this.loading = false;
               })
           },
           /**
            * Get security levels
            */
           getSecurityLevels() {
               this.loading = true;

               this.axios.get(SECURITY_LEVELS).then(success => {
                   this.availableSecurityLevels = success.data.filter(security_level => {
                       return security_level.level !== -1;
                   });
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               }).finally(() => {
                   this.loading = false;
               })
           },
           /**
            * Get risks
            */
           getRisks() {
               this.loading = true;

               this.axios.get(RISKS).then(success => {
                   this.risks = success.data;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               }).finally(() => {
                   this.loading = false;
               })
           },
           /**
            * Get color
            *
            * @param securityLevelColor
            * @returns {string}
            */
           getColorToDisplay(securityLevelColor) {
               switch (securityLevelColor) {
                   case 'Green':
                       return 'green';
                   case 'Orange':
                       return 'orange';
                   case 'Red':
                       return 'red';
                   case 'Black':
                       return 'black';
                   default:
                       return '';
               }
           },
           save() {
               this.submit_button_is_disabled = true;

               this.$validator.validateAll().then((result) => {
                   if (result) {
                       if (this.editedIndex > -1) {
                           this.updateItem(this.editedItem);
                       } else {
                           this.createItem(this.editedItem);
                       }
                   } else {
                       this.submit_button_is_disabled = false;
                   }
               });
           },
           /**
            * Update risk
            *
            * @param {object}  risk
            */
           updateItem(risk) {
               this.axios.patch(
                   this.replacePathParams(RISK, {risk: risk.id}),
                   risk
               ).then((success) => {
                   Object.assign(this.risks[this.editedIndex], success.data);
                   this.showSnackbar('success', this.$t('repository.updated'));
                   this.close()
               }).catch((error) => {
                   if (error.response.status === 422) {
                       this.showSnackbar('warning', this.parseLaravelError(error));
                   } else {
                       this.showSnackbar('error', this.$t('global.error'));
                   }
               }).finally(() => {
                   this.submit_button_is_disabled = false;
               })
           },
           /**
            * Show edit dialog
            *
            * @param {object} risk
            */
           editItem(risk) {
               this.editedIndex = this.risks.indexOf(risk);
               this.editedItem = Object.assign({}, risk);
               this.editedItem.instructions = this.editedItem.instructions.map(({ id }) => id);
               if(this.editedItem.media.length < 1) {
                   this.editedItem.media.push({});
               }
               this.dialog = true;
           },
           /**
            * Add risk
            *
            * @param {object} risk
            */
           createItem(risk) {
               this.axios.post(
                   RISKS,
                   risk
               ).then((success) => {
                   this.risks.push(success.data);
                   this.showSnackbar('success', this.$t('repository.created'));
                   this.close()
               }).catch((error) => {
                   if (error.response.status === 422) {
                       this.showSnackbar('warning', this.parseLaravelError(error));
                   } else {
                       this.showSnackbar('error', this.$t('global.error'));
                   }
               }).finally(() => {
                   this.submit_button_is_disabled = false;
               })
           },
           open() {
               this.editedItem = Object.assign({}, this.defaultItem);
               this.editedIndex = -1
           },
           /**
            * Hide add/edit item modal
            */
           close() {
               this.submit_button_is_disabled = false;
               this.dialog = false;
               setTimeout(() => {
                   this.editedItem = Object.assign({}, this.defaultItem);
                   this.editedIndex = -1
               }, 300)
           },
           /**
            * Show delete modal
            *
            * @param {object} risk
            */
           deleteItem(risk) {
               this.itemToDelete = risk;
               this.dialogDelete = true;
           },
           /**
            * Remove risk from database
            */
           deleteRisk() {
               this.axios.delete(
                   this.replacePathParams(RISK, {risk: this.itemToDelete.id})
               ).then(() => {
                   this.risks.splice(this.risks.indexOf(this.itemToDelete), 1);
                   this.showSnackbar('success', this.$t('repository.deleted'));
                   this.dialogDelete = false;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               });
           },
           /**
            * Remove pictogram risk from database
            */
           deletePictogramRisk() {
               this.axios.delete(
                   this.replacePathParams(RISK, {risk: this.editedItem.id}) + '/pictogram'
               ).then(() => {
                   this.risks[this.editedIndex].media = [{}];
                   this.editedItem.media = [{}];
                   this.showSnackbar('success', this.$t('repository.deleted'));
                   this.dialogPictogramDelete = false;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               });
           }
       }
   };
</script>
