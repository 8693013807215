<template>
    <div>
        <v-data-table
            :items="silos"
            :no-data-text="$t('global.no_data')"
            :loading-text="$t('global.loading_text')"
            :no-results-text="$t('global.no_results_text')"
            :headers="headers"
            :loading="loading"
            :server-items-length="number_of_alerts"
            @update:options="changeOptions"
            :options.sync="options"
            :footer-props="{
                        'items-per-page-options': rowsPerPageItems,
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
        >
            <template v-slot:item.created_at="{ item }">
                {{ item.updated_at | moment('L LT') }}
            </template>
            <template v-slot:item.user="{ item }">
                <span v-if="item.alertable.histories[item.alertable.histories.length - 1].user">
                    {{ item.alertable.histories[item.alertable.histories.length - 1].user.name || $t('alert.none') }}
                </span>
            </template>
            <template v-slot:item.truck="{ item }">
                <span v-if="item.alertable.histories[item.alertable.histories.length - 1].truck">
                    {{ item.alertable.histories[item.alertable.histories.length - 1].truck.code || $t('alert.none') }}
                </span>
            </template>
            <template v-slot:item.name_site="{ item }">
                {{ item.alertable.site.name || $t('alert.none') }}
            </template>
            <template v-slot:item.code_silo="{ item }">
                {{ item.alertable.code || $t('alert.none') }}
            </template>
            <template v-slot:item.security_level="{ item }">
                <img :src="'images/' + getSecurityLevelImage(item.alertable.security_level.color)">
            </template>
            <template v-slot:item.risks="{ item }">
                <span v-for="(risk, index) in item.alertable.risks">
                    <span v-if="index !== Object.keys(item.alertable.risks).length - 1">
                        {{ risk.name }},
                    </span>
                    <span v-else>
                        {{ risk.name }}
                    </span>
                </span>
            </template>
            <template v-slot:item.comment="{ item }">
                {{ item.alertable.histories[item.alertable.histories.length - 1].comment || $t('alert.none') }}
            </template>
            <template v-slot:item.related_media="{ item }">
                <v-chip v-if="item.alertable.media.length > 0" color="success" outlined dark>
                    {{ $t('alert.yes') }}
                </v-chip>
                <v-chip v-else color="warning" outlined dark>
                    {{ $t('alert.no') }}
                </v-chip>
            </template>
            <template v-slot:item.is_treated="{ item }">
                <span v-if="item.is_treated">
                    <v-icon color="success">mdi-check</v-icon>
                </span>
                <span v-else>
                    <v-icon color="warning">mdi-close</v-icon>
                </span>
            </template>
            <template v-slot:item.actions="{ item }">
                <!--<v-icon
                    small
                    :title="$t('alert.transmit_information')"
                    @click="showResolveItem(item)"
                    :disabled="item.is_treated"
                >
                    mdi-email
                </v-icon> Désactivé à la demande d'Avril. @TODO : A réactiver à la demande d'Avril -->
<!--                <v-icon-->
<!--                    small-->
<!--                    :title="$t('alert.delete')"-->
<!--                    @click="deleteItem(item)"-->
<!--                >-->
<!--                    mdi-delete-->
<!--                </v-icon>-->
                <v-icon
                    small
                    class="mr-2"
                    @click="goToAddAction(item)"
                    :title="$t('alert.go_to_add_action')"
                >
                    mdi-file-plus
                </v-icon>
                <v-icon
                    small
                    class="mr-2"
                    @click="goToSilo(item.alertable.id)"
                    :title="$t('alert.go_to_silo')"
                >
                    mdi-arrow-right
                </v-icon>
            </template>
        </v-data-table>

        <v-dialog v-model="dialogDelete" max-width="290">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('alert.sure') }}</v-card-title>
                <v-card-text>{{ $t('alert.irremediable') }}</v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="darken-1" text @click="dialogDelete = false">
                        {{ $t('alert.cancel') }}
                    </v-btn>
                    <v-btn color="warning darken-1" text @click="deleteAlert()">
                        {{ $t('alert.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="resolve_dialog" max-width="290">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('alert.sure') }}</v-card-title>
                <v-card-text>{{ $t('alert.irremediable') }}</v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="darken-1" text @click="resolve_dialog = false">
                        {{ $t('alert.cancel') }}
                    </v-btn>
                    <v-btn color="primary darken-1" text @click="resolveAlert()">
                        {{ $t('alert.treat') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackbar"
            top
            :color="snackbar_type"
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import {ACTIONS, ALERT, ALERTS} from "../../api";
    import {mapActions} from "vuex";

    export default {
        name: 'security-alert-silo-data-table',
        props: {
            alerts: {
                type: Array,
                required: true,
                default: [],
            },
            number_of_alerts: {
                type: Number,
                default: null
            }
        },
        computed: {
            silos() {
                return this.alerts;
            }
        },
        data() {
            return {
                headers: [
                    {text: this.$t('alert.created_at'), value: 'created_at', sortable: false},
                    {text: this.$t('alert.driver'), value: 'user', sortable: false},
                    {text: this.$t('alert.truck'), value: 'truck', sortable: false},
                    {text: this.$t('alert.name_site'), value: 'name_site', sortable: false},
                    {text: this.$t('alert.silo'), value: 'code_silo', sortable: false},
                    {text: this.$t('alert.security'), value: 'security_level', sortable: false},
                    {text: this.$t('alert.risks'), value: 'risks', sortable: false},
                    {text: this.$t('alert.comment'), value: 'comment', sortable: false},
                    {text: this.$t('alert.picture'), value: 'related_media', sortable: false},
                    /*{text: this.$t('alert.is_treated'), value: 'is_treated', sortable: false}, //Désactivé à la demande d'Avril */
                    {text: this.$t('alert.actions'), value: 'actions', sortable: false},
                ],
                loading: true,
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                dialogDelete: false,
                itemToDelete: {},
                options: {page: 1, itemsPerPage: 10},
                rowsPerPageItems: [1, 5, 10, 15],
                resolve_dialog: false,
                itemToResolve: {}
            }
        },
        methods: {
            ...mapActions({count_alerts: 'alert/count'}),
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Show delete modal
             *
             * @param {object} item
             */
            deleteItem(item) {
                this.itemToDelete = item;
                this.dialogDelete = true;
            },
            /**
             * Method used for the suppression of the alert
             */
            deleteAlert() {
                this.axios.delete(ALERTS + '/' + this.itemToDelete.id).then(() => {
                    this.showSnackbar('success', this.$t('alert.deleted'));
                    this.count_alerts();
                    this.$emit('refresh', {level: this.itemToDelete.alertable.security_level.level, options: this.options})
                    this.dialogDelete = false;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Get pictogram for the security level
             *
             * @param securityLevelColor
             * @returns {string}
             */
            getSecurityLevelImage(securityLevelColor) {
                switch (securityLevelColor) {
                    case 'Green':
                        return 'silo_vert.svg';
                    case 'Orange':
                        return 'silo_orange.svg';
                    case 'Red':
                        return 'silo_rouge.svg';
                    case 'Black':
                        return 'silo_noir.svg';
                    default:
                        return 'silo_neutre.svg';
                }
            },
            /**
             * Go to silo specified
             *
             * @param {integer} siloId
             */
            goToSilo(siloId) {
                window.open((this.$router.resolve({name: 'silo', params: {siloId}})).href, '_blank');

            },
            /**
             * Change options
             *
             * @param {object} options
             */
            changeOptions(options) {
                this.options = options

                if(this.silos.length > 0)
                    this.$emit('refresh', {level: this.silos[0].alertable.security_level.level, options})
            },
            showResolveItem(item) {
                this.resolve_dialog = true;
                this.itemToResolve = item;
            },
            async resolveAlert() {
                this.loading = true;

                let action = {
                    site_id: this.itemToResolve.alertable.site_id,
                    type_id: 5,
                    details: this.comment,
                    notification_sent: true
                };
                await this.axios.post(ACTIONS, action);
                this.itemToUpdate = this.itemToResolve;
                await this.updateAlert();
                this.loading = false;
                this.dialogResolve = false;
            },
            /**
             * Method used for the update of the alert
             */
            updateAlert() {
                this.loading = true;
                this.axios.put(
                    this.replacePathParams(ALERT, {alert: this.itemToUpdate.id}),
                    {
                        'is_treated' : 1,
                    }
                ).then(() => {
                    this.$emit('refresh', {level: this.itemToUpdate.alertable.security_level.level, options: this.options})
                    this.count_alerts();
                    this.showSnackbar('success', this.$t('silo.silo_updated'));
                    this.resolve_dialog = false;
                }).catch((error) => {
                    if (error.response?.status === 422) {
                        this.showSnackbar('warning', this.parseLaravelError(error));
                    } else {
                        this.showSnackbar('error', this.$t('global.error'));
                    }
                }).finally(() => {
                    this.loading = false;
                })

            },
            /**
             * Go to add action
             *
             * @param {object} alert
             */
            goToAddAction(alert) {
                window.open((this.$router.resolve({
                    name: 'site',
                    params: {siteId: alert.alertable.site_id},
                    query: {
                        tab: 'tab-actions',
                        showDialog: true,
                        siloId: alert.alertable.id,
                        actionTypeCode: 'transmitted.info',
                        actionNotificationSent: true
                    }
                })).href, '_blank');
            }
        }
    }
</script>
