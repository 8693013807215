<template>
<div>

    <v-data-table
    :no-data-text="$t('global.no_data')"
    :loading-text="$t('global.loading_text')"
    :no-results-text="$t('global.no_results_text')"
    :headers="headers"
    :items="history"
    :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
    >
        <template v-slot:item.date="{ item }">
           <span> {{ getOperationDate(item) | moment('L LT') }}</span>
        </template>

        <template v-slot:item.driver="{ item }">
            <span v-if="item.user"> {{ item.user.name }}</span>
            <span v-else> {{ item.driver }}</span>
        </template>

        <template v-slot:item.vehicle="{ item }">
            <span v-if="item.truck"> {{ item.truck.code }}</span>
        </template>

        <template v-slot:item.product="{ item }">
            <span v-if="item.category.media[0]">
                <img
                    height="32"
                    :src="`storage/${item.category.media[0].id}/${item.category.media[0].name}`"
                    :alt="$t('repository.pictogram')"
                >
            </span>
            <span>{{ getProduct(item)}} </span>
        </template>

        <template v-slot:item.loading_company="{ item }">
            <span>{{ item.loading_company.name}} </span>
        </template>

        <template v-slot:item.delivered_quantity="{ item }">
            <span>{{ getQuantity(item) }} </span>
        </template>

    </v-data-table>


    <v-snackbar v-model="snackbar" top :color="snackbar_type">
        {{ snackbar_text }}
        <v-btn text @click="snackbar = false">
            Close
        </v-btn>
    </v-snackbar>

</div>

</template>

<script>
    import {SILO_ORDERS_HISTORY} from "../../api";

    export default {
        name: "orders_history",

        data() {
            return {
                headers: [
                    {text: this.$t('silo.date'), value: 'date', sortable: false},
                    {text: this.$t('silo.driver'), value: 'driver', sortable: false},
                    {text: this.$t('silo.vehicle'), value: 'vehicle', sortable: false},
                    {text: this.$t('silo.order_id'), value: 'order', sortable: false},
                    {text: this.$t('silo.cr_status'), value: 'status', sortable: false},
                    {text: this.$t('silo.product'), value: 'product', sortable: false},
                    {text: this.$t('silo.delivered_quantity'), value: 'delivered_quantity', sortable: false},
                    {text: this.$t('silo.loading_company'), value: 'loading_company', sortable: false},
                    {text: this.$t('silo.cr_comment'), value: 'comment', sortable: false},
                ],
                history : [],
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,

            }
        },

        props: {
            silo_selected: {
                type: Object,
                required: true
            }
        },

        methods: {

            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },

            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Get Silo's history
             *
             * @param silo
             */
             async getHistory(silo) {
                 await this.axios.get(
                    this.replacePathParams(SILO_ORDERS_HISTORY, {silo})
                ).then((success) => {
                    this.history = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                })
            },

            /**
             * Get the operation date according to the step type
             *
             * @param item
             * @returns {*}
             */
            getOperationDate : function(item) {

                 if(item.delivery_real_start_datetime !== null){
                     return item.delivery_real_start_datetime;
                 }

                 if(item.delivery_driver_start_datetime !== null){
                     return item.delivery_driver_start_datetime
                 }

                if(item.delivery_expected_datetime !== null){
                    return item.delivery_expected_datetime
                }

                return this.$t('silo.none')
        },

            /**
             * Get the product from the step
             *
             * @param item
             * @returns {string}
             */
            getProduct : function(item) {
                return item.product_code + ' - ' + item.product_name;
            },
            /**
             * Get quantity product
             *
             * @param item
             */
            getQuantity : function(item) {

                if(item.product_loaded_quantity !== null) {
                    return item.product_loaded_quantity + 'kg';
                }

                if(item.product_ordered_quantity !== null) {
                    return item.product_ordered_quantity + 'kg';
                }

                return this.$t('silo.none');

            },

            /**
             * Get step's type
             *
             * @param item
             * @returns {string}
             */
            getType : function (item) {
                if(item.type === 'LOADING') {
                    return 'C';
                } else if (item.type === 'DELIVERY') {
                    return 'L';
                }
            }
        },

        computed : {
        },

         mounted() {
             this.getHistory(this.silo_selected.id);
        }

    }
</script>

<style scoped>

</style>
