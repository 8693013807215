<template>
    <div class="mobile-parameters-module">
        <v-row>
            <v-col
                class="white"
            >
                <!--
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('navigation.mobile_parameters') }}
                    </p>
                </v-subheader>
                -->
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('navigation.administration'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                                {
                                  text: $t('navigation.back_office_parameters'),
                                  disabled: true,
                                  href: 'breadcrumbs_bo_parameters',
                                },
                           ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card style="position:relative">
                  <v-btn @click="action(back_office_parameter[0])"
                         color="primary"
                         elevation="0"
                         class="ma-2 white--text"
                         absolute
                         top
                         right
                  >
                    Modifier
                    <v-icon
                        right
                        dark
                    >
                      mdi-pencil
                    </v-icon>

                  </v-btn>
                  <div class="grid mt-4">
                    <template v-for="(item, key) in
                    computed_back_office_parameter[0]">
                      <template>
                        <div class="param-title">{{$t(`bo_parameters.${key}`) }}</div>
                        <div class="param-value">{{item}}</div>
                      </template>
                    </template>
                  </div>

                    <v-dialog v-model="dialog" max-width="600px" @input="$validator.reset()">
                        <v-card>
                            <v-card-title>
                                {{$t('bo_parameters.update_bo_parameters')}}
                            </v-card-title>
                            <v-card-text>
                                <v-form ref="form">
                                    <v-text-field
                                        :label="$t('bo_parameters.step_delay_minute')"
                                        name="database_retention_time"
                                        prepend-icon="mdi-time"
                                        v-validate="'required|numeric'"
                                        type="text"
                                        data-vv-name="database_retention_time"
                                        v-model="editedItem.step_delay_minute"
                                    ></v-text-field>
                                  <v-text-field
                                        :label="$t('bo_parameters.tracking_truck_purge_days')"
                                        name="tracking_truck_purge_days"
                                        prepend-icon="mdi-time"
                                        :rules="[rules.min]"
                                        type="number"
                                        aria-valuemin="1"
                                        data-vv-name="database_retention_time"
                                        v-model="editedItem.tracking_truck_purge_days"
                                    ></v-text-field>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    {{ $t('bo_parameters.cancel') }}
                                </v-btn>
                                <v-btn @click="updateItem()">
                                    {{ $t('bo_parameters.save') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card>
            </v-col>
        </v-row>
        <v-snackbar
            v-model="snackbar"
            top
            :color="snackbar_type"
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import {BACK_OFFICE_PARAMETER, BACK_OFFICE_PARAMETERS} from "../api";

    export default {
        name: "MobileParameters",

        data() {
            return {
                headers: [
                    {text: this.$t('bo_parameters.step_delay_minute'), value: 'step_delay_minute', sortable: false},
                    {text: this.$t('bo_parameters.actions'), value: 'actions', sortable: false},
                ],
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                back_office_parameter : [],
                loading : false,
                dialog : false,
                editedItem : {},
                editedIndex: -1,
              rules:{
                  min: value => value >= 1 || this.$t('bo_parameters.rules.min')
              }
            }
        },
        computed:{
          computed_back_office_parameter(){
            const param = structuredClone(this.back_office_parameter)
            return param.map(item => {
              delete item.created_at
              delete item.updated_at
              delete item.id
              return {...item}
            })
          }
        },
        methods:{
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Get mobile parameters
             */
            getBOParameters() {
                this.loading = true;
                this.axios.get(BACK_OFFICE_PARAMETERS).then(success => {
                    this.back_office_parameter = success.data
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                })
            },
            /**
             * action on click to open the dialog
             */
            action(item){
                this.editedIndex = this.back_office_parameter.indexOf(item);
                this.editedItem = item;
                this.dialog = true;
            },

            /**
             * Hide edit dialog
             */
            close () {
                this.dialog = false;
            },

            /**
             * Update mobile parameter
             *
             */
            updateItem() {
              if (!this.$refs.form.validate()){
                return
              }
                this.axios.patch(
                    this.replacePathParams(BACK_OFFICE_PARAMETER, {back_office_parameter: this.editedItem.id}),
                    this.editedItem
                ).then((success) => {
                    this.editedItem = success.data;
                    this.showSnackbar('success', this.$t('bo_parameters.updated'));
                    this.close()
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.showSnackbar('warning', this.parseLaravelError(error));
                    } else {
                        this.showSnackbar('error', this.$t('global.error'));
                    }
                })
            },
        },
        mounted() {
            this.getBOParameters();
        },
    }
</script>

<style lang="scss">

.mobile-parameters-module {
    .v-breadcrumbs li:last-child a {
        font-size: 1.25rem !important;
        color: rgba(0, 0, 0, .6) !important;
        font-weight: 500;
    }

    .v-list--dense .v-list-item {
        .v-list-item__title,
        .v-list-item__subtitle {
            font-size: 0.9rem;
        }
    }
}

</style>
