var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sites-alerts-module"},[_c('v-row',[_c('v-col',{staticClass:"white"},[_c('v-breadcrumbs',{staticClass:"mt-0 pt-0 pl-4",attrs:{"items":[
                            {
                              text: _vm.$t('navigation.alerts'),
                              disabled: true,
                              href: 'breadcrumbs_dashboard',
                            },
                            {
                              text: _vm.$t('navigation.alert_sites'),
                              disabled: true,
                              href: 'breadcrumbs_dashboard',
                            } ]}})],1)],1),_vm._v(" "),_c('alert-sites-datatable')],1)}
var staticRenderFns = []

export { render, staticRenderFns }