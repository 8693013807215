<template>
    <div class="tours-module">
        <v-row>
            <v-col
                class="white"
            >
                <!--
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('navigation.tours') }}
                    </p>
                </v-subheader>
                -->
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('navigation.tours'),
                                  disabled: true,
                                  href: 'breadcrumbs_tours',
                                },
                              ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="3">
                <v-menu
                    v-model="date_picker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                >
                    <template v-slot:activator="{ on }">
                        <v-text-field
                            v-model="dateRangeText"
                            :label="$t('tour.date_range')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-on="on"
                        ></v-text-field>
                    </template>
                    <v-date-picker :locale="lang"
                                   v-model="dates"
                                   range
                                   :allowed-dates="val => allowedFutureDate(val)"
                    >
                    </v-date-picker>
                </v-menu>
            </v-col>
            <v-col cols="3">
                <v-autocomplete
                    v-show="logistic_areas.length > 1"
                    id="logistic_area"
                    name="logistic_area"
                    v-model="logistic_areas_choices"
                    item-text="code"
                    item-value="id"
                    multiple
                    prepend-icon="mdi-target"
                    class="px-4 mb-3"
                    :clearable="true"
                    :items="orderedAreas"
                    :label="$t('tour.logistic_areas')"
                    :filter="filterAreas"
                    :search-input.sync="searchAreasInput"
                    @blur="getTrucks(logistic_areas_choices)"
                >
                    <v-list
                        v-if="isSelectAllAreasAvailable"
                        slot="prepend-item"
                        class="pa-0"
                    >
                        <v-list-item-group v-model="toggleAreas" class="d-flex align-center pa-0">
                            <v-list-item>
                                <v-list-item-icon class="mr-2">
                                    <v-icon :color="logistic_areas_choices.length > 0 ? 'primary' : ''">
                                        {{ iconAreas }}
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('global.select_all') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                    <v-divider
                        slot="prepend-item"
                    ></v-divider>
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="3">
                <v-autocomplete
                    v-show="trucks.length > 1"
                    id="truck"
                    name="truck"
                    v-model="trucks_choices"
                    item-text="code"
                    item-value="id"
                    multiple
                    prepend-icon="mdi-truck"
                    class="px-4 mb-3"
                    :clearable="true"
                    :items="trucks"
                    :label="$t('tour.trucks')"
                    :filter="filterTrucks"
                    :search-input.sync="searchTrucksInput"
                    @change="searchTrucksInput=''"
                >
                </v-autocomplete>
            </v-col>
            <v-col cols="3">
                <v-btn @click="refresh" color="primary">{{ $t('tour.apply') }}</v-btn>
            </v-col>
            <v-col>
                <v-data-table
                    :no-data-text="$t('global.no_data')"
                    :loading-text="$t('global.loading_text')"
                    :no-results-text="$t('global.no_results_text')"
                    :headers="headers"
                    :items="steps"
                    class="elevation-1"
                    :loading="loading"
                    :page.sync="currentPage"
                    :footer-props="{'items-per-page-options': [5,10,100],
                        'items-per-page-text': $t('global.rows_per_page')}"
                >
                    <template v-slot:item.date="{ item }">
                        {{ item.date | moment('L') }}
                    </template>
                    <template v-slot:item.driver="{ item }">
                        <v-tooltip color="rgba(0,0,0,0.9)" v-if="item.driver" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn text color="secondary" outlined v-on="on">
                                    <v-icon left>mdi-buddhism</v-icon>
                                    {{ item.driver.name }}
                                </v-btn>
                            </template>
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-subtitle class="primary--text">
                                        {{ $t('tour.driver') }}
                                    </v-list-item-subtitle>
                                    <v-list-item-title class="white--text">
                                        {{ item.driver.name }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-subtitle class="primary--text">
                                        {{ $t('tour.code') }}
                                    </v-list-item-subtitle>
                                    <v-list-item-title class="white--text">
                                        {{ item.driver.code }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-tooltip>
                        <div v-else>
                            <v-btn text color="accent" disabled outlined>
                                <v-icon left>mdi-buddhism</v-icon>
                                {{ $t('tour.not_assigned') }}
                            </v-btn>
                        </div>
                    </template>
                    <template v-slot:item.truck="{ item }">
                        <v-tooltip color="rgba(0,0,0,0.9)" v-if="item.truck" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn text color="secondary" outlined v-on="on">
                                    <v-icon left>mdi-truck</v-icon>
                                    {{ item.truck.code||$t('tour.no_code') }}
                                </v-btn>
                            </template>
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-subtitle class="primary--text">
                                        {{ $t('tour.id') }}
                                    </v-list-item-subtitle>
                                    <v-list-item-title class="white--text">
                                        {{ item.truck.id }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item two-line>
                                <v-list-item-content>
                                    <v-list-item-subtitle class="primary--text">
                                        {{ $t('tour.code') }}
                                    </v-list-item-subtitle>
                                    <v-list-item-title class="white--text">
                                        {{ item.truck.code||$t('tour.no_code') }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-tooltip>
                        <div v-else>
                            <v-btn text color="accent" disabled outlined>
                                <v-icon left>mdi-truck</v-icon>
                                {{ $t('tour.not_assigned') }}
                            </v-btn>
                        </div>
                    </template>
                    <template v-slot:item.step_number="{ item }">
                        {{ item.steps.length }}
                    </template>
                    <template v-slot:item.steps="{ item }">
                        <div class="d-inline" v-for="(step, order) in item.steps" :key="step.id">
                            <v-tooltip  color="rgba(0,0,0,0.9)" bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        @mouseenter="getStepDetails(step)"
                                        class="btn--steps"
                                        :color="colorFromStatus(step)"
                                        small v-on="on"
                                        @click="openStepDialog(item.steps, step, item.driver, order+1)"
                                    >
                                        {{ step.type|initial }}
                                    </v-btn>
                                </template>
                                <v-list-item two-line>
                                    <v-list-item-content>
                                        <v-list-item-subtitle class="primary--text">
                                            {{ $t('tour.status') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-title class="white--text">
                                            {{ formatStatus(step.status) }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item three-line>
                                    <v-list-item-content v-if="hoverStepLoading === false && hoverStepDetail !== null">
                                        <v-list-item-subtitle class="primary--text">
                                            {{ $t('tour.site') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-title class="white--text">
                                           {{ hoverStepDetail.name }}
                                        </v-list-item-title>
                                        <v-list-item-title class="white--text">
                                            {{ hoverStepDetail.address_1 }}
                                        </v-list-item-title>
                                        <v-list-item-title class="white--text">
                                           {{ hoverStepDetail.zip }} {{ hoverStepDetail.city }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <span v-else class="white--text">
                                        Chargement de la donnée...
                                    </span>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-subtitle class="primary--text">
                                            {{ $t('tour.real_entrance_date') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-title class="white--text">
                                            {{ real_entrance_date(step) }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                        <v-list-item-subtitle class="primary--text">
                                            {{ $t('tour.real_exit_date') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-title class="white--text">
                                            {{ real_exit_date(step) }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-content>
                                            <v-list-item-subtitle class="primary--text">
                                            {{ $t('tour.sequence') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-title class="white--text">
                                            {{ order+1 }}
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-tooltip>
                        </div>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>
        <v-dialog
            v-model="step_dialog"
            v-if="step_dialog"
        >
            <step-detail
                :step_number="hover_step_number"
                :site_selected="hoverStepDetail"
                :driver_name="driver_selected"
                :step_selected="step_selected"
                :steps_selected="steps_selected"
                :inside_cleanings="inside_cleanings"
                :outside_cleanings="outside_cleanings"
            >
            </step-detail>
        </v-dialog>
    </div>
</template>

<style lang="scss" scoped>
    .btn--steps {
        min-width: 28px !important;
        padding: 0 !important;
        margin: 5px 10px !important;
    }
</style>

<script>
import {
    AREA_TRUCKS,
    STEPS_TOURS,
    USER_LOGISTIC_AREAS,
    SITE,
    BACK_OFFICE_PARAMETERS,
    COMPANY,
    INSIDE_CLEANINGS, OUTSIDE_CLEANINGS
} from "../api";
    import StepDetail from '../components/Step/Detail';
    import _ from "lodash";

    const
        STATUS = {
            0: {color: 'secondary', name: 'not_started'},
            1: {color: 'accent', name: 'started'},
            2: {color: 'success', name: 'terminate'},
            3: {color: 'error', name: 'not_ras'}
        },
        LOADING = 'LOADING',
        DELIVERY = 'DELIVERY'
    ;

    export default {
        name: "tour",
        data() {
            return {
                headers: [
                    {text: this.$t('tour.date'), value: 'date', sortable: false},
                    {text: this.$t('tour.truck'), value: 'truck', sortable: false},
                    {text: this.$t('tour.driver'), value: 'driver', sortable: false},
                    {text: this.$t('tour.step_number'), value: 'step_number', sortable: false},
                    {text: this.$t('tour.steps'), value: 'steps', sortable: true},
                ],
                steps: [],
                loading: false,
                dates: [this.$moment().format('YYYY-MM-DD'), this.$moment().add(1, 'days').format('YYYY-MM-DD')],
                date_picker: false,
                logistic_areas: [],
                logistic_areas_choices: [],
                toggleAreas: null,
                searchAreasInput: '',
                trucks: [],
                trucks_choices: [],
                searchTrucksInput: '',
                lang,
                step_dialog: false,
                step_selected: {site: {company: {contact: {}}}},
                steps_selected: [],
                driver_selected: '',
                hoverStepLoading: false,
                hoverStepDetail:null,
                currentPage:1,
                parameters:{},
                hover_step_number:null,
                outside_cleanings : [],
                inside_cleanings: []
            }
        },
        components: {StepDetail},
        async mounted() {
            await this.getBackOfficeParameters()
            this.getLogisticAreas();
            this.getTrucks();
            this.getSteps();
            this.getInsideCleanings();
            this.getOutsideCleanings();
        },
        watch: {
            /**
             * Select all areas or not
             */
            toggleAreas() {
                this.$nextTick(() => {
                    if (this.allAreas) {
                        this.logistic_areas_choices = []
                    } else {
                        this.logistic_areas.forEach(element => {
                            if (!this.logistic_areas_choices.includes(element.id)) {
                                this.logistic_areas_choices.push(element.id);
                            }
                        });
                    }
                })
            },
        },
        computed: {
            isSelectAllAreasAvailable() {
                return this.searchAreasInput === '' || this.searchAreasInput === null;
            },
            dateRangeText() {
                return [this.$moment(this.dates[0]).format('L'), this.$moment(this.dates[1]).format('L')].join(' ➤ ')
            },
            allAreas() {
                return this.logistic_areas_choices.length === this.logistic_areas.length
            },
            someAreas() {
                return this.logistic_areas_choices.length > 0 && !this.allAreas
            },
            iconAreas() {
                if (this.allAreas) return 'mdi-close-box'
                if (this.someAreas) return 'mdi-minus-box'
                return 'mdi-checkbox-blank-outline'
            },
            orderedAreas() {
                return _.orderBy(this.logistic_areas, 'code')
            },
        },
        methods: {
            getInsideCleanings() {
                this.loading = true;
                this.axios.get(INSIDE_CLEANINGS).then(success => {
                    this.inside_cleanings = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                })
            },
            /**
             * Get vehicle fleets
             */
            getOutsideCleanings() {
                this.loading = true;
                this.axios.get(OUTSIDE_CLEANINGS).then(success => {
                    this.outside_cleanings = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                })
            },


            real_entrance_date(step) {
                if (step.type === 'LOADING') {
                    if (step.loading_driver_start_datetime) {
                        return this.$moment(this.getText(step.loading_driver_start_datetime)).format('L LT');
                    } else if (step.loading_real_start_datetime) {
                        return this.$moment(this.getText(step.loading_real_start_datetime)).format('L LT');
                    }
                }

                if (step.type === 'DELIVERY') {
                    if (step.delivery_driver_start_datetime) {
                        return this.$moment(this.getText(step.delivery_driver_start_datetime)).format('L LT');
                    } else if (step.delivery_real_start_datetime) {
                        return this.$moment(this.getText(step.delivery_real_start_datetime)).format('L LT');
                    }
                }

                return '-';
            },
            real_exit_date(step) {
                if (step.type === 'LOADING') {
                    if (step.loading_driver_end_datetime) {
                        return this.$moment(this.getText(step.loading_driver_end_datetime)).format('L LT');
                    } else if (step.loading_real_end_datetime) {
                        return this.$moment(this.getText(step.loading_real_end_datetime)).format('L LT');
                    }
                }

                if (step.type === 'DELIVERY') {
                    if (step.delivery_driver_end_datetime) {
                        return this.$moment(this.getText(step.delivery_driver_end_datetime)).format('L LT');
                    } else if (step.delivery_real_end_datetime) {
                        return this.$moment(this.getText(step.delivery_real_end_datetime)).format('L LT');
                    }
                }

                return '-';
            },
            /**
             * Filter areas drop-down
             */
            filterAreas(item, queryText) {
                var textOne = "";
                var textTwo = "";
                var searchText = "";
                if (item.code && item.name) {
                    textOne = item.name.toLowerCase();
                    textTwo = item.code.toLowerCase();
                    searchText = queryText.toLowerCase();
                }

                return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
            },
            /**
             * Filter trucks drop-down
             */
            filterTrucks(item, queryText) {
                var text = "";
                var searchText = "";
                if (item.code) {
                    text = item.code.toLowerCase();
                    searchText = queryText.toLowerCase();
                }

                return text.indexOf(searchText) > -1;
            },
            /**
             * Get all the location states
             *
             * @returns {Promise<void>}
             */
            getLogisticAreas() {
                this.axios.get(USER_LOGISTIC_AREAS).then((success) => {
                    this.logistic_areas = _.orderBy(success.data, ['name']);
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Get all the trucks
             *
             * @returns {Promise<void>}
             */
            getTrucks() {
                this.axios.get(
                    AREA_TRUCKS,
                    {
                        params: {
                            logistic_areas: this.logistic_areas_choices
                        },
                    }
                ).then((success) => {
                    this.trucks_choices = [];
                    this.trucks = _.orderBy(success.data, ['code']);
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                })
            },
            /**
             * Get steps
             */
            getSteps() {
                this.loading = true;
                this.axios.get(STEPS_TOURS, {
                    params: {dates: this.dates, logisticAreas: this.logistic_areas_choices, trucks: this.trucks_choices}
                }).then((success) => {
                    this.steps = success.data;
                }).finally(() => {
                    this.loading = false;
                    this.currentPage = 1
                });
            },
            allowedFutureDate(val) {
                return (this.dates[0] && !this.dates[1]) ? this.$moment(this.dates[0]) <= this.$moment(val) : true;
            },
            refresh() {
                this.getSteps();
            },
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Format status
             *
             * @param value
             * @returns {TranslateResult|VueI18n.TranslateResult}
             */
            formatStatus(value) {
                return (value === undefined) ? this.$t('tour.unknown') : this.$t('tour.' + STATUS[value].name);
            },
            /**
             *
             * @returns {*}
             * @param step
             */
            colorFromStatus(step) {
                if(step.status === 2){
                    let expected_date = {};
                    let real_date = {};
                    step.type === 'DELIVERY'? expected_date = new Date(step.delivery_expected_datetime) : expected_date = new Date(step.loading_expected_datetime)

                    if (step.type === 'DELIVERY') {
                        if (step.delivery_real_start_datetime !== null) {
                            real_date = new Date(step.delivery_real_start_datetime);
                        }
                        else {
                            real_date = new Date(step.delivery_driver_start_datetime);
                        }
                    }
                    else {
                        if (step.loading_real_start_datetime !== null) {
                            real_date = new Date(step.loading_real_start_datetime);
                        }
                        else {
                            real_date = new Date(step.loading_driver_start_datetime);
                        }
                    }

                    let minutes = Math.abs(Math.round((real_date.getTime() - expected_date.getTime()) / 60000));

                    let time = expected_date.getMinutes() +  expected_date.getHours();
                    if(minutes > this.parameters.step_delay_minute && time !== 0) return STATUS[3].color;
                }
                return STATUS[step.status].color;
            },
            /**
             *
             * @param steps
             * @param step
             * @param order
             * @param driver
             */
            openStepDialog(steps, step, driver, order) {
                if (this.hoverStepLoading === false){
                    this.driver_selected = step.driver[0].name;
                    this.step_selected = step;
                    this.steps_selected = steps;
                    this.step_dialog = true;
                    this.hover_step_number = order
                }

            },
            getText(texte) {
                if ( texte === null) {
                    return ' - ';
                }

                return texte;
            },
            /**
             * @param step
             */
            async getStepDetails(step){
                this.resetHover()
                this.hoverStepLoading = true
                let company_id = step.type ==="LOADING" ? step.order_lines[0].loading_company_id : step.order_lines[0].delivery_company_id
                try {
                    let result =  await this.axios.get(this.replacePathParams(COMPANY, {company:company_id}), {
                        params: {includes: "company.site"}
                    });
                    this.hoverStepDetail = result.data.data.site
                }catch (e) {

                }
                this.hoverStepLoading = false
            },
            resetHover(){
                this.hoverStepLoading = false
                this.hoverStepDetail = null
            },
            async getBackOfficeParameters(){
                let {data} = await axios.get(BACK_OFFICE_PARAMETERS)
                this.parameters = data[0]
            }
        },
        filters: {
            initial(value) {
                if (value === LOADING) {
                    return 'C'
                } else if (value === DELIVERY) {
                    return 'L';
                }

                return value.charAt(0);
            }
        }
    }
</script>

<style lang="scss">

.tours-module {
    .v-breadcrumbs li:last-child a {
        font-size: 1.25rem !important;
        color: rgba(0, 0, 0, .6) !important;
        font-weight: 500;
    }

    .v-list--dense .v-list-item {
        .v-list-item__title,
        .v-list-item__subtitle {
            font-size: 0.9rem;
        }
    }
}

</style>
