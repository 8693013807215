var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"silos-alerts-module"},[_c('v-row',[_c('v-col',{staticClass:"white"},[_c('v-breadcrumbs',{staticClass:"mt-0 pt-0 pl-4",attrs:{"items":[
                    {
                      text: _vm.$t('navigation.alerts'),
                      disabled: true,
                      href: 'breadcrumbs_tours',
                    },
                    {
                      text: _vm.$t('alert.reported_hazards'),
                      disabled: true,
                      href: 'breadcrumbs_tours',
                    } ]}})],1)],1),_vm._v(" "),_c('v-card',[_c('v-tabs',{attrs:{"grow":"","background-color":"primary","dark":"","icons-and-text":""}},[_c('v-tab',{attrs:{"href":"#tab-security-alert-silos"}},[_c('v-badge',{attrs:{"color":"secondary","content":_vm.number_of_security_alert_silo_alerts()}},[_c('v-icon',[_vm._v("mdi-security")]),_vm._v("\n                    "+_vm._s(_vm.$t('alert.security_alert_silos'))+"\n                ")],1)],1),_vm._v(" "),_c('v-tab-item',{attrs:{"value":'tab-security-alert-silos'}},[_c('v-col',{attrs:{"cols":"12"}},[_c('security-alert-silos')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }