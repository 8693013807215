<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="600px">
            <template v-slot:activator="{ on }">
                <a class="overline grey--text" v-on="on">
                    <v-icon class="pr-2">mdi-key</v-icon>
                    {{ $t('login.forget_password') }}
                </a>
            </template>
            <v-card>
                <v-card-title>
                    <span class="headline">{{ $t('login.forget_password') }}</span>
                </v-card-title>
                <v-card-text>
                    <v-form>
                        <v-text-field
                            :label="$t('login.email')"
                            name="email"
                            prepend-icon="mdi-email"
                            v-validate="'required|email'"
                            type="text"
                            :error-messages="errors.collect('email')"
                            data-vv-name="email"
                            v-model="email"
                        ></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="grey" text @click="dialog = false">{{ $t('login.close') }}</v-btn>
                    <v-btn color="blue darken-1" text @click="validateBeforeSubmit" :disabled="submit_button_is_disabled">
                        {{ $t('login.send_reset_password') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {SEND_PASSWORD_RESET} from "../api";

    export default {
        name: "reset-password",
        props: {
            email_retrieve: {
                type: String,
                required: false,
                default: null
            }
        },
        watch: {
            email_retrieve(new_email) {
                this.email = new_email;
            },
            dialog_retrieve(new_email) {
                this.email = new_email;
            }
        },
        data() {
            return {
                submit_button_is_disabled: false,
                email: null,
                dialog: false
            }
        },
        methods: {
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Validate data before AJAX request
             */
            validateBeforeSubmit() {
                this.resetSnackbar();

                this.submit_button_is_disabled = true;
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.resetPassword({email: this.email});
                    } else {
                        this.submit_button_is_disabled = false;
                    }
                });
            },
            /**
             * Reset password
             *
             * @param {object} credential
             */
            resetPassword(credential) {
                this.axios.post(SEND_PASSWORD_RESET, credential).then(() => {
                    this.showSnackbar('success', this.$t('login.email_sent'));
                    this.dialog = false;
                }).catch((error) => {
                    if (error.response.status === 400) {
                        this.showSnackbar('warning', error.response.data);
                    } else {
                        this.showSnackbar('error', this.$t('global.error'));
                    }
                }).finally(() => {
                    this.submit_button_is_disabled = false;
                })
            },
        }
    }
</script>
