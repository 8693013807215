import Vue from 'vue'
import * as _ from "lodash";

Vue.mixin({
    methods: {
        /**
         * Parse Laravel error into string
         *
         * @param {object} error
         */
        'parseLaravelError' : (error) => {
            let errorsToString = '';

            _.each(error.response.data.errors, (error) => {
                _.each(error, (value) => {
                    errorsToString += value;
                    errorsToString += ' ';
                });
            });

            return errorsToString;
        }
    }
});
