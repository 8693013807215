var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"no-data-text":_vm.$t('global.no_data'),"loading-text":_vm.$t('global.loading_text'),"no-results-text":_vm.$t('global.no_results_text'),"headers":_vm.headers,"items":_vm.sites,"loading":_vm.loading,"server-items-length":_vm.number_of_alerts,"options":_vm.options,"footer-props":{
                    'items-per-page-options': _vm.rowsPerPageItems,
                    'items-per-page-text': _vm.$t('global.rows_per_page')
                }},on:{"update:options":[_vm.changeOptions,function($event){_vm.options=$event}]},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){return _vm.getDuplicateSiteAlert()}}},[_vm._v("\n                    "+_vm._s(_vm.$t('alert.refresh'))+"\n                ")])],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm._f("moment")(item.created_at,'L LT'))+"\n        ")]}},{key:"item.concerned_element",fn:function(ref){
                var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm.formatType(item))+"\n        ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","title":_vm.$t('alert.go_to_alert_site')},on:{"click":function($event){return _vm.goTo(item.id)}}},[_vm._v("\n                mdi-arrow-right\n            ")])]}}])}),_vm._v(" "),_c('v-snackbar',{attrs:{"top":"","color":_vm.snackbar_type},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n        "+_vm._s(_vm.snackbar_text)+"\n        "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("\n            Close\n        ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }