<template>
    <div>
        <v-data-table
            :footer-props="{
                        'items-per-page-options': rowsPerPageItems,
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
            :headers="headers"
            :items="reports"
            :loading="loading"
            :loading-text="$t('global.loading_text')"
            :no-data-text="$t('global.no_data')"
            :no-results-text="$t('global.no_results_text')"
            :options.sync="options"
            :server-items-length="number_of_alerts"
            @update:options="changeOptions"
        >
            <template v-slot:item.user="{ item }">
                {{ item.alertable.user.name }}
            </template>
            <template v-slot:item.truck="{ item }">
                {{ item.alertable.used_truck.code }}
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | moment('L LT') }}
            </template>
            <!--
            <template v-slot:item.is_treated="{ item }">
                <span class="font-weight-medium" v-if="item.is_treated" >{{$t('alert.yes')}}</span>
                <span class="font-weight-medium" v-else>{{$t('alert.no')}}</span>
            </template>
            -->
            <template v-slot:item.silo="{ item }">
                <span v-if="item.alertable.delivery_report_state !== null">
                    <span v-if="item.alertable.type === 'LOADING' && item.alertable.loading_site.silos.length > 0">
                        {{ item.alertable.loading_site.silos[0].code }}
                    </span>
                    <span v-if="item.alertable.type === 'DELIVERY' && item.alertable.delivery_site.silos.length > 0">
                        {{ item.alertable.delivery_site.silos[0].code }}
                    </span>
                </span>
            </template>
            <template v-slot:item.site="{ item }">
                <span v-if="item.alertable.type === 'LOADING'">{{ item.alertable.loading_site.name }}</span>
                <span v-else> {{ item.alertable.delivery_site.name }} </span>
            </template>
            <template v-slot:item.status="{ item }">
                <span v-if="item.alertable.delivery_report_state !== null">
                    {{ item.alertable.delivery_report_state.name }}
                </span>
                <span v-else>
                    {{ item.alertable.loading_report_state.name }}
                </span>
            </template>
            <template v-slot:item.comment="{ item }">
                <span v-if="item.alertable.delivery_report_state !== null">
                    {{ item.alertable.delivery_comments||$t('alert.none')  }}
                </span>
                <span v-else>
                    {{ item.alertable.loading_comments||$t('alert.none') }}
                </span>
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    :title="$t('site.see_step_detail')"
                    class="mr-2"
                    small
                    @click="goToSite(item)"
                >
                    mdi-eye
                </v-icon>
                <v-btn
                    :disabled="item.is_treated == 1"
                    :loading="loading"
                    :title="$t('alert.manage')"
                    class="mr-2"
                    color="primary"
                    tile
                    x-small
                    @click="resolve(item)"
                >
                    <v-icon
                        :title="$t('alert.manage')"
                        class="mr-1"
                        small
                    >
                        mdi-hammer-screwdriver
                    </v-icon>
                    {{ $t('alert.manage') }}
                </v-btn>
            </template>
        </v-data-table>
        <v-dialog v-model="dialogDelete" :disabled="loading" max-width="290">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('alert.sure') }}</v-card-title>
                <v-card-text>{{ $t('alert.irremediable') }}</v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn
                        :disabled="loading"
                        color="darken-1"
                        text
                        @click="dialogDelete = false"
                    >
                        {{ $t('alert.cancel') }}
                    </v-btn>
                    <v-btn :disabled="loading"
                           color="warning darken-1"
                           text
                           @click="deleteAlert()"
                    >
                        {{ $t('alert.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogResolve" :disabled="loading" max-width="290">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('alert.resolve_alert') }}</v-card-title>
                <v-card-text>
                    <v-col>
                        <v-textarea
                            v-model="action_comment"
                            :disabled="loading"
                            :label="$t('alert.comment')"
                            name="input-7-4"
                            solo
                        ></v-textarea>
                    </v-col>
                </v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn :disabled="loading" color="darken-1" text @click="dialogResolve = false">
                        {{ $t('alert.cancel') }}
                    </v-btn>
                    <v-btn :disabled="loading" color="success darken-1" text @click="createAction()">
                        {{ $t('alert.resolve_alert') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type"
            top
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {ACTIONS, ALERT, ALERTS, REPORT} from "../../api";
import {bus} from "../../app";

export default {
    name: 'report-alert-datatable',
    data() {
        return {
            loading: false,
            headers: [
                {text: this.$t('alert.created_at'), value: 'created_at', sortable: false},
                {text: this.$t('alert.driver'), value: 'user', sortable: false},
                {text: this.$t('alert.truck'), value: 'truck', sortable: false},
                {text: this.$t('alert.site'), value: 'site', sortable: false},
                {text: this.$t('alert.status'), value: 'status', sortable: false},
                    {text: this.$t('alert.comment'), value: 'comment', sortable: false, width: 300},
                    //{text: this.$t('alert.is_treated'), value: 'is_treated', sortable: true},
                    {text: this.$t('alert.actions'), value: 'actions', sortable: false},
                ],
                dialogDelete: false,
                dialogResolve: false,
                itemToDelete: {},
                itemToUpdate: {},
                itemToResolve: {},
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                action_comment: null,
                alerts: [],
                number_of_alerts: 0,
                options: {page: 1, itemsPerPage: 10},
                rowsPerPageItems: [5, 10, 100],
            }
        },
        mounted() {
            this.getReportAlerts();
        },
        created() {
            bus.$on("applyFilter", () => {
                this.getReportAlerts()
            })
        },
        computed: {
            reports() {
                return this.alerts;
            },

        },
        methods: {
            ...mapActions({count_alerts: 'alert/count'}),
            ...mapActions({unread_messages_count: 'alert/count'}),
            ...mapGetters({get_filters: 'alert/get_filters'}),
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Show delete modal
             *
             * @param {object} item
             */
            deleteItem(item) {
                this.itemToDelete = item;
                this.dialogDelete = true;
            },
            /**
             * Method used for the suppression of the alert
             */
            deleteAlert() {
                this.loading = true;
                this.axios.delete(ALERTS + '/' + this.itemToDelete.id).then(() => {
                    this.getReportAlerts();
                    this.count_alerts();
                    this.showSnackbar('success', this.$t('alert.deleted'));
                    this.dialogDelete = false;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                });
            },
            /**
             * Method used for the update of the alert
             */
            updateAlert() {
                this.loading = true;
                this.axios.put(
                    this.replacePathParams(ALERT, {alert: this.itemToUpdate.id}),
                    {
                        'is_treated' : 1,
                    }
                ).then(() => {
                    this.getReportAlerts();
                    this.count_alerts();
                    this.showSnackbar('success', this.$t('site.site_updated'));
                    this.dialogDelete = false;
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.showSnackbar('warning', this.parseLaravelError(error));
                    } else {
                        this.showSnackbar('error', this.$t('global.error'));
                    }
                }).finally(() => {
                    this.loading = false;
                })

            },
            /**
             * Updating the datable after alert processing
             */
            getReportAlerts() {
                this.loading = true;
                this.axios.get(REPORT,
                    {params: {
                        page: this.options.page, per_page: this.options.itemsPerPage,
                        logistic_areas: this.get_filters().logistic_areas_choices
                    }}
                ).then((success) => {
                    this.alerts = success.data.data;
                    this.number_of_alerts = success.data.total;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                });
            },
            /**
             * Show popup to resolve
             *
             * @param {Object} itemToResolve
             */
            resolve(itemToResolve) {
                this.dialogResolve = true;
                this.action_comment = null;
                this.itemToResolve = itemToResolve;
            },
            /**
             * Go to site specified
             *
             * @param item
             */
            goToSite(item) {
                let siteId = 0;
                if(item.alertable.type === 'LOADING') {
                    siteId = item.alertable.loading_site.id;
                } else {
                    siteId = item.alertable.delivery_site.id;
                }
                let routeData = this.$router.resolve({name: 'site', params: {siteId}});
                window.open(routeData.href, '_blank');
            },

            async createAction() {
                this.loading = true;

                let siteId = 0;
                if(this.itemToResolve.alertable.type === 'LOADING') {
                    siteId = this.itemToResolve.alertable.loading_site.id;
                } else {
                    siteId = this.itemToResolve.alertable.delivery_site.id;
                }

                let action = {
                    site_id: siteId,
                    type_id: 15, // @todo A change with avril_id
                    details: this.action_comment,
                };
                /*
                                if (this.itemToResolve.alertable.delivery_report_state !== null && this.itemToResolve.alertable.site.silos[0]) {
                                    action.silo_id = this.itemToResolve.alertable.site.silos[0].id;
                                }
                */
                await this.axios.post(ACTIONS, action);
                this.itemToDelete = this.itemToResolve;
                this.itemToUpdate = this.itemToResolve;
                this.updateAlert();
                //this.deleteAlert(); Modifié le 17/07 pour pouvoir accéder aux alertes déjà traité
                this.loading = false;
                this.dialogResolve = false;
            },
            /**
             * Change options
             *
             * @param {object} options
             */
            changeOptions(options) {
                this.options.itemsPerPage = options.itemsPerPage;
                this.options.page = options.page;
                this.getReportAlerts();
            },
        },
    }
</script>
