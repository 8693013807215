<template>
    <div>
        <v-card-title class="d-flex align-start">
            <v-row>
                <v-spacer></v-spacer>
                <v-col cols="4">
                    <v-select
                        v-model="selectedType"
                        :items="['Sécurité', 'Admin', 'Exécution']"
                        :label="$t('action.type')"
                        clearable
                        dense
                        outlined
                    ></v-select>
                </v-col>
                <v-col cols="1">
                    <v-btn
                        class="ml-4"
                        color="primary"
                        @click="openDialog"
                    >
                        {{ $t('site.new') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-title>

        <v-data-table
            ref="databaseAction"
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
            :headers="headers"
            :items="filteredActions"
            :loading-text="$t('global.loading_text')"
            :no-data-text="$t('global.no_data')"
            :no-results-text="$t('global.no_results_text')"
            sort-by="created_at"
            sort-desc
        >
            <template v-slot:item.created_at="{ item }">
                <span v-if="item.created_at">
                    {{ item.created_at | moment('L LT') }}
                </span>
                <span v-else>
                    {{ $t('site.no_data') }}
                </span>
            </template>
            <template v-slot:item.user="{ item }">
                <span v-if="item.user">
                    {{ item.user.name || $t('site.no_data') }}
                </span>
                <span v-else>
                    {{ item.user_avril_id || $t('site.no_data') }}
                </span>
            </template>
            <template v-slot:item.type.description="{ item }">
                <span v-if="item.type">
                    <v-chip
                        :color="getTypeColor(item.type.name)"
                        class="ma-2"
                        label
                        text-color="white"
                    >
                         {{ item.type.description || $t('site.no_data') }}
                    </v-chip>
                </span>
                <span v-else>
                    {{ $t('site.no_data') }}
                </span>
            </template>
            <template v-slot:item.security_level.color="{ item }">
                <img v-if="item.history && item.history.security_level" :src="'images/' + getSecurityLevelImage(item.history.security_level.color)">
            </template>
            <template v-slot:item.history_silos_media="{ item }">
                <span v-if="item.history">
                    <span v-for="history in item.history.history_silos">
                        <div v-if="history.media.length > 0">
                            <img
                                :alt="$t('repository.pictogram')"
                                :src="`storage/${history.media[0].id}/${history.media[0].name}`"
                                height="32"
                            >
                        </div>
                    </span>
                </span>
            </template>
            <template v-slot:item.details="{ item }">
                <template v-if="item.is_manual == 1 || !!item.details">
                    <span v-for="line in replaceBrTags(item.details).split('\n')" v-if="item.details">
                        {{ line }} <br/>
                    </span>
                </template>
                <template v-else>
                    <span v-if="item.silo_comment_at_action_creation">
                        {{ item.silo_comment_at_action_creation }}
                    </span>
                </template>
            </template>
            <template v-slot:item.silo="{ item }">
                <span v-if="item.silo">
                    {{ item.silo.code || $t('site.no_data') }}
                </span>
                <span v-else>
                    {{ $t('site.no_data') }}
                </span>
            </template>
            <template v-slot:item.email_send="{ item }">
                <span v-if="item.email_send">
                    {{ format_mail(item.email_send) }}
                </span>
            </template>
            <template v-slot:item.media="{ item }">
                <span v-if="item.media.length > 0">
                    <v-icon color="grey"
                            class="cursor-pointer"
                            :disabled="updating"
                            @click="downloadZip(item)">
                        mdi-file-download
                    </v-icon>
                </span>
            </template>
        </v-data-table>

        <v-dialog v-model="dialog" max-width="1300px" @close="reset_action" persistent>
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('action.new') }}</v-card-title>
                <v-card-text>
                    <v-form name="sites">
                        <v-row>
                            <v-col cols="7">
                                <!-- Type d'action -->
                                <v-select
                                    id="action.type"
                                    v-model="action.type_id"
                                    v-validate="'required'"
                                    :items="this.types"
                                    :label="$t('action.type')"
                                    data-vv-name="states"
                                    item-text="name"
                                    item-value="id"
                                    name="action.type"
                                    prepend-icon="mdi-tag-text"
                                    required
                                    :disabled="updating"
                                >
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.name }} : {{ data.item.description }}
                                    </template>

                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.name }} : {{ data.item.description }}
                                    </template>
                                </v-select>

                                <!-- SILOS -->
                                <v-select
                                    id="action.silo"
                                    v-model="action.silo_id"
                                    v-validate="'required'"
                                    :items="site.silos"
                                    :label="$t('action.silo')"
                                    chips
                                    clearable
                                    data-vv-name="states"
                                    item-text="code"
                                    item-value="id"
                                    name="action.silo"
                                    prepend-icon="mdi-silo"
                                    :disabled="updating"
                                >
                                </v-select>

                                <!-- Détail-->
                                <v-textarea
                                    v-model="action.details"
                                    :label="$t('action.details')"
                                    name="input-7-4"
                                    outlined
                                    prepend-icon="mdi-comment-text-multiple-outline"
                                    rows="5"
                                    :disabled="updating"
                                ></v-textarea>

                                <div class="font-weight-bold text-uppercase ">{{ $t('action.add_photos') }}</div>

                                <!-- Message warning sur la taille des photos -->
                                <div class="mt-5 text-red">
                                    {{ $t('action.photo_limitation_rules') }}
                                </div>

                                <v-row>
                                    <v-col cols="12">
                                        <!-- Ajout depuis le PC -->
                                        <v-btn color="primary" @click="openPcPhotoPicker" :disabled="updating">
                                            {{ $t('action.add_from_computer') }}
                                        </v-btn>
                                        <!-- Ajout depuis sandra -->
                                        <v-btn color="primary ml-4" @click="openSandraPhotoPicker" :disabled="updating">
                                            {{ $t('action.add_from_sandra') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>

                                <!-- Photos importées par le PC -->
                                <div class="font-weight-bold text-uppercase mt-5">
                                    {{ $t('action.photo_imported_from_computer') }}
                                </div>

                                <!-- Sélecteur de fichier caché -->
                                <v-file-input
                                    v-model="pc_photos"
                                    ref="fileInput"
                                    style="display: none"
                                    accept="image/*"
                                    multiple
                                    @change="handleFileSelect"
                                />

                                <v-row v-if="formatted_pc_photos.length > 0"  >
                                    <v-col cols="4" v-for="(photo, index) in formatted_pc_photos" :key="index">
                                        <v-img :src="photo.url" height="150px" width="400px"/>
                                        <div>{{ photo.name }}</div>
                                        <div>
                                            <span>({{ photo.size }} ko)</span>
                                            <v-icon color="primary" class="cursor-pointer" @click="removeFileFromPc(index)">
                                                mdi-trash-can
                                            </v-icon>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row v-else>
                                    <v-col class="text-caption">
                                        {{ $t('action.empty') }}
                                    </v-col>
                                 </v-row>

                                <!-- Photos importées par Sandra -->
                                <div class="font-weight-bold text-uppercase mt-5">
                                    {{ $t('action.photo_retrieved_from_sandra') }}
                                </div>

                                <v-row v-if="selected_sandra_photos.length > 0"  >
                                    <v-col cols="4" v-for="(photo, index) in selected_sandra_photos" :key="index">
                                        <v-img :src="photo.url" height="150px" width="400px"/>
                                        <div>{{ photo.file_name }}</div>
                                        <div>
                                            <span>({{ photo.size }} ko)</span>
                                            <v-icon color="primary" class="cursor-pointer" @click="removeFileFromSandra(index)">
                                                mdi-trash-can
                                            </v-icon>
                                        </div>
                                    </v-col>
                                </v-row>

                                <v-row v-else>
                                    <v-col class="text-caption">
                                        {{ $t('action.empty') }}
                                    </v-col>
                                </v-row>

                            </v-col>

                            <v-col cols="5">
                                <v-switch
                                    v-model="action.notification_sent"
                                    :label="$t('action.notify')"
                                    class="ma-2"
                                    @change="displayEmails"
                                    :disabled="updating"
                                />

                                <div v-if="action.notification_sent">
                                    <v-checkbox
                                        v-if="sales_representative_email"
                                        v-model="emailsToNotify"
                                        :value="sales_representative_email"
                                        :disabled="updating"
                                    >
                                        <template slot="label">
                                            <span class="font-weight-bold role">Commercial :&nbsp;</span>
                                            <span class="role">{{ sales_representative_email }} </span>
                                        </template>
                                    </v-checkbox>
                                    <span v-for="email in emails">
                                <span class="font-weight-bold role">{{ email.name }}</span>

                                <v-checkbox
                                    v-if="email.logistic_manager_email"
                                    v-model="emailsToNotify"
                                    :value="email.logistic_manager_email"
                                    :disabled="updating"
                                    class="mt-0"
                                >
                                    <template slot="label">
                                        <span class="font-weight-bold role">Responsable logistique :&nbsp;</span>
                                        <span class="role">{{ email.logistic_manager_email }}</span>
                                    </template>
                                </v-checkbox>
                                <v-checkbox
                                    v-if="email.quality_safety_manager_email"
                                    v-model="emailsToNotify"
                                    :value="email.quality_safety_manager_email"
                                    :disabled="updating"
                                    class="mt-0"
                                >
                                    <template slot="label">
                                        <span class="font-weight-bold role">Responsable qualité/sécurité :&nbsp;</span>
                                        <span class="role">{{ email.quality_safety_manager_email }}</span>
                                    </template>
                                </v-checkbox>
                                <v-checkbox
                                    v-if="email.sales_manager_email"
                                    v-model="emailsToNotify"
                                    :value="email.sales_manager_email"
                                    :disabled="updating"
                                    class="mt-0"
                                >
                                    <template slot="label">
                                        <span class="font-weight-bold role">{{ $t('repository.sales_manager') }} : </span> &nbsp;
                                        <span class="role"> {{ email.sales_manager_email }}</span>
                                    </template>
                                </v-checkbox>
                                <v-checkbox
                                    v-if="email.sandra_manager_email"
                                    v-model="emailsToNotify"
                                    :value="email.sandra_manager_email"
                                    :disabled="updating"
                                    class="mt-0"
                                >
                                    <template slot="label">
                                        <span class="font-weight-bold role">Référent ZL :&nbsp;</span>
                                        <span class="role">{{ email.sandra_manager_email }}</span>
                                    </template>
                                </v-checkbox>

                            </span>

                                    <v-textarea
                                        v-model="additionalMails"
                                        hint="Séparer les mails par un point virgule ( ; )"
                                        label='Adresses mails supplémentaires séparés par des ";"'
                                        outlined
                                        rows="2"
                                    >
                                    </v-textarea>

                                </div>
                            </v-col>
                        </v-row>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="darken-1" :disabled="updating" text @click="cancelDialog">
                                {{ $t('site.cancel') }}
                            </v-btn>
                            <v-btn :disabled="updating" color="warning darken-1"
                                   text @click="addAction()">
                                {{ $t('action.add') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>


        <v-dialog v-model="sandra_dialog" max-width="1200px" @close="reset_action" persistent>
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('action.pick_photos_from_sandra') }}</v-card-title>
                <v-card-text>

                    <v-data-table
                        :no-data-text="$t('global.no_data')"
                        :loading-text="$t('global.loading_text')"
                        :no-results-text="$t('global.no_results_text')"
                        :headers="sandra_photos_headers"
                        :items="sandra_server_photos"
                        class="elevation-1"
                        :items-per-page="-1"
                        v-model="dialog_selected_sandra_photos"
                        item-key="id"
                        show-select                    >
                        <template v-slot:item.url="{ item }">
                            <v-img :src="item.url" class="ma-2" height="120px" width="200px"/>
                        </template>

                        <template v-slot:item.name="{ item }">
                            {{ nullable(item.file_name)}} ({{ item.size}} ko)
                        </template>

                        <template v-slot:item.silo="{ item }">
                            {{ nullable(item.silo)}}
                        </template>

                        <template v-slot:item.created_at="{ item }">
                            {{ item.created_at | moment('L LT') }}
                        </template>

                        <template v-slot:item.comment="{ item }">
                            {{ nullable(item.comment)}}
                        </template>
                    </v-data-table>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" text @click="cancelSandraDialog">
                        {{ $t('site.cancel') }}
                    </v-btn>
                    <v-btn color="primary"
                           text @click="addSandraImages()">
                        {{ $t('action.add') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type"
            top
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import {
    ACTIONS_FORM,
    ACTIONS_PHOTOS,
    ALERT,
    MANUAL_ACTION_TYPES,
    SILO,
    SILOS_IMAGES,
    SITE_UPDATE_REQUESTS
} from "../../api";

export default {
        name: "actions",
        props: {
            siteProp: {
                type: Object,
                required: true
            },
            showDialog: {
                type: Boolean,
                default: false
            },
            siloId: {
                type: Number,
                default: null
            },
            actionTypeCode: {
                type: String,
                default: ''
            },
            notificationSent: {
                type: Boolean,
                default: null
            },
            alertId: {
                type: Number,
                default: null
            }
        },
        data() {
            return {
                headers: [
                    {text: this.$t('action.created_at'), value: 'created_at', sortable: false, align: 'start'},
                    {text: this.$t('action.type_description'), value: 'type.description', sortable: true},
                    {
                        text: this.$t('action.silo'), value: 'silo', sortable: true, sort: (a, b) => {
                            return (a.code > b.code) ? 1 : -1;
                        }
                    },
                    {text: this.$t('action.user'), value: 'user', sortable: true, width: '100px'},
                    {text: this.$t('action.security_level'), value: 'security_level.color', sortable: false},
                    {text: this.$t('action.history_risks'), value: 'history_silos_media', sortable: false},
                    {text: this.$t('action.details'), value: 'details', sortable: true},
                    {text: this.$t('action.photos'), value: 'media', sortable: false},
                    {text: this.$t('action.send_to'), value: 'email_send', sortable: true, width: '100px'},
                ],
                site: {},
                dialog: false,
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                types: [],
                updating: false,
                additionalMails: null,
                selectedType: null,
                emailsToNotify: [],
                action: {
                    site_id: null,
                    type_id: null,
                    details: null,
                    silo_id: null,
                    notification_sent: false,
                    is_manual: true,
                },
                existing_silo: {},
                update_request: {},
                pc_photos: [],
                formatted_pc_photos: [],
                sandra_dialog: false,
                sandra_photos: [],
                sandra_server_photos: [],
                sandra_photos_headers: [
                    {
                        text: this.$t('action.photo'),
                        value: 'url',
                        sortable: false
                    },
                    {
                        text: this.$t('action.name'),
                        value: 'name',
                        sortable: false
                    },
                    {
                        text: this.$t('action.silo'),
                        value: 'silo',
                        sortable: false
                    },
                    {
                        text: this.$t('action.date'),
                        value: 'created_at',
                        sortable: false
                    },
                    {
                        text: this.$t('action.comment'),
                        value: 'comment',
                        sortable: false
                    },
                ],
                selected_sandra_photos: [],
                dialog_selected_sandra_photos: []
            }
        },
        async mounted() {
            this.site = Object.assign({}, this.siteProp);

            this.action.site_id = this.$route.params.siteId;

            await this.getManualActionTypes();
            if (this.actionTypeCode !== '') {
                const actionType = this.types.find(actionType => actionType.code === this.actionTypeCode);
                this.action.type_id = actionType.id;
            }
            this.action.silo_id = this.siloId;
            if (this.action.silo_id !== null) {
                await this.getSilo(this.action.silo_id);
                let risks = '';
                this.existing_silo.risks.forEach(function(risk) {
                    risks += risk.name + ', ';
                });
                risks = risks.slice(0,-2);
                const comment = this.existing_silo.histories[this.existing_silo.histories.length - 1].comment || this.$t('alert.none');
                let logisticManagerMail = '';
                this.existing_silo.site.logistic_areas.every(function(logisticArea) {
                    if (logisticArea.logistic_manager_email != null) {
                        logisticManagerMail = logisticArea.logistic_manager_email;
                        return false;
                    }
                    return true;
                });
                this.action.details =
                    'Niveau de sécurité : ' + this.existing_silo.security_level.name + '\n' +
                    'Risque : ' + risks + '\n' +
                    'Commentaire : ' + comment;

                this.action.details = this.action.details + '\n\nExpéditeur : ' + this.getAuthenticatedUserMail();
            }
            /**
             * Modification du détails si il s'agit d'une demande de mise à jour de site
             */
            await this.setUpdateRequestDetails();

            if (this.notificationSent !== null) {
                this.action.notification_sent = this.notificationSent;
            }
            this.dialog = this.showDialog;
            if (this.showDialog && !!this.site.logistic_areas && this.site.logistic_areas.filter(zl => zl.sales_manager_email !== null)) {
                this.site.logistic_areas.filter(zl => zl.sales_manager_email !== null)
                    .forEach(zl => this.emailsToNotify.push(zl.sales_manager_email))
            }
        },
        methods: {
            /**
             * Permet d'ouvrir la dialog de sélection des photos sandra
             */
            openSandraDialog(){
                this.sandra_dialog = true
            },
            /**
             * Récupère les photos des silos de sandra
             * @returns {Promise<void>}
             */
            async getSandraSilosImages(){
                this.loading = true;
                let response = await this.axios.get(this.replacePathParams(SILOS_IMAGES,
                    {site: this.site.id}),
                );
                this.sandra_server_photos = response.data
                this.loading = false;
            },
            /**
             * Permet d'ouvrir le sélecteur des photos de sandra
             */
            async openSandraPhotoPicker() {
                await this.getSandraSilosImages()
                this.openSandraDialog()
            },
            /**
             * Permet d'ouvrir le sélecteur de photos depuis le pc
             */
            openPcPhotoPicker(){
                this.$refs.fileInput.$el.querySelector('input[type="file"]').click();
            },
            /**
             * Méthode qui se déclenche quand on clique on valide la sélection des photos du PC
             */
            handleFileSelect() {
                let photos = this.formatPcPhotos()
                this.formatted_pc_photos = [...photos, ...this.formatted_pc_photos]
            },
            /**
             * Permet de formatter les photos qui proviennent du PC
             */
            formatPcPhotos(){
                return this.pc_photos.map(image => ({
                    name: image.name ?? '-',
                    size: typeof image.size === 'number' ? Math.floor(image.size / 1000) : '-',
                    url: URL.createObjectURL(image),
                    is_new: true,
                    file: image
                }));
            },
            /**
             * Permet de supprimer une photo des photos sélectionnées
             *
             * @param index
             */
            removeFileFromPc(index){
                this.formatted_pc_photos.splice(index, 1);
            },
            /**
             * Ferme la dialog de création d'action
             */
            cancelDialog(){
                this.dialog = false
                this.reset_action()
            },
            /**
             * Permet de fermer et reset les images sélectionnées
             */
            cancelSandraDialog(){
                this.sandra_dialog = false
            },
            /**
             * Permet de renvoyer un "-" si il y a une valeur null
             * @param item
             * @returns {*|string}
             */
            nullable(item){
                if(item === null) return '-'
                return item
            },
            /**
             * Permet d'ajouter des photos de sandra
             */
            addSandraImages(){
                this.selected_sandra_photos = this.dialog_selected_sandra_photos
                this.sandra_dialog = false
            },
            /**
             * Retire une photo de sandra qui a été prisé
             *
             * @param index
             */
            removeFileFromSandra(index){
                this.selected_sandra_photos.splice(index, 1);
                this.dialog_selected_sandra_photos = this.selected_sandra_photos
            },
            /**
             * Création d'un form data pour passer les données au back
             * @param action
             * @returns {FormData}
             */
            formatData(action){
                let data = new FormData();

                if(action.details !== null && typeof action.details !== 'undefined') {
                    data.append("details", action.details)
                }

                if(action.is_manual !== null && typeof action.is_manual !== 'undefined') {
                    data.append("is_manual", action.is_manual)
                }

                if(action.notification_sent !== null && typeof action.notification_sent !== 'undefined') {
                    data.append("notification_sent", action.notification_sent)
                }

                if(action.email_send !== null && typeof action.email_send !== 'undefined') {
                    data.append("email_send", action.email_send)
                }

                if(action.site_id !== null && typeof action.site_id !== 'undefined') {
                    data.append("site_id", action.site_id)
                }

                if(action.silo_id !== null && typeof action.silo_id !== 'undefined') {
                    data.append("silo_id", action.silo_id)
                }

                if(action.type_id !== null && typeof action.type_id !== 'undefined') {
                    data.append("type_id", action.type_id)
                }
                /**
                 * On passe les photos sous la forme d'un string (pas de tableau dans un form data)
                 */
                if(this.selected_sandra_photos.length > 0) {
                    data.append("photos_from_sandra", JSON.stringify(this.selected_sandra_photos));
                }
                /**
                 * Ajout des photos dans un tableau
                 */
                let images = this.formatted_pc_photos.map(item => {
                    return item.file
                })

                if(images.length > 0){
                    images.forEach(image => {
                        data.append("photos_from_computer[]", image);
                    })
                }

                return data;
            },
            /**
             * Permet de lancer le téléchargement du zip des photos
             * @param item
             * @returns {Promise<void>}
             */
            async downloadZip(item){
                const response = await this.axios.get(this.replacePathParams(ACTIONS_PHOTOS, { action: item.id }), {
                    responseType: 'blob',
                });
                this.downloadFile(response)
            },
            /**
             * Permet de télécharger un fichier
             * @param response
             */
            downloadFile(response){
                const blob = new Blob([response.data], { type: 'application/zip' })
                const url = window.URL.createObjectURL(blob)
                const a = document.createElement('a')
                a.href = url
                a.download = this.extractFilename(response.headers['content-disposition'])
                a.style.display = 'none'
                document.body.appendChild(a)
                a.click()
                window.URL.revokeObjectURL(url)
            },
            /**
             * Permet d'extraire le nom du fichier
             * @param contentDisposition
             * @returns {*|null}
             */
             extractFilename(contentDisposition) {
                const match = contentDisposition.match(/filename="(.+?)"/);

                if (match && match[1]) {
                    return match[1];
                }

                return `action_${this.site.id}_${this.getFormattedDate()}.zip`
            },
            getFormattedDate() {
                const now = new Date();

                const year = now.getFullYear();
                const month = (now.getMonth() + 1).toString().padStart(2, '0');
                const day = now.getDate().toString().padStart(2, '0');
                const hours = now.getHours().toString().padStart(2, '0');
                const minutes = now.getMinutes().toString().padStart(2, '0');

                return `${year}_${month}_${day}_${hours}_${minutes}`;
            },
            /**
             * Method used to get the site coordinates
             *
             * @returns {*|string|string}
             */
            getSiteCoordinates(){
                if (this.site) {
                    let coordinates = this.site.address_1;
                    if (this.site.address_2 !== null) coordinates = coordinates + ' ' + this.site.address_2;
                    if (this.site.address_3 !== null) coordinates = coordinates + ' ' + this.site.address_3;
                    if (this.site.zip !== null) coordinates = coordinates + ' ' + this.site.zip;
                    if (this.site.city !== null) coordinates = coordinates + ', ' + this.site.city;

                    return coordinates;
                }
                return '-';
            },
            getSiteGpsCoordinates(){
                if(this.site && this.site.longitude && this.site.latitude) {
                    return this.site.latitude + ', ' + this.site.longitude;
                }
                return '-';
            },
            /**
             * Method used to get the site type
             *
             * @returns {VueI18n.TranslateResult}
             */
            getSiteType(){

                 if(this.siteProp?.companies[0]?.code?.length > 2){
                     return this.$t('site.breeding');
                 }

                return this.$t('site.factory');
            },
            /**
             * Method used to get the site reference
             *
             * @returns {string}
             */
            getSiteReference(){
                let reference = '';
                let companies = []
                _.each(this.site?.companies, (company) => {
                    companies.push(company.code);
                });

                companies.forEach( (company, key) => {
                    reference = reference + company;
                    if(key + 1 !== companies.length){
                        reference += ', ';
                    }
                })

                return reference;
            },
            /**
             * Method used to get the contact avril name from the site
             *
             * @returns {*|string|VueI18n.LocaleMessages}
             */
            getSiteContactName(){
                return this.site?.contact_avril_name ?? this.$t('site.none');
            },
            /**
             * Method used to get the contact avril phone from the site
             *
             * @returns {*|string|VueI18n.LocaleMessages}
             */
            getSiteContactPhone(){
                return this.site?.contact_avril_telephone ?? this.$t('site.none');
            },
            /**
             * Method used to retrieve a site update request
             *
             * @returns {Promise<void>}
             */
            async getSiteUpdateRequest() {
                this.loading = true;
                await this.axios.get(SITE_UPDATE_REQUESTS + '/' + this.$route.query.siteUpdateRequestId)
                .then((success) => {
                    this.update_request = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                });
            },
            /**
             * Method used to get the site update request type
             *
             * @returns {VueI18n.TranslateResult}
             */
            getSiteUpdateRequestType(){
                return this.$t('site.' + this.update_request?.type);
            },
            /**
             * Method used to get the auth user mail
             *
             * @returns {*}
             */
            getAuthenticatedUserMail(){
                return this.$auth.user().email;
            },
            /**
             * Method used to clear the emails to notify
             */
            clearEmailsToNotify(){
                this.emailsToNotify = [];
            },
            /**
             * Method used to get today's date
             *
             * @returns {string}
             */
            getTodayDate(){
                const date = new Date()
                return date.toLocaleDateString('fr-FR')
            },
            async setUpdateRequestDetails(){
                let update_site_action_type = this.types.find(actionType => actionType.code === 'update.site');
                if(this.action?.type_id === update_site_action_type?.id){
                    await this.getSiteUpdateRequest();
                    this.clearEmailsToNotify()

                    this.action.details =
                        this.$t('site.new_update_request_created') + '\n'

                        +' \n' +

                        this.$t('site.concerned_site').toUpperCase() + '\n \n' +
                        this.$t('site.reference_breeder_gescom') +' : ' + this.getSiteReference() + '\n' +
                        this.$t('site.site_name') +' : ' + this.site?.name + '\n' +
                        this.$t('site.coordinates') +' : ' + this.getSiteCoordinates() + '\n'+
                        this.$t('site.gps_coordinates') +' : ' + this.getSiteGpsCoordinates() + '\n'+
                        //this.$t('site.type') +' : ' + this.getSiteType() + '\n' +
                        this.$t('site.contact_name') +' : ' + this.getSiteContactName() + '\n'+
                        this.$t('site.phone') +' : ' + this.getSiteContactPhone() + '\n'

                        + '\n' +

                        this.$t('site.detail_of_the_request').toUpperCase() + '\n \n' +
                        this.$t('site.date') +' : ' + this.getTodayDate()  + '\n' +
                        this.$t('site.type') +' : ' + this.getSiteUpdateRequestType()  + '\n' +
                        this.$t('site.comment') +' : ' + this.update_request?.comment  + '\n' +
                        this.$t('site.driver') +' : ' + this.update_request?.user?.name  + '\n' +
                        this.$t('site.truck') +' : ' + this.update_request?.truck?.code  + '\n'

                        + '\n' +

                        this.$t('site.sandra_user_approving_the_request') + ' : ' + '\n' + this.getAuthenticatedUserMail();
                }
            },
            mergeMails() {
                const mails = this.additionalMails === null ? [] : this.additionalMails.split(';');
                mails.forEach(mail => this.emailsToNotify.push(mail));
            },
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            getTypeColor(name) {
                switch (name) {
                    case "Sécurité":
                        return "#e68e22";
                    case "Exécution":
                        return "#631f26";
                    case "Admin":
                        return "#747577";
                    default:
                        return "";
                }
            },
            /**
             * Get the action types
             *
             * @returns {Promise<void>}
             */
            async getManualActionTypes() {
                try {
                    let {data} =  await this.axios.get(MANUAL_ACTION_TYPES)
                    this.types = data
                }catch (e) {
                    this.showSnackbar('error', this.$t('global.error'));
                }
            },
            /**
             * Get silo
             *
             * @param {int} id_silo
             * @returns {Promise<unknown>}
             */
            getSilo(id_silo) {
                return new Promise((resolve, reject) => {
                    this.axios.get(this.replacePathParams(SILO, {silo: id_silo})).then(success => {
                        this.existing_silo = success.data;
                        resolve();
                    }).catch(() => {
                        // this.showSnackbar('error', this.$t('global.error'));
                        reject();
                    })
                });
            },
            addAction() {
                this.updating = true;
                if (this.action.notification_sent) {
                    this.mergeMails();
                    this.action.email_send = _.join(this.emailsToNotify, ';');
                }
                let data = this.formatData(this.action)
                this.axios.post(ACTIONS_FORM, data).then(({data: action}) => {
                    this.site.actions.push(action);
                    if (this.alertId) {
                        this.axios.delete(this.replacePathParams(ALERT, {alert: this.alertId}))
                    }
                    this.showSnackbar('success', this.$t('site.site_updated'));
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.updating = false;
                    this.cancelDialog()
                });
            },
            /**
             * Method used to format mails
             *
             * @param mails
             */
            format_mail(mails) {
                return mails.split(';').join('\n')
            },
            /**
             * Method used to reset action
             */
            reset_action() {
                this.action.details = null
                this.action.type_id = null
                this.action.silo_id = null
                this.action.notification_sent = false
                this.action.is_manual = true
                this.additionalMails = null
                this.dialog_selected_sandra_photos = []
                this.selected_sandra_photos = []
                this.formatted_pc_photos = []
                this.setDefaultEmailsToNotify()
            },
            setDefaultEmailsToNotify() {
                this.emails.forEach(email => {
                    if (email.quality_safety_manager_email) {
                        this.emailsToNotify.push(email.quality_safety_manager_email);
                    }
                    if (email.logistic_manager_email) {
                        this.emailsToNotify.push(email.logistic_manager_email);
                    }
                    if (email.sandra_manager_email) {
                        this.emailsToNotify.push(email.sandra_manager_email);
                    }
                    if (email.sales_manager_email) {
                        this.emailsToNotify.push(email.sales_manager_email);
                    }
                });
            },
            setDefaultSalesEmailsToNotify() {
                if (this.sales_representative_email) {
                    this.emailsToNotify.push(this.sales_representative_email);
                }
            },
            replaceBrTags(item) {
                if (item) {
                    return item?.replace(/<br\s*[\/]?>/gi, "\n");
                }

                return "";
            },
            /**
             * Get pictogram for the security level
             *
             * @param securityLevelColor
             * @returns {string}
             */
            getSecurityLevelImage(securityLevelColor) {
                switch (securityLevelColor) {
                    case 'Green':
                        return 'silo_vert.svg';
                    case 'Orange':
                        return 'silo_orange.svg';
                    case 'Red':
                        return 'silo_rouge.svg';
                    case 'Black':
                        return 'silo_noir.svg';
                    default:
                        return 'silo_neutre.svg';
                }
            },
            displayEmails() {
                this.site = this.siteProp;
            },
            openDialog() {
                this.reset_action();
                this.dialog = true;
            }
        },
        computed: {
            filteredActions() {
                this.site.actions = _.orderBy(this.site.actions, 'created_at', 'desc')
                if (this.selectedType)
                    return this.site.actions.filter(action => action.type?.name === this.selectedType);
                return this.site.actions.filter(action => action.type?.is_visible === 1);
            },
            sales_representative_email() {
                let sales = null;
                if (this.action.silo_id) {
                    sales = this.site.silos.find(silo => silo.id === this.action.silo_id).sales_representative_email;
                }

                return sales;
            },
            emails() {
                let emails = [];

                this.site?.logistic_areas?.forEach(logistic_area => {
                    emails.push({
                        name: logistic_area.name,
                        logistic_manager_email: logistic_area.logistic_manager_email,
                        quality_safety_manager_email: logistic_area.quality_safety_manager_email,
                        sandra_manager_email: logistic_area.sandra_manager_email,
                        sales_manager_email: logistic_area.sales_manager_email,
                    });
                });

                _.forEach(emails, function (object) {
                    Object.keys(object).forEach((key) => (object[key] == null) && delete object[key]);

                    var keys = Object.keys(object);
                    if (keys.length === 1) {
                        delete emails[emails.indexOf(object)];
                    }
                });

                return emails.filter(function (email) {
                    return email != null;
                });
            }
        },
        watch: {
            emails: {
                handler: "setDefaultEmailsToNotify",
                immediate: true,
            },
            sales_representative_email: {
                handler: "setDefaultSalesEmailsToNotify",
                immediate: true,
            },
            siteProp: {
                async handler(newVal) {
                    this.site = Object.assign({}, newVal);
                    await this.setUpdateRequestDetails();
                },
                deep: true,
            }
        }
    }
</script>

<style scoped lang="scss">

.text-red{
    color: #c23a3a
}

.cursor-pointer{
    cursor: pointer;
}

.role{
    font-size: 14px;
}

</style>
