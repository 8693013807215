<template>
    <div class="cartography-module">
        <v-row>
            <v-col
                class="white"
            >
                <!--
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('navigation.cartography') }}
                    </p>
                </v-subheader>
                -->
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('navigation.cartography'),
                                  disabled: true,
                                  href: 'breadcrumbs_tours',
                                },
                                {
                                  text: mode,
                                  disabled: true,
                                  href: 'breadcrumbs_tours',
                                },
                              ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="6">
                <v-btn
                    block
                    :class="{ 'inactive': mode === 'tracking', secondary: mode === 'live', 'disable-events': mode === 'live' }"
                    @click="goTo('live')"
                >
                    {{ $t('cartography.live')}}
                </v-btn>
            </v-col>
            <v-col cols="6">
                <v-btn
                    block
                    :class="{ 'inactive': mode === 'live', secondary: mode === 'tracking', 'disable-events': mode === 'tracking' }"
                    @click="goTo('tracking')"
                >
                    {{ $t('cartography.tracking')}}
                </v-btn>
            </v-col>
            <v-col cols="12" v-if="mode === 'live'">
                <trucks-live></trucks-live>
            </v-col>
            <v-col cols="12" v-if="mode === 'tracking'">
                <tracking-truck></tracking-truck>
            </v-col>
        </v-row>
    </div>
</template>

<style lang="scss" scoped>
    .disable-events {
        pointer-events: none
    }

    .inactive {
        background-color: rgba(0,0,0,.12) !important;
    }
</style>

<script>
    import TrucksLive from "../components/Cartography/TrucksLive";
    import TrackingTruck from "../components/Cartography/TrackingTruck";

    export default {
        name: "cartography",
        components: {TrucksLive, TrackingTruck},
        computed: {
            mode() {
                return this.$route.params.mode === 'tracking'?'tracking':'live';
            },
        },
        methods: {
            goTo(mode) {
                this.$router.push(`/cartography/${mode}`)
            },
        },
    }
</script>

<style lang="scss">

.cartography-module {
    .v-breadcrumbs li:last-child a {
        font-size: 1.25rem !important;
        color: rgba(0, 0, 0, .6) !important;
        font-weight: 500;
    }

    .v-list--dense .v-list-item {
        .v-list-item__title,
        .v-list-item__subtitle {
            font-size: 0.9rem;
        }
    }
}

</style>

