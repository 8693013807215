<template>
    <div>
        <v-data-table
            :no-data-text="$t('global.no_data')"
            :loading-text="$t('global.loading_text')"
            :no-results-text="$t('global.no_results_text')"
            :headers="headers"
            :items="sites"
            :loading="loading"
            :server-items-length="number_of_alerts"
            @update:options="changeOptions"
            :options.sync="options"
            :footer-props="{
                        'items-per-page-options': rowsPerPageItems,
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
        >
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark class="mb-2" @click="getDuplicateSiteAlert()">
                        {{ $t('alert.refresh') }}
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | moment('L LT') }}
            </template>
            <template v-slot:item.concerned_element="{ item }">
                {{ formatType(item) }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    @click="goTo(item.id)"
                    :title="$t('alert.go_to_alert_site')"
                >
                    mdi-arrow-right
                </v-icon>
            </template>
        </v-data-table>

        <v-snackbar
            v-model="snackbar"
            top
            :color="snackbar_type"
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import {DUPLICATE_SITE} from "../../api";
    import {mapGetters} from "vuex";
    import {bus} from "../../app";

    export default {
        name: 'site-alert-datatable',
        data() {
            return {
                headers: [
                    {text: this.$t('alert.created_at'), value: 'created_at', sortable: false},
                    {text: this.$t('alert.address'), value: 'concerned_element', sortable: false},
                    {text: this.$t('alert.actions'), value: 'actions', sortable: false},
                ],
                loading: false,
                dialogDelete: false,
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                alerts: [],
                number_of_alerts: 0,
                options: {page: 1, itemsPerPage: 10},
                rowsPerPageItems: [5, 10, 100],
            }
        },
        created() {
            bus.$on("applyFilter", this.getDuplicateSiteAlert)
        },
        mounted() {
            this.getDuplicateSiteAlert();
        },
        computed: {
            sites() {
                return this.alerts;
            }
        },
        methods: {
            ...mapGetters({get_filters: 'alert/get_filters'}),
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Format type
             *
             * @param item
             * @returns {string}
             */
            formatType(item) {
                return item.alertable.name +
                    ' (' + item.alertable.city + ', ' +
                    item.alertable.zip + ', ' +
                    item.alertable.address_1 + ')';
            },
            /**
             * Go to alert site
             *
             * @param {integer} id
             */
            goTo(id) {
                this.$router.push({name: 'alerte_site', params: {alertId: id}})
            },
            /**
             * Get alerts
             */
            getAlerts() {
                this.loading = true;
                this.get().catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                });
            },
            /**
             * Get alerts
             */
            getDuplicateSiteAlert() {
                this.loading = true;
                this.axios.get(DUPLICATE_SITE,
                    {params: {page: this.options.page, per_page: this.options.itemsPerPage,
                            logistic_areas: this.get_filters().logistic_areas_choices}}
                ).then((success) => {
                    this.alerts = success.data.data;
                    this.number_of_alerts = success.data.total;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                });
            },
            /**
             * Change options
             *
             * @param {object} options
             */
            changeOptions(options) {
                if (this.options.itemsPerPage !== options.itemsPerPage || this.options.page !== options.page) {
                    this.options.itemsPerPage = options.itemsPerPage;
                    this.options.page = options.page;

                    this.getDuplicateSiteAlert();
                }
            },
        }
    }
</script>
