    <template>
    <div class="drivers-module">
        <v-row>
            <v-col
            class="white">
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('navigation.administration'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                                {
                                  text: $t('navigation.transporters'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                              ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-dialog v-model="dialog" max-width="500px" @input="$validator.reset()">
                            <template v-slot:activator="{ on }">
                                <v-btn class="mb-2" color="primary" dark v-on="on">{{ $t('driver.new') }}</v-btn>
                            </template>
                            <v-card
                                center
                            >
                                <v-card-title>
                                    <span class="headline">{{$t('transporter.new')}}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-form>
                                        <v-text-field
                                            v-model="editedItem.email"
                                            v-validate="'required|email'"
                                            :error-messages="errors.collect('email')"
                                            :label="$t('driver.email')"
                                            data-vv-name="email"
                                            name="email"
                                            prepend-icon="mdi-email"
                                            type="text"
                                        ></v-text-field>
                                        <v-text-field
                                            id="name"
                                            v-model="editedItem.name"
                                            v-validate="'required|min:3'"
                                            :error-messages="errors.collect('name')"
                                            :label="$t('driver.name')"
                                            data-vv-name="name"
                                            name="name"
                                            prepend-icon="mdi-account"
                                            type="text"
                                        ></v-text-field>
                                        <v-autocomplete
                                            id="roles"
                                            v-model="editedItem.roles"
                                            v-validate="'required'"
                                            :error-messages="errors.collect('roles')"
                                            :item-value="item => item.name"
                                            :items="filteredRoles"
                                            :label="$t('user.roles')"
                                            chips
                                            data-vv-name="roles"
                                            item-text="name"
                                            multiple
                                            name="roles"
                                            prepend-icon="mdi-chess-queen"
                                            required

                                        >
                                            <template v-slot:item="{ item, attrs, on}">
                                                <v-list-item #default="{ active }" v-bind="attrs" v-on="on">
                                                    <v-list-item-action>
                                                        <v-checkbox :input-value="active"></v-checkbox>
                                                    </v-list-item-action>
                                                    <v-list-item-content>
                                                        {{ $t(`role.${item.name}`) }}
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <template v-slot:selection="data">
                                                <v-chip>{{ $t(`role.${data.item.name}`) }}</v-chip>
                                            </template>
                                        </v-autocomplete>
                                        <v-autocomplete
                                            id="transporters"
                                            v-model="editedItem.transporters"
                                            :item-value="item => item"
                                            :items="transporters"
                                            :label="$t('transporter.transporters_default')"
                                            chips
                                            item-text="code"
                                            multiple
                                            name="transporters"
                                            prepend-icon="mdi-lan"
                                        ></v-autocomplete>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="close">{{ $t('driver.cancel') }}</v-btn>
                                    <v-btn :disabled="submit_button_is_disabled" @click="save(editedItem)">
                                        {{ formSubmit }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            :label="$t('transporter.search')"
                            append-icon="mdi-magnify"
                            hide-details
                            single-line
                        ></v-text-field>
                    </v-card-title>
                    <v-data-table
                        :headers="headers"
                        :items="transporters_users"
                        :search="search">
                        <template v-slot:item.transporters="{item}">
                            <div v-for="transporter in item.transporters" :key="transporter.id">
                                <v-chip>{{transporter.code}}</v-chip>
                            </div>
                        </template>
                        <template v-slot:item.last_login_date="{ item }">
                            <span v-if="item">
                            {{ item.last_login_date | moment('L LT') }}
                            </span>
                            <span v-else>
                                -
                            </span>
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            <span v-if="item">
                                {{ item.created_at | moment('L LT') }}
                            </span>
                            <span v-else>
                                -
                            </span>
                        </template>
                        <template v-slot:item.updated_at="{ item }">
                            <span v-if="item">
                                {{ item.updated_at | moment('L LT') }}
                            </span>
                            <span v-else>
                                -
                            </span>
                        </template>
                        <template v-slot:item.action="{item}">
                            <v-icon
                                :title="$t('driver.edit')"
                                class="mr-2"
                                small
                                @click="editItem(item)"
                            >
                                mdi-pencil
                            </v-icon>
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="dialogDelete" max-width="290">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('driver.sure') }}</v-card-title>
                <v-card-text>{{ $t('driver.irremediable') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" text @click="dialogDelete = false">
                        {{ $t('driver.cancel') }}
                    </v-btn>
                    <v-btn color="warning darken-1" text @click="deleteDriver()">
                        {{ $t('driver.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type"
            top
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
import _ from 'lodash'
import {ROLES, TRANSPORTER_USER, TRANSPORTER_USERS, TRANSPORTERS} from "../api";

export default {
    name: "transporters",
    data() {
        return {
            snackbar: false,
            snackbar_text: '',
            snackbar_type: null,
            formSubmit: "Créer",
            headers: [
                {text: this.$t('driver.id'), value: 'id', sortable: false},
                {text: this.$t('driver.name'), value: 'name', sortable: true},
                {text: this.$t('driver.email'), value: 'email', sortable: true},
                {text: this.$t('driver.transporters'), value: 'transporters', sortable: true},
                {text: this.$t('driver.last_login_date'), value: 'last_login_date', sortable: true},
                {text: this.$t('driver.created_at'), value: 'created_at', sortable: false},
                {text: this.$t('driver.updated_at'), value: 'updated_at', sortable: false},
                {text: this.$t('driver.actions'), value: 'action', sortable: false},
            ],
            loading: true,
            options: {},
            page: 1,
            transporters: [],
            search: '',
            toggleAreas: null,
            searchAreasInput: '',
            searchTrucksInput: '',
            expected_drivers: [],
            editedItem: {
                email: '',
                name: '',
                transporters: [],
                roles:[]
            },
            editedIndex: -1,
            defaultItem: {
                email: '',
                name: '',
                transporters: [],
                roles:[]
            },
            base_roles:[],
            transporters_users:[],
            dialog: false,
            dialogDelete: false,
            submit_button_is_disabled: false,
            driverToDelete: null,
            role_driver_id: null,

            roles:[],
        }
    },
    computed: {
        filteredRoles(){
            return this.roles.filter(role =>  (role.name === 'driver' || role.name === 'transporter'))
        }
    },
    mounted() {
        this.getRoles();
        this.getTransporterUsers()
        this.getTransporters()
    },
    methods: {
        /**
         * Get roles from API
         */
        getRoles() {
            this.axios.get(ROLES).then((success) => {
                this.roles = success.data;
            }).catch(() => {
                this.showSnackbar('error', this.$t('global.error'));
            })
        },
        /**
         * Reset snackbar
         */
        resetSnackbar() {
            this.snackbar_type = null;
            this.snackbar_text = null;
            this.snackbar = false;
        },
        /**
         * Show snackbar
         *
         * @param {string} type
         * @param {string} text
         */
        showSnackbar(type, text) {
            this.snackbar_type = type;
            this.snackbar_text = text;
            this.snackbar = true;
        },
        getTransporterUsers(){
            this.axios.get(TRANSPORTER_USERS)
            .then(result => {
                this.transporters_users = result.data
            })
        },
        getTransporters(){
            this.axios.get(TRANSPORTERS)
            .then(res => {
                this.transporters = res.data
            })
        },
        close(){
            this.dialog = false
            this.editedIndex = -1
            this.editedItem = this.defaultItem
            this.getTransporterUsers()
        },
        editItem(item) {
            this.dialog = true
            this.editedItem = item
            this.editedIndex = item.id
            this.base_roles = item.roles.map(item => item.name).filter(name => (name === 'web' || name === 'admin'))
            this.editedItem.roles = _.map(item.roles, 'name')
            this.formSubmit = "Editer"
        },
        editTransporterUser(user) {
            this.$validator.validateAll()
                .then((result) => {
                    if (result) {
                        axios.put(this.replacePathParams(TRANSPORTER_USER, {user: user.id}), {
                            name: user.name,
                            email: user.email,
                            transporter_ids: _.map(user.transporters, 'id'),
                            roles: [...user.roles, ...this.base_roles]
                        })
                            .then(res => {
                                this.close()
                            })
                    }
                })
        },
        createTransporter(transporter) {
            axios.post(
                TRANSPORTER_USERS,
                transporter
            ).then(res => {
                this.showSnackbar('success', this.$t('transporter.created'));
                this.close()
            }).catch(error => {
                console.log(error, error.response)
                if (error.response.status === 422) {
                    this.showSnackbar('warning', this.parseLaravelError(error));
                } else {
                    this.showSnackbar('error', this.$t('global.error'));
                }
            })
        },
        save(){
            if (this.editedIndex === -1){
                this.createTransporter({
                    name:this.editedItem.name,
                    email:this.editedItem.email,
                    transporters: this.editedItem.transporters.map(item => item.id),
                    roles: this.editedItem.roles
                })
            }else{
                this.editTransporterUser(this.editedItem)
                this.close()
            }
        },
        /**
         * Filter areas drop-down
         */
        filterAreas (item, queryText) {
            let textOne = "";
            let textTwo = "";
            let searchText = "";
            if (item.code && item.name)
            {
                textOne = item.name.toLowerCase();
                textTwo = item.code.toLowerCase();
                searchText = queryText.toLowerCase();
            }

            return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
        },
    },
    watch: {
    }
}
</script>

    <style lang="scss" scoped>
    .drivers-module {
        .v-breadcrumbs li:last-child a {
            font-size: 1.25rem !important;
            color: rgba(0, 0, 0, .6) !important;
            font-weight: 500;
        }

        .v-list--dense .v-list-item {
            .v-list-item__title,
            .v-list-item__subtitle {
            font-size: 0.9rem;
        }
    }
}
</style>
