<template>
    <div class="site-module">

        <v-row class="align-items-center limit-height " style="position: relative;top: -20px;">

            <v-col class="flex-grow-0">
                <v-btn :title="$t('site.back')" icon x-large @click="$router.go(-1)">
                    <v-icon>mdi-arrow-left-thick</v-icon>
                </v-btn>
            </v-col>

            <v-col>
                <v-breadcrumbs
                    :items="[
                        {
                          text: $t('navigation.sites'),
                          disabled: false,
                          href: '#/sites',
                        },
                        {
                          text: site.name,
                          disabled: true,
                          href: 'breadcrumbs_dashboard',
                        },
                    ]"
                />
            </v-col>

            <v-col class="flex-grow-0">
                <div class="d-flex align-content-center">
                    <v-icon
                        :title="$t('site.edit_site')"
                        x-large
                        @click="editSite()"
                    >
                        mdi-lead-pencil
                    </v-icon>
                    <img :src="'images/' + getSiteSecurityLevel(site)" alt="" width="54">
                </div>
            </v-col>

        </v-row>

        <v-row >
            <v-col style="position: relative; top: -40px; z-index: 1" >
                <v-container >
                    <v-row>
                        <v-col cols="8" >
                            <v-list class="row" dense disabled>
                                <v-list-item class="col-3" style="height: 100%;">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-map-marker</v-icon>
                                            {{ $t('site.contact_information') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">
                                            {{ this.site.address_1 || $t('site.none') }}
                                            <br v-if="site.address_1">
                                            {{ this.site.address_2 }}
                                            <br v-if="site.address_2">
                                            {{this.site.address_3}}
                                            <br v-if="site.address_3">
                                            {{this.site.zip}}, {{this.site.city}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3" style="height: 100%;">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-crosshairs-gps</v-icon>
                                            {{ $t('site.gps_coordinate') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                        <span v-if="site.longitude && site.latitude">
                                            {{ Number.parseFloat(site.latitude).toFixed(3) }}, {{ Number.parseFloat(site.longitude).toFixed(3) }}
                                        </span>
                                            <span v-else>
                                            0, 0
                                        </span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3" style="height: 100%;">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-google-earth</v-icon>
                                            {{ $t('site.site_references') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                        <span
                                            v-for="(company,key) in site_references"
                                            v-if="site_references.length > 0"
                                        >
                                            {{ company }}<span v-if="key+1 !== site_references.length">,</span>
                                        </span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3" style="height: 100%;">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-google-earth</v-icon>
                                            {{ $t('site.intern_site_reference') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="this.site.id">
                                            {{ this.site.id }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else>
                                            {{ $t('site.none') }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3" style="height: 100%;">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-factory</v-icon>
                                            {{ $t('site.type') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="this.site.companies">
                                        <span v-if="this.site.companies[0].code.length > 2">
                                            {{ $t('site.breeding') }}
                                        </span>
                                            <span v-else>
                                            {{ $t('site.factory') }}
                                        </span>
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else>
                                            {{$t('site.none')}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3" style="height: 100%;">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-truck-delivery-outline</v-icon>
                                            {{ $t('site.last_delivery') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="site.last_delivery_date">
                                            {{ this.site.last_delivery_date | moment('L LT') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else>
                                            {{$t('site.none')}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3" style="height: 100%;">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-truck-check-outline</v-icon>
                                            {{ $t('site.last_loading') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="site.last_loading_date">
                                            {{ this.site.last_loading_date | moment('L LT') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else>
                                            {{$t('site.none')}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3" style="height: 100%;">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-tools</v-icon>
                                            {{ $t('site.last_checking') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-if="site.updated_at">
                                            {{ this.site.updated_at | moment('L LT') }}
                                        </v-list-item-subtitle>
                                        <v-list-item-subtitle v-else>
                                            {{$t('site.none')}}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3" style="height: 100%;">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-circle</v-icon>
                                            {{ $t('site.status') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                        <span v-if="this.site.active">
                                            {{ $t('site.active') }}
                                        </span>
                                            <span v-else>
                                            {{ $t('site.inactive') }}
                                        </span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3" style="height: 100%;">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-crosshairs</v-icon>
                                            {{ $t('site.logistic_area') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle>
                                        <span v-for="(logistic_area,key) in site.logistic_areas" class="text-wrap">
                                            {{ logistic_area.code }}
                                            ({{ logistic_area.name }})<span
                                            v-if="key+1 !== site.logistic_areas.length">,</span>
                                        </span>
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3" style="height: 100%;">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-message</v-icon>
                                            {{ $t('site.comments') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">
                                            {{ this.site.comments||$t('site.none') }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item class="col-3" style="height: 100%;">
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            <v-icon>mdi-message</v-icon>
                                            {{ $t('site.route_comments') }}
                                        </v-list-item-title>
                                        <v-list-item-subtitle class="text-wrap">
                                            {{ this.site.route_comments||$t('site.none') }}
                                        </v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list>
                        </v-col>
                        <v-col class="d-flex flex-column justify-center" cols="4" style="position:relative">
                            <div class="container_lock_unlock_gps_coordinate">
                                <v-btn
                                    :disabled="updating || (site.location_state.code !== LOCKED && site.location_state.code !== SPECIFIED)"
                                    class="container_lock_unlock_gps_coordinate__button"
                                    color="primary"
                                    fab
                                    x-large
                                    @click="updateSiteLocationState()"
                                >
                                    <v-icon
                                        v-if="site.location_state.code === LOCKED"
                                        :title="getTitleFromGps(site.location_state.code)"
                                    >
                                        mdi-lock
                                    </v-icon>
                                    <v-icon
                                        v-else
                                        :title="getTitleFromGps(site.location_state.code)"
                                    >
                                        mdi-lock-open-variant
                                    </v-icon>
                                </v-btn>
                            </div>
                            <maps
                                :latitude="site.latitude"
                                :longitude="site.longitude"
                                @updateCoordinates="updateCoordinates"
                            ></maps>
                        </v-col>
                    </v-row>
                </v-container>

                <v-divider></v-divider>

                <v-container>
                    <v-row no-gutters>
                        <v-col cols="12">
                            <v-tabs
                                v-model="activeTab"
                                background-color="accent-4"
                                centered
                                icons-and-text
                            >
                                <v-tabs-slider></v-tabs-slider>

                                <div class="d-flex" style="width: 100%;">
                                    <v-tab href="#tab-silos">{{ $t('site.silos') }}</v-tab>
                                    <v-tab href="#tab-contacts">{{ $t('site.contacts') }}</v-tab>
                                    <v-tab href="#tab-attachments">{{ $t('site.attachments') }}</v-tab>
                                    <v-tab href="#tab-actions">{{ $t('site.actions') }}</v-tab>
                                    <v-tab href="#tab-history">{{ $t('site.history') }}</v-tab>
                                    <v-tab href="#tab-reattached">{{ $t('site.reattached_sites') }} ({{ reattached_sites_count }})</v-tab>
                                </div>
                                <v-tab-item
                                    :value="'tab-silos'">
                                    <silos :silos="silos" @updateSite="handleUpdate"></silos>
                                </v-tab-item>
                                <v-tab-item
                                    :value="'tab-contacts'">
                                    <contacts :contacts="contacts"></contacts>
                                </v-tab-item>
                                <v-tab-item
                                    :value="'tab-attachments'">
                                    <attachments :site="site"></attachments>
                                </v-tab-item>
                                <v-tab-item
                                    :value="'tab-actions'">
                                    <actions
                                        :action-type-code="actionTypeCode"
                                        :alert-id="alertId"
                                        :notification-sent="actionNotificationSent"
                                        :show-dialog="showDialog"
                                        :silo-id="siloId"
                                        :site-prop="site"
                                    ></actions>
                                </v-tab-item>
                                <v-tab-item
                                    :value="'tab-history'">
                                    <history :site="site_to_update"></history>
                                </v-tab-item>
                                <v-tab-item
                                    :value="'tab-reattached'">
                                    <reattached :site="site"></reattached>
                                </v-tab-item>
                            </v-tabs>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog" max-width="600px">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('site.edit_site') }}</v-card-title>
                <v-card-text>
                    <v-form name="sites">
                        <v-text-field
                            v-model="site_to_update.id"
                            :disabled="true"
                            :label="$t('site.code')"
                            data-vv-name="site.code"
                            name="site.code"
                            prepend-icon="mdi-alpha-c-box"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="site_to_update.name"
                            :label="$t('site.name')"
                            data-vv-name="site.name"
                            name="site.name"
                            prepend-icon="mdi-alpha-n-box"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="site_to_update.address_1"
                            :label="$t('site.address_1')"
                            data-vv-name="site.address_1"
                            name="site.address_1"
                            prepend-icon="mdi-numeric-1-box-multiple-outline"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="site_to_update.address_2"
                            :label="$t('site.address_2')"
                            data-vv-name="site.address_2"
                            name="site.address_2"
                            prepend-icon="mdi-numeric-2-box-multiple-outline"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="site_to_update.address_3"
                            :label="$t('site.address_3')"
                            data-vv-name="site.address_3"
                            name="site.address_3"
                            prepend-icon="mdi-numeric-3-box-multiple-outline"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="site_to_update.city"
                            :label="$t('site.city')"
                            data-vv-name="site.city"
                            name="site.city"
                            prepend-icon="mdi-home-city-outline"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="site_to_update.zip"
                            :label="$t('site.zip_code')"
                            data-vv-name="site.zip_code"
                            name="site.zip_code"
                            prepend-icon="mdi-home-city"
                            type="text"
                        ></v-text-field>

                        <v-select
                            id="site.location_states"
                            v-model="site_to_update.location_state_id"
                            v-validate="'required'"
                            :disabled="true"
                            :items="location_states"
                            :label="$t('site.location_state')"
                            chips
                            data-vv-name="location_states"
                            item-text="name"
                            item-value="id"
                            name="site.location_states"
                            prepend-icon="mdi-lock-outline"
                            required
                        >
                        </v-select>

                        <v-select
                            id="site.status"
                            v-model="site_to_update.active"
                            v-validate="'required'"
                            :items="this.status"
                            :label="$t('site.status')"
                            chips
                            data-vv-name="states"
                            item-text="name"
                            item-value="id"
                            name="site.status"
                            prepend-icon="mdi-moon-full"
                            required
                        >
                        </v-select>

                        <v-text-field
                            v-model="site_to_update.latitude"
                            :label="$t('site.latitude')"
                            data-vv-name="site.latitude"
                            name="site.latitude"
                            prepend-icon="mdi-latitude"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="site_to_update.longitude"
                            :label="$t('site.longitude')"
                            data-vv-name="site.longitude"
                            name="site.longitude"
                            prepend-icon="mdi-longitude"
                            type="text"
                        ></v-text-field>

                        <v-textarea
                            v-model="site_to_update.comments"
                            :label="$t('site.comments')"
                            data-vv-name="site.comments"
                            name="site.comments"
                            prepend-icon="mdi-comment-text-multiple-outline"
                            type="textarea"
                        ></v-textarea>

                        <v-textarea
                            v-model="site_to_update.route_comments"
                            :label="$t('site.route_comments')"
                            data-vv-name="site.route_comments"
                            name="site.route_comments"
                            prepend-icon="mdi-comment-multiple-outline"
                            type="textarea"
                        ></v-textarea>

                        <v-text-field
                            v-model="site_to_update.contact_avril_name"
                            :label="$t('site.contact_name')"
                            data-vv-name="site.contact_name"
                            name="site.contact_name"
                            prepend-icon="mdi-account-box-outline"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="site_to_update.contact_avril_telephone"
                            :label="$t('site.contact_telephone')"
                            data-vv-name="site.contact_telephone"
                            name="site.contact_telephone"
                            prepend-icon="mdi-phone"
                            type="text"
                        ></v-text-field>

                        <v-text-field
                            v-model="site_to_update.contact_avril_mobile"
                            :label="$t('site.contact_mobile')"
                            data-vv-name="site.contact_mobile"
                            name="site.contact_mobile"
                            prepend-icon="mdi-cellphone"
                            type="text"
                        ></v-text-field>

                        <v-checkbox
                            v-model="site_to_update.silo_qrcode_pairing_is_disabled"
                            :label="$t('site.disable_qrcode_pairing')"
                        />

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="darken-1" text @click="dialog= false">
                                {{ $t('site.cancel') }}
                            </v-btn>
                            <v-btn :disabled="updating" color="warning darken-1"
                                   text @click="updateSite(site_to_update)">
                                {{ $t('site.edit') }}
                            </v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" :color="snackbar_type" top>
            {{ snackbar_text }}
            <v-btn text @click="snackbar = false">
                Close
            </v-btn>
        </v-snackbar>

    </div>
</template>

<script>
import Silos from "../components/Site/Silos";
import Contacts from "../components/Site/Contacts";
import Attachments from "../components/Site/Attachments";
import Actions from "../components/Site/Actions";
import {LOCATION_STATES, SITE} from "../api";
import History from "../components/Site/History";
import Maps from "../components/Maps";
import Reattached from "../components/Site/Reattached";
import {mapActions} from "vuex";

export default {
    name: "Site",
    components: {Reattached, History, Silos, Contacts, Attachments, Actions, Maps},
    data() {
        return {
            status: [
                {name: this.$t('site.active'), id: 1,},
                {name: this.$t('site.inactive'), id: 0,},
            ],
            snackbar: false,
            snackbar_text: '',
            snackbar_type: null,
            tab: null,
            dialog: false,
            updating: false,
            site_to_update: {},
            site: {location_state: {}},
            location_states: [],
            UNSPECIFIED: null,
            SPECIFIED: 'GPS_OK',
            LOCKED: 'GPS_VERR',
            activeTab: '',
            showDialog: false,
            siloId: null,
            actionTypeCode: '',
            actionNotificationSent: null,
            alertId: null
        }
    },
    computed: {
        silos() {
            return this.site.silos ?? [];
        },
        contacts() {
            return this.site.contacts ?? [];
        },
        location_state_code() {
            return this.site.location_state.code === this.UNSPECIFIED ||
            this.site.location_state.code === this.SPECIFIED ? this.LOCKED : this.SPECIFIED;
        },
        site_references() {
            let site_references = [];
            _.each(this.site.companies, (company) => {
                site_references.push(company.code);
            });

            return site_references;
        },
        reattached_sites_count() {
            return this.site.reattached_sites ? this.site.reattached_sites.length : 0;
        },
        last_delivery() {
            let dates = [];
            this.site.silos.forEach(silo => {
                if (silo.last_delivery_datetime && this.$moment(silo.last_delivery_datetime).format() <= this.$moment().format()) {
                    dates.push(silo.last_delivery_datetime);
                }
            });

            return _.max(dates) ? this.$moment(_.max(dates)).format('L LT') : this.$t('site.none');
        },
    },
    async created() {
        await this.getSite(this.$route.params.siteId);
        await this.getLocationStates();
        if (this.$route.query.tab !== undefined) {
            this.activeTab = this.$route.query.tab;
        }
        if (this.$route.query.siloId !== undefined) {
            this.siloId = parseInt(this.$route.query.siloId);
        }
        if (this.$route.query.actionTypeCode !== undefined) {
            this.actionTypeCode = this.$route.query.actionTypeCode;
        }
        if (this.$route.query.actionNotificationSent !== undefined) {
            this.actionNotificationSent = this.$route.query.actionNotificationSent === 'true' || this.$route.query.actionNotificationSent === true;
        }
        if (this.$route.query.showDialog !== undefined) {
            this.showDialog = this.$route.query.showDialog === 'true' || this.$route.query.showDialog === true;
        }
        if (this.$route.query.alertId !== undefined) {
            this.alertId = parseInt(this.$route.query.alertId)
        }
    },
    methods: {
        ...mapActions(['alert/count']),
        editSite() {
            Object.assign(this.site_to_update, this.site);
            this.dialog = true;
        },
        /**
         * Get the title for the associated GPS
         *
         */
        getTitleFromGps(location_state_code) {
            if (location_state_code === this.LOCKED) {
                return this.$t('site.unlock');
            } else {
                return this.$t('site.lock');
            }
        },
        /**
         * Method used to validate the update on a site. Multiple fields are null in the request.
         * So we remove them from the request.
         *
         * @param site
         */
        validateSite(site) {
            if (site.location_state_id !== 1 && (site.longitude === '' || site.latitude === '')) {
                site.location_state_id = 1;
            }

            if (site.location_state_id === 1 && ((site.longitude !== '' && site.longitude !== null) && (site.latitude !== '' && site.latitude !== null))) {
                site.location_state_id = 2;
            } else if (site.location_state_id !== 1 && (site.longitude === '' || site.longitude === null || site.latitude === '' || site.latitude === null)) {
                site.location_state_id = 1;
            }
            for (let property in site) {
                if (site.hasOwnProperty(property) && (site[property] == null)) {
                    site[property] = undefined;
                }
            }

            site['last_operation_datetime'] = undefined;
            site['last_loading_datetime'] = undefined;
            site['last_delivery_datetime'] = undefined;
        },
        /**
         * Method used to update the site
         */
        updateSite() {
            this.updating = true;
            this.validateSite(this.site_to_update);
            this.axios.patch(
                this.replacePathParams(SITE, {site: this.site_to_update.id}),
                this.site_to_update
            ).then((success) => {
                    Object.assign(this.site, success.data);
                    this.showSnackbar('success', this.$t('site.site_updated'));
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.showSnackbar('warning', this.parseLaravelError(error));
                    } else {
                        this.showSnackbar('error', this.$t('global.error'));
                    }
                }).finally(() => {
                    this.updating = false;
                    this.dialog = false;
                })
            },
            /**
             * Method used to update the site
             */
            updateSiteLocationState() {
                this.updating = true;
                this.axios.patch(
                    this.replacePathParams(SITE, {site: this.$route.params.siteId}),
                    {
                        location_state_id: _.get(_.find(this.location_states, {code: this.location_state_code}), 'id'),
                    }
                ).then((success) => {
                    this.showSnackbar('success', this.$t('site.site_updated'));
                    this.site = success.data;
                    this['alert/count']();
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.showSnackbar('warning', this.parseLaravelError(error));
                    } else {
                        this.showSnackbar('error', this.$t('global.error'));
                    }
                }).finally(() => {
                    this.updating = false;
                })
            },
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Get site
             */
            getSite(site) {
                this.axios.get(
                    this.replacePathParams(SITE, {site})
                ).then((success) => {
                    this.site = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                })
            },
            /**
             * Get all location state
             */
            getLocationStates() {
                this.axios.get(LOCATION_STATES).then((success) => {
                    this.location_states = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            updateCoordinates(data) {
                this.updating = true;
                this.axios.patch(
                    this.replacePathParams(SITE, {site: this.$route.params.siteId}),
                    data
                ).then((success) => {
                    Object.assign(this.site, success.data);
                    this.showSnackbar('success', this.$t('site.site_updated'));
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.showSnackbar('warning', this.parseLaravelError(error));
                    } else {
                        this.showSnackbar('error', this.$t('global.error'));
                        this.close();
                    }
                }).finally(() => {
                    this.updating = false;
                })
            },
            getSiteSecurityLevel(site)
            {
                let color = "Undefined";
                let security_level = -1;
                site.silos?.forEach((item) => {
                    if (item.security_level !== null && item.security_level.level > security_level) {
                        security_level = item.security_level.level;
                        color = item.security_level.color;
                    }
                });

                return this.getSecurityLevelImage(color);
            },
            /**
             * Get pictogram for the security level
             *
             * @param securityLevelColor
             * @returns {string}
             */
            getSecurityLevelImage(securityLevelColor) {
                switch (securityLevelColor) {
                    case 'Green':
                        return 'silo_vert.svg';
                    case 'Orange':
                        return 'silo_orange.svg';
                    case 'Red':
                        return 'silo_rouge.svg';
                    case 'Black':
                        return 'silo_noir.svg';
                    default:
                        return 'silo_neutre.svg';
                }
            },

            handleUpdate() {
                this.getSite(this.$route.params.siteId)
            }
        }
    }
</script>

<style lang="scss" scoped>
.container_lock_unlock_gps_coordinate {
    position: absolute;
    z-index: 2;
    top: calc(50% - 36px);
    left: -24px;
}
</style>

<style lang="scss">
    .sub-offset{
        position: relative;
        top: -20px;
    }
    .limit-height{
        z-index: 2;
        max-height: 50px;
    }
    .align-items-center {
        align-items: center;
    }
    .site-module {
        .v-breadcrumbs li:last-child a {
            font-size: 1.25rem !important;
            color: rgba(0, 0, 0, .6) !important;
            font-weight: 500;
        }

        .v-list--dense .v-list-item {
            .v-list-item__title,
            .v-list-item__subtitle {
                font-size: 0.9rem;
            }
        }
    }
</style>
