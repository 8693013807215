

require('./bootstrap');

import Vue from 'vue'
import vuetify from './plugins/vuetify'
import {router} from './plugins/router'
import VueBase64FileUpload from 'vue-base64-file-upload';
import i18n from './plugins/i18n';
import './plugins/http';
import './plugins/auth';
import './plugins/validate';
import './mixins/parse_laravel_errors';
import './plugins/moment'
import './plugins/split'
import './plugins/leaflet'
//import './plugins/echo'
import './plugins/datetime'
import './mixins/replace_path_params';
import store from './store'
import {mapActions, mapGetters} from "vuex";
import firebaseMessaging from './plugins/firebase'
export const bus = new Vue()

Vue.prototype.$messaging = firebaseMessaging

new Vue({
    router,
    vuetify,
    store,
    i18n,
    components: {
        VueBase64FileUpload
    },
    data() {
        return {
            drawer: null,
        }
    },
    methods: {
        ...mapActions({ count_alerts : 'alert/count',  unread_messages_count : 'alert/unread_messages_count'}),
    },
    computed: {
        ...mapGetters({
            number_of_alerts: 'alert/number_of_alerts',
            number_of_site_is_missing_from_company_alerts: 'alert/number_of_site_is_missing_from_company_alerts',
            number_of_duplicate_site_alerts: 'alert/number_of_duplicate_site_alerts',
            number_of_security_alert_silo_alerts: 'alert/number_of_security_alert_silo_alerts',
            number_of_site_update_request_alerts: 'alert/number_of_site_update_request_alerts',
            number_of_gps_alerts: 'alert/number_of_gps_alerts',
            number_of_report_alerts: 'alert/number_of_report_alerts',
            number_of_unread_messages: 'alert/number_of_unread_messages'
        })
    },
    mounted() {
        $('.global-loader').hide();

    },
    created() {
        this.$auth.ready(() => {
            if (this.$auth.check()) {
                this.count_alerts();
                this.unread_messages_count();
            }
        });
    }
}).$mount('#app');
