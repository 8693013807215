var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cartography-module"},[_c('v-row',[_c('v-col',{staticClass:"white"},[_c('v-breadcrumbs',{staticClass:"mt-0 pt-0 pl-4",attrs:{"items":[
                            {
                              text: _vm.$t('navigation.cartography'),
                              disabled: true,
                              href: 'breadcrumbs_tours',
                            },
                            {
                              text: _vm.mode,
                              disabled: true,
                              href: 'breadcrumbs_tours',
                            } ]}})],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{class:{ 'inactive': _vm.mode === 'tracking', secondary: _vm.mode === 'live', 'disable-events': _vm.mode === 'live' },attrs:{"block":""},on:{"click":function($event){return _vm.goTo('live')}}},[_vm._v("\n                "+_vm._s(_vm.$t('cartography.live'))+"\n            ")])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"6"}},[_c('v-btn',{class:{ 'inactive': _vm.mode === 'live', secondary: _vm.mode === 'tracking', 'disable-events': _vm.mode === 'tracking' },attrs:{"block":""},on:{"click":function($event){return _vm.goTo('tracking')}}},[_vm._v("\n                "+_vm._s(_vm.$t('cartography.tracking'))+"\n            ")])],1),_vm._v(" "),(_vm.mode === 'live')?_c('v-col',{attrs:{"cols":"12"}},[_c('trucks-live')],1):_vm._e(),_vm._v(" "),(_vm.mode === 'tracking')?_c('v-col',{attrs:{"cols":"12"}},[_c('tracking-truck')],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }