<template>
    <div>
        <v-data-table
            :no-data-text="$t('global.no_data')"
            :loading-text="$t('global.loading_text')"
            :no-results-text="$t('global.no_results_text')"
            :headers="headers"
            :items="transporters"
            :loading="loading"
            class="elevation-1"
            :items-per-page="10"
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-dialog v-model="dialog" max-width="500px" @input="$validator.reset()">
                      <!--
                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" dark class="mb-2" v-on="on">{{ $t('repository.new_item') }}</v-btn>
                        </template>
                        -->
                        <v-card>
                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>
                            <v-card-text>
                                <v-form>
                                    <v-text-field
                                        :label="$t('repository.code')"
                                        name="code"
                                        prepend-icon="mdi-numeric"
                                        v-validate="'required|max:50'"
                                        type="text"
                                        :error-messages="errors.collect('code')"
                                        data-vv-name="code"
                                        v-model="editedItem.code"
                                    ></v-text-field>
                                    <v-text-field
                                        :label="$t('repository.name')"
                                        name="name"
                                        prepend-icon="mdi-textbox"
                                        v-validate="'required|max:50'"
                                        type="text"
                                        :error-messages="errors.collect('name')"
                                        data-vv-name="name"
                                        v-model="editedItem.name"
                                    ></v-text-field>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    {{ $t('repository.cancel') }}
                                </v-btn>
                                <v-btn @click="save" :disabled="submit_button_is_disabled">
                                    {{ $t('repository.save') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-toolbar>
            </template>
            <template v-slot:item.action="{ item }">
                <v-icon
                    small
                    class="mr-2"
                    :title="$t('repository.update')"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <!-- Retrait à la demande de AVRIL 17/06/2020
               <v-icon
                   small
                   :title="$t('repository.delete')"
                   @click="deleteItem(item)"
               >
                   mdi-delete
               </v-icon>
               -->
            </template>
        </v-data-table>
        <v-dialog v-model="dialogDelete" max-width="290">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('repository.sure') }}</v-card-title>
                <v-card-text>{{ $t('repository.irremediable') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" text @click="dialogDelete = false">
                        {{ $t('repository.cancel') }}
                    </v-btn>
                    <v-btn color="warning darken-1" text @click="deleteTransporter()">
                        {{ $t('repository.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import {TRANSPORTER, TRANSPORTERS} from "../../api";

    export default {
        name: "transporters",
        data() {
            return {
                headers: [
                    {text: this.$t('repository.code'), value: 'code', sortable: true},
                    {text: this.$t('repository.name'), value: 'name', sortable: true},
                    {text: this.$t('repository.actions'), value: 'action', sortable: false},
                ],
                loading: false,
                dialog: false,
                transporters: [],
                editedIndex: -1,
                editedItem: {
                    code: '',
                    name: '',
                },
                defaultItem: {
                    code: '',
                    name: '',
                },
                submit_button_is_disabled: false,
                itemToDelete: {},
                dialogDelete: false,
            }
        },
        mounted() {
            this.getTransporters();
        },
        computed: {
            formTitle () {
                return this.editedIndex === -1 ? this.$t('repository.new_item') : this.$t('repository.edit_item')
            },
        },
        methods: {
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Get transporters
             */
            getTransporters() {
                this.loading = true;

                this.axios.get(TRANSPORTERS).then(success => {
                    this.transporters = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                })
            },
            save() {
                this.submit_button_is_disabled = true;

                this.$validator.validateAll().then((result) => {
                    if (result) {
                        if (this.editedIndex > -1) {
                            this.updateItem(this.editedItem);
                        } else {
                            this.createItem(this.editedItem);
                        }
                    } else {
                        this.submit_button_is_disabled = false;
                    }
                });
            },
            /**
             * Update transporter
             *
             * @param {object}  transporter
             */
            updateItem(transporter) {
                this.axios.patch(
                    this.replacePathParams(TRANSPORTER, {transporter: transporter.id}),
                    transporter
                ).then((success) => {
                    Object.assign(this.transporters[this.editedIndex], success.data);
                    this.showSnackbar('success', this.$t('repository.updated'));
                    this.close()
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.showSnackbar('warning', this.parseLaravelError(error));
                    } else {
                        this.showSnackbar('error', this.$t('global.error'));
                    }
                }).finally(() => {
                    this.submit_button_is_disabled = false;
                })
            },
            /**
             * Show edit dialog
             *
             * @param {object} transporter
             */
            editItem(transporter) {
                this.editedIndex = this.transporters.indexOf(transporter);
                this.editedItem = Object.assign({}, transporter);
                this.dialog = true;
            },
            /**
             * Add transporter
             *
             * @param {object} transporter
             */
            createItem(transporter) {
                this.axios.post(
                    TRANSPORTERS,
                    transporter
                ).then((success) => {
                    this.transporters.push(success.data);
                    this.showSnackbar('success', this.$t('repository.created'));
                    this.close()
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.showSnackbar('warning', this.parseLaravelError(error));
                    } else {
                        this.showSnackbar('error', this.$t('global.error'));
                    }
                }).finally(() => {
                    this.submit_button_is_disabled = false;
                })
            },
            /**
             * Hide add/ edit item modal
             */
            close () {
                this.submit_button_is_disabled = false;
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1
                }, 300)
            },
            /**
             * Show delete modal
             *
             * @param {object} transporter
             */
            deleteItem(transporter) {
                this.itemToDelete = transporter;
                this.dialogDelete = true;
            },
            /**
             * Remove transporter from database
             */
            deleteTransporter() {
                this.axios.delete(
                    this.replacePathParams(TRANSPORTER, {transporter: this.itemToDelete.id})
                ).then(() => {
                    this.transporters.splice(this.transporters.indexOf(this.itemToDelete), 1);
                    this.showSnackbar('success', this.$t('repository.deleted'));
                    this.dialogDelete = false;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
        },
        watch: {
            dialog (val) {
                val || this.close()
            },
        },
    }
</script>
