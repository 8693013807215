<template>
    <div>
        <v-data-table
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
            :headers="headers"
            :items="logistic_areas"
            :items-per-page="10"
            :loading="loading"
            :loading-text="$t('global.loading_text')"
            :no-data-text="$t('global.no_data')"
            :no-results-text="$t('global.no_results_text')"
            class="elevation-1"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-dialog v-model="dialog" max-width="500px" @input="$validator.reset()">
                <!--
                <template v-slot:activator="{ on }">
                    <v-btn color="primary" dark class="mb-2" v-on="on">{{ $t('repository.new_item') }}</v-btn>
                </template>
                -->
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-form>
                      <v-text-field
                          v-model="editedItem.code"
                          v-validate="'required|max:2'"
                          :error-messages="errors.collect('code')"
                          :label="$t('repository.code')"
                          data-vv-name="code"
                          name="code"
                          prepend-icon="mdi-numeric"
                          type="text"
                      ></v-text-field>
                      <v-text-field
                          v-model="editedItem.name"
                          v-validate="'required|max:50'"
                          :error-messages="errors.collect('name')"
                          :label="$t('repository.name')"
                          data-vv-name="name"
                          name="name"
                          prepend-icon="mdi-textbox"
                          type="text"
                      ></v-text-field>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      {{ $t('repository.cancel') }}
                    </v-btn>
                    <v-btn :disabled="submit_button_is_disabled" @click="save">
                      {{ $t('repository.save') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.logistic_manager="{ item }">
            <v-tooltip v-if="item.logistic_manager_name" bottom color="rgba(0,0,0,0.9)">
              <template v-slot:activator="{ on }">
                <v-btn color="secondary" outlined text v-on="on">
                  <v-icon left>mdi-face-woman</v-icon>
                  {{ item.logistic_manager_name }}
                </v-btn>
              </template>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle class="primary--text">
                    {{ $t('tour.logistic_manager_phone') }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="white--text">
                    {{ item.logistic_manager_phone_number }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle class="primary--text">
                    {{ $t('tour.logistic_manager_mobile_phone') }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="white--text">
                    {{ item.logistic_manager_mobile_phone_number }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle class="primary--text">
                    {{ $t('tour.logistic_manager_email') }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="white--text">
                    {{ item.logistic_manager_email }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-tooltip>
            <div v-else>
              <v-btn color="accent" disabled outlined text>
                <v-icon left>mdi-face-woman-outline</v-icon>
                {{ $t('tour.not_assigned') }}
              </v-btn>
            </div>
          </template>
          <template v-slot:item.quality_safety_manager="{ item }">
            <v-tooltip v-if="item.quality_safety_manager_name" bottom color="rgba(0,0,0,0.9)">
              <template v-slot:activator="{ on }">
                <v-btn color="secondary" outlined text v-on="on">
                  <v-icon left>mdi-face-woman</v-icon>
                  {{ item.quality_safety_manager_name }}
                </v-btn>
              </template>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle class="primary--text">
                    {{ $t('tour.quality_safety_manager_phone') }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="white--text">
                    {{ item.quality_safety_manager_phone_number }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle class="primary--text">
                    {{ $t('tour.quality_safety_manager_mobile_phone') }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="white--text">
                    {{ item.quality_safety_manager_mobile_phone_number }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle class="primary--text">
                    {{ $t('tour.quality_safety_manager_email') }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="white--text">
                    {{ item.quality_safety_manager_email }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-tooltip>
            <div v-else>
              <v-btn color="accent" disabled outlined text>
                <v-icon left>mdi-face-woman-outline</v-icon>
                {{ $t('tour.not_assigned') }}
              </v-btn>
            </div>
          </template>
          <template v-slot:item.sandra_manager="{ item }">
            <v-tooltip v-if="item.sandra_manager_name" bottom color="rgba(0,0,0,0.9)">
              <template v-slot:activator="{ on }">
                <v-btn color="secondary" outlined text v-on="on">
                  <v-icon left>mdi-face-woman</v-icon>
                  {{ item.sandra_manager_name }}
                </v-btn>
              </template>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle class="primary--text">
                    {{ $t('tour.sandra_manager_phone') }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="white--text">
                    {{ item.sandra_manager_phone_number }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle class="primary--text">
                    {{ $t('tour.sandra_manager_mobile_phone') }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="white--text">
                    {{ item.sandra_manager_mobile_phone_number }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle class="primary--text">
                    {{ $t('tour.sandra_manager_email') }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="white--text">
                    {{ item.sandra_manager_email }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-tooltip>
            <div v-else>
              <v-btn color="accent" disabled outlined text>
                <v-icon left>mdi-face-woman-outline</v-icon>
                {{ $t('tour.not_assigned') }}
              </v-btn>
            </div>
          </template>
          <template v-slot:item.sales_manager="{ item }">
            <v-tooltip v-if="item.sales_manager_name" bottom color="rgba(0,0,0,0.9)">
              <template v-slot:activator="{ on }">
                <v-btn color="secondary" outlined text v-on="on">
                  <v-icon left>mdi-face-woman</v-icon>
                  {{ item.sales_manager_name }}
                </v-btn>
              </template>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle class="primary--text">
                    {{ $t('tour.sandra_manager_phone') }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="white--text">
                    {{ item.sales_manager_phone }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle class="primary--text">
                    {{ $t('tour.sandra_manager_mobile_phone') }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="white--text">
                    {{ item.sales_manager_mobile }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item two-line>
                <v-list-item-content>
                  <v-list-item-subtitle class="primary--text">
                    {{ $t('tour.sandra_manager_email') }}
                  </v-list-item-subtitle>
                  <v-list-item-title class="white--text">
                    {{ item.sales_manager_email }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-tooltip>
            <div v-else>
              <v-btn color="accent" disabled outlined text>
                <v-icon left>mdi-face-woman-outline</v-icon>
                {{ $t('tour.not_assigned') }}
              </v-btn>
            </div>
          </template>
          <template v-slot:item.action="{ item }">
            <v-icon
                :title="$t('repository.update')"
                class="mr-2"
                small
                @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>
            <!-- Retrait à la demande de AVRIL 17/06/2020
           <v-icon
               small
               :title="$t('repository.delete')"
               @click="deleteItem(item)"
           >
               mdi-delete
           </v-icon>
           -->
          </template>
        </v-data-table>
       <v-dialog v-model="dialogDelete" max-width="290">
           <v-card elevation="0">
               <v-card-title class="headline">{{ $t('repository.sure') }}</v-card-title>
               <v-card-text>{{ $t('repository.irremediable') }}</v-card-text>
               <v-card-actions>
                   <v-spacer></v-spacer>
                   <v-btn color="darken-1" text @click="dialogDelete = false">
                       {{ $t('repository.cancel') }}
                   </v-btn>
                   <v-btn color="warning darken-1" text @click="deleteLogisticArea()">
                       {{ $t('repository.delete') }}
                   </v-btn>
               </v-card-actions>
           </v-card>
       </v-dialog>
   </div>
</template>

<script>
import {AREA, AREAS} from "../../api";

export default {
  name: "logistic-areas",
  data() {
    return {
      headers: [
        {text: this.$t('repository.code'), value: 'code', sortable: true},
        {text: this.$t('repository.name'), value: 'name', sortable: true},
        {text: this.$t('repository.logistic_manager'), value: 'logistic_manager', sortable: false},
        {text: this.$t('repository.quality_safety_manager'), value: 'quality_safety_manager', sortable: false},
        {text: this.$t('repository.sales_manager'), value: 'sales_manager', sortable: false},
        {text: this.$t('repository.sandra_manager'), value: 'sandra_manager', sortable: false},
        {text: this.$t('repository.actions'), value: 'action', sortable: false},
      ],
      loading: false,
      dialog: false,
      logistic_areas: [],
      editedIndex: -1,
      editedItem: {
        database_retention_time: 0,
        message_retention_time: 0,
      },
      defaultItem: {
        database_retention_time: 7,
        message_retention_time: 14,
      },
      submit_button_is_disabled: false,
      itemToDelete: {},
      dialogDelete: false,
    }
  },
  mounted() {
    this.getLogisticAreas();
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('repository.new_item') : this.$t('repository.edit_item')
    },
  },
  methods: {
    /**
     * Reset snackbar
     */
    resetSnackbar() {
      this.snackbar_type = null;
      this.snackbar_text = null;
      this.snackbar = false;
    },
    /**
     * Show snackbar
     *
     * @param {string} type
     * @param {string} text
            */
           showSnackbar(type, text) {
               this.snackbar_type = type;
               this.snackbar_text = text;
               this.snackbar = true;
           },
           /**
            * Get logistic areas
            */
           getLogisticAreas() {
               this.loading = true;

               this.axios.get(AREAS).then(success => {
                   this.logistic_areas = success.data;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               }).finally(() => {
                   this.loading = false;
               })
           },
           save () {
               this.submit_button_is_disabled = true;

               this.$validator.validateAll().then((result) => {
                   if (result) {
                       if (this.editedIndex > -1) {
                           this.updateItem(this.editedItem);
                       } else {
                           this.createItem(this.editedItem);
                       }
                   } else {
                       this.submit_button_is_disabled = false;
                   }
               });
           },
           /**
            * Update logistic area
            *
            * @param {object}  logistic_area
            */
           updateItem(logistic_area) {
               this.axios.patch(
                   this.replacePathParams(AREA, {logistic_area: logistic_area.id}),
                   logistic_area
               ).then((success) => {
                   Object.assign(this.logistic_areas[this.editedIndex], success.data);
                   this.showSnackbar('success', this.$t('repository.updated'));
                   this.close()
               }).catch((error) => {
                   if (error.response.status === 422) {
                       this.showSnackbar('warning', this.parseLaravelError(error));
                   } else {
                       this.showSnackbar('error', this.$t('global.error'));
                   }
               }).finally(() => {
                   this.submit_button_is_disabled = false;
               })
           },
           /**
            * Show edit dialog
            *
            * @param {object} logistic_area
            */
           editItem(logistic_area) {
               this.editedIndex = this.logistic_areas.indexOf(logistic_area);
               this.editedItem = Object.assign({}, logistic_area);
               this.dialog = true;
           },
           /**
            * Add logistic area
            *
            * @param {object} logistic_area
            */
           createItem(logistic_area) {
               this.axios.post(
                   AREAS,
                   logistic_area
               ).then((success) => {
                   this.logistic_areas.push(success.data);
                   this.showSnackbar('success', this.$t('repository.created'));
                   this.close()
               }).catch((error) => {
                   if (error.response.status === 422) {
                       this.showSnackbar('warning', this.parseLaravelError(error));
                   } else {
                       this.showSnackbar('error', this.$t('global.error'));
                   }
               }).finally(() => {
                   this.submit_button_is_disabled = false;
               })
           },
           /**
            * Hide add/ edit item modal
            */
           close () {
               this.submit_button_is_disabled = false;
               this.dialog = false;
               setTimeout(() => {
                   this.editedItem = Object.assign({}, this.defaultItem);
                   this.editedIndex = -1
               }, 300)
           },
           /**
            * Show delete modal
            *
            * @param {object} logistic_area
            */
           deleteItem(logistic_areas) {
               this.itemToDelete = logistic_areas;
               this.dialogDelete = true;
           },
           /**
            * Remove logistic area from database
            */
           deleteLogisticArea() {
               this.axios.delete(
                   this.replacePathParams(AREA, {logistic_area: this.itemToDelete.id})
               ).then(() => {
                   this.logistic_areas.splice(this.logistic_areas.indexOf(this.itemToDelete), 1);
                   this.showSnackbar('success', this.$t('repository.deleted'));
                   this.dialogDelete = false;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               });
           },
       },
       watch: {
           dialog (val) {
               val || this.close()
           },
       },
   };
</script>
