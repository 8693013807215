<template>
    <div>
        <v-data-table
            :headers="headers"
            :items="site.reattached_sites"
            :loading-text="$t('global.loading_text')"
            :no-data-text="$t('global.no_data')"
            :no-results-text="$t('global.no_results_text')"
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
        >
            <template v-slot:top>
                <v-toolbar flat color="white" v-if="site.reattached_sites.length < 1 && site.alert === null">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark class="mb-2" @click="dialogAlert = true">
                        {{ $t('site.create_alert') }}
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | moment('L LT') }}
            </template>
            <template v-slot:item.original_companies="{ item }">
                <span
                    v-for="(company,key) in item.original_companies"
                    v-if="item.original_companies.length > 0"
                >
                    {{ company.code }}<span v-if="key+1 !== item.original_companies.length">,</span>
                </span>
            </template>
            <template v-slot:item.contact="{ item }">
                {{ item.contact_avril_name }}
                <span v-if="item.contact_avril_name && item.contact_avril_telephone">
                ,
                </span>
                {{ item.contact_avril_telephone }}
                <span v-if="(item.contact_avril_name && item.contact_avril_mobile) || (item.contact_avril_telephone && item.contact_avril_mobile)">
                ,
                </span>
                {{ item.contact_avril_mobile }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn
                    v-if="item.original_companies.length"
                    color="secondary"
                    tile
                    :title="$t('alert.detach')"
                    outlined
                    @click="detachSite(item.id)"
                >
                    <v-icon left>mdi-arrow-down</v-icon>
                    {{ $t('alert.detach') }}
                </v-btn>
            </template>
        </v-data-table>

        <v-dialog v-model="dialogAlert" max-width="290">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('site.attention') }}</v-card-title>
                <v-card-text>{{ $t('site.confirmation_action') }}</v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="darken-1" text @click="dialogAlert = false">
                        {{ $t('alert.cancel') }}
                    </v-btn>
                    <v-btn color="warning darken-1" text @click="sendAlert()">
                        {{ $t('alert.yes') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar"
            top
            :color="snackbar_type"
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import {SITE_DETACH, SITE_ALERT, ACTIONS} from "../../api";

    export default {
        name: "reattached",
        props: {
            site: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                headers: [
                    {text: this.$t('alert.site_name'), value: 'name', sortable: false},
                    {text: this.$t('alert.address'), value: 'address_1', sortable: false},
                    {text: this.$t('alert.city'), value: 'city', sortable: false},
                    {text: this.$t('alert.farmer'), value: 'original_companies', sortable: false},
                    {text: this.$t('alert.contact'), value: 'contact', sortable: false},
                    {text: this.$t('alert.created_at'), value: 'created_at', sortable: false},
                    {text: this.$t('alert.actions'), value: 'actions', sortable: false},
                ],
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                dialogAlert: false,
            }
        },
        methods: {
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            async createAction(siteId) {
                this.loading = true;
                let action = {
                    site_id: siteId,
                    type_id: 16, // @todo A change with avril_id
                    details: "Détachement du site",
                };

                await this.axios.post(ACTIONS, action);
                this.loading = false;
            },
            detachSite(siteId) {
                this.axios.patch(
                    this.replacePathParams(SITE_DETACH, {site: siteId})
                ).then(() => {
                    this.createAction(siteId);
                    var deletedSite = this.site.reattached_sites.findIndex(e => e.id === siteId);
                    this.site.reattached_sites.splice(deletedSite, 1);
                    this.showSnackbar('success', this.$t('site.success_detachment'));
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                })
            },
            sendAlert() {
                this.axios.post(
                    this.replacePathParams(SITE_ALERT, {site: this.site.id})
                ).then(() => {
                    this.showSnackbar('success', 'Alerte créée avec succès.');
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.dialogAlert = false;
                })
            }
        },
    }
</script>
