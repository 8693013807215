<template>
    <v-card class="d-inline-block">
        <v-container fluid>
            <v-row
                no-gutters
            >
                <v-col cols="12">
                    <v-row v-if="loading"
                    no-gutters
                    >
                        <v-spacer></v-spacer>
                            <v-progress-circular
                            color="primary"
                            indeterminate
                            ></v-progress-circular>
                        <v-spacer></v-spacer>
                    </v-row>
                    <v-row v-else
                           no-gutters
                   >
                        <v-col>
                            <v-btn
                                :disabled="previousStep === null"
                                :title="$t('tour.previous_step')"
                                icon
                                @click="selectPreviousStep"
                            >
                                <v-icon>mdi-arrow-left</v-icon>
                            </v-btn>
                        </v-col>
                        <v-spacer></v-spacer>
                        <v-col>
                            <v-btn
                                :disabled="nextStep === null"
                                :title="$t('tour.next_step')"
                                class="float-right"
                                icon
                                @click="selectNextStep"
                            >
                                <v-icon>mdi-arrow-right</v-icon>
                            </v-btn>

                        </v-col>
                    </v-row>

                </v-col>
                <v-row class="primary lighten-3 ma-2" >
                <v-col cols="4">
                    <v-list class="primary lighten-3" subheader two-line>
                        <v-subheader class="title">{{ $t('tour.step_information') + ' n°' + currentStepOrder }}</v-subheader>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    {{ $t('tour.expected_date') }}
                                </v-list-item-subtitle>
                                <v-list-item-title v-show="step.type === 'LOADING'">
                                    {{ getText(step.loading_expected_datetime) | moment('L LT') }}
                                </v-list-item-title>
                                <v-list-item-title v-show="step.type === 'DELIVERY'">
                                    {{ getText(step.delivery_expected_datetime) | moment('L LT') }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item  v-show="step.type === 'LOADING'">
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    {{ $t('tour.driver_expected_date') }}
                                </v-list-item-subtitle>
                                <v-list-item-title v-show="step.type === 'LOADING'">
                                    {{ getText(step.loading_expected_driver_datetime) | moment('L LT') }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    {{ $t('tour.driver_dates') }}
                                </v-list-item-subtitle>
                                <v-list-item-title v-show="step.type === 'LOADING'">
                                    {{ $t('tour.entrance') }} {{ getText(step.loading_real_start_datetime) | moment('L LT')  }}
                                </v-list-item-title>
                                <v-list-item-title v-show="step.type === 'LOADING'">
                                    {{ $t('tour.exit') }} {{ getText(step.loading_real_end_datetime) | moment('L LT') }}
                                </v-list-item-title>

                                <v-list-item-title v-show="step.type === 'DELIVERY'">
                                    {{ $t('tour.entrance') }} {{ getText(step.delivery_real_start_datetime) | moment('L LT')  }}
                                </v-list-item-title>
                                <v-list-item-title v-show="step.type === 'DELIVERY'">
                                    {{ $t('tour.exit') }} {{ getText(step.delivery_real_end_datetime) | moment('L LT') }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-subtitle>
                                        {{ $t('tour.effective_dates') }}
                                    </v-list-item-subtitle>
                                    <v-list-item-title v-show="step.type === 'LOADING'">
                                        {{$t('tour.entrance') }}  {{ getText(step.loading_driver_start_datetime) | moment('L LT')  }}
                                    </v-list-item-title>
                                    <v-list-item-title v-show="step.type === 'LOADING'">
                                        {{$t('tour.exit') }}  {{ getText(step.loading_driver_end_datetime) | moment('L LT') }}
                                    </v-list-item-title>

                                    <v-list-item-title v-show="step.type === 'DELIVERY'">
                                        {{$t('tour.entrance') }}  {{ getText(step.delivery_driver_start_datetime) | moment('L LT')  }}
                                    </v-list-item-title>
                                    <v-list-item-title v-show="step.type === 'DELIVERY'">
                                        {{$t('tour.exit') }}  {{getText(step.delivery_driver_end_datetime) | moment('L LT') }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    {{ $t('tour.expected_driver') }}
                                </v-list-item-subtitle>
                                <v-list-item-title v-if="step_selected.driver && step_selected.driver[0]">
                                    {{ step_selected.driver[0].name }}
                                </v-list-item-title>
                                <v-list-item-title v-else>
                                    {{ $t('tour.not_assigned') }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    {{ $t('tour.status') }}
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    {{ formatStatus(step_selected.status) }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    {{ $t('tour.sanitary_context') }}
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    <div v-if="biosecurityExists">
                                        <span>{{ biosecurityText }}</span>
                                        <biosecurity-info :biosecurity="biosecurity"/>
                                    </div>
                                    <span v-else>-</span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-col>
                <v-col cols="4">
                   <v-list class="primary lighten-3" subheader two-line>
                       <v-subheader class="title">{{ $t('tour.cr_step') }}</v-subheader>
                       <v-list-item>
                           <v-list-item-content>
                               <v-list-item-subtitle>
                                   {{ $t('tour.cr_date') }}
                               </v-list-item-subtitle>
                               <v-list-item-title v-show="step.type === 'LOADING'">
                                   {{ getText(step.loading_validated_at) | moment('L LT') }}
                               </v-list-item-title>
                               <v-list-item-title v-show="step.type === 'DELIVERY'">
                                   {{ getText(step.delivery_validated_at) | moment('L LT') }}
                               </v-list-item-title>
                           </v-list-item-content>
                       </v-list-item>
                       <v-list-item>
                           <v-list-item-content>
                               <v-list-item-subtitle>
                                   {{ $t('tour.cr_approving_driver') }}
                               </v-list-item-subtitle>
                               <v-list-item-title>
                                  <span v-if="step_selected.user">  {{ getText(step_selected.user.name) }} </span>
                                  <span v-else>{{ $t('tour.empty') }} </span>
                               </v-list-item-title>
                           </v-list-item-content>
                       </v-list-item>
                       <v-list-item>
                           <v-list-item-content>
                               <v-list-item-subtitle>
                                   {{ $t('tour.cr_status') }}
                               </v-list-item-subtitle>
                               <v-list-item-title>
                                   {{ getCrDetail(step_selected)}}
                               </v-list-item-title>
                           </v-list-item-content>
                       </v-list-item>
                       <v-list-item>
                           <v-list-item-content>
                               <v-list-item-subtitle>
                                   {{ $t('tour.step_comment') }}
                               </v-list-item-subtitle>
                               <v-list-item-title v-show="step.type === 'LOADING'">
                                   {{ getText(step.loading_comments) }}
                               </v-list-item-title>
                               <v-list-item-title v-show="step.type === 'DELIVERY'">
                                   {{ getText(step.delivery_comments) }}
                               </v-list-item-title>
                           </v-list-item-content>
                       </v-list-item>

                       <v-list-item>
                           <v-list-item-content>
                               <v-list-item-subtitle>
                                   {{ $t('tour.cleaning_inside') }}
                               </v-list-item-subtitle>
                               <v-list-item-title v-if="step.type === 'LOADING'">
                                   {{ getCleaningInside(step.loading_cleaning_inside) }}
                               </v-list-item-title>
                               <v-list-item-title v-if="step.type === 'LOADING'">
                                   {{ step.loading_cleaning_inside_comment }}
                               </v-list-item-title>
                               <v-list-item-title v-if="step.type === 'DELIVERY'">
                                   {{ getCleaningInside(step.delivery_cleaning_inside) }}
                               </v-list-item-title>
                               <v-list-item-title v-if="step.type === 'DELIVERY'">
                                   {{ step.delivery_cleaning_inside_comment }}
                               </v-list-item-title>
                           </v-list-item-content>
                       </v-list-item>

                       <v-list-item>
                           <v-list-item-content>
                               <v-list-item-subtitle>
                                   {{ $t('tour.cleaning_outside') }}
                               </v-list-item-subtitle>
                               <v-list-item-title v-if="step.type === 'LOADING'">
                                   {{ getCleaningOutside(step.loading_cleaning_outside) }}
                               </v-list-item-title>
                               <v-list-item-title v-if="step.type === 'LOADING'">
                                   {{ step.loading_cleaning_outside_comment }}
                               </v-list-item-title>
                               <v-list-item-title v-if="step.type === 'DELIVERY'">
                                   {{ getCleaningOutside(step.delivery_cleaning_outside) }}
                               </v-list-item-title>
                               <v-list-item-title v-if="step.type === 'DELIVERY'">
                                   {{ step.delivery_cleaning_outside_comment }}
                               </v-list-item-title>
                           </v-list-item-content>
                       </v-list-item>
                       <v-list-item>
                           <v-list-item-content>
                               <v-list-item-subtitle>
                                   {{ $t('tour.signature') }}
                               </v-list-item-subtitle>
                               <v-list-item-title v-if="step.loading_signature && step.type === 'LOADING'">
                                   <span>
                                       {{ getText(step.loading_signature.name) }}
                                   </span>
                                   <br />
                                   <img
                                       :alt="$t('repository.pictogram')"
                                       :src="`data:image/png;base64, ${step.loading_signature.signature}`"
                                       height="64"
                                   >
                               </v-list-item-title>
                               <v-list-item-title v-if="step.delivery_signature && step.type === 'DELIVERY'">
                                   <span>
                                       {{ getText(step.delivery_signature.name) }}
                                   </span>
                                   <br />
                                   <img
                                       :alt="$t('repository.pictogram')"
                                       :src="`data:image/png;base64, ${step.delivery_signature.signature}`"
                                       height="64"
                                   >
                               </v-list-item-title>
                           </v-list-item-content>
                       </v-list-item>
                       <v-list-item>
                           <v-list-item-content>
                               <v-list-item-subtitle>
                                   {{ $t('tour.driver_comment') }}
                               </v-list-item-subtitle>
                               <v-list-item-title>
                                   -
                               </v-list-item-title>
                           </v-list-item-content>
                       </v-list-item>
                       <v-list-item>
                           <v-list-item-content>
                               <v-list-item-subtitle>
                                   {{ $t('tour.tour_comment') }}
                               </v-list-item-subtitle>
                               <v-list-item-title v-if="step.tour">
                                   {{ getText(step.tour.comments) }}
                               </v-list-item-title>
                           </v-list-item-content>
                       </v-list-item>

                      </v-list>
                   </v-col>

                <v-divider
                class="mx-4"
                inset
                vertical
                ></v-divider>
            <v-col col="4">
                    <v-list class="primary lighten-3" subheader  two-line>
                        <v-subheader class="title">{{ $t('tour.site_information') }}</v-subheader>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    {{ $t('tour.name') }}
                                </v-list-item-subtitle>
                                <v-list-item-title v-if="site.name">
                                    {{ site.name }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    {{ $t('tour.address') }}
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    {{ site.address_1 }}<br>
                                    {{ site.zip }}, {{ site.city }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    {{ $t('tour.type') }}
                                </v-list-item-subtitle>
                                <v-list-item-title v-if="site.companies">
                                        <span v-if="site.companies[0].code.length > 2">
                                            {{ $t('site.breeding') }}
                                        </span>
                                    <span v-else>
                                            {{ $t('site.factory') }}
                                        </span>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    {{ $t('tour.contacts') }}
                                </v-list-item-subtitle>
                                <v-list-item-title
                                    v-for="(contact,key) in contactsStepSelected"
                                    :key="contact.id"
                                >
                                                <span>
                                                    <span v-if="contact.name">
                                                        {{ contact.name }}
                                                    </span>
                                                    <img
                                                        v-if="contactsStepSelected.category"
                                                        :alt="$t('repository.pictogram')"
                                                        :src="`storage/${contactsStepSelected.category.media[0].id}/${contactsStepSelected.category.media[0].name}`"
                                                        height="32"
                                                    >
                                                    <span v-if="contact.phone">
                                                        <br>
                                                        {{ contact.phone }}
                                                    </span>
                                                    <span v-if="contact.mobile">
                                                        <br>
                                                        {{ contact.mobile }}
                                                    </span>
                                                </span>
                                    <v-divider v-show="key+1 !== contactsStepSelected.length"
                                               class="mt-2"></v-divider>
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item v-if="step.site">
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    {{ $t('tour.comment') }}
                                </v-list-item-subtitle>
                                <v-list-item-title>
                                    {{ step.site.comments }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-subtitle>
                                    {{ $t('tour.security_level') }}
                                </v-list-item-subtitle>
                                <v-list-item-title v-show="step.site">
                                    <img :src="'images/' + getSiteSecurityLevel(site)" alt="">
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list>

                    <v-row v-if="$auth.check('web') || $auth.check('admin')">
                        <v-btn
                            class="ml-6 mt-3"
                            tile
                            @click="goToSite(site.id)"
                        >
                            <v-icon
                                class="mr-2"
                                small
                            >
                                mdi-eye
                            </v-icon>
                            {{ $t('tour.see_site') }}
                        </v-btn>
                    </v-row>

                </v-col>

            </v-row>
                <v-col cols="12">
                    <v-list subheader two-line>
                        <v-subheader class="title">{{ $t('tour.order_line_information') }}</v-subheader>
                        <v-data-table
                            v-if="step.order_lines"
                            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
                            :headers="orderLinesHeader"
                            :items="step.order_lines"
                            :items-per-page="5"
                            class="elevation-1 ml-3"
                            group-by="order.order_id"
                        >
                            <template v-slot:group.header="{items, isOpen, toggle}">
                                <th colspan="100%">
                                    <v-icon @click="toggle"
                                    >{{ isOpen ? 'mdi-minus' : 'mdi-plus' }}
                                    </v-icon>
                                    <span v-if="items[0].order">
                                        {{ items[0].order.order_id}}
                                    </span>
                                </th>
                            </template>

                            <template v-slot:item.order="{ item }">
                                <span v-if="item.order">
                                    {{ item.order.order_id}} -
                                </span>
                                <span>
                                    {{item.line_id}}
                                </span>
                            </template>

                            <template v-slot:item.silos="{ item }">
                                <v-row v-for="silo in item.silos" :key="silo.id" no-gutters>
                                    {{ silo.code }}
                                </v-row>
                            </template>

                            <template v-slot:item.equipment="{ item }">
                                <v-row no-gutters>
                                    <span v-if="item.equipment">
                                        {{ item.equipment.name }}
                                    </span>
                                </v-row>
                            </template>
                            <template v-slot:item.vehicle_access="{ item }">
                                <v-row no-gutters>
                                    <span v-if="item.vehicle_access">
                                        {{ item.vehicle_access.name }}
                                    </span>
                                </v-row>
                            </template>
                            <template v-slot:item.product="{ item }">
                                {{ item.product_code + ' - ' + item.product_name + ' - ' + item.presentation_id }}
                            </template>
                        </v-data-table>
                    </v-list>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import {COMPANY, SITE, STEP, STEP_BIOSECURITY} from "../../api";
import BiosecurityInfo from "../Biosecurity/BiosecurityInfo.vue";

const
        STATUS = {
            0: {color: 'secondary', name: 'not_started'},
            1: {color: 'accent', name: 'started'},
            2: {color: 'success', name: 'terminate'},
            3: {color: 'error', name: 'not_ras'}
        }
    ;

    export default {
        name: 'detail',
        components: {BiosecurityInfo},
        props: {
            step_selected: {
                type: Object,
                required: true
            },
            steps_selected: {
                type: Array,
                required: true
            },
            driver_name: {
                type: String,
                required: false
            },
            site_selected:{
                type:Object,
                required:false
            },
            inside_cleanings:{
                type: Array,
                required:false
            },
            outside_cleanings:{
                type: Array,
                required:false
            }
        },
        data() {
            return {
                orderLinesHeader: [
                    {text: this.$t('order_line.order_id'), value: 'order', sortable: false},
                    {text: this.$t('order_line.product'), value: 'product', sortable: false},
                    {text: this.$t('order_line.supplementation'), value: 'product_supplementation_code', sortable: false},
                    {text: this.$t('order_line.silos'), value: 'silos', sortable: false},
                    {text: this.$t('order_line.product_comment'), value: 'product_comments', sortable: false},
                    {text: this.$t('order_line.loading_site'), value: 'loading_company.code', sortable: false},
                    {text: this.$t('order_line.product_quantity_to_load'), value: 'product_ordered_quantity', sortable: false},
                    {text: this.$t('order_line.product_loaded_quantity'), value: 'product_loaded_quantity', sortable: false},
                    {text: this.$t('order_line.packaging'), value: 'product_packing_id', sortable: false},
                    {text: this.$t('order_line.equipment'), value: 'equipment', sortable: false},
                    {text: this.$t('order_line.vehicle_access'), value: 'vehicle_access', sortable: false},
                    {text: this.$t('order_line.purge'), value: 'purge', sortable: false},
                    {text: this.$t('order_line.casing'), value: 'casing', sortable: false},
                ],
                step: {site: { silos :[], company: {contact: {}}}},
                site : {},
                loading: false,
                direction: null,
                biosecurity: null
            }
        },
        async mounted() {
            await this.getBiosecurity(this.step_selected.id)
            await this.getStep(this.step_selected.id)
            this.site = this.getSite(this.site_selected.id);
            this.step.site = this.site
        },
        watch: {
             step_selected() {
                this.step = this.step_selected
            }
        },
        computed: {
            currentStepOrder(){
                if (this.steps_selected){
                    return _.findIndex(this.steps_selected, (o) => {
                        return o.id === this.step.id;
                    }) + 1;
                }
                return null
            },
            /**
             * Flat contacts and remove duplicate
             *
             * @returns {Array}
             */
            contactsStepSelected() {
                let contacts = [{}];
                return _.uniqBy(this.site.contacts, 'name');
            },
            /**
             *
             */
            previousStep() {
                if (this.currentStepOrder){
                    return this.steps_selected[this.currentStepOrder - 2]??null;
                }
            },
            /**
             *
             */
            nextStep() {
                if (this.currentStepOrder) {
                    return this.steps_selected[this.currentStepOrder] ?? null;
                }
            },
            biosecurityText(){
                return (this.biosecurity.code ?? '/') + ' - ' + (this.biosecurity.name ?? '/')
            },
            biosecurityExists(){
                return !!(!!this.biosecurity && this.biosecurity?.id);
            }
        },
        methods: {
            /**
             * Format status
             *
             * @param value
             * @returns {TranslateResult|VueI18n.TranslateResult}
             */
            formatStatus(value) {
                return (value === undefined) ? this.$t('tour.unknown') : this.$t('tour.' + STATUS[value].name);
            },
            /**
             *
             */
            async selectPreviousStep() {
                this.loading = true
                this.direction = "previous"
                await this.getBiosecurity(this.previousStep.id)
                await this.getStep(this.previousStep.id);
                await this.getStepDetails(this.step)
                this.loading = false
            },
            /**
             *
             */
            async selectNextStep() {
                this.loading = true
                this.direction = "next"
                await this.getBiosecurity(this.nextStep.id)
                await this.getStep(this.nextStep.id);
                await this.getStepDetails(this.step)
                this.loading = false
            },

            getText(texte) {
               if ( texte === null || texte === '') {
                   return ' - ';
               }

               return texte;
            },

            /**
             * Method used to format a list of inside cleanings id into a list of inside cleaning names
             *
             * @param list
             * @returns {string}
             */
            getCleaningInside(list){

                if(!!list) {
                    let cleanings = list.split(";");
                    let valid_cleanings = [];

                    cleanings.forEach(cleaning => {
                        if(this.cleaningExists(cleaning, this.inside_cleanings)) valid_cleanings.push(cleaning);
                    })

                    const formatted_cleanings = valid_cleanings.map(cleaning => this.getCleaningById(cleaning, this.inside_cleanings)?.name);

                    return formatted_cleanings.join('; ')
                }

                return '-'
            },
            /**
             * Method used to format a list of outside cleanings id into a list of outside cleaning names
             *
             * @param list
             * @returns {string}
             */
            getCleaningOutside(list){

                if(!!list) {
                    let cleanings = list.split(";");
                    let valid_cleanings = [];

                    cleanings.forEach(cleaning => {
                        if(this.cleaningExists(cleaning, this.outside_cleanings)) valid_cleanings.push(cleaning);
                    })

                    const formatted_cleanings = valid_cleanings.map(cleaning => this.getCleaningById(cleaning, this.outside_cleanings)?.name);

                    return formatted_cleanings.join('; ')
                }

                return '-'
            },

            /**
             * Method used to get a cleaning with is id
             *
             * @param id
             * @param items
             * @returns {{}}
             */
            getCleaningById(id, items){
                return items.find(item => item.id === parseInt(id));
            },

            /**
             * Method used to check if a cleaning exists
             *
             * @param id
             * @param items
             * @returns {boolean}
             */
            cleaningExists(id, items){
                let item = items.find(item => item.id === parseInt(id));
                return !!item;
            },

            /**
             * Get the detail of the "CR"
             *
             * @param step
             * @returns {*}
             */
            getCrDetail(step) {
                if(step.type === 'DELIVERY' && step.delivery_report_state){

                    if(step.delivery_report_state.name != null) {
                        return step.delivery_report_state.name;
                    }

                }

                if(step.type === 'LOADING' && step.loading_report_state){

                    if(step.loading_report_state.name != null) {
                        return step.loading_report_state.name;
                    }

                }
                return this.$t('tour.empty');
            },

            /**
             * Go to site specified
             *
             * @param {integer} siteId
             */
            goToSite(siteId) {
                let routeData = this.$router.resolve({name: 'site', params: {siteId}});
                window.open(routeData.href, '_blank');
            },
            /**
             * Get Silo's security level
             *
             * @param site
             * @returns {string}
             */
            getSiteSecurityLevel(site)
            {
                var color = "Undefined";
                var security_level = -1;

                if(site.silos){
                    site.silos.forEach((item) => {
                        if (item.security_level !== null && item.security_level.level > security_level) {
                            security_level = item.security_level.level;
                            color = item.security_level.color;
                        }
                    });
                }

                return this.getSecurityLevelImage(color);
            },
            /**
             * Get pictogram for the security level
             *
             * @param securityLevelColor
             * @returns {string}
             */
            getSecurityLevelImage(securityLevelColor) {
                switch (securityLevelColor) {
                    case 'Green':
                        return 'silo_vert.svg';
                    case 'Orange':
                        return 'silo_orange.svg';
                    case 'Red':
                        return 'silo_rouge.svg';
                    case 'Black':
                        return 'silo_noir.svg';
                    default:
                        return 'silo_neutre.svg';
                }
            },

            /**
             * Get site
             */
            async getSite(site) {
                await this.axios.get(
                    this.replacePathParams(SITE, {site})
                ).then((success) => {
                    this.site = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                })
            },

            async getStep(step){
                try {
                    let result  = await this.axios.get(this.replacePathParams(STEP,{step}))
                    this.step = result.data
                } catch (e){
                    this.showSnackbar('error', this.$t('global.error'));
                }
            },
            async getStepDetails(step){
                let company_id  = step.type ==="LOADING" ? step.order_lines[0].loading_company_id : step.order_lines[0].delivery_company_id
                try {
                    let result =  await this.axios.get(this.replacePathParams(COMPANY, {company:company_id }))
                    this.site = this.step.site = result.data.site
                }catch (e) {

                }
            },
            async getBiosecurity(step){
                try {
                    let response  = await this.axios.get(this.replacePathParams(STEP_BIOSECURITY,{step}))
                    this.biosecurity = response.data
                } catch (e){
                    this.showSnackbar('error', this.$t('global.error'));
                }
            },
        }
    }
</script>
