<template>
    <div class="login">
        <v-row

            align="center"
            class="login-row"
            justify="center"
        >
            <v-col
                cols="12"
                md="4"
                sm="8"
            >
                <v-card
                    align-self="center"
                    class="elevation-12"
                >
                    <v-toolbar
                        color="primary"
                        dark
                        flat
                    >
                        <v-toolbar-title>{{ $t('login.title')}}</v-toolbar-title>
                        <div class="flex-grow-1"></div>
                    </v-toolbar>
                    <v-card-text>
                        <v-form @keyup.native.enter="validateBeforeSubmit">
                            <v-text-field
                                v-model="email"
                                v-validate="'required|email'"
                                :error-messages="errors.collect('email')"
                                :label="$t('login.email')"
                                data-vv-name="email"
                                name="email"
                                prepend-icon="mdi-email"
                                type="text"
                            ></v-text-field>
                            <v-text-field
                                id="password"
                                v-model="password"
                                v-validate="'required|min:2'"
                                :error-messages="errors.collect('password')"
                                :label="$t('login.password')"
                                data-vv-name="password"
                                name="password"
                                prepend-icon="mdi-lock"
                                type="password"
                            ></v-text-field>
                            <v-checkbox
                                v-model="remember_me"
                                :label="$t('login.remember_me')"
                                required
                            ></v-checkbox>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <div class="flex-grow-1"></div>
                        <v-btn :disabled="submit_button_is_disabled"
                               color="primary"
                               @click.prevent="validateBeforeSubmit"
                        >
                            {{ $t('login.title') }}
                        </v-btn>
                    </v-card-actions>
                    <v-card-actions style="border-bottom: 1px solid #ddd">
                        <div class="flex-grow-1"></div>
                        <reset-password
                            :email_retrieve="email"
                        >
                        </reset-password>
                    </v-card-actions>
                    <v-img aspect-ratio="1.7" class="mx-auto" contain src="images/avril_logo.jpg" width="150"></v-img>
                </v-card>
            </v-col>

        </v-row>

        <v-snackbar
            v-model="snackbar"
            :color="snackbar_type"
            top
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<style lang="scss" scoped>
    .login {
        position: fixed;

        z-index: 9999;

        width: 100%;
        height: 100%;

        top: 0;
        left: 0;

        margin: 0;
        padding: 0;
        background: url(/images/background_login.jpg) no-repeat center fixed;
        -webkit-background-size: cover;
        background-size: cover;

        .login-row {
            height: 100%;

            background: hsla(360, 0%, 0%, 0.4);
        }
    }
</style>

<script>
import ResetPassword from "../components/ResetPassword";
import {mapActions} from "vuex";
import {MAIN} from "../api";

export default {
    name: "login",
    components: {ResetPassword},
    data() {
        return {
            email: null,
            password: null,
            remember_me: false,
            submit_button_is_disabled: false,
            snackbar: false,
            snackbar_text: '',
            snackbar_type: null,
        }
    },
    methods: {
        ...mapActions(['alert/count']),
        /**
         * Validate data before call login method
         */
        validateBeforeSubmit() {
            this.resetSnackbar();

            this.submit_button_is_disabled = true;
            this.$validator.validateAll().then((result) => {
                if (result) {
                    this.login({email: this.email, password: this.password});
                } else {
                    this.submit_button_is_disabled = false;
                }
            });
        },
        /**
         * Login user with credentials
         *
         * @param {object} credential
         */
        login(credential) {
            let snackbar_type = '';
            let snackbar_text = '';

            this.$auth.login({
                    data: credential,
                    rememberMe: this.remember_me,
                    error: (error) => {
                        if (error.response.status === 401) {
                            snackbar_type = 'warning';
                            snackbar_text = this.$t('auth.failed');
                        } else {
                            snackbar_type = 'error';
                            snackbar_text = this.$t('global.error');
                        }

                        this.showSnackbar(snackbar_type, snackbar_text);
                        this.submit_button_is_disabled = false;
                    },
                    success: () => {
                        this['alert/count']()
                        if (this.$auth.check('transporter')
                            && !this.$auth.check('web')
                            && !this.$auth.check('admin')
                        ) {
                            this.$router.push('/tour')
                        } else {
                            this.$router.push(MAIN)
                        }
                    }
                });
            },
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            }
        }
    }
</script>
