var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{ref:"databaseContact",attrs:{"no-data-text":_vm.$t('global.no_data'),"loading-text":_vm.$t('global.loading_text'),"no-results-text":_vm.$t('global.no_results_text'),"headers":_vm.headers,"items":_vm.contacts,"footer-props":{
                    'items-per-page-text': _vm.$t('global.rows_per_page')
                }},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
                var item = ref.item;
return [(item.category.media[0])?_c('span',[_c('img',{attrs:{"height":"32","src":("storage/" + (item.category.media[0].id) + "/" + (item.category.media[0].name)),"alt":_vm.$t('repository.pictogram')}})]):_c('span',[_vm._v("\n                "+_vm._s(item.category.code)+"\n            ")])]}},{key:"item.mail",fn:function(ref){
                var item = ref.item;
return [(item.mail)?_c('span',[_vm._v("\n                "+_vm._s(item.mail||_vm.$t('site.no_data'))+"\n                "),_c('v-btn',{attrs:{"icon":"","color":"primary","href":'mailto:'+ item.mail}},[_c('v-icon',[_vm._v("mdi-email")])],1)],1):_vm._e()]}},{key:"item.company.code",fn:function(ref){
                var item = ref.item;
return [(item.company)?_c('span',[_vm._v("\n                "+_vm._s(item.company.code||_vm.$t('site.no_data'))+"\n            ")]):_c('span',[_vm._v("\n                "+_vm._s(_vm.$t('site.no_data'))+"\n            ")])]}}])}),_vm._v(" "),_c('v-snackbar',{attrs:{"top":"","color":_vm.snackbar_type},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n        "+_vm._s(_vm.snackbar_text)+"\n        "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("\n            Close\n        ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }