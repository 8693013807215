<template>
    <div>
        <v-stepper v-model="step">
            <v-stepper-header>
                <v-stepper-step editable :complete="step > 1" step="1">
                    {{ $t('cartography.truck_choice') }}
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step :complete="step > 2" step="2">
                    {{ $t('cartography.visualization')}}
                </v-stepper-step>
            </v-stepper-header>

            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-row>
                        <v-col cols="12">
                            <v-autocomplete
                                id="trucks"
                                name="trucks"
                                v-model="chosen_truck"
                                data-vv-name="trucks"
                                item-text="code"
                                item-value="id"
                                prepend-icon="mdi-tanker-truck"
                                v-validate="'required'"
                                :items="trucks"
                                :label="$t('driver.trucks')"
                                :error-messages="errors.collect('trucks')"
                                :disabled="trucks.length <= 0"
                                :filter="filterTrucks"
                                :search-input.sync="searchTrucksInput"
                            >
                            </v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                            <v-date-picker
                                v-model="picker"
                                full-width
                                show-current
                                :locale="lang"
                                color="primary"
                                header-color="secondary"
                                :landscape="$vuetify.breakpoint.smAndUp"
                            ></v-date-picker>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-btn
                                color="primary"
                                @click="step = 2"
                                :disabled="!chosen_truck"
                            >
                                {{ $t('cartography.continue') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-stepper-content>

                <v-stepper-content step="2">
                    <v-col>
                        <div class="text-center" v-if="truck && !loading">
                            <span class="title">
                                {{ truck.code }} - {{ picker | moment('L') }}
                            </span>
                        </div>
                    </v-col>
                    <l-map
                        :options="map.options"
                        style="height: 600px;z-index:2"
                        ref="map"
                        @ready="refreshMap()"
                    >
                        <l-tile-layer
                            :url="map.url"
                            :attribution="map.attribution"
                        />
                        <l-feature-group ref="feature_group">
                            <l-marker-cluster>
                                <l-marker v-for="( marker, count) in step_markers"
                                          :lat-lng="marker.latLng"
                                          :key="marker.id"
                                          :icon="marker.state_icon"
                                >
                                    <l-popup>
                                        <b>N°étape : {{ marker.step_number }}</b><br>
                                        {{ $t('cartography.site_name') }} : {{ marker.site }}<br>
                                        {{ $t('cartography.address') }} : {{ marker.address }}<br>
                                        {{ $t('cartography.expected_date') }} : {{ marker.expected_date | moment('L LT') }}<br>
                                        {{ $t('cartography.started_date') }} : {{ marker.started_date | moment('L LT') }}
                                        <br>
                                        {{ $t('cartography.ended_date') }} : {{ marker.ended_date | moment('L LT') }}<br>
                                        {{ $t('cartography.driver') }} : {{ marker.driver }}
                                    </l-popup>
                                </l-marker>
                            </l-marker-cluster>
                        </l-feature-group>
                            <l-marker v-for="marker  in truck_markers"
                                      :key="'m' + marker.id"
                                      :lat-lng="marker.latLng"
                                      :icon="map.point_icon"
                            >
                                <l-popup>
                                    {{ $t('cartography.transition_date') }} : {{ marker.transition_date | moment('L LT') }}
                                </l-popup>
                            </l-marker>

                        <l-marker v-if="truck_markers.length > 0"
                                  :key="'mt' + truck_markers[0].id"
                                  :lat-lng="truck_markers[0].latLng"
                                  :icon="map.truck_icon"
                        >
                            <l-popup>
                                {{ $t('cartography.transition_date') }} : {{ truck_markers[0].transition_date | moment('L LT') }}
                            </l-popup>
                        </l-marker>
                        <l-polyline
                            :lat-lngs="truck_polyline"
                            :color="'#2f4858'"
                        >
                            <l-popup content="polyline"/>
                        </l-polyline>
                        <div class="local-loader" v-if="loading">
                            <div class="loader"></div>
                        </div>
                    </l-map>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>

        <v-snackbar
            v-model="snackbar"
            top
            :color="snackbar_type"
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import {LFeatureGroup, LMap, LPolyline, LPopup, LTileLayer} from "vue2-leaflet";
    import Vue2LeafletMarkercluster from 'vue2-leaflet-markercluster'
    import {AREA_TRUCKS, TRACKING_TRUCK, TRUCK_STEPS} from "../../api";
    import {icon, latLng, latLngBounds} from "leaflet";
    import _ from "lodash";

    require('leaflet-bing-layer');
    require('@runette/leaflet-fullscreen');

    export default {
        name: "tracking-truck",
        data() {
            return {
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                map: {
                    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
                    truck_icon: icon({
                        iconUrl: '../images/truck-icon.svg',
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                        popupAnchor: [1, -34],
                        tooltipAnchor: [16, -28],
                        shadowSize: [41, 41]
                    }),
                    point_icon: icon({
                        iconUrl: '../images/point-icon.svg',
                        iconSize: [6, 10],
                        iconAnchor: [6, 10],
                        popupAnchor: [1, -34],
                        tooltipAnchor: [16, -28],
                        shadowSize: [10, 10]
                    }),
                    step_delivery_icon_terminated_ntr: icon({
                        iconUrl: '../images/step-green-icon-L.svg',
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                        popupAnchor: [1, -34],
                        tooltipAnchor: [16, -28],
                        shadowSize: [41, 41]
                    }),
                    step_delivery_icon_terminated_not_ntr: icon({
                        iconUrl: '../images/step-red-icon-L.svg',
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                        popupAnchor: [1, -34],
                        tooltipAnchor: [16, -28],
                        shadowSize: [41, 41]
                    }),
                    step_delivery_icon_not_started: icon({
                        iconUrl: '../images/step-grey-icon-L.svg',
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                        popupAnchor: [1, -34],
                        tooltipAnchor: [16, -28],
                        shadowSize: [41, 41]
                    }),
                    step_loading_icon_terminated_ntr: icon({
                        iconUrl: '../images/step-green-icon-C.svg',
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                        popupAnchor: [1, -34],
                        tooltipAnchor: [16, -28],
                        shadowSize: [41, 41]
                    }),
                    step_loading_icon_terminated_not_ntr: icon({
                        iconUrl: '../images/step-red-icon-C.svg',
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                        popupAnchor: [1, -34],
                        tooltipAnchor: [16, -28],
                        shadowSize: [41, 41]
                    }),
                    step_loading_icon_not_started: icon({
                        iconUrl: '../images/step-grey-icon-C.svg',
                        iconSize: [25, 41],
                        iconAnchor: [12, 41],
                        popupAnchor: [1, -34],
                        tooltipAnchor: [16, -28],
                        shadowSize: [41, 41]
                    }),
                    attribution: 'Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
                    options: {
                      fullscreenControl: true
                    },
                },
                step: 1,
                trucks: [],
                chosen_truck: null,
                searchTrucksInput: '',
                picker: new Date().toISOString().substr(0, 10),
                lang,
                steps: [],
                tracking: [],
                truck: {},
                loading: false
            }
        },
        components: {LFeatureGroup, LMap, LTileLayer, LPopup, LPolyline, LMarkerCluster: Vue2LeafletMarkercluster},
        async mounted() {
            let osm = L.tileLayer(this.map.url, {
                    attribution: this.map.attribution
                }),
                bing = L.tileLayer.bing(process.env.MIX_BING_API_KEY)
            ;

            let baseMaps = {
                "Plan": osm,
                "Satellite": bing
            };
            L.control.layers(baseMaps, null, {position: 'topright'}).addTo(this.$refs.map.mapObject);
            await this.getTrucksFromLogisticArea();
        },
        methods: {
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Get trucks from auth user
             */
            async getTrucksFromLogisticArea() {
                await this.axios.get(AREA_TRUCKS).then((success) => {
                    this.trucks = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                })
            },
            /**
             * Get trucks from auth user
             */
            async getSteps() {
                this.loading = true;
                await this.axios.get(
                    this.replacePathParams(TRUCK_STEPS, {truck: this.chosen_truck}),
                    {
                        params: {
                            delivery_expected_date: this.picker,
                            loading_expected_date: this.picker
                        }
                    }
                ).then((success) => {
                    this.steps = success.data.steps;
                    this.truck = success.data.truck;

                    this.getTracking();
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                })
            },
            /**
             * Get tracking from truck
             */
            getTracking() {
                this.axios.get(
                    this.replacePathParams(TRACKING_TRUCK, {truck: this.chosen_truck}),
                    {
                        params: {
                            transition_date: this.picker,
                        }
                    }
                ).then((success) => {
                    this.tracking = success.data;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Filter trucks drop-down
             */
            filterTrucks(item, queryText) {
                let text = "";
                let searchText = "";

                if (item.code) {
                    text = item.code.toLowerCase();
                    searchText = queryText.toLowerCase();
                }

                return text.indexOf(searchText) > -1;
            },
            refreshMap() {
                setTimeout(() => {
                    this.$refs.map.mapObject.invalidateSize();
                }, 0);
            },
            refreshBounds() {
                setTimeout(() => {
                    let bounds = this.computeBounds();
                    this.$refs.map.mapObject.fitBounds(bounds)
                }, 0);
            },
            computeBounds() {
                const bounds = this.$refs.feature_group.mapObject.getBounds()
                if (!!bounds && bounds.isValid()) return bounds
                return [[50.65294336725709, -4.21875000000000], [42.261049162113856, 7.55859375000000]]
            }
        },
        computed: {
            step_markers() {
                let steps = [];
                let latLngSteps = [];

                _.each(this.steps, (step) => {
                    let state_icon, started_date, ended_date, expected_date, step_number;
                    step_number = step.step_order_number

                    if (step.type === 'DELIVERY') {
                        started_date = step.delivery_start_real_datetime ?? step.delivery_driver_start_datetime;
                        ended_date = step.delivery_end_real_datetime ?? step.delivery_driver_end_datetime;
                        expected_date = step.delivery_expected_datetime
                        if (step.delivery_report_state_id) {
                            state_icon = step.delivery_report_state.id === 1 ? 'step_delivery_icon_terminated_ntr' : 'step_delivery_icon_terminated_not_ntr'
                        } else {
                            state_icon = 'step_delivery_icon_not_started'
                        }
                    }
                    if (step.type === 'LOADING') {
                        started_date = step.loading_start_real_datetime ?? step.loading_driver_start_datetime;
                        ended_date = step.loading_end_real_datetime ?? step.loading_driver_end_datetime;
                        expected_date = step.loading_expected_datetime
                        if (step.loading_report_state) {
                            state_icon = step.loading_report_state.id === 1 ? 'step_loading_icon_terminated_ntr' : 'step_loading_icon_terminated_not_ntr'
                        } else {
                            state_icon = 'step_loading_icon_not_started'
                        }
                    }
                    step.type === 'DELIVERY' ? step.site = step.delivery_site : step.site = step.loading_site;
                    if (step?.site?.latitude && step?.site?.longitude) {
                        latLngSteps.push([step?.site?.latitude, step?.site?.longitude]);
                        steps.push({
                            latLng: latLng(step?.site?.latitude, step?.site?.longitude),
                            id: step?.id,
                            site: step?.site?.name,
                            started_date,
                            ended_date,
                            expected_date,
                            step_number,
                            address: step?.site?.city,
                            state_icon: this.map[state_icon],
                            driver: this.truck?.is_used_by?.name
                        });
                    }
                });
                steps = _.sortBy(steps, 'step_number')
                this.refreshMap();
                this.refreshBounds();

                return steps;
            },
            truck_markers() {
                let markers = [];

                _.each(this.tracking, (track) => {
                    markers.push({
                        latLng: latLng(track.latitude, track.longitude),
                        transition_date: track.transition_date,
                        id: track.id,
                    });
                });

                this.refreshMap();

                return markers;
            },
            truck_polyline() {
                let markers = [];

                _.each(this.tracking, (track) => {
                    markers.push([track.latitude, track.longitude]);
                });

                this.refreshMap();

                return markers;
            },
        },
        watch: {
            step(val) {
                val === 2 ? this.getSteps() : null;
            }
        }
    }
</script>

<style lang="scss">
    @import "~leaflet/dist/leaflet.css";
    @import "~leaflet.markercluster/dist/MarkerCluster.css";
    @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
</style>
