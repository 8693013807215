<template>
    <div>
        <v-data-table
            :items="silos"
            :no-data-text="$t('global.no_data')"
            :loading-text="$t('global.loading_text')"
            :no-results-text="$t('global.no_results_text')"
            :headers="headers"
            :loading="loading"
            :items-per-page="10"
            :footer-props="{
                        'items-per-page-text': $t('global.rows_per_page')
                    }"
        >
            <template v-slot:top>
                <v-toolbar flat color="white">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark class="mb-2" @click="getAlerts()">
                        {{ $t('alert.refresh') }}
                    </v-btn>
                </v-toolbar>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ item.created_at | moment('L LT') }}
            </template>
            <template v-slot:item.concerned_element="{ item }">
                {{ item.alertable.code }}
            </template>
            <template v-slot:item.actions="{ item }">
                <v-btn
                    class="mr-2"
                    :disabled="dialog"
                    :loading="loading"
                    @click="resolve(item)"
                    color="primary"
                    outlined
                    :title="$t('alert.resolve_conflict')"
                >
                    {{ $t('alert.manage') }}
                </v-btn>
                <v-btn
                    @click="deleteItem(item)"
                    color="error"
                    icon
                    outlined
                    :title="$t('alert.delete')"
                >
                    <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
            </template>
        </v-data-table>

        <v-dialog v-model="dialogDelete" max-width="290">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('alert.sure') }}</v-card-title>
                <v-card-text>{{ $t('alert.irremediable') }}</v-card-text>
                <v-card-actions>
                    <v-spacer/>
                    <v-btn color="darken-1" text @click="dialogDelete = false">
                        {{ $t('alert.cancel') }}
                    </v-btn>
                    <v-btn color="warning darken-1" text @click="deleteAlert()">
                        {{ $t('alert.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" @close="close" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card elevation="0">
                <v-toolbar dark color="primary">
                    <v-btn icon dark @click="dialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{ $t('alert.modify_silo_information') }}</v-toolbar-title>
                </v-toolbar>
                <v-row no-gutters>
                    <v-col :align="'center'">
                        <v-list subheader>
                            <v-subheader class="title">{{ $t('alert.associated_site') }}</v-subheader>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('alert.address') }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ existing_site.address_1||$t('alert.none') }}<br>
                                        {{ existing_site.address_2 }}<br>
                                        {{ existing_site.address_3 }}<br><br>
                                        {{ existing_site.zip||$t('alert.none') }},
                                        {{ existing_site.city||$t('alert.none') }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('alert.gps') }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ existing_site.longitude||$t('alert.none') }}<br>
                                        {{ existing_site.lagitude||$t('alert.none') }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('alert.comment') }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ existing_site.comment||$t('alert.none') }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('alert.contact_avril') }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ existing_site.contact_avril_name||$t('alert.none') }}<br>
                                        {{ existing_site.contact_avril_mobile||$t('alert.none') }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('alert.route_comments') }}</v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ existing_site.route_comments||$t('alert.none') }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list>
                    </v-col>
                    <v-divider class="mx-4" vertical></v-divider>
                    <v-col :align="'center'">
                        <v-list>
                            <v-subheader class="title">{{ $t('alert.treated_silo') }}</v-subheader>
                        </v-list>
                        <v-card-text>
                            <v-form>
                                <v-text-field
                                    :label="$t('alert.code')"
                                    name="alert.code"
                                    prepend-icon="mdi-alpha-c-box"
                                    type="text"
                                    data-vv-name="alert.code"
                                    v-model="existing_silo.code"
                                ></v-text-field>
                                <v-text-field
                                    :label="$t('alert.name')"
                                    name="alert.name"
                                    id="alert.name"
                                    prepend-icon="mdi-rename-box"
                                    type="text"
                                    data-vv-name="alert.name"
                                    v-model="existing_silo.name"
                                ></v-text-field>
                                <v-text-field
                                    :label="$t('alert.security_level')"
                                    name="alert.security_level"
                                    id="alert.security_level"
                                    prepend-icon="mdi-security"
                                    type="text"
                                    data-vv-name="alert.security_level"
                                    v-model="existing_silo.security_level"
                                ></v-text-field>
                                <v-text-field
                                    :label="$t('alert.comment')"
                                    name="alert.comment"
                                    id="alert.comment"
                                    prepend-icon="mdi-comment-text-multiple-outline"
                                    type="text"
                                    data-vv-name="alert.comment"
                                    v-model="existing_silo.comments"
                                ></v-text-field>
                                <v-select
                                    :items="equipment"
                                    v-validate="'required'"
                                    :label="$t('alert.equipment')"
                                    required
                                    data-vv-name="equipment"
                                    prepend-icon="mdi-toolbox"
                                    chips
                                    item-text="name"
                                    item-value="id"
                                    id="alert.equipment"
                                    name="alert.equipment"
                                    v-model="existing_silo.equipment_id"
                                >
                                </v-select>
                                <v-select
                                    :items="accesses"
                                    v-validate="'required'"
                                    :label="$t('alert.accesses')"
                                    required
                                    data-vv-name="accesses"
                                    prepend-icon="mdi-access-point"
                                    chips
                                    item-text="name"
                                    item-value="id"
                                    id="alert.accesses"
                                    name="alert.accesses"
                                    v-model="existing_silo.access_id"
                                >
                                </v-select>
                                <v-select
                                    :items="location_states"
                                    v-validate="'required'"
                                    :label="$t('alert.location_state')"
                                    required
                                    data-vv-name="location_states"
                                    prepend-icon="mdi-crosshairs"
                                    chips
                                    item-text="name"
                                    item-value="id"
                                    id="alert.location_states"
                                    name="alert.location_states"
                                    v-model="existing_silo.location_state_id"
                                >
                                </v-select>
                                <v-select
                                    :items="silo_types"
                                    v-validate="'required'"
                                    :label="$t('alert.silo_type')"
                                    required
                                    data-vv-name="silo_types"
                                    prepend-icon="mdi-clock-outline"
                                    chips
                                    item-text="name"
                                    item-value="id"
                                    id="alert.silo_types"
                                    name="alert.silo_types"
                                    v-model="existing_silo.silo_type_id"
                                >
                                </v-select>
                            </v-form>
                        </v-card-text>
                    </v-col>
                </v-row>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="blue darken-1" text @click="close">{{ $t('user.cancel') }}</v-btn>
                    <v-btn @click="updateSilo(existing_silo)" :disabled="updating">
                        {{ $t('alert.update') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar
            v-model="snackbar"
            top
            :color="snackbar_type"
        >
            {{ snackbar_text }}
            <v-btn
                text
                @click="snackbar = false"
            >
                Close
            </v-btn>
        </v-snackbar>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import {
        ALERT,
        ALERTS,
        EQUIPMENTS,
        LOCATION_STATES,
        SILO,
        SILO_TYPES,
        SILOS,
        SITE,
        VEHICLE_ACCESSES
    } from "../../api";

    export default {
        name: 'verification-silos',
        data() {
            return {
                headers: [
                    {text: this.$t('alert.created_at'), value: 'created_at', sortable: false},
                    {text: this.$t('alert.name'), value: 'concerned_element', sortable: false},
                    {text: this.$t('alert.actions'), value: 'actions', sortable: false},
                ],
                loading: false,
                dialogDelete: false,
                dialog: false,
                itemInProgress: null,
                itemToResolve: {alertable: {}},
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                updating: false,
                existing_silo: {},
                existing_site: {},
                equipment: [],
                accesses: [],
                location_states: [],
                silo_types: [],
            }
        },
        computed: {
            ...mapGetters({
                alerts: 'alert/alerts',
            }),
            silos() {
                return this.alerts.silos;
            }
        },
        methods: {
            ...mapActions({'get': 'alert/get'}),
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Get silo
             *
             * @param {int} id_silo
             * @returns {Promise<unknown>}
             */
            getSilo(id_silo) {
                return new Promise((resolve, reject) => {
                    this.axios.get(this.replacePathParams(SILO, {silo: id_silo})).then(success => {
                        this.existing_silo = success.data;
                        resolve();
                    }).catch(() => {
                        this.showSnackbar('error', this.$t('global.error'));
                        reject();
                    })
                });
            },
            /**
             * Get the Silo, and the associated Site.
             *
             * @param {int} id_silo
             * @returns {Promise<unknown>}
             */
            async getSite(id_silo) {
                await this.getSilo(id_silo);
                return new Promise((resolve, reject) => {
                    this.axios.get(this.replacePathParams(SITE, {site: this.existing_silo.site_id})).then(success => {
                        this.existing_site = success.data;
                        resolve();
                    }).catch(() => {
                        this.showSnackbar('error', this.$t('global.error'));
                        reject();
                    })
                });
            },
            /**
             * @param {object} item
             */
            /**
             * Get all the equipments
             *
             * @returns {Promise<void>}
             */
            getEquipments() {
                this.axios.get(EQUIPMENTS).then((success) => {
                    this.equipment = success.data
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Get all the vehicle accesses
             *
             * @returns {Promise<void>}
             */
            getVehicleAccesses() {
                this.axios.get(VEHICLE_ACCESSES).then((success) => {
                    this.accesses = success.data
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Get all the location states
             *
             * @returns {Promise<void>}
             */
            getLocationStates() {
                this.axios.get(LOCATION_STATES).then((success) => {
                    this.location_states = success.data
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Get all the silo types
             *
             * @returns {Promise<void>}
             */
            getSiloTypes() {
                this.axios.get(SILO_TYPES).then((success) => {
                    this.silo_types = success.data
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Method used to validate the update on a silo. Multiple fields are null in the request.
             * So we remove them from the request.
             *
             * @param silo
             */
            validateSilo(silo) {
                for (let property in silo) {
                    if (silo.hasOwnProperty(property) && (silo[property] == null)) {
                        silo[property] = undefined;
                    }
                }
            },
            /**
             * Method used to update the silo
             *
             * @param silo
             */
            updateSilo(silo) {
                this.updating = true;

                this.validateSilo(silo);

                this.axios.patch(SILOS + '/' + silo.id, silo).then((success) => {
                    this.showSnackbar('success', this.$t('alert.silo_updated'));
                    this.removeAlert(this.itemToResolve);
                    this.close();
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.showSnackbar('warning', this.parseLaravelError(error));
                    } else {
                        this.showSnackbar('error', this.$t('global.error'));
                    }
                }).finally(() => {
                    this.updating = false;
                })
            },
            /**
             *  Closing dialog
             */
            close() {
                this.updating = false;
                this.dialog = false;
                this.itemInProgress = null;
            },
            /**
             * Method used to drop amert
             *
             * @param {object} item
             */
            removeAlert(item) {
                this.axios.delete(
                    this.replacePathParams(ALERT, {alert: item.id})).then(() => {
                    this.getAlerts();
                    this.showSnackbar('success', this.$t('alert.updated'));
                });
            },
            /**
             * Updating the datable after alert processing
             */
            getAlerts() {
                this.loading = true;
                this.get().catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                }).finally(() => {
                    this.loading = false;
                });
            },
            /**
             * Show delete modal
             *
             * @param {object} item
             */
            deleteItem(item) {
                this.itemToDelete = item;
                this.dialogDelete = true;
            },
            /**
             * Method used for the suppression of the alert
             */
            deleteAlert() {
                this.axios.delete(ALERTS + '/' + this.itemToDelete.id).then(() => {
                    this.getAlerts();
                    this.showSnackbar('success', this.$t('alert.deleted'));
                    this.dialogDelete = false;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            /**
             * Method to resolve conflict
             */
            async resolve(item) {
                this.loading = true;
                this.itemInProgress = item.id;

                await this.getSite(item.alertable_id);
                await this.getEquipments();
                await this.getVehicleAccesses();
                await this.getLocationStates();
                await this.getSiloTypes();

                this.itemToResolve = item;

                this.dialog = true;
                this.loading = false;
            },
        },
        watch: {
            dialog(val) {
                !val && this.close();
            }
        }
    }
</script>
