<template>
    <v-tooltip  v-if="biosecurity !== null" color="rgba(0,0,0,0.9)" bottom>
        <template v-slot:activator="{ on }">
            <img
                v-on="on"
                v-if="biosecurity.logo_url"
                height="32"
                style="max-height: 32px; max-width: 36px"
                :src="`${biosecurity.logo_url}`"
                :alt="$t('repository.pictogram')"
            >
        </template>
        {{ getBiosecurityDescription() }}
    </v-tooltip>
</template>


<script>
export default {
    name: "BiosecurityInfo",

    props:{
        biosecurity:{
            type: null|Object,
            required: true,
            default: () => ({})
        }
    },
    methods:{
        getBiosecurityDescription(){
            let code = this.biosecurity.code ?? '/'
            let name = this.biosecurity.name ?? '/'
            return code + ' - ' + name
        }
    }
}
</script>

<style scoped lang="scss">

.info_btn{
    display:flex;
    justify-content:center;
    align-items:center;
    max-width: 12px;
    width: 12px;
    min-height: 32px;
    max-height: 32px;
}

.img-btn {
    width: 50px !important;
    min-width: 50px !important;
    max-width: 50px !important;
}

</style>
