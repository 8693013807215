<template>

    <div>

        <v-data-table
            :no-data-text="$t('global.no_data')"
            :loading-text="$t('global.loading_text')"
            :no-results-text="$t('global.no_results_text')"
            :headers="headers"
            :items="biosecurities"
            :loading="loading"
            class="elevation-1"
            :items-per-page="10"
        >
            <template v-slot:top>

                <v-toolbar flat>

                    <v-dialog v-model="dialog" max-width="650px" persistent @input="$validator.reset()">

                        <template v-slot:activator="{ on }">
                            <v-btn color="primary" dark class="mb-2" v-on="on" @click="open">{{
                                $t('repository.new_item') }}
                            </v-btn>
                        </template>

                        <v-card>

                            <v-card-title>
                                <span class="headline">{{ formTitle }}</span>
                            </v-card-title>

                            <v-card-text>
                                <v-form>
                                    <v-text-field
                                        v-model="editedItem.code"
                                        :label="$t('repository.code')"
                                        data-vv-name="code"
                                        name="code"
                                        type="text"
                                        prepend-icon="mdi-numeric"
                                        :error-messages="errors.collect('code')"
                                        v-validate="'required|max:20'"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="editedItem.name"
                                        :label="$t('repository.name')"
                                        data-vv-name="name"
                                        name="name"
                                        type="text"
                                        prepend-icon="mdi-text"
                                        :error-messages="errors.collect('name')"
                                        v-validate="'max:255'"
                                    ></v-text-field>

                                    <v-select
                                        v-model="editedItem.level"
                                        :items="levels"
                                        :label="$t('repository.level')"
                                        required
                                        prepend-icon="mdi-counter"
                                        data-vv-name="level"
                                        :error-messages="errors.collect('level')"
                                        v-validate="'min_value:0|max_value:4'"
                                    />

                                    <v-autocomplete
                                        id="areas"
                                        v-model="editedItem.inside_cleanings"
                                        :error-messages="errors.collect('inside_cleanings')"
                                        :filter="filterCleanings"
                                        :items="sortedInsideCleanings"
                                        :label="$t('repository.cleaning_inside')"
                                        :search-input.sync="searchInsideCleaningsInput"
                                        chips
                                        data-vv-name="inside_cleanings"
                                        :item-text="formattedItemText"
                                        item-value="id"
                                        multiple
                                        name="inside_cleanings"
                                        prepend-icon="mdi-silverware-clean"
                                    >
                                        <template v-slot:item="{ item }">
                                            {{ formattedItemText(item) }}
                                        </template>
                                    </v-autocomplete>

                                    <v-autocomplete
                                        id="areas"
                                        v-model="editedItem.outside_cleanings"
                                        :error-messages="errors.collect('outside_cleanings')"
                                        :filter="filterCleanings"
                                        :items="sortedOutsideCleanings"
                                        :label="$t('repository.cleaning_outside')"
                                        :search-input.sync="searchOutsideCleaningsInput"
                                        chips
                                        data-vv-name="outside_cleanings"
                                        :item-text="formattedItemText"
                                        item-value="id"
                                        multiple
                                        name="outside_cleanings"
                                        prepend-icon="mdi-silverware-clean"
                                    >
                                        <template v-slot:item="{ item }">
                                            {{ formattedItemText(item) }})
                                        </template>
                                    </v-autocomplete>

                                    <div>
                                        <div class="v-label mt-4 d-flex" style="justify-content: space-between; align-items: center">
                                            <div>
                                                <v-icon class="icon-image">mdi-image</v-icon>
                                                {{ $t('repository.logo')  }}
                                            </div>

                                            <v-btn v-if="!editedItem.logo && editedItem.logo_url" color="red darken-1" text @click="openDialogLogoSuppression">
                                                {{ $t('repository.delete') }}
                                            </v-btn>
                                        </div>

                                        <div v-if="!editedItem.logo && editedItem.logo_url" class="text-center mt-3">
                                            <img
                                                height="32"
                                                :src="editedItem.logo_url"
                                                :alt="$t('repository.pictogram')"
                                            >
                                        </div>
                                        <v-file-input
                                            v-model="editedItem.logo"
                                            data-vv-name="logo"
                                            accept="image/*"
                                            prepend-icon="mdi-paperclip"
                                        />
                                    </div>

                                    <div>
                                        <div class="v-label mt-4 d-flex" style="justify-content: space-between; align-items: center">
                                            <div>
                                                <v-icon class="mdi-pdf-file-box">mdi-image</v-icon>
                                                {{ $t('repository.pdf_file')  }}
                                            </div>

                                            <v-btn v-if="!editedItem.pdf_file && editedItem.pdf_file_url" color="red darken-1" text @click="openDialogPdfFileSuppression">
                                                {{ $t('repository.delete') }}
                                            </v-btn>
                                        </div>

                                        <div v-if="!editedItem.pdf_file && editedItem.pdf_file_url" class="text-center mt-3">
                                            <span>
                                                {{ editedItem.pdf_file_name }}
                                            </span>
                                        </div>
                                        <v-file-input
                                            v-model="editedItem.pdf_file"
                                            data-vv-name="pdf_file"
                                            accept="application/pdf"
                                            prepend-icon="mdi-paperclip"
                                        />
                                    </div>
                                </v-form>
                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="blue darken-1" text @click="close">
                                    {{ $t('repository.cancel') }}
                                </v-btn>
                                <v-btn @click="save" :disabled="submit_button_is_disabled">
                                    {{ $t('repository.save') }}
                                </v-btn>
                            </v-card-actions>

                        </v-card>

                    </v-dialog>

                </v-toolbar>

            </template>

            <template v-slot:item.action="{ item }">

                <a :href="`${item.pdf_file_url}`" v-if="item.pdf_file_url"
                   class="mr-2"
                   download>
                    <v-icon
                        small
                        :title="$t('repository.see_pdf_file')"
                    >
                        mdi-eye
                    </v-icon>
                </a>

                <v-icon
                    small
                    class="mr-2"
                    :title="$t('repository.update')"
                    @click="editItem(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    :title="$t('repository.delete')"
                    @click="deleteItem(item)"
                >
                    mdi-delete
                </v-icon>


            </template>

            <template v-slot:item.logo="{ item }">
                    <img v-if="item.logo_url"
                         height="32"
                         :src="item.logo_url ? item.logo_url : null"
                         :alt="$t('repository.pictogram')"
                    >
            </template>

            <template v-slot:item.level="{ item }">
                <v-chip v-if="item.level === 0">
                    {{item.level}}
                </v-chip>

                <v-chip v-else-if="item.level === 1" color="#fccc0c" text-color="white">
                    {{item.level}}
                </v-chip>

                <v-chip v-else-if="item.level === 2" color="#e97839" text-color="white">
                    {{item.level}}
                </v-chip>

                <v-chip v-else-if="item.level === 3" color="#fb1616" text-color="white">
                    {{item.level}}
                </v-chip>

                <v-chip v-else-if="item.level === 4" color="black" text-color="white">
                    {{item.level}}
                </v-chip>

                <span v-else>-</span>
            </template>

            <template v-slot:item.inside_cleanings="{ item }">
                <v-chip v-for="inside_cleaning in item.inside_cleanings" :key="inside_cleaning" class="mx-1">
                    {{ getInsideCleaningById(inside_cleaning) }}
                </v-chip>
            </template>

            <template v-slot:item.outside_cleanings="{ item }">
                <v-chip v-for="outside_cleaning in item.outside_cleanings" :key="outside_cleaning" class="mx-1">
                    {{ getOutsideCleaningById(outside_cleaning) }}
                </v-chip>
            </template>

        </v-data-table>

        <v-dialog v-model="dialogDelete" max-width="290">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('repository.sure') }}</v-card-title>
                <v-card-text>{{ $t('repository.irremediable') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" text @click="dialogDelete = false">
                        {{ $t('repository.cancel') }}
                    </v-btn>
                    <v-btn color="warning darken-1" text @click="deleteBiosecurity()">
                        {{ $t('repository.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogLogoSuppression" max-width="290">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('repository.sure') }}</v-card-title>
                <v-card-text>{{ $t('repository.irremediable') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" text @click="dialogLogoSuppression = false">
                        {{ $t('repository.cancel') }}
                    </v-btn>
                    <v-btn color="warning darken-1" text @click="deleteLogo()">
                        {{ $t('repository.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogPdfFileSuppression" max-width="290">
            <v-card elevation="0">
                <v-card-title class="headline">{{ $t('repository.sure') }}</v-card-title>
                <v-card-text>{{ $t('repository.irremediable') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="darken-1" text @click="dialogPdfFileSuppression = false">
                        {{ $t('repository.cancel') }}
                    </v-btn>
                    <v-btn color="warning darken-1" text @click="deletePdfFile()">
                        {{ $t('repository.delete') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar v-model="snackbar" top :color="snackbar_type">
            {{ snackbar_text }}
            <v-btn text @click="snackbar = false">
                Close
            </v-btn>
        </v-snackbar>

    </div>
</template>

<script>
import {BIOSECURITIES, BIOSECURITY, INSIDE_CLEANINGS, OUTSIDE_CLEANINGS, RISK} from "../../api";
import VueBase64FileUpload from "vue-base64-file-upload";

export default {
        name: "biosecurities",
        components: {
            VueBase64FileUpload
        },
        data() {
            return {
                snackbar: false,
                snackbar_text: '',
                snackbar_type: null,
                headers: [
                    {text: this.$t('repository.code'), value: 'code', sortable: true},
                    {text: this.$t('repository.name'), value: 'name', sortable: true},
                    {text: this.$t('repository.level'), value: 'level', sortable: true},
                    {text: this.$t('repository.logo'), value: 'logo', sortable: false},
                    {text: this.$t('repository.cleaning_inside'), value: 'inside_cleanings', sortable: true},
                    {text: this.$t('repository.cleaning_outside'), value: 'outside_cleanings', sortable: true},
                    {text: this.$t('repository.actions'), value: 'action', sortable: false}
                ],
                loading: false,
                dialog: false,
                biosecurities: [],
                editedIndex: -1,
                editedItem: {
                    code: null,
                    name: null,
                    level: null
                },
                defaultItem: {
                    code: null,
                    name: null,
                    level: null
                },
                levels: [0,1,2,3,4],
                submit_button_is_disabled: false,
                itemToDelete: {},
                dialogDelete: false,
                dialogLogoSuppression: false,
                dialogPdfFileSuppression: false,
                searchInsideCleaningsInput: '',
                searchOutsideCleaningsInput: '',
                insideCleanings: [],
                outsideCleanings: []
            }
        },
        mounted() {
            this.getBiosecurities();
            this.getOutsideCleanings();
            this.getInsideCleanings();
        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? this.$t('repository.new_item') : this.$t('repository.edit_item')
            },
            sortedInsideCleanings() {
                return this.insideCleanings.slice().sort((a, b) => a.code.localeCompare(b.code));
            },
            sortedOutsideCleanings() {
                return this.outsideCleanings.slice().sort((a, b) => a.code.localeCompare(b.code));
            },
        },
        methods: {
            /**
             * Reset snackbar
             */
            resetSnackbar() {
                this.snackbar_type = null;
                this.snackbar_text = null;
                this.snackbar = false;
            },
            /**
             * Show snackbar
             *
             * @param {string} type
             * @param {string} text
             */
            showSnackbar(type, text) {
                this.snackbar_type = type;
                this.snackbar_text = text;
                this.snackbar = true;
            },
            /**
             * Get biosecurities
             */
            async getBiosecurities() {
                this.loading = true;
                let response = await this.axios.get(BIOSECURITIES);
                this.biosecurities = response.data
                this.loading = false;
            },
            save() {
                this.submit_button_is_disabled = true;

                this.$validator.validateAll().then((result) => {
                    if (result) {
                        if (this.editedIndex > -1) {
                            this.updateItem(this.editedItem);
                        } else {
                            this.createItem(this.editedItem);
                        }
                    } else {
                        this.submit_button_is_disabled = false;
                    }
                });
            },
            /**
             * Update vehicle fleet
             *
             * @param {object} biosecurity
             */
            updateItem(biosecurity) {
                let formatted_data = this.formatData(biosecurity)
                this.axios.post(
                    this.replacePathParams(BIOSECURITY, {biosecurity: biosecurity.id}),
                    formatted_data
                ).then((success) => {
                    Object.assign(this.biosecurities[this.editedIndex], success.data);
                    this.showSnackbar('success', this.$t('repository.updated'));
                    this.close()
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.showSnackbar('warning', this.parseLaravelError(error));
                    } else {
                        this.showSnackbar('error', this.$t('global.error'));
                    }
                }).finally(() => {
                    this.submit_button_is_disabled = false;
                })
            },
            /**
             * Show edit dialog
             *
             * @param {object} biosecurity
             */
            editItem(biosecurity) {
                this.editedIndex = this.biosecurities.indexOf(biosecurity);
                this.editedItem = Object.assign({}, biosecurity);
                this.dialog = true;
            },
            /**
             * Add risk
             *
             * @param {object} biosecurity
             */
            createItem(biosecurity) {
                let data = this.formatData(biosecurity)
                this.axios.post(
                    BIOSECURITIES,
                    data
                ).then((success) => {
                    this.biosecurities.push(success.data);
                    this.showSnackbar('success', this.$t('repository.created'));
                    this.close()
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.showSnackbar('warning', this.parseLaravelError(error));
                    } else {
                        this.showSnackbar('error', this.$t('global.error'));
                    }
                }).finally(() => {
                    this.submit_button_is_disabled = false;
                })
            },
            open() {
                this.editedItem = Object.assign({}, this.defaultItem);
                this.editedIndex = -1
            },
            /**
             * Hide add/edit item modal
             */
            close() {
                this.submit_button_is_disabled = false;
                this.dialog = false;
                setTimeout(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1
                }, 300)
            },
            /**
             * Show delete modal
             *
             * @param {object} biosecurity
             */
            deleteItem(biosecurity) {
                this.itemToDelete = biosecurity;
                this.dialogDelete = true;
            },
            /**
             * Remove vehicle fleet from database
             */
            deleteBiosecurity() {
                this.axios.delete(
                    this.replacePathParams(BIOSECURITY, {biosecurity: this.itemToDelete.id})
                ).then(() => {
                    this.biosecurities.splice(this.biosecurities.indexOf(this.itemToDelete), 1);
                    this.showSnackbar('success', this.$t('repository.deleted'));
                    this.dialogDelete = false;
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            formatData(biosecurity){
                let data = new FormData();

                data.append("id", biosecurity.id ?? null);

                if(biosecurity.pdf_file){
                    data.append("pdf_file", biosecurity.pdf_file);
                }

                if(biosecurity.logo){
                    data.append("logo", biosecurity.logo);
                }

                if(biosecurity.name){
                    data.append("name", biosecurity.name)
                }

                if(biosecurity.level !== null && typeof biosecurity.level !== 'undefined') {
                    data.append("level", biosecurity.level)
                }

                data.append("code", biosecurity.code)
                data.append("inside_cleanings", biosecurity.inside_cleanings ?? [])
                data.append("outside_cleanings", biosecurity.outside_cleanings ?? [])

                return data;
            },
            openDialogLogoSuppression(){
                this.dialogLogoSuppression = true
            },
            closeDialogLogoSuppression(){
                this.dialogLogoSuppression = false
            },
            openDialogPdfFileSuppression(){
                this.dialogPdfFileSuppression = true
            },
            closeDialogPdfFileSuppression(){
                this.dialogPdfFileSuppression = false
            },
            deleteLogo(){
                this.deleteMedia('/logo')
            },
            deletePdfFile(){
                this.deleteMedia('/pdf_file')
            },
            deleteMedia(type){
                this.axios.put(
                    this.replacePathParams(BIOSECURITY, {biosecurity: this.editedItem.id}) + type
                ).then((success) => {
                    Object.assign(this.biosecurities[this.editedIndex], success.data);
                    this.editedItem = success.data
                    this.showSnackbar('success', this.$t('repository.deleted'));
                    this.closeDialogPdfFileSuppression();
                    this.closeDialogLogoSuppression();
                }).catch(() => {
                    this.showSnackbar('error', this.$t('global.error'));
                });
            },
            async getInsideCleanings() {
                this.loading = true;
                let response = await this.axios.get(INSIDE_CLEANINGS);
                this.insideCleanings = response.data;
                this.loading = false;
            },
            async getOutsideCleanings() {
                this.loading = true;
                let response = await this.axios.get(OUTSIDE_CLEANINGS);
                this.outsideCleanings = response.data;
                this.loading = false;
            },
            filterCleanings(item, queryText) {
                const textOne = item.name.toLowerCase();
                const textTwo = item.code.toLowerCase();
                const searchText = queryText.toLowerCase();

                return textOne.indexOf(searchText) > -1 ||
                    textTwo.indexOf(searchText) > -1
            },
            formattedItemText(item) {
                return `(${item.code}) ${item.name}`;
            },
            getInsideCleaningById(id) {
                let inside_cleaning = this.insideCleanings.find(item => item.id === id);
                return inside_cleaning ? inside_cleaning.name : "-";
            },
            getOutsideCleaningById(id) {
                let outside_cleaning = this.outsideCleanings.find(item => item.id === id);
                return outside_cleaning ? outside_cleaning.name : "-";
            },
        }
    };
</script>
