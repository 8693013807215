var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{staticClass:"white"},[_c('v-subheader',[_c('p',{staticClass:"title mb-0"},[_vm._v("\n                    Get Orders\n                ")])])],1)],1),_vm._v(" "),_c('v-col',[_c('v-card',[_c('v-card-text',[_vm._v("\n                Date de début\n                "),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{ref:"startDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.start_date,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.start_date=$event},"update:return-value":function($event){_vm.start_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date","prepend-icon":"mdi-calendar","readonly":"","outlined":"","dense":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDateMenu),callback:function ($$v) {_vm.startDateMenu=$$v},expression:"startDateMenu"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.startDateMenu = false}}},[_vm._v("Cancel")]),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.startDateMenu.save(_vm.start_date)}}},[_vm._v("OK")])],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{ref:"startTimeMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.start_time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.start_time=$event},"update:return-value":function($event){_vm.start_time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Heure","prepend-icon":"mdi-clock-outline","readonly":"","outlined":"","dense":""},model:{value:(_vm.start_time),callback:function ($$v) {_vm.start_time=$$v},expression:"start_time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startTimeMenu),callback:function ($$v) {_vm.startTimeMenu=$$v},expression:"startTimeMenu"}},[_vm._v(" "),(_vm.startTimeMenu)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.startTimeMenu.save(_vm.start_time)}},model:{value:(_vm.start_time),callback:function ($$v) {_vm.start_time=$$v},expression:"start_time"}}):_vm._e()],1)],1)],1),_vm._v("\n                Date de fin\n                "),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{ref:"maxDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.max_date,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.max_date=$event},"update:return-value":function($event){_vm.max_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date","prepend-icon":"mdi-calendar","readonly":"","outlined":"","dense":""},model:{value:(_vm.max_date),callback:function ($$v) {_vm.max_date=$$v},expression:"max_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.maxDateMenu),callback:function ($$v) {_vm.maxDateMenu=$$v},expression:"maxDateMenu"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.max_date),callback:function ($$v) {_vm.max_date=$$v},expression:"max_date"}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.maxDateMenu = false}}},[_vm._v("Cancel")]),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.maxDateMenu.save(_vm.max_date)}}},[_vm._v("OK")])],1)],1)],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{ref:"maxTimeMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.max_time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.max_time=$event},"update:return-value":function($event){_vm.max_time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Heure","prepend-icon":"mdi-clock-outline","readonly":"","outlined":"","dense":""},model:{value:(_vm.max_time),callback:function ($$v) {_vm.max_time=$$v},expression:"max_time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.maxTimeMenu),callback:function ($$v) {_vm.maxTimeMenu=$$v},expression:"maxTimeMenu"}},[_vm._v(" "),(_vm.maxTimeMenu)?_c('v-time-picker',{attrs:{"format":"24hr","full-width":""},on:{"click:minute":function($event){return _vm.$refs.maxTimeMenu.save(_vm.max_time)}},model:{value:(_vm.max_time),callback:function ($$v) {_vm.max_time=$$v},expression:"max_time"}}):_vm._e()],1)],1)],1),_vm._v("\n                Site de chargement :\n                "),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","prepend-icon":"mdi-truck-check-outline"},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}})],1)],1),_vm._v("\n                Site de livraison :\n                "),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","prepend-icon":"mdi-truck-delivery-outline"},model:{value:(_vm.delivery),callback:function ($$v) {_vm.delivery=$$v},expression:"delivery"}})],1)],1),_vm._v("\n                Camion :\n                "),_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"outlined":"","dense":"","prepend-icon":"mdi-truck"},model:{value:(_vm.truck_id),callback:function ($$v) {_vm.truck_id=$$v},expression:"truck_id"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"4"}},[_c('v-menu',{ref:"filteringDateMenu",attrs:{"close-on-content-click":false,"return-value":_vm.filtering_date,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.filtering_date=$event},"update:return-value":function($event){_vm.filtering_date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date","prepend-icon":"mdi-calendar","readonly":"","outlined":"","dense":""},model:{value:(_vm.filtering_date),callback:function ($$v) {_vm.filtering_date=$$v},expression:"filtering_date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.filteringDateMenu),callback:function ($$v) {_vm.filteringDateMenu=$$v},expression:"filteringDateMenu"}},[_vm._v(" "),_c('v-date-picker',{attrs:{"no-title":"","scrollable":""},model:{value:(_vm.filtering_date),callback:function ($$v) {_vm.filtering_date=$$v},expression:"filtering_date"}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.filteringDateMenu = false}}},[_vm._v("Cancel")]),_vm._v(" "),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.filteringDateMenu.save(_vm.filtering_date)}}},[_vm._v("OK")])],1)],1)],1)],1),_vm._v(" "),_c('v-btn',{on:{"click":function($event){return _vm.getOrders()}}},[_vm._v("\n                    Enregistrer\n                ")])],1)],1)],1),_vm._v(" "),_c('v-snackbar',{attrs:{"top":"","color":_vm.snackbar_type},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n        "+_vm._s(_vm.snackbar_text)+"\n        "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("\n            Close\n        ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }