var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.site.reattached_sites,"loading-text":_vm.$t('global.loading_text'),"no-data-text":_vm.$t('global.no_data'),"no-results-text":_vm.$t('global.no_results_text'),"footer-props":{
                    'items-per-page-text': _vm.$t('global.rows_per_page')
                }},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.site.reattached_sites.length < 1 && _vm.site.alert === null)?_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-spacer'),_vm._v(" "),_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogAlert = true}}},[_vm._v("\n                    "+_vm._s(_vm.$t('site.create_alert'))+"\n                ")])],1):_vm._e()]},proxy:true},{key:"item.created_at",fn:function(ref){
                var item = ref.item;
return [_vm._v("\n            "+_vm._s(_vm._f("moment")(item.created_at,'L LT'))+"\n        ")]}},{key:"item.original_companies",fn:function(ref){
                var item = ref.item;
return _vm._l((item.original_companies),function(company,key){return (item.original_companies.length > 0)?_c('span',[_vm._v("\n                "+_vm._s(company.code)),(key+1 !== item.original_companies.length)?_c('span',[_vm._v(",")]):_vm._e()]):_vm._e()})}},{key:"item.contact",fn:function(ref){
                var item = ref.item;
return [_vm._v("\n            "+_vm._s(item.contact_avril_name)+"\n            "),(item.contact_avril_name && item.contact_avril_telephone)?_c('span',[_vm._v("\n            ,\n            ")]):_vm._e(),_vm._v("\n            "+_vm._s(item.contact_avril_telephone)+"\n            "),((item.contact_avril_name && item.contact_avril_mobile) || (item.contact_avril_telephone && item.contact_avril_mobile))?_c('span',[_vm._v("\n            ,\n            ")]):_vm._e(),_vm._v("\n            "+_vm._s(item.contact_avril_mobile)+"\n        ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [(item.original_companies.length)?_c('v-btn',{attrs:{"color":"secondary","tile":"","title":_vm.$t('alert.detach'),"outlined":""},on:{"click":function($event){return _vm.detachSite(item.id)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-arrow-down")]),_vm._v("\n                "+_vm._s(_vm.$t('alert.detach'))+"\n            ")],1):_vm._e()]}}])}),_vm._v(" "),_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialogAlert),callback:function ($$v) {_vm.dialogAlert=$$v},expression:"dialogAlert"}},[_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"headline"},[_vm._v(_vm._s(_vm.$t('site.attention')))]),_vm._v(" "),_c('v-card-text',[_vm._v(_vm._s(_vm.$t('site.confirmation_action')))]),_vm._v(" "),_c('v-card-actions',[_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"color":"darken-1","text":""},on:{"click":function($event){_vm.dialogAlert = false}}},[_vm._v("\n                    "+_vm._s(_vm.$t('alert.cancel'))+"\n                ")]),_vm._v(" "),_c('v-btn',{attrs:{"color":"warning darken-1","text":""},on:{"click":function($event){return _vm.sendAlert()}}},[_vm._v("\n                    "+_vm._s(_vm.$t('alert.yes'))+"\n                ")])],1)],1)],1),_vm._v(" "),_c('v-snackbar',{attrs:{"top":"","color":_vm.snackbar_type},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n        "+_vm._s(_vm.snackbar_text)+"\n        "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("\n            Close\n        ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }