import firebase from "firebase/app";
import 'firebase/firebase-messaging'

let firebaseConfig = {
    apiKey: "AIzaSyC_u4UtpKfL6nEYv0lbdPdW1Y655Uc6X4s",
    authDomain: "sandra-69a45.firebaseapp.com",
    databaseURL: "https://sandra-69a45.firebaseio.com",
    projectId: "sandra-69a45",
    storageBucket: "sandra-69a45.appspot.com",
    messagingSenderId: "906862099046",
    appId: "1:906862099046:web:4dbf70723b9996715bcba7",
    measurementId: "G-NS8M1X6MDT"
}
firebase.initializeApp(firebaseConfig)

export default firebase.messaging()
