import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Locale from '../locales.js';

Vue.use(VueI18n);

window.lang = document.documentElement.lang.substr(0, 2);

export default new VueI18n({
    locale: lang,
    messages: Locale
});
