<template>
    <v-col class="flex-column" cols="12">
        <v-row>
            <v-col
                class="white"
            >
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('contribution.exploitant_title') }}
                    </p>
                </v-subheader>
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('report.kpis'),
                                  disabled: false,
                                  href: '/#/dashboard',
                                },
                                {
                                  text: $t('contribution.exploitant_title'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                              ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2"
                v-show="logistic_areas.length > 1"
            >
                <v-autocomplete
                    id="logistic_area"
                    v-model="logistic_areas_choices"
                    :clearable="true"
                    :filter="filterAreas"
                    :items="orderedAreas"
                    :label="$t('tour.logistic_areas')"
                    :search-input.sync="searchAreasInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="logistic_area"
                    prepend-icon="mdi-target"
                >
                    <template v-slot:selection="{item, index}">
                        <v-chip v-if="index <= 0">
                            {{item.code}}
                        </v-chip>
                        <span v-if="index === 1">
                            (+ {{logistic_areas_choices.length - 1}})
                        </span>
                    </template>
                    <v-list
                        v-if="isSelectAllAreasAvailable"
                        slot="prepend-item"
                        class="pa-0"
                    >
                        <v-list-item-group v-model="toggleAreas" class="d-flex align-center pa-0">
                            <v-list-item>
                                <v-list-item-icon class="mr-2">
                                    <v-icon :color="logistic_areas_choices.length > 0 ? 'primary' : ''">
                                        {{ iconAreas }}
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('global.select_all') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                    <v-divider
                        slot="prepend-item"
                    ></v-divider>
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="2">
                <VueCtkDateTimePicker
                    id="start_date"
                    v-model="dates"
                    :button-color="'#e3672b'"
                    :color="'#e3672b'"
                    :custom-shortcuts="customShortcuts"
                    :format="date_format"
                    :formatted="'ll'"
                    :hint="$t('exchange.start_date')"
                    :label="$t('exchange.start_date')"
                    :locale="lang"
                    :range="true"
                />
            </v-col>
            <v-col cols="2">
                <v-autocomplete
                    id="transporter"
                    v-model="transporters_choices"
                    :clearable="true"
                    :disable-lookup="true"
                    :disabled="true"
                    :filter="filterTransporters"
                    :items="transporters"
                    :label="$t('dashboard.transporters')"
                    :search-input.sync="searchTransportersInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="transporter"
                    prepend-icon="mdi-truck"
                    @change="searchTransportersInput=''"
                >
                </v-autocomplete>
            </v-col>
            <v-col cols="2">
                <v-autocomplete
                    id="fleets"
                    v-model="fleet_choices"
                    :clearable="true"
                    :disable-lookup="true"
                    :disabled="true"
                    :items="fleets"
                    :label="$t('dashboard.fleets')"
                    :search-input.sync="searchFleetsInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="fleets"
                    prepend-icon="mdi-truck"
                    @change="searchFleetsInput=''"
                >
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="2" >
                <v-autocomplete
                    id="conditioning"
                    v-model="conditioning__choices"
                    :clearable="true"
                    :disable-lookup="true"
                    :disabled="true"
                    :items="conditionings"
                    :label="$t('dashboard.conditioning')"
                    :search-input.sync="searchConditioningsInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="transporter"
                    prepend-icon="mdi-truck"
                    @change="searchConditioningsInput=''"
                >
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="2">
                <v-btn color="primary" @click="applyFilter">{{ $t('tour.apply') }}</v-btn>
            </v-col>
        </v-row>
        <v-row class="mt-0">

        </v-row>
        <div class="d-flex justify-center align-center flex-row "   >
            <div :class="{
               'active-tab': active_tab === 'user',
               'inactive-tab': active_tab !== 'user'
               }"
                 class="pa-4 clickable text--white"
                 @click="active_tab='user'"
            ><v-icon color="black">mdi-account</v-icon></div>
            <div :class="{
               'active-tab': active_tab === 'site',
               'inactive-tab': active_tab !== 'site'
                }"
                 class="pa-4 clickable text--white"
                 @click="active_tab='site'">
                <v-icon color="black">
                    mdi-crosshairs-gps
                </v-icon>
            </div>
        </div>
        <v-card :loading="loading" class="d-block w-full mt-4" elevation="0">
            <div v-if="loading || items.length === 0" class=" d-flex justify-center align-center ">Aucune donnée</div>
            <table v-else  class="w-full m-auto">
                <tr class="table-header">
                    <th width="15%">ZL/Exploitant</th>
                    <th class="clickable"  @click="sortColumn('actionRatio')"> CR Non RAS
                        <span v-if="lastColumnSorted === 'actionRatio'">
                             <span v-if="sortValues[sortIndex%3] === 'asc'">
                                 <v-icon small>mdi-arrow-up</v-icon>
                             </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                    </th>
                    <th class="clickable" @click="sortColumn('gpsNotLocked')">GPS à vérrouiller
                        <span v-if="lastColumnSorted === 'gpsNotLocked'">
                            <span v-if="sortValues[sortIndex%3] === 'asc'">
                                <v-icon small>mdi-arrow-up</v-icon>
                            </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                    </th>
                    <th class="clickable" @click="sortColumn('newSiteRatio')">Actions sécurité
                        <span v-if="lastColumnSorted === 'newSiteRatio'">
                            <span v-if="sortValues[sortIndex%3] === 'asc'">
                                    <v-icon small>mdi-arrow-up</v-icon>
                            </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                    </th>
                    <th class="clickable"  @click="sortColumn('noRasRatio')">Rattachement des sites
                        <span v-if="lastColumnSorted === 'noRasRatio'">
                            <span v-if="sortValues[sortIndex%3] === 'asc'">
                                <v-icon small>mdi-arrow-up</v-icon>
                            </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                    </th>
                    <th @click="sortColumn('total')">Total
                        <span v-if="lastColumnSorted === 'total'">
                            <span v-if="sortValues[sortIndex%3] === 'asc'">
                                <v-icon small>mdi-arrow-up</v-icon>
                            </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                    </th>
                </tr>
                <template v-for="(item, code) in items">
                    <tr :key="item + code + item.name">
                        <td class="table-title font-weight-bold clickable"  @click="item.visibility = !item.visibility">
                            <span v-if="item.visibility" ><v-icon>mdi-minus</v-icon></span>
                            <span v-if="item.visibility === false" ><v-icon>mdi-plus</v-icon></span>
                            {{ item.main  }}</td>
                        <td :class="colorFromNumber(totalNoRas(item))">{{ totalNoRas(item) }}%</td>
                        <td :class="colorFromNumber(totalGpsNotLocked(item))">{{ totalGpsNotLocked(item) }}%</td>
                        <td :class="colorFromNumber(totalAction(item))">{{ totalAction(item) }}%</td>
                        <td :class="colorFromNumber(totalSiteRatio(item))">{{ totalSiteRatio(item) }}%</td>
                        <td :class="colorFromNumber(Math.round((totalNoRas(item) +  totalGpsNotLocked(item) + totalAction(item) + totalSiteRatio(item) ) / 4))">
                            {{ Math.round((totalNoRas(item) +  totalGpsNotLocked(item) + totalAction(item) + totalSiteRatio(item) ) / 4) }}%</td>
                    </tr>
                    <tr v-for="(index, name) in item.data" v-if="item.visibility" :key="name+code+item.main">
                        <td class="table-subtitle">{{name}}</td>
                        <td :class="colorFromNumber(Math.round(index.noRasRatio))">{{ Math.round(index.noRasRatio) }}%</td>
                        <td :class="colorFromNumber(Math.round(index.gpsNotLocked))">{{ Math.round(index.gpsNotLocked) }}%</td>
                        <td :class="colorFromNumber(Math.round(index.actionRatio))">{{ Math.round(index.actionRatio) }}%</td>
                        <td :class="colorFromNumber(Math.round(index.newSiteRatio))">{{ Math.round(index.newSiteRatio) }}%</td>
                        <td :class="colorFromNumber(Math.round((index.noRasRatio + index.gpsNotLocked + index.actionRatio + index.newSiteRatio) / 4))">
                            {{ Math.round((index.noRasRatio + index.gpsNotLocked + index.actionRatio + index.newSiteRatio) / 4)}}%</td>
                    </tr>
                </template>
            </table>
        </v-card>
    </v-col>
</template>

<style scoped>

table, tr, th{
    top: 64px;
}

table tr th{
    height: 4rem;
    background-color: white;
}

.clickable{
    cursor: pointer;
}

.w-full{
    width: 100%;
}
td, th {
    width: 100px;
    overflow: hidden;
}

tr {
    text-align: center;
}

tr:hover {
    background: rgba(0,0,0,.02);
}
.table-header {
    color: rgba(0, 0, 0, 0.6);
}

.table-title {
    text-align: left;
    padding-left: 1rem;
    line-height: 56px;
    background-color: #f1f1f1;
}

.table-subtitle {
    color: rgba(0,0,0,.7);
    text-align: left;
    padding-left: 1rem;
}
table {
    table-layout: fixed;
}
table, td, th {
    border-collapse: collapse;
    border: 1px solid black;
}
.m-auto {
    margin: auto;
}


.active-tab{
    border-bottom: 5px solid orangered;
}
.red-cell{
    background: #ffcdd2;
}
.green-cell{
    background: #c8e6c9;
}
.orange-cell{
    background: #ffe0b2 ;
}
.yellow-cell{
    background: #fff9c4 ;
}
.grey-cell{
    background: #e0e0e0 ;
}

</style>

<script>
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";
import {EXPLOITANT_CONTRIBUTION, USER_LOGISTIC_AREAS, VEHICLE_CONDITIONNINGS, VEHICLE_FLEETS} from "../../api";

export default {
    name: "ExploitantContribution",
    data(){
        return{
            dates: {
                start: this.$moment().add(-7, 'days').format('YYYY-MM-DD'),
                end: this.$moment().format('YYYY-MM-DD')
            },
            logistic_areas: [],
            logistic_areas_choices: [],
            transporters: [],
            searchTransportersInput: '',
            transporters_choices: [],
            number_exposition_inventory: null,
            customShortcuts: [
                {
                    key: "next_week", label: this.$t('dashboard.next_week'), value: () => {
                        return {
                            start: this.$moment().add(7, 'day').startOf('week'),
                            end: this.$moment().add(7, 'day').endOf('week')
                        }
                    }
                },
                {
                    key: 'tomorrow', label: this.$t('dashboard.tomorrow'), value: () => {
                        return {
                            start: this.$moment().startOf('day').add(1, "day"),
                            end: this.$moment().endOf('day').add(1, "day")
                        }
                    }
                },
                {key: 'today', label: this.$t('dashboard.today'), value: 'day'},
                {key: 'yesterday', label: this.$t('dashboard.yesterday'), value: '-day'},
                {key: '7_last_days', label: this.$t('dashboard.7_last_days'), value: 7},
                {key: 'last_week', label: this.$t('dashboard.last_week'), value: '-isoWeek'},
                {key: 'last_month', label: this.$t('dashboard.last_month'), value: '-month'},
                {
                    key: '12_last_months', label: this.$t('dashboard.12_last_months'), value: () => {
                        return {
                            start: this.$moment().add(-12, "month"),
                            end: this.$moment()
                        }
                    }
                },
            ],
            searchAreasInput: '',
            lang,
            date_format: 'Y-MM-DD H:mm',
            toggleAreas: null,

            active_tab:"site",
            loading: false,
            sortValues:["asc", "dsc", null],
            sortIndex:0,
            lastColumnSorted:null,
            items:[],
            global:{},

            conditioning__choices :[],
            conditionings :[],
            searchConditioningsInput :'',
            fleet_choices :[],
            fleets :[],
            searchFleetsInput :'',
        }
    },
    methods:{
        ...mapGetters({
            get_filters: "alert/get_filters"
        }),
        applyFilter() {
            this.setFilter(this.filter)
            this.getTable();
        },
        getTable(){
            this.loading = true
            this.axios.get(EXPLOITANT_CONTRIBUTION, {params:{...this.filter, order:this.active_tab}})
                .then(response => {
                    this.loading = false
                    this.items= response.data
                    this.items = _.forEach(this.items,(item, index) => {
                        const data = item
                        this.items[index] = {
                            main: index,
                            visibility: false,
                            data,
                        }
                    })
                    this.items = _.sortBy(this.items, item => item.main)
                })
        },
        ...mapActions({
            setFilter: 'alert/setFilter'
        }),
        colorFromNumber(number){
            number = parseInt(number)
            if(number === 0) {
                return 'grey-cell  '
            }
            if( _.inRange(number, 0, 50)) {
                return 'red-cell  '
            }
            if( _.inRange(number, 50, 85)) {
                return 'orange-cell  '
            }
            if( _.inRange(number, 85, 100)) {
                return 'yellow-cell '
            }
            if( number === 100) {
                return "green-cell "
            }
        },
        filterAreas(item, queryText) {
            let textOne = "";
            let textTwo = "";
            let searchText = "";
            if (item.code && item.name) {
                textOne = item.name.toLowerCase();
                textTwo = item.code.toLowerCase();
                searchText = queryText.toLowerCase();
            }

            return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
        },
        filterTransporters(item, queryText) {
            var text = "";
            var searchText = "";
            if (item.code) {
                text = item.code.toLowerCase();
                searchText = queryText.toLowerCase();
            }

            return text.indexOf(searchText) > -1;
        },
        async getLogisticAreas() {
            try {
                let success = await this.axios.get(USER_LOGISTIC_AREAS)
                this.logistic_areas = _.orderBy(success.data, ['name']);
                if (this.$auth.user().reference_logistic_areas.length > 0) {
                    this.logistic_areas_choices = _.map(this.$auth.user().reference_logistic_areas, 'id')
                } else if (this.logistic_areas.length === 1) {
                    this.logistic_areas_choices = [this.logistic_areas[0].id]
                }
                if (this.get_filters().logistic_areas_choices.length > 0){
                    this.logistic_areas_choices =  this.get_filters().logistic_areas_choices
                }
            }catch (e){
                this.showSnackbar('error', this.$t('global.error'));
            }
        },
        toPercent(value, total){
            return _.round((value * 100)/total);
        },
        totalNoRas(items){
            let count = 0
            _.each(items.data, item => {
                count += item.noRasRatio
            })
            if (count === 0){
                return 0
            }
            return Math.round(count / _.size(items.data))
        },
        totalGpsNotLocked(items){
            let count = 0
            _.each(items.data, item => {
                count += item.gpsNotLocked
            })
            if (count === 0){
                return 0
            }
            return  Math.round(count / _.size(items.data))
        },
        totalAction(items){
            let count = 0
            _.each(items.data, item => {
                count += item.actionRatio
            })
            if (count === 0){
                return 0
            }
            return Math.round(count / _.size(items.data))
        },
        totalSiteRatio(items){
            let count = 0
            _.each(items.data, item => {
                count += item.newSiteRatio
            })
            if (count === 0){
                return 0
            }
            return Math.round(count / _.size(items.data))
        },
        sortColumn(columnName){
            if (this.lastColumnSorted === columnName){
                this.sortIndex ++
            }else{
                this.sortIndex = 0
                this.lastColumnSorted = columnName
            }

            this.items = _.sortBy(this.items, item => {
                switch (columnName){
                    case "actionRatio":
                        return this.totalNoRas(item)
                    case "gpsNotLocked":
                        return this.totalGpsNotLocked(item)
                    case "newSiteRatio":
                        return this.totalAction(item)
                    case "noRasRatio":
                        return this.totalSiteRatio(item)
                    case "total":
                        return Math.round((this.totalNoRas(item) +  this.totalGpsNotLocked(item) + this.totalAction(item) + this.totalSiteRatio(item) ) / 4)
                }
            })
            switch(this.sortValues[this.sortIndex % 3]){
                case "dsc":
                    this.items =_.reverse(this.items)
                    break
                case "asc":
                    break
                default:
                    this.items = _.sortBy(this.items, item => item.main)
                    break
            }
        },
        async getFleets(){
            this.fleets = _.sortBy((await axios.get(VEHICLE_FLEETS)).data, ['name'])
        },
        async getConditionings(){
            this.conditionings = _.sortBy((await axios.get(VEHICLE_CONDITIONNINGS)).data, ['name'])
        }

    },
    computed:{
        orderedAreas() {
            return _.orderBy(this.logistic_areas, 'code')
        },
        isSelectAllAreasAvailable() {
            return this.searchAreasInput === '' || this.searchAreasInput === null;
        },
        filter() {
            return {
                dates: [this.dates.start, this.dates.end],
                logistic_areas_choices: this.logistic_areas_choices,
                transporters_choices: this.transporters_choices,
                fleet_choices: this.fleet_choices,
                conditioning__choices: this.conditioning__choices,
            }
        },
        iconAreas() {
            if (this.allAreas) return 'mdi-close-box'
            if (this.someAreas) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    watch:{
        active_tab(){
            this.getTable()
        },
        toggleAreas() {
            this.$nextTick(() => {
                if (this.allAreas) {
                    //this.logistic_areas_choices = []
                } else {
                    this.logistic_areas.forEach(element => {
                        if (!this.logistic_areas_choices.includes(element.id)) {
                            this.logistic_areas_choices.push(element.id);
                        }
                    });
                }
            })
        },
    },
    async mounted() {
        await this.getLogisticAreas()
        await this.getFleets()
        await this.getConditionings()
        this.conditioning__choices = this.$store.getters['alert/get_filters'].conditioning__choices
        this.fleet_choices = this.$store.getters['alert/get_filters'].fleet_choices
        this.transporters_choices = this.get_filters().transporters_choices
        if (this.$store.getters["alert/get_filters"].dates){
            this.dates.start = this.get_filters().dates[0]
            this.dates.end = this.get_filters().dates[1]
        }
        this.getTable()
    }
}
</script>
