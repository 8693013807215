<template>
    <div class="users-module">
        <v-row>
            <v-col
                class="white"
            >
                <!--
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('navigation.users') }}
                    </p>
                </v-subheader>
                -->
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('navigation.administration'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                                {
                                  text: $t('navigation.users'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                              ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-dialog v-model="dialog" max-width="500px" @input="$validator.reset()">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" class="mb-2" color="primary" dark>{{ $t('user.new') }}</v-btn>
                            </template>
                            <v-card
                                center
                            >
                                <v-card-title>
                                    <span class="headline">{{ formTitle }}</span>
                                </v-card-title>
                                <v-card-text>
                                    <v-form>
                                        <v-text-field
                                            v-model="editedItem.email"
                                            v-validate="'required|email'"
                                            :error-messages="errors.collect('email')"
                                            :label="$t('user.email')"
                                            data-vv-name="email"
                                            name="email"
                                            prepend-icon="mdi-email"
                                            type="text"
                                        ></v-text-field>
                                        <v-text-field
                                            id="name"
                                            v-model="editedItem.name"
                                            v-validate="'required|min:3'"
                                            :error-messages="errors.collect('name')"
                                            :label="$t('user.name')"
                                            data-vv-name="name"
                                            name="name"
                                            prepend-icon="mdi-account"
                                            type="text"
                                        ></v-text-field>
                                        <v-select
                                            id="roles"
                                            v-model="editedItem.roles"
                                            v-validate="'required'"
                                            :error-messages="errors.collect('roles')"
                                            :items="roles"
                                            :label="$t('user.roles')"
                                            chips
                                            data-vv-name="roles"
                                            item-text="name"
                                            item-value="id"
                                            multiple
                                            name="roles"
                                            prepend-icon="mdi-chess-queen"
                                            required
                                        >
                                            <template slot="selection" slot-scope="data">
                                                <v-chip>{{ $t(`role.${data.item.name}`) }}</v-chip>
                                            </template>
                                            <template slot="item" slot-scope="data">
                                                {{ $t(`role.${data.item.name}`) }}
                                            </template>
                                        </v-select>
                                        <v-autocomplete
                                            id="areas"
                                            v-model="editedItem.logistic_areas"
                                            v-validate="''"
                                            :error-messages="errors.collect('areas')"
                                            :filter="filterAreas"
                                            :items="orderedAreas"
                                            :label="$t('user.areas')"
                                            :search-input.sync="searchAreasInput"
                                            chips
                                            data-vv-name="areas"
                                            item-text="code"
                                            item-value="id"
                                            multiple
                                            name="areas"
                                            prepend-icon="mdi-crosshairs"
                                        >
                                            <v-list
                                                v-if="isSelectAllAreasAvailable"
                                                slot="prepend-item"
                                                class="pa-0"
                                            >
                                                <v-list-item-group v-model="toggleAreas" class="d-flex align-center pa-0">
                                                    <v-list-item>
                                                        <v-list-item-icon class="mr-2">
                                                            <v-icon :color="editedItem.logistic_areas.length > 0 ? 'primary' : ''">{{ iconAreas }}</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title>{{ $t('global.select_all') }}</v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                            <v-divider
                                                slot="prepend-item"
                                            ></v-divider>
                                            <template v-slot:selection="data">
                                                <v-chip class="mx-1">
                                                    {{ data.item.code }}
                                                </v-chip>
                                            </template>
                                            <template v-slot:item="data">
                                                {{ data.item.code }} ({{ data.item.name }})
                                            </template>
                                        </v-autocomplete>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="blue darken-1" text @click="close">{{ $t('user.cancel') }}</v-btn>
                                    <v-btn :disabled="submit_button_is_disabled" @click="save">
                                        {{ formTitle }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-spacer></v-spacer>
                        <v-text-field
                            v-model="search"
                            :label="$t('user.search')"
                            append-icon="mdi-account-search"
                            hide-details
                            single-line
                        ></v-text-field>
                    </v-card-title>

                    <v-data-table
                        :footer-props="{'items-per-page-options': [10,20,100],
                        'items-per-page-text': $t('global.rows_per_page')}"
                        :headers="headers"
                        :items="users"
                        :items-per-page="15"
                        :loading="loading"
                        :loading-text="$t('global.loading_text')"
                        :no-data-text="$t('global.no_data')"
                        :no-results-text="$t('global.no_results_text')"
                        :options.sync="options"
                        :search="search"
                        :server-items-length="totalUsers"
                        class="elevation-1"
                        multi-sort
                    >
                        <template v-slot:item.roles="{ item }">
                            <v-chip v-for="role in item.roles" :key="role.id" class="mx-1">
                                {{ $t(`role.${role.name}`) }}
                            </v-chip>
                        </template>
                        <template v-slot:item.logistic_areas="{ item }">
                            {{ item.logistic_areas.length }}
                        </template>
                        <template v-slot:item.last_login_date="{ item }">
                            {{ item.last_login_date | moment('L LT') }}
                        </template>
                        <template v-slot:item.created_at="{ item }">
                            {{ item.created_at | moment('L LT') }}
                        </template>
                        <template v-slot:item.updated_at="{ item }">
                            {{ item.updated_at | moment('L LT') }}
                        </template>
                        <template v-slot:item.action="{ item }">
                            <v-icon
                                :title="$t('user.edit')"
                                class="mr-2"
                                small
                                @click="editItem(item)"
                            >
                                mdi-pencil
                            </v-icon>
                            <!-- Retrait à la demande de AVRIL 17/06/2020
                           <v-icon
                               small
                               :title="$t('user.delete')"
                               @click="deleteItem(item)"
                           >
                               mdi-delete
                           </v-icon>
                           -->
                       </template>
                   </v-data-table>
               </v-card>
           </v-col>
       </v-row>
       <v-dialog v-model="dialogDelete" max-width="290">
           <template v-slot:activator="{ on }">
           </template>
           <v-card elevation="0">
               <v-card-title class="headline">{{ $t('user.sure') }}</v-card-title>
               <v-card-text>{{ $t('user.irremediable') }}</v-card-text>
               <v-card-actions>
                   <v-spacer></v-spacer>
                   <v-btn color="darken-1" text @click="dialogDelete = false">
                       {{ $t('user.cancel') }}
                   </v-btn>
                   <v-btn color="warning darken-1" text @click="deleteUser()">
                       {{ $t('user.delete') }}
                   </v-btn>
               </v-card-actions>
           </v-card>
       </v-dialog>
       <v-snackbar
           v-model="snackbar"
           :color="snackbar_type"
           top
       >
           {{ snackbar_text }}
           <v-btn
               text
               @click="snackbar = false"
           >
               Close
           </v-btn>
       </v-snackbar>
   </div>
</template>

<style lang="scss" scoped>
</style>

<script>
import _ from 'lodash'
import {AREAS, ROLES, USERS} from "../api";

export default {
    name: "user",
    data() {
        return {
            snackbar: false,
            snackbar_text: '',
            snackbar_type: null,
            headers: [
                {text: this.$t('user.id'), value: 'id', sortable: false},
                {text: this.$t('user.name'), value: 'name', sortable: true},
                {text: this.$t('user.email'), value: 'email', sortable: true},
                   {text: this.$t('user.roles'), value: 'roles', sortable: false},
                   {text: this.$t('user.areas'), value: 'logistic_areas', sortable: false},
                   {text: this.$t('user.last_app_version'), value: 'last_app_version', sortable: true},
                   {text: this.$t('user.last_used_truck'), value: 'last_used_truck', sortable: true},
                   {text: this.$t('user.last_login_date'), value: 'last_login_date', sortable: true},
                   {text: this.$t('user.created_at'), value: 'created_at', sortable: true},
                   {text: this.$t('user.updated_at'), value: 'updated_at', sortable: true},
                   {text: this.$t('user.actions'), value: 'action', sortable: false},
               ],
               loading: true,
               options: {},
               page: 1,
               totalUsers: 0,
               users: [],
               areas: [],
               toggleAreas: null,
               searchAreasInput: '',
               search: '',
               editedItem: {
                   name: '',
                   email: '',
                   roles: [],
                   logistic_areas: [],
               },
               editedIndex: -1,
               defaultItem: {
                   name: '',
                   email: '',
                   roles: [1],
                   logistic_areas: [],
               },
               dialog: false,
               dialogDelete: false,
               submit_button_is_disabled: false,
               roles: [],
               userToDelete: null,
                base_roles: []
           }
       },
       mounted() {
           this.getRoles();
           this.getAreas();
       },
       computed: {
           formTitle() {
               return this.editedIndex === -1 ? this.$t('user.new') : this.$t('user.edit')
           },
           orderedAreas() {
               return _.orderBy(this.areas, 'name')
           },
           isSelectAllAreasAvailable() {
               return this.searchAreasInput === '' || this.searchAreasInput === null;
           },
           allAreas () {
               return this.editedItem.logistic_areas.length === this.orderedAreas.length
           },
           someAreas () {
               return this.editedItem.logistic_areas.length > 0 && !this.allAreas
           },
           iconAreas () {
               if (this.allAreas) return 'mdi-close-box';
               if (this.someAreas) return 'mdi-minus-box';
               return 'mdi-checkbox-blank-outline';
           },
       },
       methods: {
           /**
            * Reset snackbar
            */
           resetSnackbar() {
               this.snackbar_type = null;
               this.snackbar_text = null;
               this.snackbar = false;
           },
           /**
            * Show snackbar
            *
            * @param {string} type
            * @param {string} text
            */
           showSnackbar(type, text) {
               this.snackbar_type = type;
               this.snackbar_text = text;
               this.snackbar = true;
           },
           /**
            * Filter areas drop-down
            */
           filterAreas (item, queryText) {
               const textOne = item.name.toLowerCase();
               const textTwo = item.code.toLowerCase();
               const searchText = queryText.toLowerCase();

               return textOne.indexOf(searchText) > -1 ||
                   textTwo.indexOf(searchText) > -1
           },
           /**
            * Get users from API
            */
           getUsers() {
               this.loading = true;

               const {sortBy, sortDesc, page, itemsPerPage} = this.options;

               let sort = '';
               if (Array.isArray(sortBy)) {
                   _.each(sortBy, (elem, key) => {
                       let order = sortDesc[key] ? '-' : '';
                       sort += order + elem + ',';
                   })
               } else {
                   let order = sortDesc ? '-' : '';
                   sort += order + sortBy;
               }

               this.axios.get(
                   USERS,
                   {
                       params: {
                           page,
                           per_page: itemsPerPage,
                           sort,
                           "filter[anywhere]": this.search,
                           include: 'logistic_areas'
                       }
                   }
               ).then((success) => {
                   this.users = success.data.data;
                   this.totalUsers = success.data.total;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               }).finally(() => {
                   this.loading = false;
               })
           },
           /**
            * Get roles from API
            */
           getRoles() {
               this.axios.get(ROLES).then((success) => {
                   this.roles = success.data.filter(itemRole => itemRole.name !== 'transporter');
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               })
           },
           /**
            * Get areas from API
            */
           getAreas() {
               this.axios.get(AREAS).then((success) => {
                   this.areas = success.data;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               })
           },
           editItem(user) {


               let tmpRoles = Object.assign({}, user.roles);
               let tmpAreas = Object.assign({}, user.logistic_areas);
               this.base_roles = _.toArray(user.roles).filter(role => role.name === 'transporter').map(item => item.id)
               user.roles = _.map(_.toArray(user.roles).filter(roleItem => roleItem.name !== 'transporter'), (role) => {
                   return role.id
               });
               user.logistic_areas = _.map(user.logistic_areas, (area) => {
                   return area.id
               });
               this.editedIndex = this.users.indexOf(user);
               this.editedItem = Object.assign({}, user);
               this.dialog = true;
               user.roles = tmpRoles;
               user.logistic_areas = tmpAreas;
           },
           deleteItem(user) {
               this.userToDelete = user;
               this.dialogDelete = true;
           },
           deleteUser() {
               this.axios.delete(
                   USERS + '/' + this.userToDelete.id,
               ).then(() => {
                   this.users.splice(this.users.indexOf(this.userToDelete), 1);
                   this.showSnackbar('success', this.$t('user.deleted'));
                   this.dialogDelete = false;
               }).catch(() => {
                   this.showSnackbar('error', this.$t('global.error'));
               });
           },
           close() {
               this.submit_button_is_disabled = false;
               this.dialog = false;
               this.editedItem = Object.assign({}, this.defaultItem);
               this.editedIndex = -1
           },
           save() {
               this.submit_button_is_disabled = true;

               this.$validator.validateAll().then((result) => {
                   if (result) {
                       if (this.editedIndex > -1) {
                           this.updateItem(this.editedItem);
                       } else {
                           this.createItem(this.editedItem);
                       }
                   } else {
                       this.submit_button_is_disabled = false;
                   }
               });
           },
           updateItem(user) {
               user.roles = [...user.roles, ...this.base_roles]
               this.axios.patch(
                   USERS + '/' + user.id,
                   user
               ).then((success) => {
                   Object.assign(this.users[this.editedIndex], success.data);
                   this.showSnackbar('success', this.$t('user.updated'));
                   this.close()
               }).catch((error) => {
                   if (error.response.status === 422) {
                       this.showSnackbar('warning', this.parseLaravelError(error));
                   } else {
                       this.showSnackbar('error', this.$t('global.error'));
                   }
               }).finally(() => {
                   this.submit_button_is_disabled = false;
               })
           },
           createItem(user) {
               this.axios.post(
                   USERS,
                   user
               ).then((success) => {
                   this.users.push(success.data);
                   this.showSnackbar('success', this.$t('user.created'));
                   this.close()
               }).catch((error) => {
                   if (error.response.status === 422) {
                       this.showSnackbar('warning', this.parseLaravelError(error));
                   } else {
                       this.showSnackbar('error', this.$t('global.error'));
                   }
               }).finally(() => {
                   this.submit_button_is_disabled = false;
               })
           }
       },
       watch: {
           options: {
               handler() {
                   this.getUsers();
               }
               ,
               deep: true,
           },
           search: _.debounce(function () {
               this.getUsers();
           }, 500),
           dialog(val) {
               val || this.close()
           },
           /**
            * Select all areas or not
            */
           toggleAreas () {
               this.$nextTick(() => {
                   if (this.allAreas) {
                       this.editedItem.logistic_areas = []
                   } else {
                       this.orderedAreas.forEach(element => {
                           if (!this.editedItem.logistic_areas.includes(element.id)) {
                               this.editedItem.logistic_areas.push(element.id);
                           }
                       });
                   }
               })
           },
       }
   }
</script>
<style lang="scss">

.users-module {
    .v-breadcrumbs li:last-child a {
        font-size: 1.25rem !important;
        color: rgba(0, 0, 0, .6) !important;
        font-weight: 500;
    }

    .v-list--dense .v-list-item {
        .v-list-item__title,
        .v-list-item__subtitle {
            font-size: 0.9rem;
        }
    }
}
</style>
