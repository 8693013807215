export const MAIN = '/dashboard';

export const AUTH_LOGIN = '/api/auth/login';
export const AUTH_LOGOUT = '/api/auth/logout';
export const AUTH_ME = '/api/auth/me';
export const AUTH_REFRESH = '/api/auth/refresh';

export const SEND_PASSWORD_RESET = '/api/email_reset_password';
export const PASSWORD_RESET = '/api/reset_password';

export const THREADS = '/api/messages';
export const MESSAGES = '/api/messages';
export const UNREAD_MESSAGES = '/api/messages/unread';

export const AREAS = '/api/logistic_areas';
export const AREA = '/api/logistic_areas/:logistic_area';
export const AREA_SITES = '/api/logistic_areas/:logistic_area/sites';

export const AREA_TRUCKS = '/api/logistic_areas/trucks';
export const AREA_TRANSPORTERS = '/api/logistic_areas/transporters';

export const DRIVERS = 'api/drivers';
export const DRIVER = 'api/drivers/:driver';

export const TRUCKS = 'api/trucks';
export const TRUCK = 'api/trucks/:truck';

export const TIME_SLOTS = 'api/time_slots';
export const TIME_SLOT = 'api/time_slots/:time_slot';

export const USERS = '/api/users';
export const USER_CONTACTS = '/api/users/:user/contacts';

export const PROFILES = '/api/profiles';

export const MOBILE_PARAMETERS = '/api/mobile_parameters';
export const MOBILE_PARAMETER = '/api/mobile_parameters/:mobile_parameter';

export const BACK_OFFICE_PARAMETERS = '/api/back_office_parameters';
export const BACK_OFFICE_PARAMETER = '/api/back_office_parameters/:back_office_parameter';

export const VEHICLE_ACCESSES = '/api/vehicle_accesses';
export const VEHICLE_ACCESS = '/api/vehicle_accesses/:vehicle_access';

export const SILO_TYPES = '/api/silo_types';
export const SILO_TYPE = '/api/silo_types/:silo_type';

export const EQUIPMENTS = '/api/equipment';
export const EQUIPMENT = '/api/equipment/:equipment';

export const CATEGORIES = '/api/categories';
export const CATEGORY = '/api/categories/:category';

export const DELIVERY_REPORT_STATES = '/api/delivery_report_states';
export const DELIVERY_REPORT_STATE = '/api/delivery_report_states/:delivery_report_state';

export const LOADING_REPORT_STATES = '/api/loading_report_states';
export const LOADING_REPORT_STATE = '/api/loading_report_states/:loading_report_state';

export const SIGNATURE_STATES = '/api/signature_states';
export const SIGNATURE_STATE = '/api/signature_states/:signature_state';

export const EXCHANGES = '/api/exchanges';

export const ALERTS = '/api/alerts';
export const ALERT = '/api/alerts/:alert';
export const COUNT_ALERTS = '/api/alerts/count';
export const SECURITY_ALERT_SILO = '/api/alerts/securityAlertSilo';
export const DUPLICATE_SITE = '/api/alerts/duplicateSite';
export const GPS = '/api/alerts/gps';
export const SITE_UPDATE_REQUEST = '/api/alerts/siteUpdateRequest';
export const REPORT = '/api/alerts/report';

export const SITES = '/api/sites';
export const SITE = '/api/sites/:site';
export const SITE_HISTORY = '/api/sites/:site/history';
export const SITE_DETACH = '/api/sites/:site/detach';
export const SITE_ALERT = '/api/sites/:site/alert';

export const SITE_ATTACHMENT = '/api/sites/:site/attachment';

export const SITE_UPDATE_REQUESTS = '/api/site_update_requests';

export const USER_SITES = '/api/sites/user';
export const USER_LOGISTIC_AREAS = '/api/logistic_areas/user';

export const LOGISTIC_AREAS = '/api/logistic_areas';
export const LOGISTIC_AREAS_USER = '/api/logistic_areas/user';

export const LOCATION_STATES = '/api/location_states/';

export const SILO = '/api/silos/:silo';
export const SILOS = '/api/silos';
export const SILOS_MERGE = '/api/silos/:siloToMergeId/merge';
export const SILO_ORDERS_HISTORY = '/api/silos/:silo/history';
export const SILO_PICTURE = '/api/silos/:silo/picture';

export const STEPS_TOURS = '/api/steps/tours';
export const TOURS_TRACKING = '/api/steps/tours_tracking';
export const TOURS_TRACKING_EXPORT = '/api/steps/tours_tracking/export';
export const STEP = '/api/steps/:step'
export const STEP_BIOSECURITY = '/api/steps/:step/biosecurity'
export const STEP_DRIVER = '/api/steps/:step/driver'
export const STEP_ORDER_LINES = '/api/steps/:step/tracking_order_lines'

export const COMPANY = '/api/companies/:company'

export const TRUCK_STEPS = '/api/trucks/:truck/steps';

export const ROLES = '/api/roles';
export const ROLE = '/api/roles/:role';

export const INSTRUCTIONS = 'api/instructions';
export const INSTRUCTION = 'api/instructions/:instruction';

export const SECURITY_LEVELS = 'api/security_levels';

export const RISKS = 'api/risks';
export const RISK = 'api/risks/:risk';

export const MANUAL_ACTION_TYPES = 'api/action_types/manual';
export const ACTION_TYPES = 'api/action_types';

export const ACTIONS = 'api/actions';
export const ACTIONS_FORM = 'api/actions/form';
export const ACTIONS_PHOTOS = 'api/actions/:action/photos';

export const VERSIONS = '/api/versions';
export const VERSION = '/api/versions/:version';

export const TRACKING_TRUCKS_LIVE = '/api/tracking_trucks/live'
export const TRACKING_TRUCK = '/api/tracking_trucks/:truck'

export const TRANSPORTERS = '/api/transporters';
export const TRANSPORTER = '/api/transporters/:transporter';

export const REPORT_STATISTICS = '/api/kpis/report_statistics'
export const SECURITY_LEVEL_STATISTICS = '/api/kpis/security_levels'
export const DELIVERY_STEPS_STATISTICS = '/api/kpis/delivery_silos'
export const ACTIONS_SILOS_STATISTICS = '/api/kpis/actions_silos'
export const ACTIONS_SILOS_DATATABLE = '/api/kpis/actions_silos_datatable'
export const KPI_REPORTS = '/api/kpis/reports'
export const DRIVER_CONTRIBUTION = '/api/kpis/driver_contribution'
export const EXPLOITANT_CONTRIBUTION = '/api/kpis/exploitant_contribution'


export const NOT_INVENTORYABLE_SILO = '/api/not_inventoryable_silos/:not_inventoryable_silo'
export const NOT_INVENTORYABLE_SILOS = '/api/not_inventoryable_silos'


export const VEHICLE_FLEETS = '/api/vehicle_fleets';
export const VEHICLE_FLEET = '/api/vehicle_fleets/:vehicle_fleet';

export const VEHICLE_CONDITIONNINGS = '/api/vehicle_conditionnings';
export const VEHICLE_CONDITIONNING = '/api/vehicle_conditionnings/:vehicle_conditionning';

export const INSIDE_CLEANINGS = '/api/inside_cleanings';
export const INSIDE_CLEANING = '/api/inside_cleanings/:inside_cleaning'

export const OUTSIDE_CLEANINGS = '/api/outside_cleanings';
export const OUTSIDE_CLEANING = '/api/outside_cleanings/:outside_cleaning';

export const BIOSECURITIES = '/api/biosecurities';
export const BIOSECURITY = '/api/biosecurities/:biosecurity';

export const SILOS_IMAGES = '/api/sites/:site/silos_images'
export const SILO_IMAGES = '/api/silos/:silo/images'

export const TRANSPORTER_USERS = '/api/user_transporters'
export const TRANSPORTER_USER = '/api/user_transporters/:user'

export const QR_CODE_HISTORY = '/api/QrCodeScans'
