<template>
    <div>
        <p class="mt-10 text-center">
            <span class="title">
                404 |
            </span>
            <span class="subtitle-1">
                Not found
            </span>
        </p>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
    export default {
        name: "not-found"
    }
</script>
