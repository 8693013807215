<template>
    <div>
        <v-row>
            <v-col
                class="white"
            >
                <v-subheader>
                    <p class="title mb-0">
                        {{ $t('report.reports') }}
                    </p>
                </v-subheader>
                <v-breadcrumbs
                    :items="[
                                {
                                  text: $t('report.kpis'),
                                  disabled: false,
                                  href: '/#/dashboard',
                                },
                                {
                                  text: $t('report.reports'),
                                  disabled: true,
                                  href: 'breadcrumbs_dashboard',
                                },
                              ]"
                    class="mt-0 pt-0 pl-4"
                ></v-breadcrumbs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="2"
                v-show="logistic_areas.length > 1"
            >
                <v-autocomplete
                    id="logistic_area"
                    v-model="logistic_areas_choices"
                    :clearable="true"
                    :filter="filterAreas"
                    :items="orderedAreas"
                    :label="$t('tour.logistic_areas')"
                    :search-input.sync="searchAreasInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="logistic_area"
                    prepend-icon="mdi-target"
                >
                    <template v-slot:selection="{item, index}">
                        <v-chip v-if="index <= 0">
                            {{item.code}}
                        </v-chip>
                        <span v-if="index === 1">
                            (+ {{logistic_areas_choices.length - 1}})
                        </span>
                    </template>
                    <v-list
                        v-if="isSelectAllAreasAvailable"
                        slot="prepend-item"
                        class="pa-0"
                    >
                        <v-list-item-group v-model="toggleAreas" class="d-flex align-center pa-0">
                            <v-list-item>
                                <v-list-item-icon class="mr-2">
                                    <v-icon :color="logistic_areas_choices.length > 0 ? 'primary' : ''">
                                        {{ iconAreas }}
                                    </v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>{{ $t('global.select_all') }}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                    <v-divider
                        slot="prepend-item"
                    ></v-divider>
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="2">
                <VueCtkDateTimePicker
                    id="start_date"
                    v-model="dates"
                    :button-color="'#e3672b'"
                    :color="'#e3672b'"
                    :custom-shortcuts="customShortcuts"
                    :format="date_format"
                    :formatted="'ll'"
                    :hint="$t('exchange.start_date')"
                    :label="$t('exchange.start_date')"
                    :locale="lang"
                    :only-date="true"
                    :range="true"
                />
            </v-col>
            <v-col cols="2">
                <v-autocomplete
                    id="transporter"
                    v-model="transporters_choices"
                    :clearable="true"
                    :filter="filterTransporters"
                    :items="transporters"
                    :label="$t('dashboard.transporters')"
                    :search-input.sync="searchTransportersInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="transporter"
                    prepend-icon="mdi-truck"
                    @change="searchTransportersInput=''"
                >
                </v-autocomplete>
            </v-col>

            <v-col cols="2">
                <v-autocomplete
                    id="fleets"
                    v-model="fleet_choices"
                    :clearable="true"
                    :items="fleets"
                    :label="$t('dashboard.fleets')"
                    :search-input.sync="searchFleetsInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="fleets"
                    prepend-icon="mdi-truck"
                    @change="searchFleetsInput=''"
                >
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="2" >
                <v-autocomplete
                    id="conditioning"
                    v-model="conditioning__choices"
                    :clearable="true"
                    :items="conditionings"
                    :label="$t('dashboard.conditioning')"
                    :search-input.sync="searchConditioningsInput"
                    class="px-4 mb-3"
                    item-text="code"
                    item-value="id"
                    multiple
                    name="transporter"
                    prepend-icon="mdi-truck"
                    @change="searchConditioningsInput=''"
                >
                    <template slot="item" slot-scope="data">
                        {{ data.item.code }} ({{ data.item.name }})
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="2">
                <v-btn color="primary" @click="applyFilter">{{ $t('tour.apply') }}</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-flex justify-center align-center flex-row" cols="12">
                <div :class="{
               'active-tab': reports_choice === 'driver',
               'inactive-tab': reports_choice !== 'driver'
               }"
                     class="pa-4 clickable text--white"
                     @click="reports_choice='driver'"
                ><v-icon color="black">mdi-account</v-icon></div>
                <div :class="{
               'active-tab': reports_choice === 'truck',
               'inactive-tab': reports_choice !== 'truck'
                }"
                     class="pa-4 clickable text--white"
                     @click="reports_choice='truck'">
                    <v-icon color="black">
                        mdi-truck
                    </v-icon>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card :elevation="0" :loading="loading"  class="d-block w-full mt-4">
                    <div v-if="loading || data.length === 0" class=" d-flex justify-center align-center ">Aucune donnée</div>
                    <table v-else class="m-auto">
                        <tr class="table-header">
                            <th style="z-index: 200"></th>
                            <th class="clickable" @click="sortColumn('delivery_steps_number')" >Nb d'étape de chargement
                                 <span v-if="lastColumnSorted === 'delivery_steps_number'">
                            <span v-if="sortValues[sortIndex%3] === 'asc'">
                                <v-icon small>mdi-arrow-up</v-icon>
                            </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                            </th>
                            <th class="clickable" @click="sortColumn('delivery_reports')">Cr chargement validé
                                 <span v-if="lastColumnSorted === 'delivery_reports'">
                            <span v-if="sortValues[sortIndex%3] === 'asc'">
                                <v-icon small>mdi-arrow-up</v-icon>
                            </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                            </th>
                            <th class="clickable" @click="sortColumn('delivery_not_bad_reports')">Dont non RAS
                                 <span v-if="lastColumnSorted === 'delivery_not_bad_reports'">
                            <span v-if="sortValues[sortIndex%3] === 'asc'">
                                <v-icon small>mdi-arrow-up</v-icon>
                            </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                            </th>
                            <th class="clickable" @click="sortColumn('loading_steps_number')">Nb d'étape livraison
                                 <span v-if="lastColumnSorted === 'loading_steps_number'">
                            <span v-if="sortValues[sortIndex%3] === 'asc'">
                                <v-icon small>mdi-arrow-up</v-icon>
                            </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                            </th>
                            <th class="clickable" @click="sortColumn('loading_reports')">Cr livraison validé
                                 <span v-if="lastColumnSorted === 'loading_reports'">
                            <span v-if="sortValues[sortIndex%3] === 'asc'">
                                <v-icon small>mdi-arrow-up</v-icon>
                            </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                            </th>
                            <th class="clickable" @click="sortColumn('loading_not_bad_reports')">Dont non RAS
                                 <span v-if="lastColumnSorted === 'loading_not_bad_reports'">
                            <span v-if="sortValues[sortIndex%3] === 'asc'">
                                <v-icon small>mdi-arrow-up</v-icon>
                            </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                            </th>
                            <th class="clickable" @click="sortColumn('filled_gps')">GPS renseigné
                                 <span v-if="lastColumnSorted === 'filled_gps'">
                            <span v-if="sortValues[sortIndex%3] === 'asc'">
                                <v-icon small>mdi-arrow-up</v-icon>
                            </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                            </th>
                            <th class="clickable" @click="sortColumn('no_filled_gps')">GPS non renseigné
                                 <span v-if="lastColumnSorted === 'no_filled_gps'">
                            <span v-if="sortValues[sortIndex%3] === 'asc'">
                                <v-icon small>mdi-arrow-up</v-icon>
                            </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                            </th>
                            <th class="clickable" @click="sortColumn('percent_delivery_report')">% Cr chargement validé
                                 <span v-if="lastColumnSorted === 'percent_delivery_report'">
                            <span v-if="sortValues[sortIndex%3] === 'asc'">
                                <v-icon small>mdi-arrow-up</v-icon>
                            </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                            </th>
                            <th class="clickable" @click="sortColumn('percent_loading_report')">% Cr livraison validé
                                 <span v-if="lastColumnSorted === 'percent_loading_report'">
                            <span v-if="sortValues[sortIndex%3] === 'asc'">
                                <v-icon small>mdi-arrow-up</v-icon>
                            </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                            </th>
                            <th class="clickable" @click="sortColumn('percent_completed_report')">% Cr validé
                                 <span v-if="lastColumnSorted === 'percent_completed_report'">
                            <span v-if="sortValues[sortIndex%3] === 'asc'">
                                <v-icon small>mdi-arrow-up</v-icon>
                            </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                            </th>
                            <th class="clickable" @click="sortColumn('all_not_bad_report')">Dont Cr non RAS en Nb
                                 <span v-if="lastColumnSorted === 'all_not_bad_report'">
                            <span v-if="sortValues[sortIndex%3] === 'asc'">
                                <v-icon small>mdi-arrow-up</v-icon>
                            </span>
                            <span v-else-if="sortValues[sortIndex%3] === 'dsc'">
                                <v-icon small>mdi-arrow-down</v-icon>
                            </span>
                            <span v-else></span>
                        </span>
                            </th>
                        </tr>
                        <template v-for="(items, key, index) in data">
                            <tr  :key=" index + items.main">
                                <th class="table-title clickable" @click="items.visibility = !items.visibility">
                                    <span v-if="items.visibility"><v-icon>mdi-minus</v-icon></span>
                                    <span v-if="items.visibility === false"><v-icon>mdi-plus</v-icon></span>
                                    {{items.main}}
                                </th>
                                <td>{{ sumRowByLabel(items, 'delivery_steps_number') }}</td>
                                <td>{{ sumRowByLabel(items, 'delivery_reports') }}</td>
                                <td>{{ sumRowByLabel(items, 'delivery_not_bad_reports') }}</td>
                                <td>{{ sumRowByLabel(items, 'loading_steps_number') }}</td>
                                <td>{{ sumRowByLabel(items, 'loading_reports') }}</td>
                                <td>{{ sumRowByLabel(items, 'loading_not_bad_reports') }}</td>
                                <td>{{ sumRowByLabel(items, 'filled_gps') }}</td>
                                <td>{{ sumRowByLabel(items, 'no_filled_gps') }}</td>
                                <td
                                    :class="colorFromNumber(
                                        ratioRow(sumRowByLabel(items, 'delivery_reports'), sumRowByLabel(items, 'delivery_steps_number'))
                                        )">
                                    {{ ratioRow(sumRowByLabel(items, 'delivery_reports'), sumRowByLabel(items, 'delivery_steps_number'))}} %
                                </td>
                                <td
                                    :class="colorFromNumber(
                                        ratioRow(sumRowByLabel(items, 'loading_reports'), sumRowByLabel(items, 'loading_steps_number'))
                                        )">
                                    {{ ratioRow(sumRowByLabel(items, 'loading_reports'), sumRowByLabel(items, 'loading_steps_number'))}} %
                                </td>

                                <td
                                    :class="colorFromNumber(ratioRow(
                                        (
                                            sumRowByLabel(items, 'delivery_reports') +
                                            sumRowByLabel(items, 'loading_reports')
                                        ),
                                        (
                                            sumRowByLabel(items, 'delivery_steps_number') +
                                            sumRowByLabel(items, 'loading_steps_number')
                                        )
                                    ))">

                                    {{ ratioRow(
                                        (
                                            sumRowByLabel(items, 'delivery_reports') +
                                            sumRowByLabel(items, 'loading_reports')
                                        ),
                                        (
                                            sumRowByLabel(items, 'delivery_steps_number') +
                                            sumRowByLabel(items, 'loading_steps_number')
                                        )
                                    ) }} %

                                </td>

                                <td>{{ sumRowByLabel(items ,'all_not_bad_report') }}</td>
                            </tr>
                            <tr v-for="(item,k, index) in items.data" v-if="items.visibility" :key="k+index+key">
                                <th class="table-subtitle">{{ item.main }}</th>
                                <td>{{ item.data.delivery_steps_number }}</td>
                                <td>{{ item.data.delivery_reports }}</td>
                                <td>{{ item.data.delivery_not_bad_reports }}</td>
                                <td>{{ item.data.loading_steps_number }}</td>
                                <td>{{ item.data.loading_reports }}</td>
                                <td>{{ item.data.loading_not_bad_reports }}</td>
                                <td>{{ item.data.filled_gps }}</td>
                                <td>{{ item.data.no_filled_gps }}</td>
                                <td :class="colorFromNumber(Math.round(item.data.percent_delivery_report))">
                                    {{ Math.round(item.data.percent_delivery_report) }} %</td>
                                <td :class="colorFromNumber(Math.round(item.data.percent_loading_report))">
                                    {{ Math.round(item.data.percent_loading_report) }} %</td>
                                <td :class="colorFromNumber(Math.round(item.data.percent_completed_report))">
                                    {{ Math.round(item.data.percent_completed_report) }} %</td>
                                <td>{{ item.data.all_not_bad_report }}</td>
                            </tr>
                        </template>
                    </table>
               </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import _ from "lodash";
import {mapActions, mapGetters} from "vuex";
import {AREA_TRANSPORTERS, KPI_REPORTS, USER_LOGISTIC_AREAS, VEHICLE_CONDITIONNINGS, VEHICLE_FLEETS} from "../../api";


export default {
    name: 'DeliverySteps',
    data() {
        return {
            dates: {
                start: this.$moment().add(-7, 'days').format('YYYY-MM-DD'),
                end: this.$moment().format('YYYY-MM-DD')
            },
            logistic_areas: [],
            logistic_areas_choices: [],
            transporters: [],
            searchTransportersInput: '',
            transporters_choices: [],
            number_exposition_inventory: null,
            customShortcuts: [
                {
                    key: "next_week", label: this.$t('dashboard.next_week'), value: () => {
                        return {
                            start: this.$moment().add(7, 'day').startOf('week'),
                            end: this.$moment().add(7, 'day').endOf('week')
                        }
                    }
                },
                {
                    key: 'tomorrow', label: this.$t('dashboard.tomorrow'), value: () => {
                        return {
                            start: this.$moment().startOf('day').add(1, "day"),
                            end: this.$moment().endOf('day').add(1, "day")
                        }
                    }
                },
                {key: 'today', label: this.$t('dashboard.today'), value: 'day'},
                {key: 'yesterday', label: this.$t('dashboard.yesterday'), value: '-day'},
                {key: '7_last_days', label: this.$t('dashboard.7_last_days'), value: 7},
                {key: 'last_week', label: this.$t('dashboard.last_week'), value: '-isoWeek'},
                {key: 'last_month', label: this.$t('dashboard.last_month'), value: '-month'},
                {
                    key: '12_last_months', label: this.$t('dashboard.12_last_months'), value: () => {
                        return {
                            start: this.$moment().add(-12, "month"),
                            end: this.$moment()
                        }
                    }
                },
            ],
            searchAreasInput: '',
            lang,
            date_format: 'YYYY-MM-DD',
            toggleAreas: null,
            data: [],
            reports_choice: 'driver',
            loading: false,

            sortValues:["asc", "dsc", null],
            sortIndex:0,
            lastColumnSorted:null,

            conditioning__choices :[],
            conditionings :[],
            searchConditioningsInput :'',
            fleet_choices :[],
            fleets :[],
            searchFleetsInput :'',
        }
    },
    async mounted() {
        await this.getTransporters();
        await this.getLogisticAreas();
        await this.getFleets()
        await this.getConditionings()
        this.transporters_choices = this.$store.getters["alert/get_filters"].transporters_choices
        if (this.$store.getters["alert/get_filters"].dates){
            this.dates.start = this.$store.getters["alert/get_filters"].dates[0]
            this.dates.end = this.$store.getters["alert/get_filters"].dates[1]
        }
        this.conditioning__choices = this.$store.getters['alert/get_filters'].conditioning__choices
        this.fleet_choices = this.$store.getters['alert/get_filters'].fleet_choices
        this.getKpi();
    },
    computed: {
        orderedAreas() {
            return _.orderBy(this.logistic_areas, 'code')
        },
        isSelectAllAreasAvailable() {
            return this.searchAreasInput === '' || this.searchAreasInput === null;
        },
        filter() {
            return {
                dates: [this.dates.start, this.dates.end],
                logistic_areas_choices: this.logistic_areas_choices,
                transporters_choices: this.transporters_choices,
                fleet_choices: this.fleet_choices,
                conditioning__choices: this.conditioning__choices,
            }
        },
        iconAreas() {
            if (this.allAreas) return 'mdi-close-box'
            if (this.someAreas) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },
    methods: {
        ...mapGetters({
            get_filters: "alert/get_filters"
        }),
        filterAreas(item, queryText) {
            let textOne = "";
            let textTwo = "";
            let searchText = "";
            if (item.code && item.name) {
                textOne = item.name.toLowerCase();
                textTwo = item.code.toLowerCase();
                searchText = queryText.toLowerCase();
            }

            return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
        },
        filterTransporters(item, queryText) {
            var text = "";
            var searchText = "";
            if (item.code) {
                text = item.code.toLowerCase();
                searchText = queryText.toLowerCase();
            }

            return text.indexOf(searchText) > -1;
        },
        ...mapActions({
            setFilter: 'alert/setFilter'
        }),
        applyFilter() {
            this.setFilter(this.filter)
            this.getKpi();
        },
        async getKpi() {
            this.loading = true;
            let {data} = await this.axios(KPI_REPORTS, {params: {...this.filter, order: this.reports_choice}});
            this.data = data
            this.data = _.sortBy(_.forEach(this.data,(item, index) => {
                const data = item
                this.data[index] = {
                    main: index,
                    visibility: false,
                    data : _.sortBy(_.map(data, (subItem, subIndex) =>{
                        return{
                            main: subIndex,
                            data: subItem
                        }
                    }), item => {return item.main})
                }
            }), item => {
                return item.main
            })
            this.loading = false;
        },
        async getTransporters() {
            let {data} = await this.axios.get(
                AREA_TRANSPORTERS,
                {
                    params: {
                        logistic_areas: this.logistic_areas_choices
                    },
                }
            );
            this.transporters_choices = [];
            this.transporters = _.orderBy(data, ['code']);
            this.transporters_choices = this.get_filters().transporters_choices

        },
        async getLogisticAreas() {
            try {
                let success = await this.axios.get(USER_LOGISTIC_AREAS)
                this.logistic_areas = _.orderBy(success.data, ['name']);
                if (this.$auth.user().reference_logistic_areas.length > 0) {
                    this.logistic_areas_choices = _.map(this.$auth.user().reference_logistic_areas, 'id')
                } else if (this.logistic_areas.length === 1) {
                    this.logistic_areas_choices = [this.logistic_areas[0].id]
                }
                if (this.get_filters().logistic_areas_choices.length > 0){
                    this.logistic_areas_choices =  this.get_filters().logistic_areas_choices
                }
            }catch (e){
                this.showSnackbar('error', this.$t('global.error'));
            }
        },
        sumRowByLabel(items, label){
            let count = 0
            _.each(items.data, item => {
                count += item.data[label]
            })
            return count
        },
        ratioRowByLabel(items,label){
            let count = this.sumRowByLabel(items ,label)
            if (count === 0){
                return 0
            }
            return Math.round(count / _.size(items.data))
        },
        ratioRow(item, divider){
            if (divider === 0){
                return 0
            }
            return  Math.round((item / divider) * 100)
        },
        colorFromNumber(number){
            number = parseInt(number)
            if(number === 0) {
                return 'grey-cell  '
            }
            if( _.inRange(number, 0, 50)) {
                return 'red-cell  '
            }
            if( _.inRange(number, 50, 85)) {
                return 'orange-cell  '
            }
            if( _.inRange(number, 85, 100)) {
                return 'yellow-cell '
            }
            if( number === 100) {
                return "green-cell "
            }
        },
        sortColumn(columnName){
            if (this.lastColumnSorted === columnName){
                this.sortIndex ++
            }else{
                this.sortIndex = 0
                this.lastColumnSorted = columnName
            }

            this.data = _.sortBy(this.data, item => {
                switch (columnName){
                    case "delivery_steps_number":
                    case "delivery_reports":
                    case "delivery_not_bad_reports":
                    case "loading_steps_number":
                    case "loading_reports":
                    case "loading_not_bad_reports":
                    case "filled_gps":
                    case "no_filled_gps":
                    case "all_not_bad_report":
                        return this.sumRowByLabel(item, columnName)
                    case "percent_delivery_report":
                    case "percent_loading_report":
                    case "percent_completed_report":
                        return this.ratioRowByLabel(item, columnName)
                }
            })

            switch(this.sortValues[this.sortIndex % 3]){
                case "dsc":
                    this.data =_.reverse(this.data)
                    break
                case "asc":
                    break;
                default:
                    this.data = _.sortBy(this.data, item => item.main)
                    break
            }
        },
        async getFleets(){
            this.fleets = _.sortBy((await axios.get(VEHICLE_FLEETS)).data, ['name'])
        },
        async getConditionings(){
            this.conditionings = _.sortBy((await axios.get(VEHICLE_CONDITIONNINGS)).data, ['name'])
        }
    },
    watch: {
        toggleAreas() {
            this.$nextTick(() => {
                if (this.allAreas) {
                    this.logistic_areas_choices = []
                } else {
                    this.logistic_areas.forEach(element => {
                        if (!this.logistic_areas_choices.includes(element.id)) {
                            this.logistic_areas_choices.push(element.id);
                        }
                    });
                }
            })
        },
        reports_choice(){
            this.getKpi()
        }
    },
}
</script>

<style scoped>

table, tr, th{
    top: 64px;
}

table tr th{
    background-color: white;
}
.red-cell{
    background: #ffcdd2;
}
.green-cell{
    background: #c8e6c9;
}
.orange-cell{
    background: #ffe0b2 ;
}
.yellow-cell{
    background: #fff9c4 ;
}
.grey-cell{
    background: #e0e0e0 ;
}
.active-tab{
    border-bottom: 5px solid orangered;
}
.clickable{
    cursor: pointer;
}
table {
    table-layout: fixed;
}
table, td, th {
    border-collapse: collapse;
    border: 1px solid black
}

.no-border {
    border: 0 transparent;
}

.m-auto {
    margin: auto;
}

th {
    padding: 5px;
}

td, th {
    width: 100px;
    overflow: hidden;
}

tr {
    text-align: center;
}

tr:hover {
    background: rgba(0,0,0,.02);
}

.table-header {
    color: rgba(0, 0, 0, 0.6);
}

.table-title {
    text-align: left;
    line-height: 38px;
    width: 20%;
    background-color: #f1f1f1;
}

.table-subtitle {
    padding-left: 1rem;
    color: rgba(0,0,0,.5);
}

.w-full {
    width: 100%;
}
</style>
