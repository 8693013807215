var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"no-data-text":_vm.$t('global.no_data'),"loading-text":_vm.$t('global.loading_text'),"no-results-text":_vm.$t('global.no_results_text'),"headers":_vm.headers,"items":_vm.history,"footer-props":{
                        'items-per-page-text': _vm.$t('global.rows_per_page')
                    }},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("moment")(_vm.getOperationDate(item),'L LT')))])]}},{key:"item.driver",fn:function(ref){
                    var item = ref.item;
return [(item.user)?_c('span',[_vm._v(" "+_vm._s(item.user.name))]):_c('span',[_vm._v(" "+_vm._s(item.driver))])]}},{key:"item.vehicle",fn:function(ref){
                    var item = ref.item;
return [(item.truck)?_c('span',[_vm._v(" "+_vm._s(item.truck.code))]):_vm._e()]}},{key:"item.product",fn:function(ref){
                    var item = ref.item;
return [(item.category.media[0])?_c('span',[_c('img',{attrs:{"height":"32","src":("storage/" + (item.category.media[0].id) + "/" + (item.category.media[0].name)),"alt":_vm.$t('repository.pictogram')}})]):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.getProduct(item))+" ")])]}},{key:"item.loading_company",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.loading_company.name)+" ")])]}},{key:"item.delivered_quantity",fn:function(ref){
                    var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getQuantity(item))+" ")])]}}])}),_vm._v(" "),_c('v-snackbar',{attrs:{"top":"","color":_vm.snackbar_type},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v("\n        "+_vm._s(_vm.snackbar_text)+"\n        "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("\n            Close\n        ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }